import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { formatIgnoringTimezone } from "@repo/lib";
import { ItemContent, Item, ItemMainContent } from "@/components/list";

export const UserTrackableLinkListItemQueryFragment = graphql(/* GraphQL */ `
  fragment UserTrackableLinkListItemQueryFragment on usuariosLinksRastreamentos {
    nome
    descricao
    dataCadastro
    codUsuarioLinkRastreamento
    sistema
  }
`);

export const UserTrackableLinksListItem = (props: {
  userTrackableLinkItem: FragmentType<typeof UserTrackableLinkListItemQueryFragment>;
}): JSX.Element => {
  const trackableLink = useFragment(
    UserTrackableLinkListItemQueryFragment,
    props.userTrackableLinkItem,
  );

  const dataCadastro = formatIgnoringTimezone(
    new Date(trackableLink.dataCadastro),
    "dd/MM/yyyy",
  );

  return (
    <Item
      linkProps={{
        to: "/settings/assist/user-trackable-links/$userTrackableLinkId",
        params: { userTrackableLinkId: trackableLink.codUsuarioLinkRastreamento },
      }}
    >
      <ItemContent>
        <p className="text-sm text-neutral-500 line-clamp-1">{dataCadastro}</p>
        <ItemMainContent>
          {trackableLink.nome}
          {trackableLink.sistema ? <DefaultLinkTag /> : null}
        </ItemMainContent>
        <p className="text-sm text-neutral-500 line-clamp-2 mt-1">
          {trackableLink.descricao}
        </p>
      </ItemContent>
    </Item>
  );
};

const DefaultLinkTag = (): JSX.Element => {
  return <span className="text-xs font-normal"> (PADRÃO)</span>;
};
