import type { SVGIconProps } from "@repo/icons";
import { CpsFeedbackBubble, type CpsFeedbackBubbleFill } from "corpus";
import type { FunctionComponent, ReactNode } from "react";
import { cn } from "@repo/lib";
import { Button, type ButtonProps } from "@/components/button";

interface EmptyStateRootProps {
  children: ReactNode;
}

export const EmptyStateRoot = ({ children }: EmptyStateRootProps): JSX.Element => {
  return (
    <div className="items-center justify-center flex flex-col gap-6 mx-auto max-w-[360px]">
      {children}
    </div>
  );
};

interface EmptyStateIconProps {
  icon: FunctionComponent<SVGIconProps>;
  fill?: CpsFeedbackBubbleFill;
}

export const EmptyStateIcon = ({
  icon,
  fill = "secondary",
}: EmptyStateIconProps): JSX.Element => {
  return (
    <div className="pt-2">
      <CpsFeedbackBubble Icon={icon} fill={fill} />
    </div>
  );
};

interface EmptyStateImageProps {
  icon: FunctionComponent<SVGIconProps>;
  alt?: string;
  className?: string;
}

export const EmptyStateImage = ({
  icon: Icon,
  alt = "",
  className = "",
}: EmptyStateImageProps): JSX.Element => {
  return <Icon aria-label={alt} className={cn("w-[328px] h-[290px]", className)} />;
};

interface EmptyStateChildrenProps {
  children: ReactNode;
}

export const EmptyStateTitle = ({ children }: EmptyStateChildrenProps): JSX.Element => {
  return (
    <p className="text-center text-xl text-neutral-600 font-medium w-full">{children}</p>
  );
};

export const EmptyStateDescription = ({
  children,
}: EmptyStateChildrenProps): JSX.Element => {
  return <p className="text-center text-md text-neutral-500 w-full">{children}</p>;
};

export const EmptyStateBody = ({ children }: EmptyStateChildrenProps): JSX.Element => {
  return <div className="bg-neutral-50 gap-6 rounded-40 w-full">{children}</div>;
};

type EmptyStateButtonProps = ButtonProps;

export const EmptyStateButton = ({ ...props }: EmptyStateButtonProps): JSX.Element => {
  return <Button {...props} />;
};

interface EmptyStateInfoTableProps {
  elements: {
    icon: FunctionComponent<SVGIconProps>;
    info: string;
    title?: string;
  }[];
}

export const EmptyStateInfoTable = ({
  elements,
}: EmptyStateInfoTableProps): JSX.Element => {
  return (
    <ul className="flex flex-col gap-2 px-4 py-6 text-sm font-normal text-neutral-600">
      {elements.map(({ icon: Icon, info, title }) => {
        return (
          <li className="flex gap-2" key={info}>
            <div className="flex items-center">
              <Icon className="fill-primary-400" size={24} />
            </div>
            <span className="self-end">
              <b className="font-semibold">{title} </b>
              {info}
            </span>
          </li>
        );
      })}
    </ul>
  );
};
