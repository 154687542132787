import { Bank, Left, User1 } from "@repo/icons";
import { createFileRoute, useRouter, useSearch } from "@tanstack/react-router";
import { z } from "zod";
import { MenuList, type MenuListItem } from "@/components/menu-list";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { ChangeOwnershipDrawer } from "@/components/change-ownership-drawer";
import { ChangeBankingInstitutionDrawer } from "@/components/change-banking-institution";

const changeBankAccountActionSchema = z.object({
  action: z.string().optional(),
});

export const ChangeBankAccountOrOwnershipPage = (): JSX.Element => {
  const searchParams = useSearch({
    from: "/settings/payments/bank-account/change-bank-account-or-ownership",
  });

  const { action } = searchParams;
  const router = useRouter();

  const menuItems: MenuListItem[] = [
    {
      name: "Trocar titularidade",
      Icon: User1,
      onClick: () => openDrawer({ actionString: "change-ownership" }),
      description:
        "Alterar a titularidade do meu recebedor entre Pessoa Física (PF) e Pessoa Jurídica (PJ)",
    },
    {
      name: "Trocar instituição bancária",
      Icon: Bank,
      onClick: () => openDrawer({ actionString: "change-bank-account" }),
      description: "Manter minha titularidade e alterar dados da minha conta ou banco.",
    },
  ];

  const openDrawer = ({
    actionString,
  }: {
    actionString: "change-ownership" | "change-bank-account";
  }): void => {
    void router.navigate({
      search: {
        action: actionString,
        ...searchParams,
      },
    });
  };

  const handleDrawerActions = (
    value: boolean,
    actionString: "change-ownership" | "change-bank-account",
  ): void => {
    if (!value && action === actionString) {
      router.history.back();
    }
  };

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Conta para recebimento" align="center" />
      </HeaderRoot>
      <Page className="pt-2">
        <h1 className="text-xl font-medium text-neutral-600 my-2">
          O que deseja alterar?
        </h1>
        <MenuList menuItems={menuItems} />

        <ChangeOwnershipDrawer
          open={action === "change-ownership"}
          setOpen={(value) => handleDrawerActions(value, "change-ownership")}
        />
        <ChangeBankingInstitutionDrawer
          open={action === "change-bank-account"}
          setOpen={(value) => handleDrawerActions(value, "change-bank-account")}
        />
      </Page>
    </>
  );
};

export const Route = createFileRoute(
  "/settings/payments/bank-account/change-bank-account-or-ownership",
)({
  component: ChangeBankAccountOrOwnershipPage,
  validateSearch: changeBankAccountActionSchema,
});
