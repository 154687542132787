import type { SVGIconProps } from "@repo/icons";
import {
  cloneElement,
  type FunctionComponent,
  isValidElement,
  type MouseEventHandler,
  type ReactElement,
  type ReactNode,
  forwardRef,
  type ComponentPropsWithoutRef,
  type JSX,
} from "react";
import { cn } from "@repo/cn";
import { cva } from "cva";
import { Root as SlotRoot, Slottable } from "@radix-ui/react-slot";
import { CpsSpinner } from "../CpsSpinner";

export interface CpsButtonPropsInternal extends ComponentPropsWithoutRef<"button"> {
  size?: "small" | "default";
  Icon?: FunctionComponent<SVGIconProps>;
  rounded?: boolean;
  fullWidth?: boolean;
  secondary?: boolean;
  color?: ButtonColor;
  variant?: ButtonVariant;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
  type?: "button" | "submit" | "reset";
  iconClassName?: string;
  children?: ReactNode;
  asChild?: boolean;
}

type ButtonColor = "primary" | "secondary" | "primaryLight" | "secondaryLight";

type ButtonVariant = "default" | "outlined";

type ValidButtonProps =
  | { variant?: "default"; color?: ButtonColor }
  | {
      variant?: "outlined";
      color?: Exclude<ButtonColor, "primaryLight" | "secondaryLight">;
    };

export type CpsButtonProps = CpsButtonPropsInternal & ValidButtonProps;

const buttonVariants = cva({
  base: [
    "inline-flex",
    "text-center",
    "justify-center",
    "items-center",
    "leading-5",
    "select-none",
    "transition-bg",
    "duration-200",
    "whitespace-normal",
    "transition",
    "ease-in-out",
    "rounded-30",
    "shrink-0",
    "disabled:bg-neutral-50",
    "disabled:text-neutral-300",
    "disabled:fill-neutral-300",
    "focus:ring-2",
    "focus:ring-offset-2",
  ],
  variants: {
    variant: {
      default: "",
      outlined: "",
    },
    color: {
      primary: "",
      secondary: "",
      primaryLight: "",
      secondaryLight: "",
    },
    size: {
      small: "text-sm font-medium gap-1 h-8",
      default: "font-semibold gap-2 h-14",
    },
    rounded: {
      true: "rounded-70",
      false: "rounded-30",
    },
    fullWidth: {
      true: "w-full",
      false: "",
    },
    children: {
      true: "",
      false: "",
    },
  },
  compoundVariants: [
    {
      variant: "default",
      color: "primary",
      className:
        "bg-primary-400 text-primary-400-contrast hover:bg-primary-400 hover:text-primary-400-contrast fill-primary-400-contrast focus:ring-primary-400 ",
    },
    {
      variant: "default",
      color: "secondary",
      className:
        "bg-secondary-400 text-secondary-400-contrast hover:bg-secondary-400 hover:text-secondary-400-contrast fill-secondary-400-contrast focus:ring-secondary-400",
    },
    {
      variant: "default",
      color: "primaryLight",
      className:
        "bg-primary-50 text-primary-50-contrast hover:bg-primary-50 hover:text-primary-50-contrast fill-primary-50-contrast focus:ring-primary-50",
    },
    {
      variant: "default",
      color: "secondaryLight",
      className:
        "bg-secondary-50 text-secondary-50-contrast hover:bg-secondary-50 hover:text-secondary-50-contrast fill-secondary-50-contrast focus:ring-secondary-50",
    },
    {
      variant: "outlined",
      color: "primary",
      className:
        "border bg-transparent border-primary-400 fill-primary-400 text-primary-400 hover:fill-primary-400-contrast hover:bg-primary-400 hover:text-primary-400-contrast focus:ring-primary-400",
    },
    {
      variant: "outlined",
      color: "secondary",
      className:
        "border bg-transparent border-secondary-400 fill-secondary-400 text-secondary-400 hover:fill-secondary-400-contrast hover:bg-secondary-400 hover:text-secondary-400-contrast focus:ring-secondary-400",
    },
    {
      variant: "outlined",
      color: "primaryLight",
      className:
        "border bg-transparent border-primary-50 fill-primary-50 text-primary-50 hover:fill-primary-50-contrast hover:bg-primary-50 hover:text-primary-50-contrast focus:ring-primary-50",
    },
    {
      variant: "outlined",
      color: "secondaryLight",
      className:
        "border bg-transparent border-secondary-50 fill-secondary-50 text-secondary-50 hover:fill-secondary-50-contrast hover:bg-secondary-50 hover:text-secondary-50-contrast focus:ring-secondary-50",
    },
    {
      size: "small",
      children: false,
      className: "p-2",
    },
    {
      size: "small",
      children: true,
      className: "px-6 min-h-[36px] min-w-[96px] py-2",
    },
    {
      size: "default",
      children: false,
      className: "p-4",
    },
    {
      size: "default",
      children: true,
      className: "px-6 min-h-[52px] min-w-[144px] py-4",
    },
  ],
});

/**
 * CpsButton component.
 *
 * Forwards ref and renders a button with various props for size, icon,
 * rounded corners, click handler, disabled state, flat style, full width,
 * secondary style, loading state, and type.
 *
 * Handles loading state by conditionally showing spinner and hiding children.
 * Renders icon if provided using ShowIcon component.
 *
 * @param size - the size of the button.
 * @param Icon - the icon to be rendered.
 * @param rounded - whether the button should have rounded corners.
 * @param children - the text to be rendered as the button's children.
 * @param onClick - the click handler for the button.
 * @param disabled - whether the button should be disabled.
 * @param fullWidth - whether the button should have a full width style.
 * @param secondary - whether the button should have a secondary style.
 * @param loading - whether the button should be loading.
 * @param type - the type of the button.
 * @param ref - the ref to be forwarded to the button.
 * @returns The CpsButton JSX element.
 *
 */
export const CpsButton = forwardRef<HTMLButtonElement, CpsButtonProps>(
  (
    {
      size = "default",
      Icon,
      rounded = false,
      children,
      onClick,
      disabled = false,
      fullWidth = false,
      variant = "default",
      color = "primary",
      loading = false,
      type = "button",
      className,
      asChild = false,
      ...props
    },
    ref,
  ): JSX.Element => {
    const buttonClasses = cn(
      buttonVariants({
        variant,
        color,
        size,
        rounded,
        fullWidth,
        children: Boolean(children),
      }),
      className,
    );

    const isDisabled = disabled || loading;

    const Comp = asChild ? SlotRoot : "button";

    return (
      <Comp
        className={buttonClasses}
        disabled={isDisabled}
        onClick={onClick}
        ref={ref}
        type={type}
        {...props}
      >
        <ButtonSpinner loading={loading} />

        {Icon ? (
          <Icon className={cn("shrink-0")} size={size === "small" ? 16 : 24} />
        ) : null}

        <Slottable>{slotableChildren(asChild, children, loading)}</Slottable>
      </Comp>
    );
  },
);

CpsButton.displayName = "CpsButton";

/*
Contorna a limitação do Slot do Radix.
Quem sabe no futuro eles resolvem e dá para tirar isso do código. https://github.com/radix-ui/primitives/issues/1825
*/
const slotableChildren = (asChild: boolean, children: ReactNode, loading: boolean) => {
  if (!children) return null;

  if (asChild && isValidElement(children) && hasChildren(children))
    return cloneElement(
      children,
      children.props,
      <span className={cn(loading ? "opacity-0" : "opacity-100")}>
        {children.props.children}
      </span>,
    );

  return <span className={cn(loading ? "opacity-0" : "opacity-100")}>{children}</span>;
};

function hasChildren(
  element: ReactElement,
): element is ReactElement<{ children: ReactNode }> {
  if (typeof element.props !== "object" || element.props === null) {
    return false;
  }

  return "children" in element.props;
}

const ButtonSpinner = ({ loading }: { loading: boolean }) =>
  loading ? (
    <span className={cn("absolute", "opacity-100")}>
      <CpsSpinner small />
    </span>
  ) : null;
