import { useAtom } from "jotai";
import { useNavigate, useRouteContext, useSearch } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types/gql";
import { convertBrazilianCurrencyToNumber } from "@repo/lib/src/helpers/currency";
import { type ExpensumCreateOnlinePaymentOutput } from "@repo/graphql-types/graphql";
import { useEffect } from "react";
import { useResetAtom } from "jotai/utils";
import { type Info, InfoItem, ListRoot } from "@/components/list";
import {
  createPaymentFormAtom,
  isPaymentInfoStepConcluded,
} from "@/lib/atoms/create-payment-atom";
import { Button } from "@/components/button";
import { useGraphQLMutationWithErrorHandler } from "@/hooks/use-graphql";
import { CreatePaymentConfirmSuccessDrawer } from "@/components/create-payment-confirm-success-drawer";
import { trackEvent } from "@/lib/tracking";

const CreatePaymentConfirmMutation = graphql(/* GraphQL */ `
  mutation InsertOnlinePayment($object: ExpensumCreateOnlinePaymentInput!) {
    ExpensumCreateOnlinePayment(arg1: $object) {
      guid
    }
  }
`);

export const CreatePaymentConfirm = (): JSX.Element | undefined => {
  const { flags } = useRouteContext({ strict: false });
  const showCheckboxSendPaymentAutomatically = flags["envio-automatico-link-pagamento"];

  const navigate = useNavigate();

  const searchParams = useSearch({
    from: "/settings/payments/create/confirmation",
  });

  const { appointmentId, appointmentTypeId, action, origin, paymentOnlineType } =
    searchParams;

  const [atomValues] = useAtom(createPaymentFormAtom);
  const resetCreatePaymentForm = useResetAtom(createPaymentFormAtom);

  const { mutateAsync, isPending } = useGraphQLMutationWithErrorHandler(
    CreatePaymentConfirmMutation,
  );
  const { user } = useRouteContext({ strict: false });

  const navigateToHomePage = (): void => void navigate({ to: "/" });

  useEffect(() => {
    if (!isPaymentInfoStepConcluded(atomValues)) {
      void navigate({
        to: "/",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Only on mount
  }, []);

  const paymentMethods = atomValues.paymentMethods.map((method) => method.description);
  const paymentMethodDescription = atomValues.paymentMethods
    .map((method) => {
      if (method.description === "Crédito") {
        return `${method.description} ${atomValues.installments}x`;
      }
      return method.description;
    })
    .join(", ");

  const infos: Info[] = [
    {
      label: "Nome do paciente",
      value: atomValues.patientName,
    },
    {
      label: "E-mail",
      value: atomValues.email,
    },
    {
      label: "Celular",
      value: atomValues.phone,
    },
    {
      label: "Valor",
      value: `R$ ${atomValues.value}`,
    },
    {
      label: "Nome do serviço",
      value: atomValues.serviceName,
    },
    {
      label: "Forma de pagamento",
      value: paymentMethodDescription,
    },
  ];

  const handleDrawerDismiss = (value: boolean): void => {
    if (!value) {
      resetCreatePaymentForm();
      navigateToHomePage();
    }
  };

  const handleSubmit = (): void => {
    const paymentTypeIds = atomValues.paymentMethods.map((method) => method.id);

    const onSuccess = ({
      ExpensumCreateOnlinePayment,
    }: {
      ExpensumCreateOnlinePayment?: ExpensumCreateOnlinePaymentOutput | null;
    }): void => {
      trackEvent("Pagamento Online Criado", {
        codUsuario: user.codUsuario,
        tipoPagamentoOnline: paymentOnlineType,
        origemPagamentoOnline: origin,
        formaPagamento: paymentMethods,
        parcelamento: atomValues.installments,
      });

      if (ExpensumCreateOnlinePayment) {
        void navigate({
          search: {
            ...searchParams,
            action: "success",
            guid: ExpensumCreateOnlinePayment.guid,
          },
        });
      }
    };

    const object = {
      codClinica: user.codClinica && Number(user.codClinica),
      codUsuarioCompromisso: appointmentTypeId,
      payerName: atomValues.patientName,
      payerEmail: atomValues.email,
      payerPhone: atomValues.phone,
      codAgendamento: appointmentId,
      amount: convertBrazilianCurrencyToNumber(atomValues.value) * 100,
      productDescription: atomValues.serviceName,
      automaticSending:
        showCheckboxSendPaymentAutomatically && atomValues.sendPaymentAutomatically,
      paymentTypes: paymentTypeIds,
      installments: atomValues.installments,
      originPaymentType: paymentOnlineType,
    };

    void mutateAsync(
      {
        object,
      },
      { onSuccess },
    );
  };

  return (
    <div className="mt-2">
      <h2 className="text-lg font-medium">Confirme as informações antes de enviar:</h2>
      <ListRoot>
        {infos.map((info) => (
          <InfoItem key={`${info.label}-${info.value}`} itemInfo={info} />
        ))}
      </ListRoot>
      <Button onClick={handleSubmit} loading={isPending} fullWidth>
        Enviar pagamento
      </Button>
      <CreatePaymentConfirmSuccessDrawer
        setOpen={handleDrawerDismiss}
        open={action === "success"}
      />
    </div>
  );
};
