import { isBefore } from "date-fns";
import { Transfer } from "@repo/icons";
import { formatCurrencyFromCentsToReais, formatWithZonedDate } from "@repo/lib";
import { type FragmentType, graphql, useFragment } from "@repo/graphql-types";
import { InfoSectionV2, type InfoSectionV2Props } from "@/components/info-section-v2";

export const PatientPaymentsDetailsPayableSectionFragment = graphql(`
  fragment PatientPaymentsDetailsPayableSectionFragment on ExpensumPaymentDetailsOutput {
    installments
    createdAt
    payablesInfo {
      payables {
        paymentDate
        grossValue
        netValue
        fee
        installment
        status
        anticipationFee
      }
    }
  }
`);

interface PatientPaymentsDetailsPayableSectionProps {
  data: FragmentType<typeof PatientPaymentsDetailsPayableSectionFragment>;
}

export const PatientPaymentsDetailsPayableSection = ({
  data,
}: PatientPaymentsDetailsPayableSectionProps): JSX.Element | null => {
  const payment = useFragment(PatientPaymentsDetailsPayableSectionFragment, data);

  const payableDictionary: Record<string, string> = {
    waiting_funds: "Previsto",
    paid: "Liberado",
    refund: "Estornado",
    prepaid: "Previsto",
  };

  const paymentDateLabelDictionary: Record<string, string> = {
    [payableDictionary.refund]: "Data de estorno",
    [payableDictionary.paid]: "Data de liberação",
    [payableDictionary.waiting_funds]: "Previsão do recebimento",
    [payableDictionary.prepaid]: "Previsão do recebimento",
  };

  const buildPayablesSections = (): InfoSectionV2Props | undefined => {
    const payables = payment.payablesInfo.payables;

    const SPLIT_DEACTIVATION_DATE = new Date(2025, 2, 1);
    
    const isPaymentCreatedWhenSplitWasStillActive = isBefore(
      new Date(payment.createdAt),
      SPLIT_DEACTIVATION_DATE
    );


    const subsections = payables.map((payable) => {
      const payableStatus = payableDictionary[payable.status];
      const formattedPaymentDate = formatWithZonedDate(payable.paymentDate, "dd/MM/yyyy");
      const netValue = formatCurrencyFromCentsToReais(payable.netValue);
      const fee = formatCurrencyFromCentsToReais(payable.fee ?? 0);
      const anticipationFee = formatCurrencyFromCentsToReais(payable.anticipationFee ?? 0);
      const grossValue = formatCurrencyFromCentsToReais(payable.grossValue);
      const paymentDateLabel = paymentDateLabelDictionary[payableStatus];

      const transactionFeeLabel = "Taxa transação"
      const anticipationFeeLabel = "Taxa antecipação"
      const grossValueLabel = "Valor bruto"

      let fields = [
        {
          label: "Parcela",
          value:
            payable.installment && payment.installments
              ? `parcela ${payable.installment} de ${payment.installments}`
              : null,
        },
        { label: "Status recebimento", value: payableStatus },
        { label: paymentDateLabel, value: formattedPaymentDate },
        { label: grossValueLabel, value: grossValue, valueIsRegular: true },
        { label: transactionFeeLabel, value: fee, valueIsRegular: true },
        { label: anticipationFeeLabel, value: anticipationFee, valueIsRegular: true },
        { label: "Valor líquido a receber", value: netValue },
      ];

      if (isPaymentCreatedWhenSplitWasStillActive) {
        const fieldsThatShouldNotBeDisplayedInPaymentsWithSplit = [grossValueLabel, transactionFeeLabel, anticipationFeeLabel]

        fields = fields.filter(
          (field) => !fieldsThatShouldNotBeDisplayedInPaymentsWithSplit.includes(field.label)
        );
      }

      return { fields };
    });

    return {
      section: {
        Icon: Transfer,
        title: "Detalhes do recebimento",
        fields: [],
        subsections,
      },
    };
  };

  const payablesSections = buildPayablesSections();

  if (!payablesSections) return null;

  return <InfoSectionV2 {...payablesSections} />;
};
