import { useRouter, useSearch } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types/gql";
import { type FragmentType, useFragment } from "@repo/graphql-types";
import { type AppointmentDashboardNoShowSectionQueryFragmentFragment } from "@repo/graphql-types/graphql";
import { DashboardSectionEmptyState } from "@/components/dashboard/dashboard-section-empty-state";
import { DashboardSectionHeader } from "@/components/dashboard/dashboard-section-header";
import { ChartRoot, ChartBarContainer, ChartBarItem } from "@/components/charts";
import { trackEvent } from "@/lib/tracking";
import { DashboardInformationalDrawer } from "@/components/dashboard/dashboard-informational-drawer";
import { DashboardSectionTotalNumber } from "@/components/dashboard/dashboard-section-total-number";

export const AppointmentDashboardNoShowSectionQueryFragment = graphql(/* GraphQL */ `
  fragment AppointmentDashboardNoShowSectionQueryFragment on DataItemRelatorioIndependente {
    mes
    consultasNoShow
  }
`);

interface AppointmentDashboardNoShowSectionProps {
  data: FragmentType<typeof AppointmentDashboardNoShowSectionQueryFragment>[];
  selectedMonth: string;
  onSelectedMonthChange: (newMonth: string) => void;
}

export const AppointmentDashboardNoShowSection = ({
  data,
  selectedMonth,
  onSelectedMonthChange,
}: AppointmentDashboardNoShowSectionProps): JSX.Element | null => {
  const fragmentData = useFragment(AppointmentDashboardNoShowSectionQueryFragment, data);

  const router = useRouter();
  const searchParams = useSearch({
    from: "/settings/assist/customer-appointment-overview/",
  });

  const onOpenChange = (open: boolean): void => {
    const search = {
      ...searchParams,
    };

    if (open) {
      search.showNoShowDrawer = open;

      trackEvent("Definição Gráfico Aberta", {
        grafico: "Faltas nas consultas",
      });

      void router.navigate({ search });
    } else if (searchParams.showNoShowDrawer) {
      router.history.back();
    }
  };

  const currentMonthData = fragmentData.find(
    (monthData) => monthData.mes === selectedMonth,
  );

  const fragmentDataIsEmpty = !fragmentData.some(
    (monthData) => monthData.consultasNoShow !== 0,
  );

  return (
    <div className="mt-4 mb-8 flex flex-col gap-3">
      <DashboardSectionHeader onClick={onOpenChange}>
        Faltas nas consultas
      </DashboardSectionHeader>
      {fragmentData.length === 0 || fragmentDataIsEmpty ? (
        <DashboardSectionEmptyState />
      ) : (
        <>
          <DashboardSectionTotalNumber
            total={Number(currentMonthData?.consultasNoShow ?? 0)}
          />
          <AppointmentDashboardNoShowSectionChart
            chartData={fragmentData}
            selectedMonth={selectedMonth}
            onSelectedMonthChange={onSelectedMonthChange}
          />
        </>
      )}
      <DashboardInformationalDrawer
        open={Boolean(searchParams.showNoShowDrawer)}
        onOpenChange={onOpenChange}
        drawerTitle="Faltas nas consultas"
      >
        <p>
          Este gráfico mostra as consultas não realizadas por ausência do paciente. Inclui
          consultas com menos de 5 minutos de duração, sem check-in ou cancelamento prévio
          pelo paciente.
        </p>
      </DashboardInformationalDrawer>
    </div>
  );
};

interface AppointmentDashboardNoShowSectionChartProps {
  chartData: readonly AppointmentDashboardNoShowSectionQueryFragmentFragment[];
  selectedMonth: string;
  onSelectedMonthChange: (newMonth: string) => void;
}

export const AppointmentDashboardNoShowSectionChart = ({
  chartData,
  selectedMonth,
  onSelectedMonthChange,
}: AppointmentDashboardNoShowSectionChartProps): JSX.Element => {
  const data = chartData.map(({ consultasNoShow, mes }) => ({
    "Faltas nas consultas": consultasNoShow as number,
    mes,
  }));

  const labels = data.map((entry) => {
    const noShow = entry["Faltas nas consultas"];
    return {
      mes: entry.mes ?? "",
      "Faltas nas consultas": noShow.toString(),
    };
  }) as Record<string, string>[];

  return (
    <div data-testid="appointments-no-show-chart">
      <ChartRoot
        selectedKey={selectedMonth}
        onSelectedKeyChange={onSelectedMonthChange}
        data={data}
        labels={labels}
      >
        <ChartBarContainer isStacked height={291} XAxisKey="mes">
          <ChartBarItem
            dataKey="Faltas nas consultas"
            fill="#E59112"
            disabledFill="#EDF0F2"
          />
        </ChartBarContainer>
      </ChartRoot>
    </div>
  );
};
