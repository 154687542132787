import { cn } from "@repo/lib";

export interface ButtonTabsProps {
  activeTab: string;
  onTabChange: (id: string) => void;
  tabs: { id: string; label: string }[];
  className?: string;
}

export const ButtonTabs = ({
  activeTab,
  onTabChange,
  tabs,
  className,
}: ButtonTabsProps): JSX.Element => {
  return (
    <div className={cn("rounded-t-lg w-full", className)}>
      <nav className="flex space-x-2 bg-[#BDD1D7] rounded-lg p-1" aria-label="Tabs">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            type="button"
            onClick={() => onTabChange(tab.id)}
            className="content-center flex-1"
          >
            <div
              key={tab.id}
              className={cn(
                "px-2 py-1 text-sm font-medium w-full text-center content-center h-full",
                activeTab === tab.id
                  ? "text-neutral-100 bg-[#327185] rounded-lg font-semibold"
                  : "text-primary-900",
              )}
            >
              {tab.label}
            </div>
          </button>
        ))}
      </nav>
    </div>
  );
};
