import { graphql } from "@repo/graphql-types";
import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { CpsAlert } from "corpus";
import { isInLastMonthOfPlan } from "@/lib/plans";

const psychoanalysisSpecialtyId = 57;
const psychologySpecialtyId = 58;
const psychopedagogySpecialtyId = 70;

export const ChangePlanAlertFragment = graphql(/* GraphQL */ `
  fragment ChangePlanAlertFragment on query_root {
    usuarioPlano: usuariosProfissionaisPlanos(where: { ativo: { _eq: true } }) {
      plano {
        temFidelidade
        nomeExibicao
        observacao
        duracaoFidelidadeEmMeses
      }
      dataInicio
    }
    usuariosEspecialidades {
      codEspecialidade
    }
    usuariosEspecialidades {
      codEspecialidade
    }
  }
`);

interface ChangePlanAlertProps {
  data: FragmentType<typeof ChangePlanAlertFragment>;
  useAlertFromDatabase?: boolean;
  paymentsCount?: number;
}

interface ObservationType {
  default?: string[];
  psico?: string[];
}

export const ChangePlanAlert = ({
  data,
  useAlertFromDatabase = false,
  paymentsCount = 0,
}: ChangePlanAlertProps): JSX.Element => {
  const fragmentResult = useFragment(ChangePlanAlertFragment, data);
  const { usuarioPlano, usuariosEspecialidades = [] } = fragmentResult;

  const plano: {
    nomeExibicao: string;
    temFidelidade: boolean;
    observacao?: string | null;
  } =
    Array.isArray(usuarioPlano) && usuarioPlano.length > 0
      ? usuarioPlano[0].plano
      : { nomeExibicao: "", temFidelidade: false };

  const { nomeExibicao, temFidelidade } = plano;

  const planName = nomeExibicao || "";

  const isLightPlan = planName.includes("Light");
  const isPsico = usuariosEspecialidades.some((especialidade) =>
    [
      psychoanalysisSpecialtyId,
      psychologySpecialtyId,
      psychopedagogySpecialtyId,
    ].includes(especialidade.codEspecialidade),
  );

  let observationObj: ObservationType;

  try {
    observationObj = JSON.parse(
      plano.observacao ?? `{"default": [], "psico": []}`,
    ) as ObservationType;
  } catch (error) {
    observationObj = { default: [], psico: [] };
  }

  const cpsAlertMessage = (): string => {
    if (useAlertFromDatabase) {
      if (isPsico && observationObj.psico && observationObj.psico.length > 0) {
        return observationObj.psico[0] ?? "";
      }

      return observationObj.default?.[0] ?? "";
    }

    const isInLastMonth = isInLastMonthOfPlan(paymentsCount, usuarioPlano[0].plano);

    if (isPsico && isLightPlan) {
      if (temFidelidade && !isInLastMonth) {
        return "Os demais planos estão indisponíveis. Consulte nosso time de atendimento ao membro para mais informações";
      }
    }

    if (temFidelidade && !isInLastMonth) {
      return "Você pode migrar apenas para outro plano anual, respeitando o prazo mínimo de permanência de 12 meses do seu plano atual";
    }

    return "";
  };
  const showCpsAlert = cpsAlertMessage() !== "";

  return (
    <>
      {showCpsAlert ? (
        <CpsAlert description="" title={cpsAlertMessage()} type="info" />
      ) : null}
    </>
  );
};
