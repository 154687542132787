import { type DataItemRelatorioIndependente } from "@repo/graphql-types/graphql";
import { Filesystem, Directory, Encoding } from "@capacitor/filesystem";
import { Share as CapacitorShare } from "@capacitor/share";
import {
  getCurrentDate,
  iosOrAndroid,
  isAndroid,
  logError,
  removeDiacritics,
} from "@repo/lib";
import { isBefore, isSameMonth, startOfMonth, subMonths } from "date-fns";
import { format } from "date-fns-tz";
import { trackEvent } from "@/lib/tracking";
import { getMonthDateFromFormattedString } from "@/lib/date";

/**
 * Preenche os meses não existentes na lista de períodos com valores padrão.
 *
 * @param monthsList - Lista de meses que devem estar presentes nos dados.
 * @param periodData - Dados do período que podem ter meses não existentes.
 * @returns Uma nova lista de dados do período com todos os meses presentes, preenchidos com valores padrão onde necessário.
 */
export const fillMissingPeriodData = (
  monthsList: string[],
  periodData: DataItemRelatorioIndependente[],
): DataItemRelatorioIndependente[] => {
  const defaultData: DataItemRelatorioIndependente = {
    agendamentosMarcados: 0,
    agendamentosNovos: 0,
    agendamentosPacienteAtrasado: 0,
    cancelamentosDefinitivos: 0,
    consultasNoShow: 0,
    consultasPresenciais: 0,
    consultasRealizadas: 0,
    consultasVirtuais: 0,
    reagendamentos: 0,
    agendamentosConveniosParaOMes: 0,
    agendamentosMarcadosParaOMes: 0,
    agendamentosParticularesParaOMes: 0,
    cancelamentos: 0,
  };

  return monthsList.map((month) => {
    return (
      periodData.find((item) => item.mes === month) ?? {
        ...defaultData,
        mes: month,
      }
    );
  });
};

/**
 * Compartilha um relatório gerado, seja através do Capacitor ou do navegador.
 *
 * @param fileName - O nome do arquivo a ser gerado.
 * @param fileContent - O conteúdo do arquivo, sendo as linhas e colunas, a ser utilizado no csv.
 * @param trackingLabel - O rótulo usado para o tracking de evento.
 * @returns Uma Promise que resolve quando o relatório é compartilhado.
 */
export const shareReport = async ({
  fileName,
  fileContent,
  trackingLabel,
}: {
  fileName: string;
  fileContent: string;
  trackingLabel: string;
}): Promise<void> => {
  const shareReportText =
    "O relatório foi gerado. Você pode compartilhá-lo ou abrir manualmente.";

  try {
    const canShare = await CapacitorShare.canShare();

    const treatedFileContent = isAndroid ? removeDiacritics(fileContent) : fileContent;

    if (iosOrAndroid && canShare.value) {
      const { uri } = await Filesystem.writeFile({
        path: fileName,
        data: treatedFileContent,
        directory: Directory.Documents,
        encoding: Encoding.UTF8,
      });

      await CapacitorShare.share({
        title: fileName,
        text: shareReportText,
        files: [uri],
      });
    } else {
      const blob = new Blob([treatedFileContent], { type: "text/csv" });
      const file = new File([blob], fileName, { type: "text/csv" });
      await navigator.share({
        title: fileName,
        text: shareReportText,
        files: [file],
      });
    }
  } catch (err) {
    logError(err);
  }
  trackEvent("Relatório Compartilhado", { relatorio: trackingLabel });
};

/**
 * Obtém a data de início para o compartilhamento do relatório.
 *
 * @param earliestDate - A data inicial a possuir dados do membro no relatório.
 * @param formatDate - O formato da data a ser retornado.
 * @returns A data de início para o relatório.
 */
export const getShareReportFromDate = (
  earliestDate: Date,
  formatDate = "yyyy-MM-dd HH:mm:ssXXX",
): string => {
  const oneYearAgoStartDate = subMonths(startOfMonth(new Date()), 12);

  const isEarliestDateBeforeOneYearAgo = isBefore(
    new Date(earliestDate),
    oneYearAgoStartDate,
  );

  const fromDate = isEarliestDateBeforeOneYearAgo
    ? oneYearAgoStartDate
    : new Date(earliestDate);

  return format(fromDate, formatDate, {
    timeZone: "America/Sao_Paulo",
  });
};

/**
 * Verifica se a variação do mês deve ser exibida.
 *
 * @param earliestDate - A data inicial a possuir dados do membro no relatório.
 * @param selectedMonth - O mês selecionado no formato string.
 * @returns A variação percentual do total de contatos de clientes ou undefined.
 */
export const shouldShowVariationBadge = (
  earliestDate: Date,
  selectedMonth: string,
): boolean => {
  const isSelectedMonthSameAsEarliestDate = isSameMonth(
    earliestDate,
    getMonthDateFromFormattedString(selectedMonth),
  );

  const isSelectedMonthSameAsCurrentDate = isSameMonth(
    getCurrentDate(),
    getMonthDateFromFormattedString(selectedMonth),
  );

  return !isSelectedMonthSameAsEarliestDate && !isSelectedMonthSameAsCurrentDate;
};
