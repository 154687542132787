/* eslint-disable camelcase -- Necessário para montagem dos objetos gerados pelo codegen */
/* eslint-disable react/no-array-index-key -- Utilizado para identificar os campos */
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { type z } from "zod";
import { graphql } from "@repo/graphql-types/gql";
import { type FragmentType, useFragment } from "@repo/graphql-types";
import { convertDateTimeIgnoringTimezone } from "@repo/lib";
import React from "react";
import {
  GetOneLivUserEducationalBackgroundDocument,
  type Locus_Membro_Formacao_Bool_Exp,
  type Locus_Membro_Formacao_Insert_Input,
  type Locus_Membro_Formacao_Updates,
} from "@repo/graphql-types/graphql";
import { useNavigate } from "@tanstack/react-router";
import { SelectInput } from "@/components/select-input";
import { oneLivFormEducationalBackgroundWithValidation } from "@/lib/form-schemas/oneliv-form-schema";
import {
  FormRoot,
  FormHandlerSubmit,
  FormField,
  FormItem,
  FormControl,
  FormSubmitButton,
} from "@/components/form";
import { OneLivCoursesSelectInput } from "@/components/oneliv/oneliv-courses-select-input";
import { OneLivEducationalInstitutionSelectInput } from "@/components/oneliv/oneliv-educational-institutions-select-input";
import { ActionButtonText } from "@/components/action-button-text";
import {
  useGraphQLMutationWithErrorHandler,
  useInvalidateQuery,
} from "@/hooks/use-graphql";

export const OneLivEducationalBackgroundFormFragment = graphql(/* GraphQL */ `
  fragment OneLivEducationalBackgroundFormFragment on query_root {
    membro: locus_membro {
      id
    }
    membroFormacao: locus_membro_formacao {
      conclusao
      idCurso
      idInstituicaoEnsino
    }

    cursos: locus_curso_formacao {
      ...OneLivCoursesSelectInputFragment
    }

    instituicoesEnsino: locus_instituicao_ensino {
      ...OneLivEducationalInstitutionSelectInputFragment
    }
  }
`);

const InsertAndUpdateMembroFormacoesMutation = graphql(/* GraphQL */ `
  mutation InsertAndUpdateMembroFormacoes(
    $updates: [locus_membro_formacao_updates!]!
    $inserts: [locus_membro_formacao_insert_input!]!
    $deletes: [locus_membro_formacao_bool_exp!]
  ) {
    update_locus_membro_formacao_many(updates: $updates) {
      affected_rows
    }

    insert_locus_membro_formacao(objects: $inserts) {
      affected_rows
    }

    delete_locus_membro_formacao(where: { _or: $deletes }) {
      affected_rows
    }
  }
`);

export type OneLivFormEducationalBackgroundValues = z.infer<
  typeof oneLivFormEducationalBackgroundWithValidation
>;

interface OneLivEducationalBackgroundFormProps {
  data?: FragmentType<typeof OneLivEducationalBackgroundFormFragment>;
}

export const OneLivEducationalBackgroundForm = ({
  data,
}: OneLivEducationalBackgroundFormProps): JSX.Element => {
  const fragmentResult = useFragment(OneLivEducationalBackgroundFormFragment, data);

  const initialMemberFormation = fragmentResult?.membroFormacao;
  const navigate = useNavigate();

  const { mutateAsync } = useGraphQLMutationWithErrorHandler(
    InsertAndUpdateMembroFormacoesMutation,
  );

  const invalidateGetOneLivUserEducationalBackground = useInvalidateQuery(
    GetOneLivUserEducationalBackgroundDocument,
  );

  const getDefaultValues = (): OneLivFormEducationalBackgroundValues => {
    const memberEducationBackgrounds = fragmentResult?.membroFormacao;

    const educationalBackgrounds = memberEducationBackgrounds?.length
      ? memberEducationBackgrounds.map((item, index) => ({
          hiddenId: index + 1,
          idFormation: item.idCurso,
          idInstitution: item.idInstituicaoEnsino,
          conclusionYear: convertDateTimeIgnoringTimezone(item.conclusao).getFullYear(),
        }))
      : Array.from({ length: 1 }, () => ({
          hiddenId: 0,
          idFormation: 0,
          idInstitution: 0,
          conclusionYear: 0,
        }));

    const hiddenEducationalBackgrounds = educationalBackgrounds.map((item) => ({
      ...item,
    }));

    return { educationalBackgrounds, hiddenEducationalBackgrounds };
  };

  const form = useForm<OneLivFormEducationalBackgroundValues>({
    resolver: zodResolver(oneLivFormEducationalBackgroundWithValidation),
    defaultValues: getDefaultValues(),
  });

  function buildDeleteEducationalBackgroundCondition(
    finalMemberFormations: OneLivFormEducationalBackgroundValues["educationalBackgrounds"],
    membroId?: number,
  ): Locus_Membro_Formacao_Bool_Exp[] {
    if (!finalMemberFormations || !membroId) {
      return [];
    }

    const formationsToDelete = initialMemberFormation?.filter(
      (item) =>
        !finalMemberFormations.some(
          (x) =>
            x.idFormation === item.idCurso &&
            x.idInstitution === item.idInstituicaoEnsino,
        ),
    );

    const locusMembroFormacaoBoolExp =
      formationsToDelete?.map((item) => {
        return {
          idCurso: { _eq: item.idCurso },
          idInstituicaoEnsino: { _eq: item.idInstituicaoEnsino },
          idMembro: { _eq: membroId },
        };
      }) ?? [];

    return locusMembroFormacaoBoolExp;
  }

  const onSubmit = async (
    formData: OneLivFormEducationalBackgroundValues,
  ): Promise<void> => {
    const onSuccess = (): void => {
      invalidateGetOneLivUserEducationalBackground();

      void navigate({
        to: "/oneliv/medical-institutions",
      });
    };

    const membro = fragmentResult?.membro[0];
    const membroId = membro?.id;

    const educationalBackgrounds = formData.educationalBackgrounds ?? [];
    const hiddenEducationalBackgrounds = formData.hiddenEducationalBackgrounds ?? [];

    const { toBeUpdated, toBeInserted } = educationalBackgrounds.reduce<{
      toBeUpdated: Locus_Membro_Formacao_Updates[];
      toBeInserted: Locus_Membro_Formacao_Insert_Input[];
    }>(
      (acc, item) => {
        const hiddenItemWithSameId = hiddenEducationalBackgrounds.find(
          (x) => x.hiddenId === item.hiddenId && item.hiddenId,
        );

        const educationalBackground = {
          idCurso: item.idFormation,
          idMembro: membroId,
          idInstituicaoEnsino: item.idInstitution,
          conclusao: `${item.conclusionYear}-12-31`,
        };

        if (hiddenItemWithSameId) {
          const isItemUpdated =
            hiddenItemWithSameId.idFormation !== item.idFormation ||
            hiddenItemWithSameId.idInstitution !== item.idInstitution ||
            hiddenItemWithSameId.conclusionYear !== item.conclusionYear;

          if (isItemUpdated) {
            const updatesObject: Locus_Membro_Formacao_Updates = {
              _set: educationalBackground,
              where: {
                idCurso: { _eq: hiddenItemWithSameId.idFormation },
                idInstituicaoEnsino: { _eq: hiddenItemWithSameId.idInstitution },
                idMembro: { _eq: membroId },
              },
            };

            acc.toBeUpdated.push(updatesObject);
          }
        } else if (!item.hiddenId && item.idFormation && item.idInstitution) {
          acc.toBeInserted.push(educationalBackground);
        }

        return acc;
      },

      { toBeUpdated: [], toBeInserted: [] },
    );

    const toBeDeleted = buildDeleteEducationalBackgroundCondition(
      educationalBackgrounds,
      membroId,
    );

    await mutateAsync(
      {
        inserts: toBeInserted,
        updates: toBeUpdated,
        deletes: toBeDeleted,
      },
      { onSuccess },
    );
  };

  const educationBackgroundValues = form.watch("educationalBackgrounds") ?? [];

  const addNewEducationalBackground = (): void => {
    form.setValue("educationalBackgrounds", [
      ...educationBackgroundValues,
      { hiddenId: 0, idFormation: 0, idInstitution: 0, conclusionYear: 0 },
    ]);
  };

  const removeEducationalBackground = (index: number): void => {
    const formValue = educationBackgroundValues.filter((_, i) => i !== index);
    form.setValue("educationalBackgrounds", formValue);
  };

  const currentYear = new Date().getFullYear();

  const lowerLimitEducationalBackgroundConclusion = 1950;

  return (
    <FormRoot {...form}>
      <FormHandlerSubmit handleSubmit={onSubmit}>
        <p className="font-medium text-neutral-600">
          Liste sua formação acadêmica: graduação, especialização e residência.
        </p>
        {educationBackgroundValues.map((item, index) => (
          <React.Fragment key={`${item.idFormation}-${item.idInstitution}-${index}`}>
            <FormField
              key={`${item.idFormation}-${item.idInstitution}-${index}-course`}
              control={form.control}
              name={`educationalBackgrounds.${index}.idFormation`}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <OneLivCoursesSelectInput
                      title=""
                      header={`Formação (${index + 1})`}
                      onClickDelete={() => removeEducationalBackground(index)}
                      placeholder="Digite aqui"
                      inputMode="text"
                      type="text"
                      data={fragmentResult?.cursos}
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <FormField
              key={`${item.idFormation}-${item.idInstitution}-${index}-educational-institution`}
              control={form.control}
              name={`educationalBackgrounds.${index}.idInstitution`}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <OneLivEducationalInstitutionSelectInput
                      title={`Instituição da formação (${index + 1})`}
                      placeholder="Digite aqui"
                      inputMode="text"
                      type="text"
                      data={fragmentResult?.instituicoesEnsino}
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <FormField
              key={`${item.idFormation}-${item.idInstitution}-${index}-conclusion-year`}
              control={form.control}
              name={`educationalBackgrounds.${index}.conclusionYear`}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <SelectInput<number>
                      {...field}
                      title={`Ano de conclusão da formação (${index + 1})`}
                      items={Array.from(
                        {
                          length:
                            currentYear - lowerLimitEducationalBackgroundConclusion + 1,
                        },
                        (_, i) => currentYear - i,
                      ).map((year) => ({
                        label: year.toString(),
                        value: year,
                      }))}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            {index + 1 < educationBackgroundValues.length && (
              <hr className="text-neutral-200 border-1" />
            )}
          </React.Fragment>
        ))}

        <ActionButtonText onClick={addNewEducationalBackground}>
          Adicionar nova formação
        </ActionButtonText>

        <FormSubmitButton type="submit">Avançar</FormSubmitButton>
      </FormHandlerSubmit>
    </FormRoot>
  );
};
