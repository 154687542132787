import { graphql } from "@repo/graphql-types";
import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { Check } from "@repo/icons";
import { CardContent, CardRoot } from "@/components/card";
import { getPlanValueWithAllowance } from "@/lib/allowance";
import { getPlanBenefits } from "@/lib/plans";

export const CurrentPlanCardFragment = graphql(/* GraphQL */ `
  fragment CurrentPlanCardFragment on query_root {
    tiposMovimentacoes(
      where: { ativo: { _eq: true }, identificacao: { _eq: "Mensalidade" } }
    ) {
      valorUnitario
    }
    usuariosProfissionaisPlanos(
      where: {
        ativo: { _eq: true }
        plano: {
          tipoMovimentacao: { usuariosMovimentacoesRecorrentes: { ativo: { _eq: true } } }
        }
      }
    ) {
      plano {
        tipoMovimentacao {
          valorUnitario
          usuariosMovimentacoesRecorrentes {
            quantidade
            ativo
            dataInicio
          }
        }
      }
    }
    usuarioPlano: usuariosProfissionaisPlanos(where: { ativo: { _eq: true } }) {
      plano {
        nome
        valor
        temFidelidade
        nomeExibicao
        descricao
        tipoPlano
        duracaoFidelidadeEmMeses
        carenciaEmMeses
      }
    }
  }
`);

interface CurrentPlanCardProps {
  data: FragmentType<typeof CurrentPlanCardFragment>;
  memberIsPsico: boolean;
  memberRegistrationDate: string;
}

export const CurrentPlanCard = ({
  data,
  memberIsPsico,
  memberRegistrationDate,
}: CurrentPlanCardProps): JSX.Element => {
  const fragmentResult = useFragment(CurrentPlanCardFragment, data);
  const { usuariosProfissionaisPlanos, usuarioPlano, tiposMovimentacoes } =
    fragmentResult;

  const basePrice = tiposMovimentacoes[0].valorUnitario;

  const planoMovimentacoes =
    Array.isArray(usuariosProfissionaisPlanos) && usuariosProfissionaisPlanos.length > 0
      ? usuariosProfissionaisPlanos[0].plano
      : null;

  const plano =
    Array.isArray(usuarioPlano) && usuarioPlano.length > 0 ? usuarioPlano[0].plano : null;
  const planName = plano?.nomeExibicao ?? "";

  const movimentacaoRecorrente = planoMovimentacoes?.tipoMovimentacao ?? {
    valorUnitario: 0,
    usuariosMovimentacoesRecorrentes: [],
  };

  const planPrice = getPlanValueWithAllowance(movimentacaoRecorrente, basePrice);

  const benefits = getPlanBenefits(
    Boolean(plano?.temFidelidade),
    memberRegistrationDate,
    memberIsPsico,
    plano?.duracaoFidelidadeEmMeses,
    plano?.carenciaEmMeses,
    plano?.descricao,
  );

  return (
    <CardRoot>
      <CardContent className="flex flex-col w-full justify-center items-stretch bg-neutral-50">
        <div className="flex flex-row justify-between items-start">
          <div className="flex flex-col gap-1">
            <div className="text-neutral-600 font-medium text-md">{planName}</div>
            <div className="text-neutral-600 font-semibold text-2xl">
              R$ {planPrice}
              <span className="text-neutral-500 font-normal text-xs">/mês</span>
            </div>
          </div>
          {plano?.tipoPlano ? (
            <div className="flex flex-row items-center justify-center text-primary font-medium text-xs bg-white py-1 px-2 rounded-xl">
              {plano.tipoPlano}
            </div>
          ) : null}
        </div>
        <div className="flex flex-col gap-1 mt-2">
          {benefits.map((description, index) => (
            <div
              key={`current-plan-card-${index.toString()}`}
              className="flex flex-row text-neutral-500 font-normal text-xs gap-1"
            >
              <Check size={16} className="fill-secondary-500 shrink-0" />
              {description}
            </div>
          ))}
        </div>
      </CardContent>
    </CardRoot>
  );
};
