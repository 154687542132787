import { graphql, type FragmentType, useFragment } from "@repo/graphql-types";
import { PatientAvatar, type AvatarColor } from "@/components/patient-avatar";

export const AppointmentDrawerPatientInfoFragment = graphql(/* GraphQL */ `
  fragment AppointmentDrawerPatientInfoFragment on pacientes {
    nome
    ...PatientAvatarFragment
  }
`);

interface AppointmentDrawerPatientInfoProps {
  patient: FragmentType<typeof AppointmentDrawerPatientInfoFragment>;
  appointmentType: string;
  onClick?: () => void;
}

const avatarColorByAppointmentType: Record<Event["type"], AvatarColor> = {
  pending: "primaryLight",
  confirmed: "secondaryLight",
  old: "neutralLight",
  cancelled: "dangerLight",
};

export const AppointmentDrawerPatientInfo = ({
  patient,
  appointmentType,
  onClick,
}: AppointmentDrawerPatientInfoProps): JSX.Element => {
  const patientData = useFragment(AppointmentDrawerPatientInfoFragment, patient);

  return (
    <div className="mb-1 flex flex-col w-full items-center">
      <div onClick={onClick} onKeyDown={onClick} role="button" tabIndex={0}>
        <PatientAvatar
          color={avatarColorByAppointmentType[appointmentType]}
          data={patientData}
          size={72}
        />
      </div>
      <span className="pt-1 text-xl font-medium leading-7 text-neutral-600 xl:text-xl xl:leading-8">
        {patientData.nome}
      </span>
    </div>
  );
};
