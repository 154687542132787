import { graphql } from "@repo/graphql-types/gql";
import { type FragmentType, useFragment } from "@repo/graphql-types";
import { Check } from "@repo/icons";
import {
  SubscribeAssistProSubscriptionCardRoot,
  SubscribeAssistProSubscriptionCardHeader,
  SubscribeAssistProSubscriptionCardStepperInfo,
  SubscribeAssistProSubscriptionCardDivider,
} from "@/components/subscribe-assist-pro/subscribe-assist-pro-subscription-card";
import {
  IconListRoot,
  IconListItem,
  IconListItemIcon,
  IconListItemText,
} from "@/components/icon-list";

export const SubscribeAssistProSubscriptionConfirmCardFragment = graphql(/* GraphQL */ `
  fragment SubscribeAssistProSubscriptionConfirmCardFragment on query_root {
    servicePackage: pacotesServicos_by_pk(codPacoteServicos: $codPacoteServico) {
      pacotesServicosPreco {
        valorUnitarioFixo
        valorUnitarioVariavel
      }
    }
  }
`);

interface SubscribeAssistProSubscriptionConfirmCardProps {
  data: FragmentType<typeof SubscribeAssistProSubscriptionConfirmCardFragment>;
}

export const SubscribeAssistProSubscriptionConfirmCard = ({
  data,
}: SubscribeAssistProSubscriptionConfirmCardProps): JSX.Element => {
  const fragmentData = useFragment(
    SubscribeAssistProSubscriptionConfirmCardFragment,
    data,
  );

  const servicePackagePrice = fragmentData.servicePackage?.pacotesServicosPreco;

  return (
    <SubscribeAssistProSubscriptionCardRoot>
      <SubscribeAssistProSubscriptionCardHeader />
      <SubscribeAssistProSubscriptionCardStepperInfo
        fixedPrice={servicePackagePrice?.valorUnitarioFixo}
      />

      <SubscribeAssistProSubscriptionCardDivider />

      <SubscribeAssistProSubscriptionConfirmFeatures />
    </SubscribeAssistProSubscriptionCardRoot>
  );
};

const SubscribeAssistProSubscriptionConfirmFeatures = (): JSX.Element => {
  return (
    <IconListRoot>
      <IconListItem>
        <IconListItemIcon>
          <Check />
        </IconListItemIcon>
        <IconListItemText>Cancele quando quiser sem custos.</IconListItemText>
      </IconListItem>
      <IconListItem>
        <IconListItemIcon>
          <Check />
        </IconListItemIcon>
        <IconListItemText>
          A cobrança será efetuada no seu cartão de crédito cadastrado na sua conta no
          mesmo dia da cobrança Livance.
        </IconListItemText>
      </IconListItem>
    </IconListRoot>
  );
};
