import {
  createFileRoute,
  useRouteContext,
  useRouter,
  useSearch,
} from "@tanstack/react-router";
import { CpsInput, type CpsBadgeProps, CpsToast, CpsTextArea } from "corpus";
import { HelpCircle, Left } from "@repo/icons";
import { Share as CapacitorShare } from "@capacitor/share";
import type { ExpensumGetPaymentInfo } from "@repo/graphql-types/graphql";
import { formatDateAndTimeIgnoringTimezone, formatWithZonedDate } from "@repo/lib";
import { useState } from "react";
import { z } from "zod";
import { AmountTitle } from "@/components/amount-title";
import { InfoSection, type InfoSectionProps } from "@/components/info-section";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { formatTimeHourMinute } from "@/lib/time";
import { Button } from "@/components/button";
import { generateOnlinePaymentLink } from "@/lib/online-payment";
import { trackEvent } from "@/lib/tracking";
import { PaymentStatusChangeDrawer } from "@/components/payment-status-change-drawer";

const PatientPaymentDetailsPageSearchSchema = z.object({
  about: z.boolean().optional(),
  action: z.string().optional(),
  payment: z.custom<ExpensumGetPaymentInfo>(),
});

// TODO: ESSE AQUI VAMOS EXCLUIR DEPOIS DA MIGRAÇÃO PARA V2
export const PatientPaymentDetailsPage = (): JSX.Element => {
  const router = useRouter();
  const { flags } = useRouteContext({ strict: false });
  const searchParams = useSearch({
    from: "/settings/payments/patient-payments/details",
  });

  const { payment } = searchParams;

  const [openCopiedLinkToast, setOpenCopiedLinkToast] = useState(false);

  const installments = payment.parcelas ? `${payment.parcelas}x` : "";

  const transactionId = payment.acquirerTransactionKey
    ? payment.acquirerTransactionKey
    : "";

  const buildAppointmentSection = (): InfoSectionProps | undefined => {
    const appointment = payment.agendamento;

    if (!appointment) return undefined;

    const appointmentTime = formatTimeHourMinute(appointment.horaInicio);

    return {
      section: {
        title: "Detalhes do agendamento",
        fields: [
          { label: "Paciente", value: appointment.paciente.nome },
          {
            label: "Agendamento",
            value: formatDateAndTimeIgnoringTimezone(
              appointment.data,
              appointmentTime,
              "dd/MM/yyyy HH:mm",
            ),
          },
          { label: "Unidade", value: appointment.unidade },
        ],
      },
    };
  };

  const appointmentSection = buildAppointmentSection();

  const buildPayerSection = (): InfoSectionProps => {
    const checkout = payment.checkout;

    return {
      section: {
        title: "Dados do paciente",
        fields: [
          { label: "Nome", value: checkout?.nomeDoPagador },
          {
            label: "Email",
            value: checkout?.emailDoPagador,
          },
          { label: "Telefone", value: checkout?.telefoneDoPagador },
        ],
      },
    };
  };

  const payerSection = buildPayerSection();

  const buildPaymentMethodInformation = (): string => {
    const paymentType = payment.meioPagamentoSelecionado;

    const paymentMethodsDictionary: Record<string, string> = {
      credito: "Crédito",
      debito: "Débito",
      pix: "PIX",
    };

    return paymentMethodsDictionary[paymentType ?? ""] ?? "";
  };

  const originPaymentDictionary: Record<string, string> = {
    Online: "Link de pagamento",
  };

  const getOriginPaymentText = (key: string): string => {
    return originPaymentDictionary[key] ?? key;
  };

  const paymentSection = {
    title: "Dados do pagamento",
    fields: [
      { label: "Forma de pagamento", value: buildPaymentMethodInformation() },
      {
        label: "Parcelamento",
        value: installments,
      },
      {
        label: "ID transação",
        value: transactionId,
      },
      {
        label: "Pago em",
        value:
          payment.status === "paid" && payment.pagoEm
            ? formatWithZonedDate(payment.pagoEm, "dd/MM/yyyy HH:mm")
            : undefined,
      },
      {
        label: "Origem",
        value: getOriginPaymentText(payment.origem),
      },
    ],
  };

  const badgeDictionary: Record<
    string,
    {
      text: string;
      color: CpsBadgeProps["color"];
    }
  > = {
    paid: { text: "Pago", color: "success" },
    pending: { text: "Em Aberto", color: "helper" },
    refunded: { text: "Estornado", color: "danger" },
    canceled: { text: "Cancelado", color: "warning" },
  };

  const { text, color } = badgeDictionary[payment.status];

  const { checkout } = payment;

  const shouldDisplayPaymentNote = payment.note ? payment.note : "";

  const shouldDisplayPaymentLink =
    payment.origem === "Online" && checkout?.guid && payment.status === "pending";

  const paymentLinkUrl = generateOnlinePaymentLink(checkout?.guid ?? "");

  const shareLink = async (): Promise<void> => {
    trackEvent("Pagamento Online Link Compartilhado", {
      origem: "Detalhe pagamento",
    });

    const canShare = await CapacitorShare.canShare();
    if (!canShare.value) {
      await navigator.clipboard.writeText(paymentLinkUrl);
      setOpenCopiedLinkToast(true);

      return;
    }

    await CapacitorShare.share({
      url: paymentLinkUrl,
    });
  };

  const handleClickAbout = (): void => {
    void router.navigate({
      to: "/settings/payments/patient-payments/about-payment-details",
    });
  };

  const handleStatusBadgeClick = (): void => {
    void router.navigate({
      search: {
        ...searchParams,
        action: "payment-status-change",
      },
    });
  };

  const isStatusBadgeClickable =
    flags["ativa-fluxo-de-alterar-status-do-pagamento"] &&
    payment.status === "pending" &&
    payment.origem === "Online";

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Detalhes do pagamento" align="center" />
        <HeaderButton icon={HelpCircle} onClick={handleClickAbout} align="end" />
      </HeaderRoot>
      <Page>
        <div className="flex flex-col gap-8">
          <AmountTitle
            amount={payment.valor}
            date={payment.agendamento?.data}
            startHour={payment.agendamento?.horaInicio}
            badgeColor={color}
            badgeText={text}
            onClickBadge={isStatusBadgeClickable ? handleStatusBadgeClick : undefined}
            lineThroughAmount={payment.status === "canceled"}
          />
          <div className="flex flex-col gap-8">
            {appointmentSection ? (
              <InfoSection {...appointmentSection} />
            ) : (
              <InfoSection {...payerSection} />
            )}
            <InfoSection section={paymentSection} />
          </div>
          {shouldDisplayPaymentLink ? (
            <div className="flex flex-col gap-3">
              <CpsInput
                inputMode="text"
                type="text"
                value={paymentLinkUrl}
                readOnly
                onClick={() => void shareLink()}
                data-testid="copy-payment-link-input"
              />
              <Button onClick={() => void shareLink()}>Compartilhar</Button>
            </div>
          ) : null}

          {shouldDisplayPaymentNote ? (
            <CpsTextArea value={shouldDisplayPaymentNote} disabled />
          ) : null}
        </div>
        <CpsToast
          title="Link copiado para a área de transferência"
          show={openCopiedLinkToast}
          onClose={() => setOpenCopiedLinkToast(false)}
          type="success"
          duration={10}
        />
        <PaymentStatusChangeDrawer />
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/payments/patient-payments/details")({
  component: PatientPaymentDetailsPage,
  validateSearch: PatientPaymentDetailsPageSearchSchema,
});
