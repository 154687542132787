import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgCalendar2 = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="#000"
      viewBox="0 0 16 16"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.666 1.334V4M5.333 1.334V4M2 6h12"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12.667 2.667H3.333C2.597 2.667 2 3.263 2 4v8.667C2 13.403 2.597 14 3.333 14h9.334c.736 0 1.333-.597 1.333-1.334V4c0-.737-.597-1.333-1.333-1.333"
        clipRule="evenodd"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.675 8.486a.167.167 0 1 0 .001.333.167.167 0 0 0 0-.333M8.008 8.486a.167.167 0 1 0 .001.333.167.167 0 0 0 0-.333M11.342 8.486a.167.167 0 1 0 .001.333.167.167 0 0 0-.002-.333M4.675 11.153a.167.167 0 1 0 .001.333.167.167 0 0 0 0-.334M8.008 11.153a.167.167 0 1 0 .001.333.167.167 0 0 0 0-.334"
      />
    </svg>
  );
};
export default SvgCalendar2;
