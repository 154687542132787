import { type FragmentType } from "@repo/graphql-types";
import { graphql } from "@repo/graphql-types/gql";
import { format } from "date-fns-tz";
import {
  createFileRoute,
  useNavigate,
  useRouteContext,
  useSearch,
} from "@tanstack/react-router";
import { endOfDay, isBefore } from "date-fns";
import { Left } from "@repo/icons";
import { CustomerContactDashboardRescuedAppointmentsSection } from "@/components/rescued-appointment-overview/rescued-appointments-section";
import { useGraphQL } from "@/hooks/use-graphql";
import { FIVE_MINUTES_STALE_TIME } from "@/lib/constants/dashboards-customer-overview";
import { Page } from "@/components/page";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { HeaderRoot, HeaderTitle, HeaderUpButton } from "@/components/header";
import { DashboardTimePeriodSelector } from "@/components/dashboard/dashboard-time-period-selector";
import { useInitialDateForOverviews } from "@/hooks/use-initial-date-for-overviews";
import { AssistFeatures } from "@/enums/assist-features";
import { validateFeatureAccess } from "@/lib/assist-feature-guard";
import { useFormattedMonthsArray } from "@/hooks/use-formatted-month-array-for-overview";

const RescuedAppointmentOverviewPageQuery = graphql(/* GraphQL */ `
  query RescuedAppointmentOverviewPageQuery($from: String!, $to: String!) {
    rescuedAppointments: analyticsMembroTotalDeAgendamentosResgatadosPorFluxo(
      arg1: { dataInicio: $from, dataFim: $to }
    ) {
      ...RescuedAppointmentsSectionQueryFragment
    }
  }
`);

export const RescuedAppointmentOverviewPage = (): JSX.Element => {
  const { user } = useRouteContext({ strict: false });
  const navigate = useNavigate();

  const dataCollectionStart = new Date(2024, 10);

  const memberFirstMonth = user.dataValidacao ?? dataCollectionStart;

  const earliestDate = isBefore(memberFirstMonth, dataCollectionStart)
    ? dataCollectionStart
    : memberFirstMonth;

  const endOfToday = endOfDay(new Date());

  const monthsList = useFormattedMonthsArray({
    fromDate:earliestDate,
    toDate: endOfToday,
  });

  const fromDate = useInitialDateForOverviews(endOfToday);
  const toDate = format(endOfToday, "yyyy-MM-dd HH:mm:ssXXX", {
    timeZone: "America/Sao_Paulo",
  });

  const queryResult = useGraphQL(
    RescuedAppointmentOverviewPageQuery,
    {
      from: fromDate,
      to: toDate,
    },
    {
      staleTime: FIVE_MINUTES_STALE_TIME,
    },
  );

  const { data } = queryResult;

  const rescuedAppointmentsData: FragmentType<
    typeof RescuedAppointmentOverviewPageQuery
  > = (data?.rescuedAppointments ?? []) as FragmentType<
    typeof RescuedAppointmentOverviewPageQuery
  >;
  const searchParams: Record<string, string> = useSearch({
    from: "/settings/assist/rescued-appointment-overview/",
  });

  const selectedMonth: string = searchParams.selectedMonth
    ? String(searchParams.selectedMonth)
    : monthsList[monthsList.length - 1];

  if (!searchParams.selectedMonth) {
    searchParams.selectedMonth = selectedMonth;
    void navigate({ search: searchParams, replace: true });
  }

  const onSelectedMonthChange = (newMonth: string): void => {
    const search = {
      ...searchParams,
    };

    search.selectedMonth = newMonth;
    void navigate({ search, replace: true });
  };

  return (
    <>
      <HeaderRoot>
        <HeaderUpButton icon={Left} align="start" />
        <HeaderTitle title="Recuperação de agendamentos" align="center" />
      </HeaderRoot>
      <DashboardTimePeriodSelector
        selectedMonth={searchParams.selectedMonth}
        monthsList={monthsList}
        onSelectedMonthChange={onSelectedMonthChange}
      />
      <Page>
        <AsyncDataWrapper {...queryResult}>
          <CustomerContactDashboardRescuedAppointmentsSection
            fragmentData={rescuedAppointmentsData}
            selectedMonth={selectedMonth}
          />
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/assist/rescued-appointment-overview/")({
  component: RescuedAppointmentOverviewPage,
  beforeLoad: ({ context, location }) => {
    const user = context.user;
    validateFeatureAccess({
      location,
      user,
      codFuncionalidade: AssistFeatures.RescueLeftScheduling,
    });
  },
});
