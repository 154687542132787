import { CashBanknoteSendArrow } from "@repo/icons";
import { format } from "@repo/lib";
import { Link } from "@tanstack/react-router";

interface NextPayableInfo {
  hasAutomaticAnticipation?: boolean;
  installment?: number | null;
  installments?: number | null;
  nextPaymentDate: string;
}

export const NextPayableInfo = ({
  hasAutomaticAnticipation,
  installment,
  installments,
  nextPaymentDate,
}: NextPayableInfo): JSX.Element => {
  const formattedNextPaymentDate = format(new Date(nextPaymentDate), "dd/MM/yyyy");

  if (hasAutomaticAnticipation) {
    return (
      <div className="w-full flex flex-row mt-2">
        <div className="w-full text-xs text-neutral-600 gap-1 rounded-md bg-neutral-100 p-2 flex items-center">
          <CashBanknoteSendArrow size={16} className="stroke-neutral-500" />
          <label className="w-full font-normal text-xs text-neutral-600">
            Antecipação do recebimento em D+2{" "}
            <Link
              to="/settings/payments/patient-payments/about-payment-anticipation"
              className="underline font-medium"
            >
              Saiba mais
            </Link>
          </label>
        </div>
      </div>
    );
  }

  if (installments) {
    return (
      <span className="text-sm text-neutral-500 font-regular mt-1">
        Próximo recebimento ({installment} de {installments}) em{" "}
        {formattedNextPaymentDate}
      </span>
    );
  }

  return (
    <span className="text-sm text-neutral-500 font-regular mt-1">
      Próximo recebimento em {formattedNextPaymentDate}
    </span>
  );
};
