import { formatCurrencyFromCentsToReais, formatWithZonedDate } from "@repo/lib";
import { useState } from "react";
import { useAtom } from "jotai";
import { type FragmentType, graphql, useFragment } from "@repo/graphql-types";
import {
  formatDataForOriginPaymentGraph,
  type PaymentsOriginChartsData,
  type PaymentsOriginChartsDataGroup,
} from "@/lib/mappers/payments-charts";
import {
  NumberedListItem,
  NumberedListLabel,
  NumberedListRoot,
  NumberedListValue,
} from "@/components/numbered-list";
import { ButtonTabs } from "@/components/button-tabs";
import { displayValuesAtom } from "@/lib/atoms/payments-atom";

export const PaymentsOriginChartFragment = graphql(/* GraphQL */ `
  fragment PaymentsOriginChartFragment on ExpensumGetPaymentsChartsInfoOutput {
    periods {
      intervalEnd
      intervalStart
      onlinePayments {
        totalAmount
        created {
          totalAmount
          payments {
            amount
            paymentType
            status
          }
        }
        paid {
          totalAmount
          payments {
            amount
            paymentType
            status
          }
        }
      }
      totalAmount
      totemPayments {
        totalAmount
        created {
          totalAmount
          payments {
            amount
            paymentType
            status
          }
        }
        paid {
          totalAmount
          payments {
            amount
            paymentType
            status
          }
        }
      }
    }
  }
`);

interface PaymentsOriginChartProps {
  data: FragmentType<typeof PaymentsOriginChartFragment>;
}

export const PaymentsOriginChart = ({ data }: PaymentsOriginChartProps): JSX.Element => {
  const originChartData = useFragment(PaymentsOriginChartFragment, data);

  const [activeTab, setActiveTab] =
    useState<keyof PaymentsOriginChartsDataGroup>("online");
  const [displayValues] = useAtom(displayValuesAtom);

  const chartData = formatDataForOriginPaymentGraph(originChartData);

  const buildLabelInterval = (
    periods: { intervalStart: string; intervalEnd: string }[],
  ): string => {
    const { intervalStart } = periods[0];
    const { intervalEnd } = periods[periods.length - 1];

    const start = formatWithZonedDate(intervalStart, "PP");
    const end = formatWithZonedDate(intervalEnd, "PP");
    return `${start} - ${end}`;
  };

  const labelDictionary = {
    debito: "Débito",
    credito: "Crédito",
    pix: "PIX",
  };

  const tabs = [
    { id: "online", label: "Link de pagamento" },
    { id: "totem", label: "Totem" },
  ];

  const calculateTotalAmount = (): string => {
    if (!displayValues) {
      return "R$ \u2022\u2022\u2022\u2022\u2022";
    }

    const totalAmount = chartData[activeTab].reduce(
      (acc: number, item: PaymentsOriginChartsData) => acc + item.value,
      0,
    );
    return formatCurrencyFromCentsToReais(totalAmount);
  };

  const getListValue = (item: PaymentsOriginChartsData): string => {
    if (!displayValues) {
      return "R$ \u2022\u2022\u2022\u2022\u2022";
    }

    return formatCurrencyFromCentsToReais(item.value);
  };
  return (
    <div
      className="bg-[#F4F4F5] py-6 px-2 rounded-xl mt-4"
      id={`payments-${activeTab}-origin-chart`}
      key={`payments-${activeTab}-origin-chart`}
    >
      <ButtonTabs
        activeTab={activeTab}
        onTabChange={(id: string) =>
          setActiveTab(id as keyof PaymentsOriginChartsDataGroup)
        }
        tabs={tabs}
        className="mb-4"
      />
      <div>
        <span className="text-neutral-500 text-xs">
          {buildLabelInterval(originChartData.periods)}
        </span>
        <h2 className="text-2xl font-medium text-neutral-700">
          {calculateTotalAmount()}
        </h2>
      </div>
      <div className="mt-8">
        <NumberedListRoot>
          {(activeTab === "totem" ? ["debito", "credito"] : ["pix", "credito"]).map(
            (label) => {
              const item = chartData[activeTab].find((d) => d.label === label) ?? {
                label,
                value: 0,
                percentage: 0,
              };
              return (
                <NumberedListItem key={`${item.label}-${item.value}`}>
                  <NumberedListLabel>
                    <span className="text-neutral-600 text-lg">
                      {labelDictionary[item.label as keyof typeof labelDictionary]}
                    </span>
                  </NumberedListLabel>
                  <NumberedListValue
                    value={getListValue(item)}
                    percentage={item.percentage}
                  />
                </NumberedListItem>
              );
            },
          )}
        </NumberedListRoot>
      </div>
    </div>
  );
};
