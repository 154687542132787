import { zodResolver } from "@hookform/resolvers/zod";
import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { CpsInput } from "corpus";
import { useRouteContext, useRouter, useSearch } from "@tanstack/react-router";
import { useGraphQLMutation } from "@/hooks/use-graphql";
import {
  FormItem,
  FormControl,
  FormSubmitButton,
  FormField,
  FormRoot,
  FormHandlerSubmit,
} from "@/components/form";
import { HealthInsurancesSelectInput } from "@/components/health-insurances-select-input";
import { HealthInsurancePlansSelectInput } from "@/components/health-insurance-plans-select-input";
import { UnexpectedErrorDrawer } from "@/components/unexpected-error-drawer";

export const AppointmentReceivingOptionsFormQueryFragment = graphql(/* GraphQL */ `
  fragment AppointmentReceivingOptionsFormQueryFragment on query_root {
    formaRecebimento: usuariosFormasRecebimentos_by_pk(
      codUsuarioFormaRecebimento: $codUsuarioFormaRecebimento
    ) {
      codUsuarioFormaRecebimento
      codTipoConvenio
      codTipoPlano
      nomeConvenio
      sistema
    }
    tiposConvenios {
      ...HealthInsurancesListToSelectQueryFragment
    }
    tiposPlanos {
      ...HealthInsurancePlansListToSelectQueryFragment
    }
  }
`);

const CreateAppointmentReceivingOptionMutation = graphql(`
  mutation CreateAppointmentReceivingOption($input: UsuarioFormaRecebimentoInput!) {
    LivanceApiCreateUsuarioFormaRecebimento(arg1: $input)
  }
`);

const UpdateAppointmentReceivingOptionMutation = graphql(`
  mutation UpdateAppointmentReceivingOption(
    $input: LivanceApiPatchUsuarioFormaRecebimentoInput!
  ) {
    LivanceApiPatchUsuarioFormaRecebimento(arg1: $input)
  }
`);

interface AppointmentReceivingOptionsFormProps {
  data: FragmentType<typeof AppointmentReceivingOptionsFormQueryFragment>;
}

export const AppointmentReceivingOptionsForm = ({
  data,
}: AppointmentReceivingOptionsFormProps): JSX.Element => {
  const router = useRouter();

  const { user, queryClient } = useRouteContext({ strict: false });

  const appointmentReceivingOptionsData = useFragment(
    AppointmentReceivingOptionsFormQueryFragment,
    data,
  );

  const currentUrl = appointmentReceivingOptionsData.formaRecebimento
    ?.codUsuarioFormaRecebimento
    ? "/settings/schedule-options/appointment-receiving-options/$appointmentReceivingOptionId"
    : "/settings/schedule-options/appointment-receiving-options/create";

  const searchParams = useSearch({
    from: currentUrl,
  });

  const setShowUnexpectedErrorDrawer = (value: boolean): void => {
    if (value) {
      void router.navigate({
        to: currentUrl,
        params: {
          appointmentReceivingOptionId: String(
            appointmentReceivingOptionsData.formaRecebimento?.codUsuarioFormaRecebimento,
          ),
        },
        search: { action: "create-appointment-receiving-option-error" },
      });
    } else if (searchParams.action === "create-appointment-receiving-option-error") {
      router.history.back();
    }
  };

  const onCreateOrUpdateAppointmentReceivingOptionSuccess = (): void => {
    void queryClient.resetQueries({
      queryKey: ["AppointmentReceivingOptionsPageQuery"],
    });
    form.reset();
    router.history.back();
  };

  const onCreateOrUpdateAppointmentReceivingOptionError = (): void =>
    setShowUnexpectedErrorDrawer(true);

  const HEALTH_INSURANCE_OTHER_OPTION_ID = 1;
  const RECEIVING_TYPE_HEALTH_INSURANCE_ID = 2;

  const { mutateAsync: updateAppointmentReceivingOptionMutateAsync } = useGraphQLMutation(
    UpdateAppointmentReceivingOptionMutation,
  );

  const updateAppointmentReceivingOption = async (
    formFields: FormFields,
  ): Promise<void> => {
    if (!appointmentReceivingOptionsData.formaRecebimento) return;

    const appointmentReceivingOptionId =
      appointmentReceivingOptionsData.formaRecebimento.codUsuarioFormaRecebimento;

    await updateAppointmentReceivingOptionMutateAsync(
      {
        input: {
          codUsuarioFormaRecebimento: appointmentReceivingOptionId,
          jsonPatchDocument: [
            {
              op: "replace",
              path: "/nomeConvenio",
              value: formFields.nomeConvenio,
            },
            {
              op: "replace",
              path: "/codTipoConvenio",
              value: formFields.convenio,
            },
            {
              op: "replace",
              path: "/codTipoPlano",
              value: formFields.planosAceitos,
            },
          ],
        },
      },
      {
        onSuccess: onCreateOrUpdateAppointmentReceivingOptionSuccess,
        onError: onCreateOrUpdateAppointmentReceivingOptionError,
      },
    );
  };

  const { mutateAsync: createAppointmentReceivingOptionMutateAsync } = useGraphQLMutation(
    CreateAppointmentReceivingOptionMutation,
  );

  const createAppointmentReceivingOption = async (
    formFields: FormFields,
  ): Promise<void> => {
    await createAppointmentReceivingOptionMutateAsync(
      {
        input: {
          codUsuario: user.codUsuario,
          codTipoFormaRecebimento: RECEIVING_TYPE_HEALTH_INSURANCE_ID,
          codTipoPlano: formFields.planosAceitos,
          codTipoConvenio: formFields.convenio,
          nomeConvenio: formFields.nomeConvenio,
        },
      },
      {
        onSuccess: onCreateOrUpdateAppointmentReceivingOptionSuccess,
        onError: onCreateOrUpdateAppointmentReceivingOptionError,
      },
    );
  };

  const formSchema = z
    .object({
      convenio: z.number().min(1, "Campo obrigatório"),
      planosAceitos: z.number().min(1, "Campo obrigatório"),
      nomeConvenio: z.string(),
    })
    .superRefine((values, context) => {
      if (values.convenio === HEALTH_INSURANCE_OTHER_OPTION_ID) {
        if (values.nomeConvenio.length < 5 || values.nomeConvenio.length > 20) {
          context.addIssue({
            code: z.ZodIssueCode.custom,
            message: "O nome do convênio deve conter entre 5 e 20 caracteres",
            path: ["nomeConvenio"],
          });
        }
      }
    })
    .transform((values) => ({
      ...values,
      nomeConvenio:
        values.convenio === HEALTH_INSURANCE_OTHER_OPTION_ID ? values.nomeConvenio : "",
    }));

  type FormFields = z.infer<typeof formSchema>;

  const form = useForm<FormFields>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      convenio: appointmentReceivingOptionsData.formaRecebimento?.codTipoConvenio ?? 0,
      planosAceitos: appointmentReceivingOptionsData.formaRecebimento?.codTipoPlano ?? 0,
      nomeConvenio: appointmentReceivingOptionsData.formaRecebimento?.nomeConvenio ?? "",
    },
  });

  const handleSubmit = async (formFields: FormFields): Promise<void> => {
    if (!appointmentReceivingOptionsData.formaRecebimento) {
      await createAppointmentReceivingOption(formFields);
      return;
    }
    await updateAppointmentReceivingOption(formFields);
  };

  const isSistema = appointmentReceivingOptionsData.formaRecebimento?.sistema;

  return (
    <>
      <FormRoot {...form}>
        <FormHandlerSubmit handleSubmit={handleSubmit}>
          <FormField
            control={form.control}
            name="convenio"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <HealthInsurancesSelectInput
                    disabled={isSistema}
                    data={appointmentReceivingOptionsData.tiposConvenios}
                    title="Convênio"
                    required
                    searchable
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          {form.watch("convenio") === HEALTH_INSURANCE_OTHER_OPTION_ID && (
            <FormField
              control={form.control}
              name="nomeConvenio"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <CpsInput
                      disabled={isSistema}
                      required
                      title="Nome do convênio"
                      type="text"
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          )}

          <FormField
            control={form.control}
            name="planosAceitos"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <HealthInsurancePlansSelectInput
                    disabled={isSistema}
                    data={appointmentReceivingOptionsData.tiposPlanos}
                    title="Planos aceitos"
                    required
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormSubmitButton disabled={isSistema}>Salvar</FormSubmitButton>
        </FormHandlerSubmit>
      </FormRoot>
      <UnexpectedErrorDrawer
        open={searchParams.action === "create-appointment-receiving-option-error"}
        setOpen={setShowUnexpectedErrorDrawer}
      />
    </>
  );
};
