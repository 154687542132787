import type { FragmentType } from "@repo/graphql-types";
import { graphql, useFragment } from "@repo/graphql-types";
import {
  AppointmentTypeCategoryEnum,
  AppointmentTypeModalityEnum,
  RoomTypeEnum,
} from "@repo/lib";
import { CpsAlert, CpsCheckboxV2, CpsInput, CpsTextArea } from "corpus";
import { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useRouteContext, useRouter } from "@tanstack/react-router";
import { type AppointmentTypeJsonPatch } from "@repo/lib/src/interfaces/json-patch";
import { HelpCircle } from "@repo/icons";
import { InputCurrency } from "@/components/input-currency";
import { UnexpectedErrorDrawer } from "@/components/unexpected-error-drawer";
import { AppointmentChannelsMultiSelectInput } from "@/components/appointment-channels-multi-select-input";
import { AppointmentTypeModalitiesSelectInput } from "@/components/appointment-type-modalities-select-input";
import { PaymentMethodsMultiSelectInput } from "@/components/payment-methods-multi-select-input";
import { RoomTypesPriorityMultiSelectorInput } from "@/components/room-types-priority-multi-selector";
import { TimeInput } from "@/components/time-input";
import {
  FormControl,
  FormField,
  FormHandlerSubmit,
  FormItem,
  FormRoot,
  FormSubmitButton,
} from "@/components/form";
import { useGraphQLMutation } from "@/hooks/use-graphql";
import {
  TooltipDrawerBody,
  TooltipDrawerRoot,
  TooltipDrawerTitle,
} from "@/components/tooltip-drawer";
import { AppointmentFormDurationSuggestionAlert } from "@/components/appointment-form-duration-suggestion-alert";

export const GetAppointmentTypeFormQueryFragment = graphql(`
  fragment GetAppointmentTypeFormQueryFragment on query_root {
    appointmentType: usuariosCompromissos_by_pk(
      codUsuarioCompromisso: $codUsuarioCompromisso
    ) {
      codUsuarioCompromisso
      nome
      duracao
      valor
      informacoesPaciente
      descricao
      sistema
      codParceiroIntegracao
      salaFlexHabilitada
      modality: usuariosCompromissosModalidade {
        codUsuarioCompromissoModalidade
        nome
      }
      appointmentChannels: usuariosCompromissosCanaisAgendamentos {
        codCanalAgendamento
      }
      roomTypes: tiposSalas {
        codTipoSala
        prioridade
      }
      paymentReceiptMethods: tbUsuariosCompromissosFormasRecebimentos {
        codUsuarioFormaRecebimento
      }
      category: usuariosCompromissosCategoria {
        codUsuarioCompromissoCategoria
      }
    }
    usuariosFormasRecebimentos(
      where: { ativo: { _eq: true }, codUsuario: { _eq: $codUsuario } }
    ) {
      ...PaymentMethodsListToMultiSelectQueryFragment
    }
    canaisAgendamentos(where: { codCanalAgendamento: { _nin: 2 } }) {
      ...AppointmentChannelsListToMultiSelectQueryFragment
    }
    usuariosCompromissosModalidades(order_by: { codUsuarioCompromissoModalidade: asc }) {
      ...AppointmentTypeModalitiesListToSelectQueryFragment
    }
    tiposSalas(where: { alocavel: { _eq: true }, codTipoSala: { _nin: 4 } }) {
      ...RoomTypesListQueryFragment
    }
  }
`);

const CreateAppointmentTypeMutation = graphql(`
  mutation CreateAppointmentType($input: LivanceApiCriaUsuarioCompromissoInput!) {
    LivanceApiCriaUsuarioCompromisso(arg1: $input) {
      response
    }
  }
`);

const EditAppointmentTypeMutation = graphql(`
  mutation EditAppointmentType($input: LivanceApiAlteraUsuarioCompromissoInput!) {
    LivanceApiAlteraUsuarioCompromissoPatch(arg1: $input) {
      codUsuarioCompromisso
    }
  }
`);

interface AppointmentTypeForm {
  name?: string;
  duration?: string;
  modality?: AppointmentTypeModalityEnum;
  appointmentChannels?: string[];
  appointmentRoomTypes?: number[];
  appointmentPaymentMethods?: string[];
  price?: string;
  patientInformation?: string;
  description?: string;
}

interface AppointmentTypeFormProps {
  data: FragmentType<typeof GetAppointmentTypeFormQueryFragment>;
}

const TIMER_CONFIGS = {
  minHour: 0,
  minMinutes: 0,
  maxMinutes: 59,
  stepHour: 1,
  stepMinutes: 5,
};

export const AppointmentTypeForm = ({ data }: AppointmentTypeFormProps): JSX.Element => {
  const appointmentTypeData = useFragment(GetAppointmentTypeFormQueryFragment, data);
  const appointmentType = appointmentTypeData.appointmentType;

  const formatAmount = (value: string): string => {
    const valueReplaced = value
      .replace(/\D/g, "")
      .replace(/^00?0?/g, "")
      .padStart(3, "0");

    const formattedAmount = (Number(valueReplaced) / 100).toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formattedAmount;
  };

  const buildAppointmentTypeFormObject = (): AppointmentTypeForm => {
    const name = appointmentType?.nome;
    const duration = appointmentType?.duracao;
    const modality = appointmentType?.modality.codUsuarioCompromissoModalidade;
    const appointmentChannels = appointmentType?.appointmentChannels.map(
      (x: { codCanalAgendamento: number }) => String(x.codCanalAgendamento),
    );
    const appointmentRoomTypes = appointmentType?.roomTypes
      .sort((a, b) => a.prioridade - b.prioridade)
      .map((x: { codTipoSala: number }) => x.codTipoSala);

    const appointmentPaymentMethods = appointmentType?.paymentReceiptMethods.map(
      (x: { codUsuarioFormaRecebimento: number }) => String(x.codUsuarioFormaRecebimento),
    );
    const price = formatAmount(String((appointmentType?.valor ?? 0) * 100));

    const patientInformation = appointmentType?.informacoesPaciente ?? undefined;
    const description = appointmentType?.descricao ?? undefined;

    return {
      name,
      duration,
      modality,
      appointmentChannels,
      appointmentRoomTypes,
      appointmentPaymentMethods,
      price,
      patientInformation,
      description,
    };
  };

  const {
    name,
    duration,
    modality,
    appointmentChannels,
    appointmentRoomTypes,
    appointmentPaymentMethods,
    price,
    patientInformation,
    description,
  } = buildAppointmentTypeFormObject();

  const { user, ldClient, queryClient } = useRouteContext({ strict: false });

  const [selectedAppointmentRoomTypes, setSelectedAppointmentRoomTypes] = useState<
    number[] | undefined
  >(appointmentRoomTypes);

  const [showUnexpectedErrorDrawer, setShowUnexpectedErrorDrawer] =
    useState<boolean>(false);

  const [appointmentTypeMaxDurationInHours, setAppointmentTypeMaxDurationInHours] =
    useState<number>(2);

  const [appointmentTypeMinDurationInMinutes, setAppointmentTypeMinDurationInMinutes] =
    useState<number>(30);

  const [showDescriptionHelpDialog, setShowDescriptionHelpDialog] =
    useState<boolean>(false);

  const [showFlexHelpDialog, setShowFlexHelpDialog] = useState<boolean>(false);

  const [showPatientInformationDialog, setShowPatientInformationDialog] =
    useState<boolean>(false);

  const isCategoryNotManual =
    appointmentType &&
    appointmentType.category.codUsuarioCompromissoCategoria !==
      Number(AppointmentTypeCategoryEnum.Manual);

  const isAppointmentTypeInternal = Boolean(appointmentType?.sistema);

  const isAppointmentTypeImmutable = isCategoryNotManual ?? isAppointmentTypeInternal;

  const isIntegrationPartner =
    appointmentType !== undefined &&
    appointmentType !== null &&
    appointmentType.codParceiroIntegracao !== null;

  const isOnlyMultiRoomTypeSelected =
    selectedAppointmentRoomTypes?.length === 1 &&
    selectedAppointmentRoomTypes[0] === RoomTypeEnum.Multidisciplinar.valueOf();

  useEffect(() => {
    const maxDurationResult = ldClient.getAppointmentTypeMaxDurationInMinutes();
    setAppointmentTypeMaxDurationInHours(maxDurationResult / 60);

    const allow15MinutesAppointment = ldClient.getAllow15MinutesAppointment();

    let minDuration = allow15MinutesAppointment ? 15 : 30;

    if (isIntegrationPartner) {
      const portoAppointmentTypeDuration = ldClient.getPortoAppointmentTypeDuration();

      minDuration = Math.max(5, Math.min(portoAppointmentTypeDuration, minDuration));
    }

    setAppointmentTypeMinDurationInMinutes(minDuration);
  }, [isIntegrationPartner, ldClient]);

  const isCreate = appointmentTypeData.appointmentType === null;

  const { mutateAsync: createMutateAsync } = useGraphQLMutation(
    CreateAppointmentTypeMutation,
  );
  const { mutateAsync: editMutateAsync } = useGraphQLMutation(
    EditAppointmentTypeMutation,
  );

  const router = useRouter();

  const appointmentTypeFormSchema = z
    .object({
      name: z.string().min(1, "Campo obrigatório"),
      duration: z.string(),
      modality: z.number().min(1, "Campo obrigatório"),
      appointmentChannels: z.string().array(),
      selectedAppointmentRoomTypes: z.number().array(),
      flexRoomChecked: z.boolean(),
      appointmentPaymentMethods: z.string().array().min(1, "Campo obrigatório"),
      price: z.string().refine(
        (value) => {
          const numericValue = parseFloat(value.replace(",", "."));
          return !isNaN(numericValue) && numericValue >= 0;
        },
        { message: "Campo obrigatório" },
      ),
      description: z.string().max(500, "Máximo de 500 caracteres"),
      patientInformation: z.string().max(5500, "Máximo de 5500 caracteres"),
    })
    .superRefine((values, context) => {
      const durationParts = values.duration.split(":");
      const hours = parseInt(durationParts[0]);
      const minutes = parseInt(durationParts[1]);

      if (hours === 0 && minutes < appointmentTypeMinDurationInMinutes) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: `A duração mínima é de ${appointmentTypeMinDurationInMinutes} minutos`,
          path: ["duration"],
        });
      } else if (hours === appointmentTypeMaxDurationInHours && minutes > 0) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: `A duração máxima é de ${appointmentTypeMaxDurationInHours} horas`,
          path: ["duration"],
        });
      }

      if (
        values.modality !== AppointmentTypeModalityEnum.Teleconsulta.valueOf() &&
        !values.selectedAppointmentRoomTypes.length
      ) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Campo obrigatório",
          path: ["selectedAppointmentRoomTypes"],
        });
      }

      if (appointmentChannels?.includes(CANAL_AGENDAMENTO_MEMBRO) ?? isCreate) {
        values.appointmentChannels = [
          ...values.appointmentChannels,
          CANAL_AGENDAMENTO_MEMBRO,
        ];
      }
    })
    .transform((values) => ({
      ...values,
      selectedAppointmentRoomTypes:
        values.modality === AppointmentTypeModalityEnum.Teleconsulta.valueOf()
          ? [RoomTypeEnum.Teleconsulta.valueOf()]
          : values.selectedAppointmentRoomTypes,
    }));

  type FormFields = z.infer<typeof appointmentTypeFormSchema>;

  const getFloatPrice = (stringPrice: string): number => {
    return Number(stringPrice.replace(/\D/g, "").replace(/^00?0?/g, "")) / 100;
  };

  const form = useForm<FormFields>({
    resolver: zodResolver(appointmentTypeFormSchema),
    defaultValues: {
      name: name ?? "",
      duration: duration ?? "01:00",
      modality: modality ?? 0,
      appointmentChannels: appointmentChannels ?? [],
      selectedAppointmentRoomTypes: appointmentRoomTypes ?? [],
      appointmentPaymentMethods: appointmentPaymentMethods ?? [],
      price,
      description: description ?? "",
      patientInformation: patientInformation ?? "",
      flexRoomChecked: isCreate || appointmentType?.salaFlexHabilitada,
    },
  });

  const CANAL_AGENDAMENTO_PACIENTE = "1";
  const CANAL_AGENDAMENTO_MEMBRO = "2";

  const showFlexRoom =
    ldClient.getIncludesFlexRoomInMultiAppointment() &&
    form
      .watch("selectedAppointmentRoomTypes")
      .every((roomType) => roomType === Number(RoomTypeEnum.Multidisciplinar)) &&
    form.watch("modality") !== AppointmentTypeModalityEnum.Teleconsulta.valueOf();

  const executeCreateMutationAsync = async (formData: FormFields): Promise<void> => {
    await createMutateAsync(
      {
        input: {
          codUsuario: user.codUsuario,
          dataCadastro: new Date().toISOString(),
          descricao: formData.description,
          duracao: formData.duration,
          informacoesPaciente: formData.patientInformation,
          nome: formData.name,
          valor: getFloatPrice(formData.price),
          codUsuarioCompromissoModalidade: formData.modality,
          usuariosCompromissosTiposSalas: formData.selectedAppointmentRoomTypes.map(
            (codTipoSala, ordemEscolhida) => {
              return {
                codTipoSala,
                prioridade: ordemEscolhida + 1,
              };
            },
          ),
          usuariosCompromissosFormasRecebimentos: formData.appointmentPaymentMethods.map(
            (codUsuarioFormaRecebimento) => {
              return {
                codUsuarioFormaRecebimento: Number(codUsuarioFormaRecebimento),
              };
            },
          ),
          usuariosCompromissosCanaisAgendamentos: formData.appointmentChannels.map(
            (codCanalAgendamento) => {
              return {
                codCanalAgendamento: Number(codCanalAgendamento),
                codUsuarioCompromisso: 0,
              };
            },
          ),
          ativo: true,
          online: formData.appointmentChannels.some(
            (canal) => canal === CANAL_AGENDAMENTO_PACIENTE,
          ),
          codUsuarioResponsavelPagamento: user.codUsuario,
          envioAutomaticoValorTotem: false,
          categoria: 0,
          salaFlexHabilitada: formData.flexRoomChecked,
        },
      },
      {
        onSuccess: () => {
          void queryClient.resetQueries({
            queryKey: ["AppointmentTypesPageQuery"],
          });
          router.history.back();
        },
        onError: () => {
          setShowUnexpectedErrorDrawer(true);
        },
      },
    );
  };

  const buildEditJsonPatchDocument = (
    formData: FormFields,
  ): AppointmentTypeJsonPatch[] => {
    const patches = [] as AppointmentTypeJsonPatch[];

    patches.push({
      op: "replace",
      path: "/nome",
      value: formData.name,
    });

    patches.push({
      op: "replace",
      path: "/duracao",
      value: formData.duration,
    });

    patches.push({
      op: "replace",
      path: "/codUsuarioCompromissoModalidade",
      value: formData.modality,
    });

    patches.push({
      op: "replace",
      path: "/UsuariosCompromissosTiposSalas",
      value: formData.selectedAppointmentRoomTypes.map((codTipoSala, ordemEscolhida) => {
        return {
          codTipoSala,
          prioridade: ordemEscolhida + 1,
          codUsuarioCompromisso: Number(appointmentType?.codUsuarioCompromisso),
        };
      }),
    });

    patches.push({
      op: "replace",
      path: "/UsuariosCompromissosFormasRecebimentos",
      value: formData.appointmentPaymentMethods.map((codUsuarioFormaRecebimento) => {
        return {
          codUsuarioFormaRecebimento: Number(codUsuarioFormaRecebimento),
          ativo: true,
          codUsuarioCompromisso: Number(appointmentType?.codUsuarioCompromisso),
          dataCadastro: new Date().toISOString(),
        };
      }),
    });

    patches.push({
      op: "replace",
      path: "/valor",
      value: getFloatPrice(formData.price),
    });

    patches.push({
      op: "replace",
      path: "/UsuariosCompromissosCanaisAgendamentos",
      value: formData.appointmentChannels.map((codCanalAgendamento) => {
        return {
          codCanalAgendamento: Number(codCanalAgendamento),
          codUsuarioCompromisso: Number(appointmentType?.codUsuarioCompromisso),
        };
      }),
    });

    patches.push({
      op: "replace",
      path: "/descricao",
      value: formData.description,
    });

    patches.push({
      op: "replace",
      path: "/informacoesPaciente",
      value: formData.patientInformation,
    });

    patches.push({
      op: "replace",
      path: "/salaFlexHabilitada",
      value: String(formData.flexRoomChecked),
    });

    return patches;
  };

  const executeEditMutationAsync = async (formData: FormFields): Promise<void> => {
    await editMutateAsync(
      {
        input: {
          codUsuarioCompromisso: Number(appointmentType?.codUsuarioCompromisso),
          jsonPatchDocument: buildEditJsonPatchDocument(formData),
        },
      },
      {
        onSuccess: () => {
          void queryClient.resetQueries({
            queryKey: ["AppointmentTypesPageQuery"],
          });
          router.history.back();
        },
        onError: () => {
          setShowUnexpectedErrorDrawer(true);
        },
      },
    );
  };

  const handleSubmit = async (formData: FormFields): Promise<void> => {
    if (isCreate) await executeCreateMutationAsync(formData);
    else await executeEditMutationAsync(formData);
  };

  return (
    <>
      <FormRoot {...form}>
        <FormHandlerSubmit handleSubmit={handleSubmit}>
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <CpsInput
                    disabled={isAppointmentTypeImmutable}
                    placeholder="Consulta"
                    required
                    title="Nome do atendimento"
                    type="text"
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="duration"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <TimeInput
                    label="Duração"
                    required
                    timerConfigs={{
                      ...TIMER_CONFIGS,
                      initialHour: Number(
                        form.control._defaultValues.duration?.split(":")[0] ?? "00",
                      ),
                      initialMinutes: Number(
                        form.control._defaultValues.duration?.split(":")[1] ?? "00",
                      ),
                      maxHour: 3,
                    }}
                    {...field}
                    value={field.value}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          {!isCreate && appointmentType?.codUsuarioCompromisso ? (
            <AppointmentFormDurationSuggestionAlert
              appointmentTypeId={appointmentType.codUsuarioCompromisso}
              onSubmit={(selectedValue) => {
                form.setValue("duration", selectedValue);
              }}
            />
          ) : null}

          <FormField
            control={form.control}
            name="modality"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <AppointmentTypeModalitiesSelectInput
                    data={appointmentTypeData.usuariosCompromissosModalidades}
                    disabled={isAppointmentTypeImmutable || !isCreate}
                    required
                    {...field}
                    title="Modalidade"
                  />
                </FormControl>
              </FormItem>
            )}
          />

          {form.watch("modality") !==
            Number(AppointmentTypeModalityEnum.Teleconsulta) && (
            <FormField
              control={form.control}
              name="selectedAppointmentRoomTypes"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <RoomTypesPriorityMultiSelectorInput
                      data={appointmentTypeData.tiposSalas}
                      required
                      selectedRoomTypes={selectedAppointmentRoomTypes}
                      setSelectedRoomTypes={setSelectedAppointmentRoomTypes}
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          )}

          {isOnlyMultiRoomTypeSelected ? (
            <CpsAlert
              description=""
              title="Amplie sua disponibilidade de horários atendendo em salas GOs"
              type="info"
            />
          ) : null}

          {showFlexRoom ? (
            <>
              <FormField
                control={form.control}
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <div className="flex gap-2 items-center">
                        <CpsCheckboxV2
                          className="p-0 bg-transparent"
                          id="flexRoom-check"
                          label="Habilitar sala flex (PLT e BGD)"
                          {...field}
                          defaultChecked={isCreate || appointmentType?.salaFlexHabilitada}
                          onCheckedChange={field.onChange}
                          value={String(field.value)}
                        />
                        <button
                          onClick={() => setShowFlexHelpDialog(true)}
                          tabIndex={0}
                          type="button"
                        >
                          <HelpCircle className="fill-neutral-300" size={24} />
                        </button>
                      </div>
                    </FormControl>
                  </FormItem>
                )}
                name="flexRoomChecked"
              />
              <TooltipDrawerRoot
                open={showFlexHelpDialog}
                setOpen={setShowFlexHelpDialog}
              >
                <TooltipDrawerTitle>Sala Flexível (Flex)</TooltipDrawerTitle>
                <TooltipDrawerBody>
                  <p>
                    Habilite a opção de flexibilidade e atenda em nossa sala adaptável.
                  </p>
                  <p className="pt-2">
                    Essa sala de atendimento conta com um maca exclusiva, adaptável tanto
                    para exames médicos quanto ginecológicos, garantindo flexibilidade e
                    conforto em seu atendimento.
                  </p>
                  <p className="pt-2">
                    Disponível apenas nas unidade <b>Paulista e Brigadeiro</b>.
                  </p>
                </TooltipDrawerBody>
              </TooltipDrawerRoot>
            </>
          ) : null}

          <FormField
            control={form.control}
            name="appointmentPaymentMethods"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <PaymentMethodsMultiSelectInput
                    data={appointmentTypeData.usuariosFormasRecebimentos}
                    title="Forma de recebimento"
                    required
                    disabled={isAppointmentTypeImmutable}
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="price"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <InputCurrency id="valor" title="Valor" {...field} />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="appointmentChannels"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <AppointmentChannelsMultiSelectInput
                    data={appointmentTypeData.canaisAgendamentos}
                    disabled={isIntegrationPartner}
                    {...field}
                    title="Permitir agendamentos por"
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <CpsTextArea
                    placeholder="Digite aqui"
                    title="Descrição do tipo de atendimento"
                    Icon={HelpCircle}
                    onIconClick={() => setShowDescriptionHelpDialog(true)}
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <TooltipDrawerRoot
            open={showDescriptionHelpDialog}
            setOpen={setShowDescriptionHelpDialog}
          >
            <TooltipDrawerTitle>Descrição do tipo de atendimento</TooltipDrawerTitle>
            <TooltipDrawerBody>
              As informações preenchidas aqui serão disponibilizadas apenas para as
              secretárias do time Livance. Resuma aqui dados úteis para ajudar no
              agendamento deste atendimento.
            </TooltipDrawerBody>
          </TooltipDrawerRoot>

          <FormField
            control={form.control}
            name="patientInformation"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <CpsTextArea
                    placeholder="Insira aqui informações sobre o preparo necessário para a consulta"
                    title="Informação ao paciente"
                    Icon={HelpCircle}
                    onIconClick={() => setShowPatientInformationDialog(true)}
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <TooltipDrawerRoot
            open={showPatientInformationDialog}
            setOpen={setShowPatientInformationDialog}
          >
            <TooltipDrawerTitle>Informação ao paciente</TooltipDrawerTitle>
            <TooltipDrawerBody>
              As informações preenchidas aqui serão enviadas ao seu paciente por e-mail no
              momento do agendamento.
            </TooltipDrawerBody>
          </TooltipDrawerRoot>

          <FormSubmitButton>Salvar</FormSubmitButton>
        </FormHandlerSubmit>
      </FormRoot>
      <UnexpectedErrorDrawer
        open={showUnexpectedErrorDrawer}
        setOpen={setShowUnexpectedErrorDrawer}
      />
    </>
  );
};
