import { forwardRef } from "react";
import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import MinusCircle from "@repo/icons/default/MinusCircle";
import { InputSelectTitle } from "@/components/input-select";
import { ActionButtonText } from "@/components/action-button-text";
import { type SelectDrawerItemProps } from "@/components/select-drawer";
import { SelectInput, type SelectInputProps } from "@/components/select-input";

export const OneLivMedicalInstitutionsSelectInputFragment = graphql(/* GraphQL */ `
  fragment OneLivMedicalInstitutionsSelectInputFragment on locus_instituicao_medica {
    id
    nome
  }
`);

export interface OneLivMedicalInstitutionsSelectInputProps
  extends Omit<SelectInputProps<number>, "items"> {
  data?: FragmentType<typeof OneLivMedicalInstitutionsSelectInputFragment>[];
  header?: string;
  onClickDelete: () => void;
}

export const OneLivMedicalInstitutionsSelectInput = forwardRef<
  HTMLInputElement,
  OneLivMedicalInstitutionsSelectInputProps
>(
  (
    { data, header, onClickDelete, ...props }: OneLivMedicalInstitutionsSelectInputProps,
    ref: React.Ref<HTMLInputElement>,
  ): JSX.Element => {
    const medicalInstitutions = useFragment(
      OneLivMedicalInstitutionsSelectInputFragment,
      data,
    );

    const getItems = (): SelectDrawerItemProps<number>[] => {
      if (!medicalInstitutions || medicalInstitutions.length === 0) return [];

      const items: SelectDrawerItemProps<number>[] = medicalInstitutions.map(
        (medicalInstitution) => {
          return {
            label: medicalInstitution.nome,
            value: medicalInstitution.id,
          };
        },
      );

      return items;
    };

    const items = getItems();

    return (
      <div>
        <div className="flex flex-row justify-between">
          <InputSelectTitle>{header}</InputSelectTitle>
          <ActionButtonText Icon={MinusCircle} color="danger" onClick={onClickDelete}>
            Remover
          </ActionButtonText>
        </div>
        <SelectInput<number> ref={ref} {...props} items={items} searchable />
      </div>
    );
  },
);

OneLivMedicalInstitutionsSelectInput.displayName = "OneLivMedicalInstitutionsSelectInput";
