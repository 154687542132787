import { ExclamationCircle } from "@repo/icons";
import { Link } from "@tanstack/react-router";
import {
  MessageDrawerActions,
  MessageDrawerBody,
  MessageDrawerRoot,
  MessageDrawerTitle,
} from "@/components/message-drawer";

interface ScheduleMigrationProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onClickContinue: () => void;
  currentPlanName: string;
  newPlanName: string;
  date: string;
}

export const ScheduleMigrationDrawer = ({
  open,
  setOpen,
  onClickContinue,
  currentPlanName,
  newPlanName,
  date,
}: ScheduleMigrationProps): JSX.Element => {
  return (
    <MessageDrawerRoot
      open={open}
      setOpen={setOpen}
      icon={ExclamationCircle}
      variant="secondary"
    >
      <MessageDrawerTitle>Alterar Plano</MessageDrawerTitle>
      <MessageDrawerBody>
        <p>
          O seu plano será alterado para o {newPlanName} em{" "}
          <span className="font-semibold">{date}</span>. Até lá, você pode aproveitar
          todos os benefícios do {currentPlanName}. Deseja continuar?
        </p>
      </MessageDrawerBody>
      <MessageDrawerActions>
        <div className="flex flex-col">
          <div className="flex flex-col gap-2 justify-center">
            <Link
              onClick={onClickContinue}
              className="px-4 py-5 bg-secondary-400 text-white text-center text-md font-medium rounded-lg"
            >
              Confirmar
            </Link>
            <Link
              onClick={() => setOpen(false)}
              preload={false}
              className="px-4 py-5 text-neutral-500 text-center text-md font-medium"
            >
              Cancelar
            </Link>
          </div>
        </div>
      </MessageDrawerActions>
    </MessageDrawerRoot>
  );
};
