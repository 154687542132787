import { z } from "zod";
import { graphql } from "@repo/graphql-types";
import { HelpCircle, Left } from "@repo/icons";
import {
  zonedDate,
  getDateFromToday,
  formatDateAndTimeIgnoringTimezone,
} from "@repo/lib";
import {
  createFileRoute,
  useRouteContext,
  useRouter,
  useSearch,
} from "@tanstack/react-router";
import { useGraphQL } from "@/hooks/use-graphql";
import { Page } from "@/components/page";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { PaymentsListContainer } from "@/components/patient-payments-list-container";

const PatientsPaymentsQuery = graphql(`
  query PatientsPaymentsQuery(
    $intervalStart: String!
    $intervalEnd: String!
    $codClinica: Int
  ) {
    patientPayments: ExpensumGetPaymentsV2(
      arg1: {
        intervalStart: $intervalStart
        intervalEnd: $intervalEnd
        codClinica: $codClinica
      }
    ) {
      totalAmount
      ...PatientPaymentsListFragment
    }
  }
`);

const today = zonedDate(new Date());
const last7Days = getDateFromToday(-6); // menos um dia pois conta o dia de hoje

const patientPaymentsPageSearchParams = z.object({
  back: z.number().optional(),
  filter: z.boolean().optional(),
  about: z.boolean().optional(),
  startDate: z.string().optional(),
  endDate: z.string().optional(),
  action: z.string().optional(),
});

export const PatientPaymentsPage = (): JSX.Element => {
  const searchParams = useSearch({
    from: "/settings/payments/patient-payments/",
  });
  const { user } = useRouteContext({ strict: false });

  const buildInterval = (): { intervalStart: string; intervalEnd: string } => {
    const intervalStart = searchParams.startDate ?? searchParams.endDate ?? last7Days;
    const intervalEnd = searchParams.endDate ?? searchParams.startDate ?? today;

    return {
      intervalStart: formatDateAndTimeIgnoringTimezone(
        intervalStart,
        undefined,
        "yyyy-MM-dd",
      ),
      intervalEnd: formatDateAndTimeIgnoringTimezone(
        intervalEnd,
        undefined,
        "yyyy-MM-dd",
      ),
    };
  };

  const { intervalStart, intervalEnd } = buildInterval();

  const router = useRouter();

  const queryData = useGraphQL(PatientsPaymentsQuery, {
    intervalStart,
    intervalEnd,
    codClinica: user.codClinica,
  });

  const { data } = queryData;

  const navigateBack = (): void => {
    const backSteps = searchParams.back ?? 0;
    const historySteps = -1 - backSteps;

    router.history.go(historySteps);
  };

  const handleClickAbout = (): void => {
    void router.navigate({ to: "/settings/payments/patient-payments/about-payments" });
  };

  return (
    <>
      <HeaderRoot>
        <HeaderButton onClick={navigateBack} icon={Left} align="start" />
        <HeaderTitle title="Pagamentos" />
        <HeaderButton align="end" icon={HelpCircle} onClick={handleClickAbout} />
      </HeaderRoot>

      <Page>
        <AsyncDataWrapper {...queryData}>
          <PaymentsListContainer
            data={data}
            intervalStart={intervalStart}
            intervalEnd={intervalEnd}
          />
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/payments/patient-payments/")({
  component: PatientPaymentsPage,
  validateSearch: patientPaymentsPageSearchParams,
});
