import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgDrinkColored = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        stroke="#7A8B99"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M6.73 14h10.54"
      />
      <path
        stroke="#7A8B99"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M6 10v-.803c0-.131.016-.261.048-.388l.005-.022A5 5 0 0 1 10.904 5h2.301a4.87 4.87 0 0 1 4.759 3.831v0c.024.112.036.227.036.342V10l-1.851 10.179a1 1 0 0 1-.984.821h-6.33c-.483 0-.897-.346-.984-.821z"
        clipRule="evenodd"
      />
      <path
        stroke="#7A8B99"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M5 10h14M11.955 14l1.856-11.163a1 1 0 0 1 1.281-.792L18 2.941"
      />
    </svg>
  );
};
export default SvgDrinkColored;
