import {
  type PaymentsOriginChartFragmentFragment,
  type PaymentsStatusChartFragmentFragment,
  type ExpensumGetPaymentsChartsInfoPaymentDetail,
  type PayablesStatusChartFragmentFragment,
} from "@repo/graphql-types/graphql";
import { formatWithZonedDate } from "@repo/lib";

export interface PayablesChartsDataWeek {
  week: string;
  waitingFunds: number;
  released: number;
  period: string;
  [key: string]: string | number;
}

export interface PaymentsChartsDataWeek {
  week: string;
  created: number;
  paid: number;
  period: string;
  [key: string]: string | number;
}

export interface PaymentsOriginChartsData {
  label: string;
  value: number;
  percentage: number;
}

export interface PaymentsOriginChartsDataGroup {
  online: PaymentsOriginChartsData[];
  totem: PaymentsOriginChartsData[];
}

type PaymentsOriginChartsDataOutput = Record<
  string,
  { value: number; previousValue: number }
>;

export const mapPaymentsToChartData = (
  paymentsChartData: PaymentsStatusChartFragmentFragment,
): PaymentsChartsDataWeek[] => {
  const periods = paymentsChartData.periods;

  const weeks = periods.map((period) => {
    const createdTotal =
      period.onlinePayments.created.totalAmount +
      period.totemPayments.created.totalAmount;

    const paidTotal =
      period.onlinePayments.paid.totalAmount + period.totemPayments.paid.totalAmount;

    const weekLabel = formatWithZonedDate(period.intervalStart, "dd MMM");

    const periodStart = formatWithZonedDate(period.intervalStart, "PP");
    const periodEnd = formatWithZonedDate(period.intervalEnd, "PP");

    return {
      period: `${periodStart} - ${periodEnd}`,
      week: weekLabel,
      created: createdTotal,
      paid: paidTotal,
    };
  });

  return weeks;
};

export const mapPayablesToChartData = (
  paymentsChartData: PayablesStatusChartFragmentFragment,
): PayablesChartsDataWeek[] => {
  const periods = paymentsChartData.periods;

  const weeks = periods.map((period) => {
    const weekLabel = formatWithZonedDate(period.intervalStart, "dd MMM");

    const periodStart = formatWithZonedDate(period.intervalStart, "PP");
    const periodEnd = formatWithZonedDate(period.intervalEnd, "PP");

    return {
      period: `${periodStart} - ${periodEnd}`,
      week: weekLabel,
      waitingFunds: period.waitingFunds.totalAmount,
      released: period.paid.totalAmount,
    };
  });

  return weeks;
};

export const formatDataForOriginPaymentGraph = (
  paymentsChartData: PaymentsOriginChartFragmentFragment,
): PaymentsOriginChartsDataGroup => {
  const processPayments = (
    paid: ExpensumGetPaymentsChartsInfoPaymentDetail[],
  ): PaymentsOriginChartsDataOutput => {
    const paymentTypesMap: PaymentsOriginChartsDataOutput = {};

    const allPayments = [...paid];

    allPayments.forEach((payment) => {
      const { paymentType, amount, status } = payment;

      if (!paymentType) return;

      if (status !== "paid") return;

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition --- sem essa condição não funciona
      if (!paymentTypesMap[paymentType]) {
        paymentTypesMap[paymentType] = { value: 0, previousValue: 0 };
      }

      paymentTypesMap[paymentType].value += amount;
    });

    return paymentTypesMap;
  };

  const calculatePercentagesAndFormat = (
    paymentTypesMap: PaymentsOriginChartsDataOutput,
  ): PaymentsOriginChartsData[] => {
    const totalValue = Object.values(paymentTypesMap).reduce(
      (acc, payment) => acc + payment.value,
      0,
    );

    return Object.entries(paymentTypesMap).map(([label, data]) => {
      return {
        label,
        value: data.value,
        percentage: totalValue > 0 ? Math.round((data.value / totalValue) * 100) : 0,
      };
    });
  };

  const totemPaymentsMap = processPayments(
    paymentsChartData.periods.flatMap((period) => period.totemPayments.paid.payments),
  );

  const onlinePaymentsMap = processPayments(
    paymentsChartData.periods.flatMap((period) => period.onlinePayments.paid.payments),
  );

  return {
    totem: calculatePercentagesAndFormat(totemPaymentsMap),
    online: calculatePercentagesAndFormat(onlinePaymentsMap),
  };
};
