import { useRouter, useSearch } from "@tanstack/react-router";
import { convertDateTimeIgnoringTimezone } from "@repo/lib";
import { type PatientsPaymentsQueryQuery } from "@repo/graphql-types/graphql";
import { AmountTitle } from "@/components/amount-title";
import { PatientPaymentsList } from "@/components/patient-payments-list";
import { PaymentFilterForm } from "@/components/payment-filter-form";
import {
  PaymentsActionButtons,
  PaymentsContent,
  PaymentsRoot,
} from "@/components/payments-root";

interface PaymentsListContainerProps {
  data: PatientsPaymentsQueryQuery | undefined;
  intervalStart: string;
  intervalEnd: string;
}

export const PaymentsListContainer = ({
  data,
  intervalStart,
  intervalEnd,
}: PaymentsListContainerProps): JSX.Element => {
  const router = useRouter();
  const searchParams = useSearch({
    from: "/settings/payments/patient-payments/",
  });

  const paymentFilterValue = {
    from: convertDateTimeIgnoringTimezone(intervalStart),
    to: convertDateTimeIgnoringTimezone(intervalEnd),
  };

  const setOpenDrawer = (open: boolean, paramName: "filter"): void => {
    if (open) {
      searchParams.filter = open;

      void router.navigate({ search: searchParams });
    } else if (searchParams[paramName]) {
      router.history.back();
    }
  };

  const setOpenFilter = (open: boolean): void => {
    setOpenDrawer(open, "filter");
  };

  return (
    <PaymentsRoot>
      <AmountTitle title="Valor total" amount={data?.patientPayments?.totalAmount ?? 0} />
      <PaymentsActionButtons>
        <PaymentFilterForm
          open={Boolean(searchParams.filter)}
          setOpenFilter={setOpenFilter}
          value={paymentFilterValue}
        />
      </PaymentsActionButtons>
      <PaymentsContent>
        {data?.patientPayments ? (
          <PatientPaymentsList data={data.patientPayments} />
        ) : null}
      </PaymentsContent>
    </PaymentsRoot>
  );
};
