import { createFileRoute, Link, useLoaderData } from "@tanstack/react-router";
import {
  ArrowUpRight,
  CalendarTime,
  CreditCard,
  LocationMarker,
  User1,
  Vaccine,
} from "@repo/icons";
import { HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import {
  PageTitleMainContent,
  PageTitleRoot,
} from "@/components/page-title/page-title-root";
import { EmptyStateV2Root } from "@/components/empty-state-v2";
import { Button } from "@/components/button";

const majorChanges = [
  {
    icon: User1,
    text: "Criamos o Acesso de Assistente, com o encerramento do Pool de Secretárias.",
  },
  {
    icon: CalendarTime,
    text: "Atualizamos o produto de agendamentos para incluir novas funções do Livance Assist.",
  },
  {
    icon: CreditCard,
    text: "Adicionamos informações sobre taxa de uso das máquinas de cartão e serviços adicionais de pagamento.",
  },
  {
    icon: LocationMarker,
    text: "Incluímos a Política de Uso de Estacionamento.",
  },
  {
    icon: Vaccine,
    text: "Destacamos a responsabilidade do profissional pelos procedimentos, conforme o Guia de Práticas.",
  },
];

const TERMS_AND_CONDITIONS_URL =
  "https://ajuda.livance.com.br/hc/pt-br/articles/28453177206547-Condi%C3%A7%C3%B5es-Gerais-para-a-Loca%C3%A7%C3%A3o-e-Presta%C3%A7%C3%A3o-de-Servi%C3%A7os";

export const GeneralTermsAndConditionsPage = (): JSX.Element => {
  const routerContext = useLoaderData({
    from: "/general-terms/",
  });

  const showSeeLaterGeneralTermsButton = routerContext.showSeeLaterGeneralTermsButton;

  const onSeeLaterGeneralTermsButtonClick = (): void => {
    // TODO: Implementar o botão
  };

  return (
    <>
      <HeaderRoot>
        <HeaderTitle title="Condições Gerais" align="center" />
      </HeaderRoot>
      <Page>
        <PageTitleRoot>
          <PageTitleMainContent>
            Atualização das Condições Gerais para a Locação e Prestação de Serviços
          </PageTitleMainContent>
          <h3 className="text-neutral-500 text-sm font-normal">
            Fizemos algumas <strong>atualizações no seu contrato com a Livance</strong>{" "}
            para garantir mais transparência e alinhamento com nossos serviços. As
            principais mudanças foram:
          </h3>
        </PageTitleRoot>
        <EmptyStateV2Root className="py-6">
          <ul className="flex flex-col items-start gap-6 text-sm font-normal text-neutral-600">
            {majorChanges.map((change) => (
              <li key={change.text} className="flex text-start gap-2">
                <span className="p-2 bg-primary-50 rounded-full max-h-[42px] max-w-[42px]">
                  <change.icon className="fill-primary-400" size={24} />
                </span>
                <span>{change.text}</span>
              </li>
            ))}
            <a
              className="inline-flex items-center gap-2 text-secondary-300 cursor-pointer"
              href={TERMS_AND_CONDITIONS_URL}
              target="_blank"
              rel="noopener"
            >
              <span className="text-sm font-medium">
                Clique aqui para ler o contrato na íntegra
              </span>
              <ArrowUpRight className="fill-secondary-300" size={16} />
            </a>
          </ul>
        </EmptyStateV2Root>
        <EmptyStateV2Root className="py-4 flex flex-col gap-6">
          <Button onClick={onSeeLaterGeneralTermsButtonClick} fullWidth>
            Li e aceito as condições gerais
          </Button>
          {showSeeLaterGeneralTermsButton ? (
            <Link to="/" replace>
              <p className="text-neutral-500 text-md font-medium hover:text-neutral-600 cursor-pointer text-center">
                Ver depois
              </p>
            </Link>
          ) : null}
        </EmptyStateV2Root>
      </Page>
    </>
  );
};
export const Route = createFileRoute("/general-terms/")({
  component: GeneralTermsAndConditionsPage,
  beforeLoad: ({ context }) => {
    return {
      showSeeLaterGeneralTermsButton:
        context.ldClient.getShowSeeLaterGeneralTermsButton(),
    };
  },
  loader: ({ context }) => {
    const showSeeLaterGeneralTermsButton = context.showSeeLaterGeneralTermsButton;
    return { showSeeLaterGeneralTermsButton };
  },
});
