import { useRouter, useSearch } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types/gql";
import { useFragment, type FragmentType } from "@repo/graphql-types";
import { type CustomerContactDashboardHumanContactsSectionQueryFragmentFragment } from "@repo/graphql-types/graphql";
import { DashboardInformationalDrawer } from "@/components/dashboard/dashboard-informational-drawer";
import { DashboardSectionEmptyState } from "@/components/dashboard/dashboard-section-empty-state";
import { DashboardSectionHeader } from "@/components/dashboard/dashboard-section-header";
import { DashboardSectionTotalNumber } from "@/components/dashboard/dashboard-section-total-number";
import {
  ChartRoot,
  ChartBarContainer,
  ChartBarItem,
  ChartBarLegend,
} from "@/components/charts";
import { trackEvent } from "@/lib/tracking";
import { MINIMUM_PERCENTAGE_TO_DISPLAY } from "@/lib/constants/dashboards-customer-overview";
import { shouldShowVariationBadge } from "@/lib/dashboard";

export const CustomerContactDashboardHumanContactsSectionQueryFragment = graphql(
  /* GraphQL */ `
    fragment CustomerContactDashboardHumanContactsSectionQueryFragment on NuntiusGetCustomerContactManyPeriodSummaryOutput {
      totalHumanCustomerContacts
      totalHumanCustomerContactsChat
      percentHumanCustomerContactsChat
      totalHumanCustomerContactsVoice
      percentHumanCustomerContactsVoice
      period
      percentGrowthTotalHumanCustomerContacts
    }
  `,
);

interface CustomerContactDashboardHumanContactsSectionProps {
  data: FragmentType<typeof CustomerContactDashboardHumanContactsSectionQueryFragment>[];
  selectedMonth: string;
  onSelectedMonthChange: (newMonth: string) => void;
  earliestDate: Date;
}

export const CustomerContactDashboardHumanContactsSection = ({
  data,
  selectedMonth,
  onSelectedMonthChange,
  earliestDate,
}: CustomerContactDashboardHumanContactsSectionProps): JSX.Element => {
  const fragmentData = useFragment(
    CustomerContactDashboardHumanContactsSectionQueryFragment,
    data,
  );

  const router = useRouter();
  const searchParams = useSearch({
    from: "/settings/assist/customer-contact-overview/",
  });

  const onOpenChange = (open: boolean): void => {
    const search = {
      ...searchParams,
    };

    if (open) {
      search.showHumanCustomerContactsDrawer = open;

      trackEvent("Definição Gráfico Aberta", {
        grafico: "Contatos tratados por secretárias",
      });

      void router.navigate({ search });
    } else if (searchParams.showHumanCustomerContactsDrawer) {
      router.history.back();
    }
  };

  const currentMonthData = fragmentData.find(
    (monthData) => monthData.period === selectedMonth,
  );

  const fragmentDataIsEmpty = !fragmentData.some(
    (x) => x.totalHumanCustomerContacts !== 0,
  );

  const variation = shouldShowVariationBadge(earliestDate, selectedMonth)
    ? currentMonthData?.percentGrowthTotalHumanCustomerContacts
    : undefined;

  return (
    <div className="mt-4 mb-8 flex flex-col gap-3">
      <DashboardSectionHeader onClick={onOpenChange}>
        Contatos tratados por secretárias
      </DashboardSectionHeader>
      {fragmentData.length === 0 || fragmentDataIsEmpty ? (
        <DashboardSectionEmptyState />
      ) : (
        <>
          <DashboardSectionTotalNumber
            total={currentMonthData?.totalHumanCustomerContacts ?? 0}
            variation={variation}
          />
          <CustomerContactDashboardHumanContactsSectionChart
            data={
              fragmentData as CustomerContactDashboardHumanContactsSectionQueryFragmentFragment[]
            }
            selectedMonth={selectedMonth}
            onSelectedMonthChange={onSelectedMonthChange}
          />
        </>
      )}
      <DashboardInformationalDrawer
        open={Boolean(searchParams.showHumanCustomerContactsDrawer)}
        onOpenChange={onOpenChange}
        drawerTitle="Contatos tratados por secretárias"
      >
        <p>
          Número de contatos que tiveram interação com alguma pessoa do time de
          secretárias Livance:
        </p>
        <ul className="text-md list-disc list-inside">
          <li className="mb-1 list-item">
            <span className="font-semibold ">Telefone: </span>
            <span>Ligações recebidas e realizadas.</span>
          </li>
          <li className="list-item">
            <span className="font-semibold">WhatsApp: </span>
            <span>Conversas abertas.</span>
          </li>
        </ul>
      </DashboardInformationalDrawer>
    </div>
  );
};

interface CustomerContactDashboardHumanContactsSectionChartProps {
  data: CustomerContactDashboardHumanContactsSectionQueryFragmentFragment[];
  selectedMonth: string;
  onSelectedMonthChange: (newMonth: string) => void;
}

export const CustomerContactDashboardHumanContactsSectionChart = ({
  data,
  selectedMonth,
  onSelectedMonthChange,
}: CustomerContactDashboardHumanContactsSectionChartProps): JSX.Element => {
  const chartData = data.map((x) => {
    return {
      Telefone: x.totalHumanCustomerContactsVoice,
      WhatsApp: x.totalHumanCustomerContactsChat,
      mes: x.period,
    };
  });

  const chartLabels = data.map((x) => {
    return {
      Telefone:
        x.percentHumanCustomerContactsVoice >= MINIMUM_PERCENTAGE_TO_DISPLAY
          ? `${x.percentHumanCustomerContactsVoice}%`
          : "",
      WhatsApp:
        x.percentHumanCustomerContactsChat >= MINIMUM_PERCENTAGE_TO_DISPLAY
          ? `${x.percentHumanCustomerContactsChat}%`
          : "",
    };
  });

  return (
    <div data-testid="human-customer-contact-chart">
      <ChartRoot
        selectedKey={selectedMonth}
        onSelectedKeyChange={onSelectedMonthChange}
        data={chartData}
        labels={chartLabels}
      >
        <ChartBarContainer isStacked height={291} XAxisKey="mes">
          <ChartBarItem dataKey="Telefone" fill="#72ABBC" disabledFill="#EDF0F2" />
          <ChartBarItem dataKey="WhatsApp" fill="#003C52" disabledFill="#D5DADF" />
          <ChartBarLegend />
        </ChartBarContainer>
      </ChartRoot>
    </div>
  );
};
