import { type FragmentType, useFragment } from "@repo/graphql-types";
import { graphql } from "@repo/graphql-types/gql";
import { ExclamationCircle } from "@repo/icons";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { type z } from "zod";
import { useAtomValue, useSetAtom } from "jotai";
import { Link, useNavigate } from "@tanstack/react-router";
import { CpsAlert, CpsSelectorItem, CpsSelectorRoot, CpsSingleSelector } from "corpus";
import { RoomTypeEnum } from "@repo/lib";
import {
  EmptyStateButton,
  EmptyStateDescription,
  EmptyStateIcon,
  EmptyStateRoot,
  EmptyStateTitle,
} from "@/components/empty-state";
import {
  FormRoot,
  FormHandlerSubmit,
  FormField,
  FormItem,
  FormControl,
  FormSubmitButton,
} from "@/components/form";
import { reservedScheduleSlotInfo } from "@/lib/form-schemas/reserved-schedule-schema";
import {
  reservedScheduleFormAtom,
  reservedScheduleSlotFormAtom,
} from "@/lib/atoms/reserved-schedule-form-atom";

export const ReservedSchedulesSlotsFragment = graphql(`
  fragment ReservedSchedulesSlotsFragment on query_root {
    Slots: LivanceApiBuscaHorariosDisponiveisDiaDaSemana(
      arg1: {
        duracao: $duracao
        diaDaSemana: $diaDaSemana
        codUsuario: $codUsuario
        codUnidade: $codUnidade
        codTipoSala: $codTipoSala
        codModalidadePeriodoGarantido: $codModalidadePeriodoGarantido
        codPeriodoGarantidoRecorrenteFrequencia: $codPeriodoGarantidoRecorrenteFrequencia
      }
    ) {
      sugestoesHorarios {
        data
        horaFim
        horaInicio
      }
    }
  }
`);

const formatTimePeriod = (slot: { horaFim: string; horaInicio: string }): string => {
  const { horaInicio, horaFim } = slot;

  return `${horaInicio.slice(0, 5)} - ${horaFim.slice(0, 5)}`;
};

interface ReservedSchedulesSlotsProps {
  slotsList: FragmentType<typeof ReservedSchedulesSlotsFragment>;
}

export const ReservedScheduleSlots = ({
  slotsList,
}: ReservedSchedulesSlotsProps): JSX.Element => {
  const navigate = useNavigate();
  const data = useFragment(ReservedSchedulesSlotsFragment, slotsList);
  const reservedScheduleAtom = useAtomValue(reservedScheduleFormAtom);
  const roomType = RoomTypeEnum[reservedScheduleAtom.roomType];
  const setReservedScheduleSlotFormAtom = useSetAtom(reservedScheduleSlotFormAtom);
  type ReservedScheduleForm = z.infer<typeof reservedScheduleSlotInfo>;
  const form = useForm<ReservedScheduleForm>({
    resolver: zodResolver(reservedScheduleSlotInfo),
  });

  const slots = data.Slots?.sugestoesHorarios
    ? data.Slots.sugestoesHorarios.sort((a, b) => {
        return (a?.horaInicio ?? "").localeCompare(b?.horaInicio ?? "");
      })
    : [];

  const handleSubmit = (formData: ReservedScheduleForm): void => {
    const selectedIndex = parseInt(formData.slot, 10);
    const selectedSlot = slots[selectedIndex];
    if (selectedSlot) {
      const atomSlot = {
        data: selectedSlot.data,
        horaFim: selectedSlot.horaFim,
        horaInicio: selectedSlot.horaInicio,
      };
      setReservedScheduleSlotFormAtom(atomSlot);
    }
    void navigate({ to: "/settings/reserved-schedules/create/starting-day" });
  };

  return (
    <>
      {slots.length > 0 ? (
        <FormRoot {...form}>
          <FormHandlerSubmit handleSubmit={handleSubmit}>
            <div>
              <span className="text-neutral-600 font-medium text-left">
                Defina o horário do período
              </span>
            </div>
            <FormField
              control={form.control}
              name="slot"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <CpsSelectorRoot>
                      <CpsSingleSelector
                        activeBackgroundColor="secondary"
                        {...field}
                        fullWidth
                      >
                        <div className="grid grid-cols-2 gap-3 pt-6 w-full">
                          {slots.map((slot, index) => (
                            <>
                              {slot ? (
                                <CpsSelectorItem value={`${index}`} key={slot.horaInicio}>
                                  {formatTimePeriod(slot)}
                                </CpsSelectorItem>
                              ) : null}
                            </>
                          ))}
                        </div>
                      </CpsSingleSelector>
                    </CpsSelectorRoot>
                  </FormControl>
                </FormItem>
              )}
            />
            <CpsAlert
              title="Horários apresentados com base na disponibilidade da unidade."
              description=""
              Icon={ExclamationCircle}
              type="info"
            />
            <FormSubmitButton>Avançar</FormSubmitButton>
          </FormHandlerSubmit>
        </FormRoot>
      ) : (
        <EmptyStateRoot>
          <EmptyStateIcon icon={ExclamationCircle} />
          <EmptyStateTitle>Não há salas disponíveis</EmptyStateTitle>
          <EmptyStateDescription>
            Não conseguimos encontrar salas do tipo {roomType} disponíveis para a criação
            do seu período garantido.
          </EmptyStateDescription>
          <Link className="w-full" to="/settings/reserved-schedules/create">
            <EmptyStateButton fullWidth>Alterar a opção</EmptyStateButton>
          </Link>
          <Link className="w-full" to="/settings/reserved-schedules/">
            <EmptyStateButton fullWidth>Ir para meus períodos</EmptyStateButton>
          </Link>
        </EmptyStateRoot>
      )}
    </>
  );
};
