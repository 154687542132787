import { useRouteContext } from "@tanstack/react-router";
import { Building1, CashDuplicate, Chart, CreditCard, Discount } from "@repo/icons";
import { graphql } from "@repo/graphql-types/gql";
import { useGraphQL } from "@/hooks/use-graphql";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import {
  EmptyStateDescription,
  EmptyStateIcon,
  EmptyStateRoot,
  EmptyStateTitle,
} from "@/components/empty-state";

export const RecipientEmptyStateWrapperQuery = graphql(`
  query RecipientEmptyStateWrapperQuery($codUsuario: Int!, $codClinica: Int) {
    expensumRecipient: ExpensumGetRecipient(
      arg1: { codUsuario: $codUsuario, codClinica: $codClinica }
    ) {
      recipient {
        pagarmeId
      }
    }
  }
`);

interface RecipientEmptyStateWrapperProps {
  children: React.ReactNode;
}

export const RecipientEmptyStateWrapper = ({
  children,
}: RecipientEmptyStateWrapperProps): JSX.Element => {
  const { user } = useRouteContext({ strict: false });

  const queryData = useGraphQL(RecipientEmptyStateWrapperQuery, {
    codUsuario: Number(user.codUsuario),
    codClinica: Number(user.codClinica),
  });

  const { data } = queryData;

  const hasRecipient = Boolean(data?.expensumRecipient?.recipient?.pagarmeId);
  return (
    <AsyncDataWrapper {...queryData}>
      {hasRecipient ? (
        children
      ) : (
        <EmptyStateRoot>
          <EmptyStateIcon icon={CreditCard} />
          <EmptyStateTitle>
            Parece que você ainda não tem a conta recebedora cadastrada
          </EmptyStateTitle>
          <EmptyStateDescription>
            <p className="text-md text-neutral-600">
              Entre em contato com nosso time de atendimento{" "}
              <a
                className="underline font-semibold"
                target="_blank"
                href="https://api.whatsapp.com/send/?phone=551132304410&text&type=phone_number&app_absent=0"
                rel="noopener noreferrer"
              >
                via WhatsApp
              </a>{" "}
              e solicite o cadastro para utilizar as nossas soluções.
            </p>

            <h3 className="text-md text-neutral-600 font-semibold mt-4 text-left">
              Entenda os benefícios das nossas soluções de pagamento:
            </h3>

            <div className="flex flex-col p-4 items-start gap-4 rounded-md bg-neutral-50 mt-2 mb-4">
              <div className="flex flex-row gap-2 items-center">
                <Discount size={24} className="fill-primary-400 flex-shrink-0" />
                <span className="text-md text-neutral-500 text-left">
                  Taxas competitivas com o mercado
                </span>
              </div>
              <div className="flex flex-row gap-2 items-center">
                <Building1 size={24} className="fill-primary-400 flex-shrink-0" />
                <span className="text-md text-neutral-500 text-left">
                  Cobre atendimentos realizados dentro e fora da Livance
                </span>
              </div>
              <div className="flex flex-row gap-2 items-center">
                <CashDuplicate size={24} className="fill-primary-400 flex-shrink-0" />
                <span className="text-md text-neutral-500 text-left">
                  Opções de Pix, crédito e débito
                </span>
              </div>
              <div className="flex flex-row gap-2 items-center">
                <Chart size={24} className="fill-primary-400 flex-shrink-0" />
                <span className="text-md text-neutral-500 text-left">
                  Visão consolidada para sua gestão financeira
                </span>
              </div>
            </div>

            <a
              className="text-[#04536B] underline text-md"
              target="_blank"
              rel="noopener noreferrer"
              href="https://ajuda.livance.com.br/hc/pt-br/articles/30006359132819-Como-funciona-o-servi%C3%A7o-de-recebimento-dos-valores-das-minhas-consultas-na-Livance"
            >
              Mais informações em nossa central de ajuda
            </a>
          </EmptyStateDescription>
        </EmptyStateRoot>
      )}
    </AsyncDataWrapper>
  );
};
