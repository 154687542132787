import { Link } from "@tanstack/react-router";
import { CpsBadge, type CpsBadgeProps } from "corpus";
import { Item } from "@/components/list";
import { formatDateOfBirth } from "@/lib/string.ts";
import { formatTimeHourMinute } from "@/lib/time.ts";

export interface AgendamentoProps {
  codAgendamento: number;
  data: string;
  horaInicio: string;
  horaFim: string;
  Unidade: {
    nome: string;
  };
  cancelado: boolean;
  AgendamentoAlocacao: {
    horaFim?: string;
  };
}

const STATUS_CONFIG = {
  cancelado: { label: "Cancelado", color: "danger" },
  finalizado: { label: "Finalizado", color: "success" },
  agendado: { label: "Agendado", color: "primary" },
};

function getAppointmentFinished(data: string, horaFim: string): boolean {
  const [year, month, day] = data.split("-").map(Number);
  const [hour, minute, second] = horaFim.split(":").map(Number);
  const appointmentEndDateTime = new Date(year, month - 1, day, hour, minute, second);
  const now = new Date();
  return appointmentEndDateTime <= now;
}

function getAppointmentStatus(
  cancelado: boolean,
  finalizado: boolean,
): keyof typeof STATUS_CONFIG {
  if (cancelado) return "cancelado";
  if (finalizado) return "finalizado";
  return "agendado";
}

function getTagLabel(cancelado: boolean, finalizado: boolean): string {
  const status = getAppointmentStatus(cancelado, finalizado);
  return STATUS_CONFIG[status].label;
}

function getTagColor(cancelado: boolean, finalizado: boolean): string {
  const status = getAppointmentStatus(cancelado, finalizado);
  return STATUS_CONFIG[status].color;
}

export const PatientHistoryListItem = (props: {
  item: AgendamentoProps;
}): JSX.Element => {
  const { item } = props;

  const horaFim = item.AgendamentoAlocacao.horaFim ?? item.horaFim;
  const finalizado = getAppointmentFinished(item.data, horaFim);
  const label = getTagLabel(item.cancelado, finalizado);
  const color = getTagColor(item.cancelado, finalizado) as CpsBadgeProps["color"];

  return (
    <Link
      to="/"
      search={{
        action: "appointment-details",
        date: item.data,
        appointmentId: item.codAgendamento,
      }}
      className="w-full"
    >
      <Item noChevron>
        <div className="flex w-full justify-between items-center">
          <div>
            <div className="pb-1">{formatDateOfBirth(item.data)}</div>
            <div className="text-sm pb-1">{`${formatTimeHourMinute(item.horaInicio)} até ${formatTimeHourMinute(item.horaFim)}`}</div>
            <CpsBadge>{item.Unidade.nome}</CpsBadge>
          </div>
          <CpsBadge color={color}>{label}</CpsBadge>
        </div>
      </Item>
    </Link>
  );
};
