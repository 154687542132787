import { graphql } from "@repo/graphql-types/gql";
import { Left } from "@repo/icons";
import {
  createFileRoute,
  useLoaderData,
  useRouteContext,
  useRouter,
  useSearch,
} from "@tanstack/react-router";
import { useState } from "react";
import type { UsuariosProfissionaisPlanos } from "@repo/graphql-types/graphql";
import { formatWithZonedDate, getCurrentDate } from "@repo/lib";
import { CpsAlert } from "corpus";
import { useGraphQL, useGraphQLMutation } from "@/hooks/use-graphql";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import {
  isPlanValidForMigration,
  getFilteredSortedPlans,
  type Plan,
  getCurrentPlan,
  getNextDueDate,
  canMigrateImmediately,
} from "@/lib/plans";
import { CurrentPlanCard } from "@/components/current-plan-card";
import { trackEvent } from "@/lib/tracking";
import { Button } from "@/components/button";
import { UnexpectedErrorDrawer } from "@/components/unexpected-error-drawer";
import { SuccessChangePlanDrawer } from "@/components/success-change-plan-drawer";
import { ChangePlanAlert } from "@/components/change-plan-alert";
import { ChangePlanCheckboxTerms } from "@/components/change-plan-checkbox-terms";
import { ChangePlanSelectionSection } from "@/components/change-plan-selection-section";
import { useMemberInfo } from "@/lib/member";
import { ScheduleMigrationDrawer } from "@/components/schedule-migration-drawer";

const ChangePlanPageQuery = graphql(/* GraphQL */ `
  query ChangePlanPageQuery($currentDate: datetime2!) {
    ...CurrentPlanCardFragment
    ...ChangePlanAlertFragment
    usuarioPlano: usuariosProfissionaisPlanos(where: { ativo: { _eq: true } }) {
      plano {
        codPlano
        codTipoMovimentacao
        nome
        valor
        temFidelidade
        nomeExibicao
        descricao
        tipoPlano
        duracaoFidelidadeEmMeses
        carenciaEmMeses
        migravel
      }
      dataInicio
    }
    usuarioPlanoMovimentacoesRecorrentes: usuariosProfissionaisPlanos(
      where: { ativo: { _eq: true } }
    ) {
      plano {
        tipoMovimentacao {
          usuariosMovimentacoesRecorrentes(
            where: { dataFim: { _is_null: true }, ativo: { _eq: true } }
            order_by: { dataCadastro: asc }
          ) {
            codUsuarioMovimentacaoRecorrente
          }
        }
      }
    }
    usuarioPlanoFuturo: usuariosProfissionaisPlanos(
      where: {
        dataInicio: { _gt: $currentDate }
        ativo: { _eq: false }
        dataFim: { _is_null: true }
      }
    ) {
      plano {
        nomeExibicao
      }
      dataInicio
    }
    planos(where: { ativo: { _eq: true }, migravel: { _eq: true } }) {
      codPlano
      nome
      valor
      temFidelidade
      nomeExibicao
      descricao
      tipoPlano
      duracaoFidelidadeEmMeses
      carenciaEmMeses
      migravel
    }
    usuariosProfissionais {
      dataValidacao
      diaVencimento
    }
    usuariosEspecialidades {
      codEspecialidade
    }
    planosMigracoes(where: { ativo: { _eq: true } }) {
      codPlanoDestino
      codPlanoOrigem
    }
  }
`);

const UpdateUsuarioPlanoMigracaoMutation = graphql(`
  mutation LivanceApiUsuarioPlanoMigracao($input: LivanceApiUsuarioPlanoMigracaoInput!) {
    LivanceApiUsuarioPlanoMigracao(arg1: $input) {
      codPlano
    }
  }
`);

const GetMovimentacoesByMovimentacaoRecorrenteQuery = graphql(/* GraphQL */ `
  query GetMovimentacoesByMovimentacaoRecorrenteQuery($codMovimentacaoRecorrente: Int!) {
    tbMovimentacoes(
      where: {
        tipoOrigem: { _eq: "recorrente" }
        codOrigem: { _eq: $codMovimentacaoRecorrente }
      }
    ) {
      codMovimentacao
    }
  }
`);

export const ChangePlanPage = (): JSX.Element => {
  const { user, queryClient, flags } = useRouteContext({ strict: false });
  const router = useRouter();
  const searchParams = useSearch({
    from: "/settings/profile/change-plan/",
  });

  const { scheduledPlanMigration } = useLoaderData({
    from: "/settings/profile/change-plan/",
  });

  const [isChecked, setIsChecked] = useState(false);
  const [checkedPlan, setCheckedPlan] = useState(0);

  const queryResult = useGraphQL(ChangePlanPageQuery, {
    currentDate: getCurrentDate().toISOString(),
  });
  const { data } = queryResult;

  const {
    planos: plans = [],
    usuarioPlano = [],
    usuariosProfissionais = [],
    usuariosEspecialidades = [],
    planosMigracoes = [],
    usuarioPlanoFuturo = [],
    usuarioPlanoMovimentacoesRecorrentes = [],
  } = data ?? {};

  const movimentacoesRecorrentes =
    usuarioPlanoMovimentacoesRecorrentes[0]?.plano.tipoMovimentacao
      ?.usuariosMovimentacoesRecorrentes ?? [];

  const menorDataMovimentacao =
    movimentacoesRecorrentes.length > 0 ? movimentacoesRecorrentes[0] : null;

  const codUsuarioMovimentacaoRecorrente =
    menorDataMovimentacao?.codUsuarioMovimentacaoRecorrente;

  const movimentacoesResult = useGraphQL(
    GetMovimentacoesByMovimentacaoRecorrenteQuery,
    { codMovimentacaoRecorrente: Number(codUsuarioMovimentacaoRecorrente) },
    { enabled: Boolean(codUsuarioMovimentacaoRecorrente) },
  );

  const { data: movimentacoesData } = movimentacoesResult;
  const { tbMovimentacoes: movimentacoes = [] } = movimentacoesData ?? {};

  const paymentsCount = Number(movimentacoes.length);

  const hasFuturePlan = usuarioPlanoFuturo.length > 0;
  const futurePlanName = usuarioPlanoFuturo[0]?.plano.nomeExibicao ?? "";
  const futurePlanDate = formatWithZonedDate(
    usuarioPlanoFuturo[0]?.dataInicio ?? new Date(),
    "dd/MM/yyyy",
  );

  const currentPlan = getCurrentPlan(usuarioPlano as UsuariosProfissionaisPlanos[]);
  const { memberRegistrationDate, memberIsPsico } = useMemberInfo(
    usuariosProfissionais,
    usuariosEspecialidades,
  );

  const paymentDueDay = usuariosProfissionais[0]?.diaVencimento ?? 1;

  const memberRegistrationDateCheckingFlag = flags[
    "deve-considerar-plano-mensal-como-permanencia-de-3-meses"
  ]
    ? memberRegistrationDate
    : "";

  const { mutateAsync: updateUsuarioPlanoMigracaoAsync, isPending } = useGraphQLMutation(
    UpdateUsuarioPlanoMigracaoMutation,
  );

  const handlePlanChange = (value: number): void => {
    if (checkedPlan === value) {
      setCheckedPlan(0);
      return;
    }

    trackEvent("Opção de Plano Selecionado", {
      codUsuario: user.codUsuario,
      codPlano: value,
      label: "Alterar Plano",
      page: "/settings/profile/change-plan/",
    });
    setCheckedPlan(value);
  };

  const handleDrawerState = (action: string | null, navigateBack = false): void => {
    if (navigateBack) {
      router.history.go(-2);
      return;
    }

    void router.navigate({
      to: "/settings/profile/change-plan/",
      search: { action },
    });
  };

  const handleSuccessfulUpdate = (): void => {
    setIsChecked(false);
    void queryClient.resetQueries({ queryKey: ["MyProfilePageQuery"] });
    handleDrawerState("change-plan-success");
  };

  const handlePlanUpdate = async (): Promise<void> => {
    handleConfirmationDrawer(false);

    await updateUsuarioPlanoMigracaoAsync(
      {
        input: {
          codUsuario: user.codUsuario,
          codPlano: checkedPlan,
        },
      },
      {
        onSuccess: handleSuccessfulUpdate,
        onError: () => handleDrawerState("error"),
      },
    );
  };

  const handleSuccessDrawer = (value: boolean): void => {
    if (value) {
      void router.navigate({
        to: "/settings/profile/change-plan/",
        search: { action: "change-plan-success" },
      });
    } else if (searchParams.action === "change-plan-success") {
      setCheckedPlan(0);
      router.history.go(-2);
    }
  };

  const successDrawerMessage = (): string => {
    if (
      scheduledPlanMigration &&
      !canMigrateImmediately(planosMigracoes, Number(currentPlan.codPlano), checkedPlan)
    ) {
      return `A partir de ${getNextDueDate(Number(paymentDueDay))}, você estará no novo plano. Verifique sua fatura para eventuais ajustes devido à diferença de valores entre os planos.`;
    }

    return "A partir de agora, você está no novo plano. Verifique sua fatura para eventuais ajustes devido à diferença de valores entre os planos.";
  };

  const handleConfirmationDrawer = (value: boolean): void => {
    if (value) {
      void router.navigate({
        to: "/settings/profile/change-plan/",
        search: { action: "change-plan-confirm" },
      });
    } else if (searchParams.action === "change-plan-confirm") {
      router.history.back();
    }
  };

  const handlePlanSelection = (plan: Plan): void => {
    if (disabled(plan)) return;

    handlePlanChange(plan.codPlano ?? 0);
    document.getElementById("change-plan-button")?.scrollIntoView({ behavior: "smooth" });
  };

  const setShowUnexpectedErrorDrawer = (value: boolean): void => {
    if (value) {
      void router.navigate({
        to: "/settings/profile/change-plan/",
        search: { action: "error" },
      });
    } else if (searchParams.action === "error") {
      router.history.back();
    }
  };

  const disabled = (plan: Plan): boolean => {
    return !isPlanValidForMigration(plan, currentPlan, paymentsCount, planosMigracoes);
  };

  const sortedPlans = getFilteredSortedPlans(
    plans,
    currentPlan,
    paymentsCount,
    planosMigracoes,
  );

  const handlePlanChoice = async (): Promise<void> => {
    if (
      canMigrateImmediately(planosMigracoes, Number(currentPlan.codPlano), checkedPlan)
    ) {
      await handlePlanUpdate();
    } else {
      handleConfirmationDrawer(true);
    }
  };

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Alterar Plano" align="center" />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data ? (
            <div className="flex flex-col gap-4">
              <div className="flex flex-col justify-center items-stretch gap-2 py-4">
                <p className="font-sm font-medium text-neutral-600">Plano atual</p>
                <CurrentPlanCard
                  data={data}
                  memberIsPsico={memberIsPsico}
                  memberRegistrationDate={memberRegistrationDate}
                />
                <ChangePlanAlert
                  data={data}
                  useAlertFromDatabase={scheduledPlanMigration}
                  paymentsCount={paymentsCount}
                />
                {scheduledPlanMigration && hasFuturePlan ? (
                  <CpsAlert
                    description=""
                    title={`Seu plano será alterado para o ${futurePlanName} em ${futurePlanDate}. Caso solicite uma nova alteração, a atual será cancelada.`}
                    type="info"
                  />
                ) : null}
              </div>

              <ChangePlanSelectionSection
                sortedPlans={sortedPlans}
                checkedPlan={checkedPlan}
                disabled={disabled}
                memberIsPsico={memberIsPsico}
                memberRegistrationDate={memberRegistrationDateCheckingFlag}
                onPlanSelect={handlePlanSelection}
              />

              <ChangePlanCheckboxTerms isChecked={isChecked} onChange={setIsChecked} />

              <Button
                id="change-plan-button"
                fullWidth
                disabled={!isChecked || checkedPlan === 0}
                onClick={() => {
                  if (scheduledPlanMigration) {
                    void handlePlanChoice();
                  } else {
                    void handlePlanUpdate();
                  }
                }}
                loading={isPending}
              >
                Alterar Plano
              </Button>

              <UnexpectedErrorDrawer
                open={searchParams.action === "error"}
                setOpen={setShowUnexpectedErrorDrawer}
              />
              <SuccessChangePlanDrawer
                open={searchParams.action === "change-plan-success"}
                setOpen={handleSuccessDrawer}
                onClickProfile={() => {
                  router.history.go(-2);
                }}
                description={successDrawerMessage()}
                onClickSchedule={() => void router.navigate({ to: "/" })}
              />
              <ScheduleMigrationDrawer
                open={searchParams.action === "change-plan-confirm"}
                setOpen={handleConfirmationDrawer}
                onClickContinue={() => void handlePlanUpdate()}
                currentPlanName={currentPlan.nomeExibicao}
                newPlanName={
                  sortedPlans.find((p) => p.codPlano === checkedPlan)?.nomeExibicao ?? ""
                }
                date={getNextDueDate(Number(paymentDueDay))}
              />
            </div>
          ) : null}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/profile/change-plan/")({
  component: ChangePlanPage,
  beforeLoad: ({ context }) => ({
    enableFullPlansForPsico: context.ldClient.getEnableFullPlansForPsico(),
    scheduledPlanMigration: context.ldClient.getScheduledPlanMigration(),
  }),
  loader: ({ context }) => ({
    enableFullPlansForPsico: context.enableFullPlansForPsico,
    scheduledPlanMigration: context.scheduledPlanMigration,
  }),
});
