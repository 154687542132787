import { PlusCircle, type MinusCircle } from "@repo/icons";
import { type CpsButtonTextProps, CpsButtonText } from "corpus";
import { forwardRef } from "react";

export interface ActionButtonTextProps extends CpsButtonTextProps {
  Icon?: typeof PlusCircle | typeof MinusCircle;
  color?: "primary" | "secondary" | "danger";
}

export const ActionButtonText = forwardRef<HTMLButtonElement, CpsButtonTextProps>(
  ({ children, Icon = PlusCircle, color = "secondary", ...props }, ref) => {
    return (
      <CpsButtonText
        ref={ref}
        className="justify-start"
        Icon={Icon}
        color={color}
        {...props}
      >
        {children}
      </CpsButtonText>
    );
  },
);

ActionButtonText.displayName = "ActionButtonText";
