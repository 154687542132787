import { cn } from "@repo/lib";
import { Link, type LinkOptions } from "@tanstack/react-router";

export interface BannerMenuItemLinkRootProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  to: LinkOptions["to"];
}

export const BannerMenuItemLinkRoot = ({
  to,
  ...props
}: BannerMenuItemLinkRootProps): JSX.Element => {
  return (
    <Link to={to}>
      <BannerMenuItemRoot {...props} />
    </Link>
  );
};

export interface BannerMenuItemRootProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
}

export const BannerMenuItemRoot = ({
  children,
  className,
  ...props
}: BannerMenuItemRootProps): JSX.Element => {
  return (
    <button
      type="button"
      className={cn(
        "bg-secondary-50 text-neutral-500 border-secondary-400 border-2 rounded-40 gap-2 flex items-center p-4 text-left w-full",
        className,
      )}
      {...props}
    >
      {children}
    </button>
  );
};

export interface BannerMenuItemContentProps {
  children: React.ReactNode;
}

export const BannerMenuItemContent = ({
  children,
}: BannerMenuItemContentProps): JSX.Element => {
  return <div className="flex flex-col flex-1 items-start">{children}</div>;
};

export interface BannerMenuItemContentTitleProps {
  children: React.ReactNode;
}

export const BannerMenuItemContentTitle = ({
  children,
}: BannerMenuItemContentTitleProps): JSX.Element => {
  return <span className="text-secondary-600 font-medium text-md">{children}</span>;
};

export interface BannerMenuItemContentDescriptionProps {
  children: React.ReactNode;
}

export const BannerMenuItemContentDescription = ({
  children,
}: BannerMenuItemContentDescriptionProps): JSX.Element => {
  return <span className="text-neutral-500 font-normal text-xs">{children}</span>;
};
