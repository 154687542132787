import { Check, Edit1, Exclamation } from "@repo/icons";
import { CpsBadge } from "corpus";
import { useNavigate, useRouteContext, useSearch } from "@tanstack/react-router";
import { formatCurrency, ServicePackageEnum } from "@repo/lib";
import { format } from "date-fns";
import { CardRoot, CardContent, CardHeader } from "@/components/card";
import { trackEvent } from "@/lib/tracking";
import {
  MessageDrawerBody,
  MessageDrawerRoot,
  MessageDrawerTitle,
} from "@/components/message-drawer";
import {
  IconListItem,
  IconListItemIcon,
  IconListItemText,
  IconListRoot,
} from "@/components/icon-list";
import {
  DIGITAL_CONTACT_PRICE,
  HUMAN_CONTACT_PRICE,
  START_CHARGE_DATE,
  TRIAL_LIMIT_DATE,
} from "@/lib/constants/subscribe-assist-pro";
import { SubscribedAssistProPhoneNumberCard } from "@/components/subscribed-assist-pro/subscribed-assist-pro-phone-number-card";

export const SubscribedAssist = (): JSX.Element => {
  const { user } = useRouteContext({ strict: false });
  const navigate = useNavigate();
  const searchParams = useSearch({ from: "/settings/assist/subscribed-assist/" });
  const proPackageService = user.pacotesServicos.find(
    (servicePkg) =>
      servicePkg.codPacoteServicos === ServicePackageEnum.Professional.valueOf(),
  );

  const hasAlreadyCancelled = Boolean(proPackageService?.dataDesativacao);
  const pricePackageService = proPackageService?.pacoteServicoPreco;

  const onClickToCancelSubscription = (): void => {
    trackEvent("Botão Cancelar Assinatura Assist Pro Clicado");
    searchParams.showCancelSubscriptionDrawer = true;

    void navigate({
      search: searchParams,
    });
  };

  const message =
    "Olá, gostaria de saber mais sobre o cancelamento da minha assinatura do Livance Assist Pro.";

  return (
    <>
      <div className="flex flex-col gap-2 pt-2 pb-8">
        <span className="text-2xl font-semibold">
          Confira aqui os detalhes do seu plano Livance Assist
        </span>
      </div>

      <div className="flex flex-col gap-2">
        <SubscribedAssistProPhoneNumberCard />
        <CardRoot>
          <CardContent className="flex flex-col justify-center items-start w-full">
            <CardHeader className="py-0 px-0 flex flex-row justify-between items-center w-full">
              <CpsBadge>Mensal</CpsBadge>
              {!hasAlreadyCancelled ? (
                <button
                  type="button"
                  className="flex flex-row justify-center text-secondary-400 font-medium text-xs"
                  onClick={onClickToCancelSubscription}
                >
                  <Edit1 className="fill-secondary-400" />
                  Cancelar minha assinatura
                </button>
              ) : null}
            </CardHeader>
            <div className="pb-4">
              <div className="text-neutral-600 font-medium text-md mt-4">
                Livance Assist Pro
              </div>
              <div className="text-neutral-600 font-semibold text-2xl">
                {formatCurrency((pricePackageService?.valorUnitarioFixo ?? 0) as number)}
                <span className="text-xs"> /mês</span>
              </div>
              <div className="text-neutral-500 text-xs mt-2 font-semibold">
                + {formatCurrency(DIGITAL_CONTACT_PRICE)}
                <span className="text-xs"> /atendimento digital</span>
              </div>
              <div className="text-neutral-500 text-xs mt-2 font-semibold">
                + {formatCurrency(HUMAN_CONTACT_PRICE)}
                <span className="text-xs"> /atendimento humano</span>
              </div>
            </div>
            <IconListRoot>
              <IconListItem>
                <IconListItemIcon>
                  <Check />
                </IconListItemIcon>
                <IconListItemText>
                  Período de teste gratuito até{" "}
                  <strong>{format(TRIAL_LIMIT_DATE, "dd/MM/yyyy")}</strong>.
                </IconListItemText>
              </IconListItem>
              <IconListItem>
                <IconListItemIcon>
                  <Check />
                </IconListItemIcon>
                <IconListItemText>
                  O período de cobrança será iniciado no dia{" "}
                  <strong>{format(START_CHARGE_DATE, "dd/MM/yyyy")}</strong>.
                </IconListItemText>
              </IconListItem>
              <IconListItem>
                <IconListItemIcon>
                  <Check />
                </IconListItemIcon>
                <IconListItemText>
                  A cobrança será efetuada no seu cartão de crédito cadastrado na sua
                  conta no mesmo dia da cobrança Livance.
                </IconListItemText>
              </IconListItem>
              <IconListItem>
                <IconListItemIcon>
                  <Check />
                </IconListItemIcon>
                <IconListItemText>
                  Confira nossa{" "}
                  <a
                    href="https://ajuda.livance.com.br/hc/pt-br/articles/38331254747923-Pacote-de-Servi%C3%A7o-Livance-Assist-Pro"
                    target="_blank"
                    rel="noopener"
                    className="underline"
                  >
                    Central de Ajuda
                  </a>{" "}
                  para mais detalhes.
                </IconListItemText>
              </IconListItem>
            </IconListRoot>
          </CardContent>
        </CardRoot>
      </div>

      <MessageDrawerRoot
        icon={Exclamation}
        setOpen={(open) => {
          if (!open) {
            void navigate({
              search: {
                showCancelSubscriptionDrawer: undefined,
              },
              replace: true,
            });
          }
        }}
        open={Boolean(searchParams.showCancelSubscriptionDrawer)}
        variant="secondary"
      >
        <MessageDrawerTitle>Cancelamento Assist Pro</MessageDrawerTitle>
        <MessageDrawerBody>
          Você ainda está no período de teste gratuito, então não precisa se preocupar com
          o cancelamento. Se quiser cancelar mesmo assim, entre em contato pelo WhatsApp:{" "}
          <a
            href={`https://wa.me/551132303323?text=${encodeURIComponent(message)}`}
            target="_blank"
            rel="noopener"
            className="text-secondary-400 underline"
          >
            (11) 3230-3323.
          </a>
        </MessageDrawerBody>
      </MessageDrawerRoot>
    </>
  );
};
