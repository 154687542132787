import { type FragmentType, graphql, useFragment } from "@repo/graphql-types";
import { mapPayablesToChartData } from "@/lib/mappers/payments-charts";
import { PaymentsPayablesStatusChart } from "@/components/payments-payables-status-chart";

export const PayablesStatusChartFragment = graphql(/* GraphQL */ `
  fragment PayablesStatusChartFragment on ExpensumGetPayablesChartsInfoOutput {
    periods {
      intervalEnd
      intervalStart
      waitingFunds {
        totalAmount
      }
      paid {
        totalAmount
      }
    }
  }
`);

interface PayablesStatusChartProps {
  data: FragmentType<typeof PayablesStatusChartFragment>;
}

export const PayablesStatusChart = ({ data }: PayablesStatusChartProps): JSX.Element => {
  const statusChartData = useFragment(PayablesStatusChartFragment, data);

  const chartData = mapPayablesToChartData(statusChartData);

  return (
    <PaymentsPayablesStatusChart
      chartData={chartData}
      statusChartData={statusChartData}
      titlePrimaryTotal="Total liberados"
      titleSecondaryTotal="Total previstos"
      labelPrimaryLegend="Liberados"
      labelSecondaryLegend="Previstos"
      dataKeys={{
        primary: "released",
        secondary: "waitingFunds",
      }}
    />
  );
};
