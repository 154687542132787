export enum AssistFeaturesEnum {
  ResgateAbandono = 1,
  PRM = 2,
  RelatorioConsulta = 3,
  RelatorioContato = 4,
  PersonalizarAtendimento = 5,
  ResgateCancelamento = 6,
  ResgateNoShow = 7,
  RelatorioSite = 8,
  AtendimentoDigital = 9,
  AtendimentoHumano = 10,
  ReguaDeConfirmacao = 11,
  CriarLinksRastreaveis = 12,
  LigacaoAtivaComIdentificador = 13,
}
