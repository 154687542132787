import { Left } from "@repo/icons";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useAtom } from "jotai";
import { graphql } from "@repo/graphql-types/gql";
import { useEffect } from "react";
import { z } from "zod";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import {
  appointmentFormAtom,
  isBasicInfoStepConcluded,
} from "@/lib/atoms/appointment-form-atom";
import { useGraphQL } from "@/hooks/use-graphql";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { AppointmentPatientForm } from "@/components/appointment-patient-form";

const AppointmentPatientPageQuery = graphql(`
  query AppointmentPatientPageQuery($codUsuarioCompromisso: Int!, $codUnidade: Int!) {
    ...AppointmentFormPatientFragment
  }
`);

const appointmentPatientPageSearchSchema = z.object({
  patientId: z.number().optional().catch(0),
  action: z.string().optional().catch(""),
  codAgendamento: z.number().optional(),
});

export const AppointmentPatientPage = (): JSX.Element => {
  const [formValues] = useAtom(appointmentFormAtom);

  const queryResult = useGraphQL(AppointmentPatientPageQuery, {
    codUsuarioCompromisso: formValues.appointmentType,
    codUnidade: formValues.unit,
  });

  const { data } = queryResult;

  const navigate = useNavigate();

  useEffect(() => {
    if (!isBasicInfoStepConcluded(formValues)) {
      void navigate({ to: "/" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Somente no carregamento inicial da página
  }, []);

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Novo agendamento" align="center" />
      </HeaderRoot>

      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data ? <AppointmentPatientForm data={data} /> : null}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/appointment/create/patient")({
  component: AppointmentPatientPage,
  beforeLoad: ({ context }) => {
    return {
      showCreatedAppointmentFeedbackDrawer:
        context.ldClient.getShowCreatedAppointmentFeedbackDrawer(),
    };
  },
  validateSearch: appointmentPatientPageSearchSchema,
});
