import { type ParsedLocation, redirect } from "@tanstack/react-router";
import { ServicePackageEnum, type UserContext } from "@repo/lib";

export const validateUserAccessToAssistMenu = ({
  location,
  user,
}: {
  location: ParsedLocation;
  user: UserContext;
}): void => {
  const userHasAccess = hasUserAssistAccess(user);
  const isLegacy = isUserLegacy(user);

  if (location.pathname === "/settings/assist" && !isLegacy && userHasAccess) {
    return;
  }

  if (location.pathname.includes("assist/customer-contact-overview/stories-feed")) {
    return;
  }

  if (shouldHandleLegacyUser(location, isLegacy, userHasAccess)) {
    return;
  }

  if (!userHasAccess) {
    if (isLegacy) {
      redirectToLegacySubscription();
    } else if (location.pathname.includes("/settings/assist")) {
      redirectToSettings();
    } else {
      redirectToAssistMenu();
    }
  }
};

const hasUserAssistAccess = (user: UserContext): boolean => {
  return user.pacotesServicos.some((x) => x.grupoUpgrade === "Assist");
};

const isUserLegacy = (user: UserContext): boolean => {
  return user.pacotesServicos.some(
    (servicePkg) => servicePkg.codPacoteServicos === ServicePackageEnum.Legacy.valueOf(),
  );
};

const redirectToAssistMenu = (): void => {
  redirect({ to: "/settings/assist/", throw: true });
};

const redirectToLegacySubscription = (): void => {
  redirect({ to: "/settings/assist/subscribe-assist-pro-legacy/", throw: true });
};

const redirectToSettings = (): void => {
  redirect({ to: "/settings/", throw: true });
};

const shouldHandleLegacyUser = (
  location: ParsedLocation,
  isLegacy: boolean,
  userHasAccess: boolean,
): boolean => {
  if (
    location.searchStr.includes("show-welcome-to-pro-drawer") &&
    isLegacy &&
    userHasAccess
  ) {
    return true;
  }

  const path = location.pathname;

  if (
    (path.includes("assist/subscribe-assist-pro-legacy") && isLegacy && !userHasAccess) ||
    (path.includes("/subscribe-assist-pro/confirm") && isLegacy && !userHasAccess) ||
    (path.includes("assist/subscribe-assist-pro") && isLegacy && userHasAccess)
  ) {
    if (path.includes("assist/subscribe-assist-pro") && userHasAccess) {
      redirectToAssistMenu();
    }
    return true;
  }

  return false;
};
