import { type UsuariosProfissionaisPlanos } from "@repo/graphql-types/graphql";
import { getCurrentDate } from "@repo/lib";
import { addMonths, format, isAfter, isSameDay, subDays } from "date-fns";

export interface Plan {
  codPlano?: number;
  nome: string;
  valor: number;
  temFidelidade: boolean;
  nomeExibicao: string;
  descricao?: string | null;
  tipoPlano: string;
  duracaoFidelidadeEmMeses: number;
  carenciaEmMeses: number;
  migravel: boolean;
}

export interface PlansMigrations {
  codPlanoOrigem: number;
  codPlanoDestino: number;
}

/**
 * Recebe uma data de registro do plano anual e retorna se está no último mês de fidelidade.
 *
 * @param paymentsCount - A quantidade de pagamentos recorrentes do plano atual.
 * @param plan - O plano atual do usuário.
 * @returns true ou false, indicando se o plano está no último mês de fidelidade ou não
 */
export const isInLastMonthOfPlan = (
  paymentsCount: number,
  plan: Pick<Plan, "temFidelidade" | "duracaoFidelidadeEmMeses"> | null | undefined,
): boolean => {
  if (!plan?.temFidelidade) {
    return true;
  }

  return paymentsCount > 0 && paymentsCount % plan.duracaoFidelidadeEmMeses === 0;
};

/**
 * Recebe uma lista de relações de planos para migrações, e um plano atual e o plano que o membro gostaria de migrar.
 * Retorna se a relação está na lista, o que significa que ele pode migrar imediatamente
 *
 * @param planosMigracoes - A lista de relações de planos para migrações.
 * @param currentPlanId - O id do plano atual do usuário.
 * @param selectedPlanId - O id do plano para o qual o usuário gostaria de migrar.
 * @returns true ou false, indicando se o plano terá migração imediata ou não
 */
export const canMigrateImmediately = (
  planosMigracoes: PlansMigrations[],
  currentPlanId: number,
  selectedPlanId: number,
): boolean => {
  return planosMigracoes.some(
    (p) => p.codPlanoDestino === selectedPlanId && p.codPlanoOrigem === currentPlanId,
  );
};

/**
 * Recebe um plano e informações sobre o plano atual e retorna se o plano é válido ou não para migração de plano.
 *
 * @param plan - O plano a ser verificado.
 * @param currentPlan - O plano atual do usuário.
 * @param paymentsCount - A quantidade de pagamentos recorrentes do plano atual.
 * @param planosMigracoes - A lista de relações de planos para migrações.
 * @returns true ou false, indicando se o plano é válido ou não.
 */
export const isPlanValidForMigration = (
  plan: Plan,
  currentPlan: Plan | null | undefined,
  paymentsCount: number,
  planosMigracoes: PlansMigrations[],
): boolean => {
  if (!currentPlan?.temFidelidade) {
    return true;
  }

  const isInLastMonth = isInLastMonthOfPlan(paymentsCount, currentPlan);

  if (isInLastMonth) {
    return true;
  }

  const isImediateMigration = canMigrateImmediately(
    planosMigracoes,
    currentPlan.codPlano ?? 0,
    plan.codPlano ?? 0,
  );

  return isImediateMigration;
};

/**
 * Recebe um nome de um plano cadastrado no banco de dados e retorna o nome que será exibido na tela.
 *
 * @param planName - O nome do plano.
 * @returns nome de exibição do plano na tela.
 */
export const getPlanDisplayNameForList = (planName: string): string => {
  return planName.includes("Light") ? "Plano Light" : "Plano Full";
};

/**
 * Recebe dados de um plano e retorna os pontos de destaque do plano.
 *
 * @param hasFidelity - Indica se o plano tem fidelidade.
 * @param memberRegistrationDate - A data de registro/ativação do usuário.
 * @param memberIsPsico - Indica se o usuário é um psicólogo/psicanalista/psicopedagogo.
 * @param description - Indica as descrições do plano.
 * @returns array com benefícios/destaques do plano.
 */
export const getPlanBenefits = (
  hasFidelity: boolean,
  memberRegistrationDate: string,
  memberIsPsico: boolean,
  monthlyPlanFidelity?: number,
  monthlyPlanGracePeriod?: number,
  description?: string | null,
): string[] => {
  const benefits = [];

  const descriptions = parsePlanDescriptionToJson(description ?? "");
  const descriptionsForUser = memberIsPsico ? descriptions?.psico : descriptions?.default;

  benefits.push(
    hasFidelity
      ? `Compromisso de ${monthlyPlanFidelity ?? 12} meses de utilização.`
      : getMonthlyPlanRetentionText(memberRegistrationDate, monthlyPlanGracePeriod ?? 3),
  );

  return descriptionsForUser ? [...benefits, ...descriptionsForUser] : benefits;
};

/**
 * Recebe o dia de pagamento da próxima cobrança e retorna a data da próxima cobrança.
 *
 * @param paymentDueDay - O dia de pagamento da próxima cobrança.
 * @param validationDay - A data de validação do membro.
 * @returns uma string com a data formatada da próxima cobrança
 */
export const getNextPaymentDate = (
  paymentDueDay: number,
  validationDay: string,
): string => {
  const today = new Date();
  const validationDate = new Date(validationDay);

  const sevenDaysAgo = subDays(today, 7);

  const wasValidatedInTheLastSevenDays =
    isAfter(validationDate, sevenDaysAgo) || isSameDay(validationDate, sevenDaysAgo);

  let dueDate = new Date(today.getFullYear(), today.getMonth(), paymentDueDay);

  if (today.getDate() > paymentDueDay) {
    dueDate = addMonths(dueDate, 1);
  }

  if (wasValidatedInTheLastSevenDays) {
    dueDate = addMonths(dueDate, 1);
  }

  const formattedDueDate = format(dueDate, "dd/MM/yyyy");

  return formattedDueDate;
};

/**
 * Recebe o dia de pagamento da próxima cobrança e retorna a próxima data de vencimento.
 *
 * @param paymentDueDay - O dia de pagamento da próxima cobrança.
 * @param validationDay - A data de validação do membro.
 * @returns uma string com a data formatada da próxima cobrança
 */
export const getNextDueDate = (dueDay: number, daysBeforeDueDate = 2): string => {
  const today = getCurrentDate();

  let dueDate = new Date(today.getFullYear(), today.getMonth(), dueDay);

  if (dueDate < today) {
    dueDate = addMonths(dueDate, 1);
  }

  let invoiceClosingDate = subDays(dueDate, daysBeforeDueDate);

  if (today >= invoiceClosingDate && today <= dueDate) {
    invoiceClosingDate = addMonths(invoiceClosingDate, 1);
  }

  return format(invoiceClosingDate, "dd/MM/yyyy");
};

/**
 * Recebe uma lista de planos e dados do plano atual e retorna a lista de planos filtrada e organizada.
 *
 * @param plans - A lista de planos disponíveis.
 * @param currentPlan - O plano atual do usuário.
 * @param memberRegistrationDate - A data de registro/ativação do usuário.
 * @param currentPlanRegistrationDate - A data de registro/ativação do plano atual do usuário.
 * @param memberIsPsico - Indica se o usuário é um psicólogo/psicanalista/psicopedagogo.
 * @param enableFullPlansForPsico - Indica se os planos Full estão habilitados para psicólogos/psicanalistas/psicopedagogos.
 * @param paymentsCount - A quantidade de pagamentos recorrentes do plano atual.
 * @returns array com planos filtrados e ordenados, com os planos Alice e Psico Light excluídos.
 */
export const getFilteredSortedPlans = (
  plans: Plan[],
  currentPlan: Plan,
  paymentsCount: number,
  planosMigracoes: PlansMigrations[],
): Plan[] => {
  const filteredPlans = plans.filter((plan) => currentPlan.codPlano !== plan.codPlano);

  // sorting plans to show valid plans first
  const sortedPlans = filteredPlans.sort((a, b) => {
    const isDisabledA = !isPlanValidForMigration(
      a,
      currentPlan,
      paymentsCount,
      planosMigracoes,
    );
    const isDisabledB = !isPlanValidForMigration(
      b,
      currentPlan,
      paymentsCount,
      planosMigracoes,
    );

    if (!isDisabledA && isDisabledB) {
      return -1;
    } else if (isDisabledA && !isDisabledB) {
      return 1;
    }
    return 0;
  });

  return sortedPlans;
};

/**
 * Retorna o devido texto de permanência do plano mensal
 *
 * @param memberRegistrationDate - A data de registro/ativação do usuário.
 * @returns - Retorna um texto a respeito do tempo de permanência do plano mensal.
 */
export const getMonthlyPlanRetentionText = (
  memberRegistrationDate: string,
  monthlyPlanGracePeriod: number,
): string => {
  const withoutGracePeriod = "Sem prazo de permanência mínima.";
  const withGracePeriod = `Permanência mínima de ${monthlyPlanGracePeriod} meses.`;

  if (memberRegistrationDate) {
    const hasPassedGracePeriod = hasUserRegistrationPassedGracePeriod(
      memberRegistrationDate,
      monthlyPlanGracePeriod,
    );

    return hasPassedGracePeriod ? withoutGracePeriod : withGracePeriod;
  }

  return withoutGracePeriod;
};

/**
 * Verifica se a data de validação do usuário já ultrapassou o período de carência do plano mensal
 *
 * @param memberRegistrationDate - A data de registro/ativação do usuário.
 * @returns - Retorna verdadeiro se a data de registro do usuário é mais antiga que três meses, do contrário, retorna falso.
 */
const hasUserRegistrationPassedGracePeriod = (
  memberRegistrationDate: string,
  monthlyPlanGracePeriod: number,
): boolean => {
  const registrationDate = new Date(memberRegistrationDate);

  const today = new Date();

  const threeMonthsAgo = new Date();
  threeMonthsAgo.setMonth(today.getMonth() - monthlyPlanGracePeriod);

  threeMonthsAgo.setHours(0, 0, 0, 0);
  registrationDate.setHours(0, 0, 0, 0);

  return registrationDate.getTime() <= threeMonthsAgo.getTime();
};

/**
 * Retorna o plano atual do usuário.
 *
 * @param plans - A lista de planos do usuário.
 * @returns - Retorna um objeto com dados do plano atual do usuário.
 */
export const getCurrentPlan = (plans: UsuariosProfissionaisPlanos[]): Plan => {
  return plans.length > 0
    ? plans[0].plano
    : {
        nome: "",
        valor: 0,
        temFidelidade: false,
        nomeExibicao: "",
        tipoPlano: "Mensal",
        duracaoFidelidadeEmMeses: 0,
        carenciaEmMeses: 0,
        migravel: false,
        descricao: "",
      };
};

interface PlanDescriptions {
  default: string[];
  psico: string[];
}

/**
 * Converte a descrição de um plano contendo um JSON para um objeto JSON.
 *
 * @param description - A string que contém os dados em formato JSON. Se estiver vazia ou inválida, retorna null.
 * @returns - Retorna o objeto JSON convertido ou null caso a string esteja vazia ou contenha um JSON inválido.
 */
export const parsePlanDescriptionToJson = (
  description: string,
): PlanDescriptions | null => {
  if (!description || description.trim() === "") {
    return null;
  }

  try {
    return JSON.parse(description) as PlanDescriptions;
  } catch (error) {
    return null;
  }
};
