import { cn } from "@repo/cn";
import { type SVGIconProps } from "@repo/icons";
import {
  type ComponentPropsWithoutRef,
  forwardRef,
  type FunctionComponent,
  memo,
  type MouseEventHandler,
} from "react";

export interface CpsButtonTextProps extends ComponentPropsWithoutRef<"button"> {
  size?: "base" | "xs" | "xl";
  color?: "neutral" | "primary" | "secondary" | "helper" | "danger";
  Icon?: FunctionComponent<SVGIconProps>;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  children: string;
}

const btnColors: Record<string, string> = {
  neutral:
    "text-neutral-500 hover:text-neutral-700 focus:text-neutral-700 disabled:bg-transparent disabled:text-neutral-200",
  primary:
    "text-primary-500 focus:text-primary-700 hover:text-primary-700 disabled:text-neutral-200 disabled:bg-transparent",
  secondary:
    "text-secondary-500 focus:text-secondary-700 hover:text-secondary-700 disabled:text-neutral-200 disabled:bg-transparent",
  helper:
    "text-helper-500 focus:text-helper-700 hover:text-helper-700 disabled:text-neutral-200 disabled:bg-transparent",
  danger:
    "text-danger-500 focus:text-danger-700 hover:text-danger-700 disabled:text-neutral-200 disabled:bg-transparent",
};

export const CpsButtonText = forwardRef<HTMLButtonElement, CpsButtonTextProps>(
  (
    { size = "base", color = "neutral", Icon, children, onClick, disabled, ...props },
    ref,
  ) => {
    const btnSize: Record<string, string> = {
      xs: "rounded-30 p-2 text-sm font-medium",
      base: "text-md rounded-30 p-2 font-medium",
      xl: "rounded-30 p-2 text-xl font-medium",
    };
    const sizeClasses = btnSize[size];
    const colorClasses = btnColors[color];
    const commonClasses =
      "flex select-none items-center justify-center text-center leading-5 transition-all duration-200";

    const iconSize = size === "xs" ? 16 : 24;
    const iconMarginRight = size === "xs" ? "mr-1" : "mr-2";

    const iconClasses: Record<string, string> = {
      neutral: "fill-neutral-500",
      primary: "fill-primary-500",
      secondary: "fill-secondary-500",
      helper: "fill-helper-400",
      danger: "fill-danger-500",
    };

    return (
      <button
        {...props}
        type="button"
        className={cn(sizeClasses, colorClasses, commonClasses, props.className)}
        ref={ref}
        onClick={onClick || undefined}
        disabled={disabled || false}
      >
        {Icon ? (
          <Icon
            className={`${iconMarginRight} ${disabled ? "fill-neutral-200" : iconClasses[color]}`}
            size={iconSize}
          />
        ) : null}
        {children}
      </button>
    );
  },
);

const MemoCpsButtonText = memo(CpsButtonText);

export default MemoCpsButtonText;
