import { createFileRoute } from "@tanstack/react-router";
import { Left } from "@repo/icons";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { AboutPaymentAnticipationDetails } from "@/components/about-payment-anticipation-details";

export const AboutPaymentAnticipationPage = (): JSX.Element => {
  return (
    <>
      <HeaderRoot>
        <HeaderButton align="start" icon={Left} />
        <HeaderTitle title="Sobre" align="center" />
      </HeaderRoot>
      <Page>
        <AboutPaymentAnticipationDetails />
      </Page>
    </>
  );
};

export const Route = createFileRoute(
  "/settings/payments/patient-payments/about-payment-anticipation",
)({
  component: AboutPaymentAnticipationPage,
});
