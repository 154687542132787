import { graphql } from "@repo/graphql-types";
import {
  AppointmentTypeDurationSuggestionStatus,
  formatWithZonedDate,
  logError,
} from "@repo/lib";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  AppointmentTypeDurationSuggestionAlertQueryDocument,
  GetAvailableTimesQueryDocument,
} from "@repo/graphql-types/graphql";
import {
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerRoot,
  DrawerTitle,
} from "@/components/drawer";
import { InfoSection } from "@/components/info-section";
import { formatTimeHourMinute, getTotalMinutes, parseTime } from "@/lib/time";
import { useGraphQLMutation, useInvalidateQuery } from "@/hooks/use-graphql";
import {
  FormRoot,
  FormHandlerSubmit,
  FormField,
  FormControl,
  FormItem,
} from "@/components/form";
import { Button } from "@/components/button";
import { trackEvent } from "@/lib/tracking";
import { TimeInput } from "@/components/time-input";

interface AppointmentTypeDurationSuggestionDrawerProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  appointmentTypeId: number;
  appointmentTypeName: string;
  actualDuration: string;
  newDuration: string;
  onSubmit?: (selectedValue: string) => void;
}

const DenyDurationSuggestionMutation = graphql(`
  mutation DenyDurationSuggestionMutation(
    $codUsuarioCompromisso: Int!
    $codUsuarioCompromissoTiposStatusSugestaoDuracao: Int!
    $dataUltimaAtualizacao: datetime2!
  ) {
    update_UsuariosCompromissosStatusSugestoesDuracoes_by_pk(
      pk_columns: { codUsuarioCompromisso: $codUsuarioCompromisso }
      _set: {
        dataUltimaAtualizacao: $dataUltimaAtualizacao
        codUsuarioCompromissoTiposStatusSugestaoDuracao: $codUsuarioCompromissoTiposStatusSugestaoDuracao
      }
    ) {
      codUsuarioCompromisso
      codUsuarioCompromissoTiposStatusSugestaoDuracao
      dataUltimaAtualizacao
    }
  }
`);

const AcceptDurationSuggestionMutation = graphql(`
  mutation AcceptDurationSuggestionMutation(
    $codUsuarioCompromisso: Int!
    $duracao: time!
    $codUsuarioCompromissoTiposStatusSugestaoDuracao: Int!
    $dataUltimaAtualizacao: datetime2!
  ) {
    update_usuariosCompromissos_by_pk(
      pk_columns: { codUsuarioCompromisso: $codUsuarioCompromisso }
      _set: { duracao: $duracao }
    ) {
      codUsuarioCompromisso
    }
    insert_UsuariosCompromissosStatusSugestoesDuracoes_one(
      object: {
        codUsuarioCompromisso: $codUsuarioCompromisso
        dataUltimaAtualizacao: $dataUltimaAtualizacao
        codUsuarioCompromissoTiposStatusSugestaoDuracao: $codUsuarioCompromissoTiposStatusSugestaoDuracao
      }
      if_matched: {
        match_columns: [codUsuarioCompromisso]
        update_columns: [
          dataUltimaAtualizacao
          codUsuarioCompromissoTiposStatusSugestaoDuracao
        ]
      }
    ) {
      codUsuarioCompromissoTiposStatusSugestaoDuracao
    }
  }
`);

export const AppointmentTypeDurationSuggestionDrawer = ({
  open,
  setOpen,
  appointmentTypeName,
  actualDuration,
  newDuration,
  appointmentTypeId,
  onSubmit,
}: AppointmentTypeDurationSuggestionDrawerProps): JSX.Element => {
  const { mutateAsync: denySuggestionMutation } = useGraphQLMutation(
    DenyDurationSuggestionMutation,
  );
  const { mutateAsync: acceptSuggestionMutation } = useGraphQLMutation(
    AcceptDurationSuggestionMutation,
  );

  const invalidateGetAvailableTimesQuery = useInvalidateQuery(
    GetAvailableTimesQueryDocument,
  );

  const invalidateAppointmentTypeDurationSuggestionAlertQuery = useInvalidateQuery(
    AppointmentTypeDurationSuggestionAlertQueryDocument,
  );

  const suggestionTime = parseTime(newDuration);

  const appointmentTypeDurationSuggestionFormSchema = z.object({
    duration: z.string().refine(
      (duration) => {
        const durationInMinutes = getTotalMinutes(duration);
        return durationInMinutes >= 30;
      },
      {
        message: "A duração precisa ser maior ou igual a 30 minutos",
      },
    ),
  });

  type FormFields = z.infer<typeof appointmentTypeDurationSuggestionFormSchema>;
  const form = useForm<FormFields>({
    resolver: zodResolver(appointmentTypeDurationSuggestionFormSchema),
    defaultValues: {
      duration: newDuration,
    },
  });

  const section = {
    title: "",
    fields: [
      { label: "Duração atual", value: formatTimeHourMinute(actualDuration) },
      {
        label: "Duração recomendada",
        value: formatTimeHourMinute(suggestionTime),
      },
    ],
  };

  const acceptDurationSuggestion = async (): Promise<void> => {
    const onSuccess = (): void => {
      trackEvent("Sugestao de Duracao Alterada", {
        codUsuarioCompromisso: appointmentTypeId,
        duracaoTeorica: actualDuration,
        duracaoSugerida: suggestionTime,
        duracaoNova: newDuration,
      });
      invalidateGetAvailableTimesQuery();
      invalidateAppointmentTypeDurationSuggestionAlertQuery();
    };
    const onError = (err: Error): void => {
      logError(err);
    };

    const today = formatWithZonedDate(new Date(Date.now()), "yyyy-MM-dd");

    const formDuration = form.getValues("duration");

    const isValid = await form.trigger();
    if (!isValid) {
      return;
    }

    onSubmit && onSubmit(formatTimeHourMinute(formDuration));
    await acceptSuggestionMutation(
      {
        codUsuarioCompromisso: appointmentTypeId,
        duracao: formatTimeHourMinute(formDuration),
        codUsuarioCompromissoTiposStatusSugestaoDuracao:
          AppointmentTypeDurationSuggestionStatus.Modified,
        dataUltimaAtualizacao: today,
      },
      {
        onSuccess,
        onError,
      },
    );

    setOpen(false);
  };

  const denyDurationSuggestion = async (): Promise<void> => {
    const onSuccess = (): void => {
      invalidateGetAvailableTimesQuery();
      invalidateAppointmentTypeDurationSuggestionAlertQuery();
    };
    const onError = (err: Error): void => {
      logError(err);
    };

    const today = formatWithZonedDate(new Date(Date.now()), "yyyy-MM-dd");

    await denySuggestionMutation(
      {
        codUsuarioCompromisso: appointmentTypeId,
        codUsuarioCompromissoTiposStatusSugestaoDuracao:
          AppointmentTypeDurationSuggestionStatus.Denied,
        dataUltimaAtualizacao: today,
      },
      {
        onSuccess,
        onError,
      },
    );
  };

  const handleCloseDrawer = async (): Promise<void> => {
    if (!form.formState.isSubmitted) {
      await denyDurationSuggestion();
    }
  };

  return (
    <DrawerRoot
      open={open}
      setOpen={setOpen}
      onClose={() => {
        void handleCloseDrawer();
      }}
    >
      <DrawerContent>
        <DrawerHeader>
          <DrawerTitle>Sugestão de duração</DrawerTitle>
        </DrawerHeader>
        <FormRoot {...form}>
          <FormHandlerSubmit>
            <DrawerBody>
              <div className="flex flex-col gap-4">
                <p>
                  Com base no seu histórico, sugerimos que o tipo de atendimento &apos;
                  <span className="font-semibold">{appointmentTypeName}</span>
                  &apos; tenha sua duração alterada. Dessa forma, conseguimos reservar as
                  salas com precisão, garantindo tranquilidade durante seus atendimentos.
                </p>
                <div className="px-4 py-4 bg-neutral-50 gap-6 rounded-40 w-full">
                  <InfoSection
                    section={section}
                    includeLastLine={false}
                    lineColorLevel={300}
                  />
                </div>
                <FormField
                  control={form.control}
                  name="duration"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <TimeInput
                          label="Duração"
                          timerConfigs={{
                            minHour: 0,
                            minMinutes: 0,
                            maxMinutes: 59,
                            stepHour: 1,
                            stepMinutes: 5,
                            initialHour: suggestionTime.hour,
                            initialMinutes: suggestionTime.minute,
                            maxHour: 3,
                          }}
                          {...field}
                          value={field.value}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>
            </DrawerBody>
            <DrawerFooter>
              <Button onClick={() => void acceptDurationSuggestion()}>Salvar</Button>
            </DrawerFooter>
          </FormHandlerSubmit>
        </FormRoot>
      </DrawerContent>
    </DrawerRoot>
  );
};
