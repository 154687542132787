import { graphql } from "@repo/graphql-types/gql";
import { Left } from "@repo/icons";
import { createFileRoute } from "@tanstack/react-router";
import { useGraphQL } from "@/hooks/use-graphql";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { PendingComponent } from "@/components/pending-component";
import { FullPageSpinner } from "@/components/full-page-spinner";
import { UserTrackableLinksList } from "@/components/user-trackable-links/user-trackable-links-list";

const UserTrackableLinksQuery = graphql(/* GraphQL */ `
  query UserTrackableLinksQuery {
    ...UserTrackableLinksQueryFragment
  }
`);

export const UserTrackableLinksPage = (): JSX.Element => {
  const queryResult = useGraphQL(UserTrackableLinksQuery);
  const { data } = queryResult;

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Links com rastreamento" />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          <div className="flex flex-col gap-2 mb-4 mt-2">
            <h2 className="text-neutral-600 font-semibold text-xl leading-8">
              📊 Crie, compartilhe e monitore seus links de agendamento
            </h2>
            <p className="text-neutral-600 text-sm">
              Gere links diretos para seu site ou página de agendamento, compartilhe com
              seus contatos ou redes sociais e acompanhe os resultados.
            </p>
          </div>
          {data ? (
            <UserTrackableLinksList userTrackableLinkList={data} />
          ) : (
            <FullPageSpinner />
          )}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/assist/user-trackable-links/")({
  component: UserTrackableLinksPage,
  pendingComponent: () => <PendingComponent title="Links com rastreamento" />,
});
