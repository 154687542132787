import { CpsFeedbackBubble } from "corpus";
import { ExclamationCircle } from "@repo/icons";
import { Link, useRouteContext } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types/gql";
import {
  MessageDrawerActionButton,
  MessageDrawerActions,
  MessageDrawerBody,
  MessageDrawerRoot,
  MessageDrawerTitle,
} from "@/components/message-drawer";
import { useGraphQL } from "@/hooks/use-graphql";
import { formatRecipientDocument } from "@/lib/string";

interface ChangeBankingInstitutionDrawerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const ChangeBankingInstitutionQuery = graphql(`
  query ChangeBankingInstitutionQuery($codUsuario: Int!, $codClinica: Int) {
    expensumRecipient: ExpensumGetRecipient(
      arg1: { codUsuario: $codUsuario, codClinica: $codClinica }
    ) {
      recipient {
        document
        type
      }
    }
  }
`);

export const ChangeBankingInstitutionDrawer = ({
  open,
  setOpen,
}: ChangeBankingInstitutionDrawerProps): JSX.Element => {
  const routerContext = useRouteContext({ strict: false });
  const { user } = routerContext;

  const queryData = useGraphQL(ChangeBankingInstitutionQuery, {
    codUsuario: Number(user.codUsuario),
    codClinica: Number(user.codClinica),
  });

  const { data } = queryData;

  const recipient = data?.expensumRecipient?.recipient;
  const recipientType = recipient?.type === "company" ? "CNPJ" : "CPF";

  const recipientDocument = formatRecipientDocument(
    String(recipient?.type),
    String(recipient?.document),
  );

  const changeBankingInstitutionText = (
    <>
      Confirme se a nova conta é referente ao {recipientType} {recipientDocument}.{" "}
      <b className="font-semibold">
        Erros na titularidade podem bloquear seus recebimentos.
      </b>{" "}
      Em caso de dúvidas, fale com o nosso time.
    </>
  );

  return (
    <MessageDrawerRoot setOpen={setOpen} open={open}>
      <div className="items-center justify-center flex flex-col">
        <CpsFeedbackBubble Icon={ExclamationCircle} fill="primary" />
      </div>
      <MessageDrawerTitle>Trocar instituição bancária</MessageDrawerTitle>
      <MessageDrawerBody>
        <div className="flex flex-col gap-4 text-center">
          <p>{changeBankingInstitutionText}</p>
        </div>
      </MessageDrawerBody>
      <MessageDrawerActions>
        <Link replace to="/settings/payments/bank-account/update">
          <MessageDrawerActionButton>Confirmo titularidade</MessageDrawerActionButton>
        </Link>
        <a
          target="_blank"
          href="https://api.whatsapp.com/send/?phone=551132304410&text&type=phone_number&app_absent=0"
          rel="noopener noreferrer"
        >
          <MessageDrawerActionButton secondary>
            Conversar com o time
          </MessageDrawerActionButton>
        </a>
      </MessageDrawerActions>
    </MessageDrawerRoot>
  );
};
