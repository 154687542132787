import { CpsAvatar } from "corpus";

interface Patient {
  name: string;
  email: string;
  phone: string;
}

interface PatientInfoCardProps {
  patient: Patient;
}

export const PatientInfoCard = ({ patient }: PatientInfoCardProps): JSX.Element => {
  const { name, email, phone } = patient;

  return (
    <div className="flex flex-row gap-2 my-2">
      {/* TODO: Trocar para o PatientAvatar, precisa dar uma refatorada nele pois não da para usar o mesmo fragment */}
      <CpsAvatar initials={name} color="primaryLight" size={40} />
      <div className="flex flex-col">
        <h1 className="text-neutral-600  font-medium text-md">{name}</h1>
        <p className="text-neutral-500 font-normal text-sm">{email}</p>
        <p className="text-neutral-500 font-normal text-sm">{phone}</p>
      </div>
    </div>
  );
};
