import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import {
  formatDateAndTimeIgnoringTimezone,
  formatWithZonedDate,
  PagarmePayableStatus,
} from "@repo/lib";
import { Item, ItemContent, ItemMainContent } from "@/components/list";
import { PayableStatus } from "@/components/payable-status";
import { NextPayableInfo } from "@/components/next-payable-info";
import { PatientPaymentsAmountHeader } from "@/components/patient-payments-amount-header";

export const PatientPaymentsListItemV2Fragment = graphql(/* GraphQL */ `
  fragment PatientPaymentsListItemV2Fragment on ExpensumGetPaymentInfoV2 {
    status
    meioPagamentoSelecionado
    valor
    criadoEm
    codAgendamento
    agendamento {
      data
      horaInicio
      paciente {
        nome
      }
    }
    checkout {
      guid
      nomeDoPagador
    }
    parcelas
    hasAutomaticAnticipation
    payableInfo {
      status
      installment
      nextPaymentDate
    }
  }
`);

interface PatientPaymentListItemV2Props {
  data: FragmentType<typeof PatientPaymentsListItemV2Fragment>;
}

export const PatientPaymentListItemV2 = ({
  data,
}: PatientPaymentListItemV2Props): JSX.Element => {
  const fragmentResult = useFragment(PatientPaymentsListItemV2Fragment, data);
  const {
    hasAutomaticAnticipation,
    parcelas,
    meioPagamentoSelecionado,
    status: paymentStatus,
    payableInfo,
    agendamento,
    criadoEm,
    checkout,
    valor,
  } = fragmentResult;
  const { status: payableStatus, nextPaymentDate, installment } = payableInfo ?? {};

  const payableIsWaitingFunds = payableStatus === PagarmePayableStatus.WaitingFunds;

  const buildPaymentMethodInformation = (): string => {
    const installments = parcelas;
    const paymentType = meioPagamentoSelecionado;

    const buildCreditLabel = (): string => {
      if (!installments) return "Crédito";

      return `Crédito ${installments}x`;
    };

    const paymentMethodsDictionary: Record<string, string> = {
      credito: buildCreditLabel(),
      debito: "Débito",
      pix: "PIX",
    };

    return paymentType ? paymentMethodsDictionary[paymentType] : "";
  };

  const paymentMethodInfo = buildPaymentMethodInformation();

  const buildDateInformation = (): string => {
    if (criadoEm) {
      return `Criado em ${formatWithZonedDate(criadoEm, "dd/MM/yyyy • HH:mm")}`;
    }

    if (agendamento) {
      const date = agendamento.data;
      const horaInicio = agendamento.horaInicio;
      const appointmentDate = formatDateAndTimeIgnoringTimezone(
        date,
        horaInicio,
        "dd/MM/yyyy • HH:mm",
      );

      return `Criado em ${appointmentDate}`;
    }

    return "";
  };

  const getSearchParamsToChangeStatus = (): {
    guid?: string;
    appointmentId?: number;
    payment: typeof fragmentResult;
  } => {
    if (fragmentResult.checkout?.guid) {
      return {
        guid: fragmentResult.checkout.guid,
        payment: {
          // enquanto a v1 estiver ativa precisamos manter passar o
          // payment inteiro para funcionar o drawer de alterar status
          ...fragmentResult,
        },
      };
    }

    return {
      appointmentId: fragmentResult.codAgendamento ?? undefined,
      payment: {
        ...fragmentResult,
      },
    };
  };

  return (
    <Item
      className="px-4"
      linkProps={{
        to: "/settings/payments/patient-payments/details-v2",
        search: getSearchParamsToChangeStatus,
      }}
    >
      <ItemContent>
        <ItemMainContent className="mb-1 flex-row flex justify-start gap-2 w-full">
          <PatientPaymentsAmountHeader
            status={paymentStatus}
            amount={valor}
            paymentMethod={paymentMethodInfo}
          />
        </ItemMainContent>
        <p className="text-md font-medium text-gray-800">
          {agendamento?.paciente.nome ?? checkout?.nomeDoPagador ?? ""}
        </p>
        <p className="text-sm text-gray-600 mb-1">{buildDateInformation()}</p>
        <div>
          {payableStatus ? <PayableStatus status={payableStatus} /> : null}
          {payableIsWaitingFunds && nextPaymentDate ? (
            <NextPayableInfo
              hasAutomaticAnticipation={hasAutomaticAnticipation}
              installment={installment}
              installments={parcelas}
              nextPaymentDate={nextPaymentDate}
            />
          ) : null}
        </div>
      </ItemContent>
    </Item>
  );
};
