import { formatWithZonedDate } from "@repo/lib";
import { Money } from "@repo/icons";
import { type FragmentType, graphql, useFragment } from "@repo/graphql-types";
import { InfoSectionV2 } from "@/components/info-section-v2";
import { PatientPaymentMethodInfo } from "@/components/patient-payment-method-info";

const ORIGIN_PAYMENT_DICTIONARY: Record<string, string> = {
  Online: "Link de pagamento",
};

const TYPE_PAYMENT_ONLINE_DICTIONARY: Record<string, string> = {
  pre: "Pré atendimento",
  pos: "Pós atendimento",
  avulso: "Pagamento Avulso",
};

export const PatientPaymentsDetailsPaymentSectionFragment = graphql(`
  fragment PatientPaymentsDetailsPaymentSectionFragment on ExpensumPaymentDetailsOutput {
    acquirerTransactionKey
    createdAt
    installments
    origin
    paidAt
    paymentMethod
    status
    refundedAt
    note
    brand
    onlinePaymentInfo {
      type
    }
  }
`);

interface PatientPaymentsDetailsPaymentSectionProps {
  data: FragmentType<typeof PatientPaymentsDetailsPaymentSectionFragment>;
}

export const PatientPaymentsDetailsPaymentSection = ({
  data,
}: PatientPaymentsDetailsPaymentSectionProps): JSX.Element => {
  const payment = useFragment(PatientPaymentsDetailsPaymentSectionFragment, data);

  const getOriginPaymentText = (key: string): string =>
    ORIGIN_PAYMENT_DICTIONARY[key] ?? key;

  const getTypePaymentOnlineText = (key: string): string =>
    TYPE_PAYMENT_ONLINE_DICTIONARY[key] ?? key;

  const buildPaymentMethodInformation = (): JSX.Element | undefined => {
    const { installments, paymentMethod, brand } = payment;

    if (!paymentMethod) return undefined;

    return (
      <PatientPaymentMethodInfo
        paymentMethod={paymentMethod}
        installments={installments}
        brand={brand}
      />
    );
  };

  const paymentSection = {
    Icon: Money,
    title: "Detalhes do pagamento",
    fields: [
      {
        label: "Criado em",
        value: formatWithZonedDate(payment.createdAt, "dd/MM/yyyy"),
      },
      {
        label: "Estornado em",
        value: payment.refundedAt
          ? formatWithZonedDate(payment.refundedAt, "dd/MM/yyyy • HH:mm")
          : undefined,
      },
      {
        label: "Pago em",
        value:
          payment.status === "paid" && payment.paidAt
            ? formatWithZonedDate(payment.paidAt, "dd/MM/yyyy • HH:mm")
            : undefined,
      },
      {
        label: "Forma de pagamento",
        value: buildPaymentMethodInformation(),
      },
      {
        label: "Origem",
        value: getOriginPaymentText(payment.origin),
      },
      {
        label: "Tipo",
        value: getTypePaymentOnlineText(payment.onlinePaymentInfo?.type ?? ""),
      },
      {
        label: "ID da transação",
        value: payment.acquirerTransactionKey ?? "",
      },
    ],
  };

  if (payment.status === "canceled" && payment.note) {
    paymentSection.fields.push({
      label: "Observação",
      value: "Cancelamento",
    });
  }

  return (
    <>
      <InfoSectionV2 section={paymentSection} showBorderForLastField={!payment.note} />
      {payment.note ? (
        <p className="text-sm text-medium-500 mt-[-30px]">{payment.note}</p>
      ) : null}
    </>
  );
};
