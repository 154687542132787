import { Share } from "@repo/icons";
import { endOfDay } from "date-fns";
import { format } from "date-fns-tz";
import { useState } from "react";
import { type NuntiusGetCustomerContactManyPeriodSummaryOutput } from "@repo/graphql-types/graphql";
import { graphql } from "@repo/graphql-types";
import { HeaderButton } from "@/components/header";
import { FIVE_MINUTES_STALE_TIME } from "@/lib/constants/dashboards-customer-overview";
import { useGraphQL } from "@/hooks/use-graphql";
import { contactReasonMap } from "@/enums/customer-contact/contact-reason";
import { noSchedulingReasonMap } from "@/enums/customer-contact/no-scheduling-reason";
import { getShareReportFromDate, shareReport } from "@/lib/dashboard";
import { createFileContentByCsvRows, createFileName } from "@/lib/csv-utils";

export const CustomerContactDashboardShareReportQuery = graphql(/* GraphQL */ `
  query CustomerContactDashboardShareReportQuery($from: String!, $to: String!) {
    NuntiusGetCustomerContactManyPeriodSummary(arg1: { from: $from, to: $to }) {
      totalHumanCustomerContacts
      totalHumanCustomerContactsChat
      totalHumanCustomerContactsVoice
      period
      mainReasonsOccurrences {
        key
        total
        percent
      }
      totalCustomerContacts
      totalCustomerContactsChat
      totalCustomerContactsVoice
      percentCustomerContactsAppointmentsScheduled
      totalCustomerContactsAppointmentsScheduled
      totalCustomerContactsNewAppointmentMainReason
      mostFrequentNotScheduledAppointmentReasons {
        key
        total
        percent
      }
      totalNewCustomerContacts
      totalOldCustomerContacts
    }
  }
`);

interface CustomerContactDashboardShareReportProps {
  earliestDate: Date;
}

export const CustomerContactDashboardShareReport = ({
  earliestDate,
}: CustomerContactDashboardShareReportProps): JSX.Element => {
  const [shouldFetchShareReportData, setShouldFetchShareReportData] = useState(false);

  const endOfToday = endOfDay(new Date());

  const fromDate = getShareReportFromDate(earliestDate);

  const toDate = format(endOfToday, "yyyy-MM-dd HH:mm:ssXXX", {
    timeZone: "America/Sao_Paulo",
  });

  const { data, isPending, refetch } = useGraphQL(
    CustomerContactDashboardShareReportQuery,
    {
      from: fromDate,
      to: toDate,
    },
    {
      staleTime: FIVE_MINUTES_STALE_TIME,
      enabled: shouldFetchShareReportData,
    },
  );

  let periodData = data?.NuntiusGetCustomerContactManyPeriodSummary ?? [];

  const generateReportData = (): { fileName: string; fileContent: string } => {
    const csvRows: string[][] = [];

    const headerRow = [
      "Mês/Ano",
      "Total de contatos",
      "Total de Contatos - Whatsapp",
      "Total de Contatos - Telefone",
      "Distribuição de contatos - Pacientes",
      "Distribuição de contatos - Novos contatos",
      "Motivos de contato",
      "Conversão de agendamento - Interessados em agendar",
      "Conversão de agendamento - Agendaram",
      "Porcentagem de conversão de agendamento",
      "Motivos de não agendamento",
      "Total de contatos tratados por secretárias",
      "Contatos tratados por secretárias - Whatsapp",
      "Contatos tratados por secretárias - Telefone",
    ];

    csvRows.push(headerRow);

    periodData.forEach((item) => {
      const mainContactReasonsRow = createMainContactReasonsRow(
        item.mainReasonsOccurrences,
        item.totalCustomerContacts,
      );
      const mostFrequentNotScheduledReasonsRow = createMostFrequentNotScheduledReasonsRow(
        item.mostFrequentNotScheduledAppointmentReasons,
      );

      const row = [
        item.period,
        `${item.totalCustomerContacts}`,
        `${item.totalCustomerContactsChat}`,
        `${item.totalCustomerContactsVoice}`,
        `${item.totalOldCustomerContacts}`,
        `${item.totalNewCustomerContacts}`,
        mainContactReasonsRow,
        `${item.totalCustomerContactsNewAppointmentMainReason}`,
        `${item.totalCustomerContactsAppointmentsScheduled}`,
        `${item.percentCustomerContactsAppointmentsScheduled}%`,
        mostFrequentNotScheduledReasonsRow,
        `${item.totalHumanCustomerContacts}`,
        `${item.totalHumanCustomerContactsChat}`,
        `${item.totalHumanCustomerContactsVoice}`,
      ];

      csvRows.push(row);
    });

    return {
      fileContent: createFileContentByCsvRows(csvRows),
      fileName: createFileName("relatorio_de_contatos"),
    };
  };

  const handleShareReport = async (): Promise<void> => {
    setShouldFetchShareReportData(true);

    if (isPending) {
      const refetchResult = await refetch();

      periodData = refetchResult.data?.NuntiusGetCustomerContactManyPeriodSummary ?? [];
    }

    if (!periodData.length) return;

    const { fileName, fileContent } = generateReportData();

    await shareReport({
      fileName,
      fileContent,
      trackingLabel: "contatos",
    });
  };

  return (
    <div className="absolute right-4">
      <HeaderButton
        icon={Share}
        onClick={() => void handleShareReport()}
        title="Compartilhar relatório de contatos"
      />
    </div>
  );
};

const createMostFrequentNotScheduledReasonsRow = (
  mostFrequentNotScheduledAppointmentReasons: NuntiusGetCustomerContactManyPeriodSummaryOutput["mainReasonsOccurrences"],
): string => {
  if (!mostFrequentNotScheduledAppointmentReasons.length) return "";

  const mostFrequentNotScheduledReasons = mostFrequentNotScheduledAppointmentReasons
    .map((reason) => `${noSchedulingReasonMap[reason.key]}: ${reason.total}`)
    .join(" | ");

  return mostFrequentNotScheduledReasons;
};

const createMainContactReasonsRow = (
  mainReasonsOccurrences: NuntiusGetCustomerContactManyPeriodSummaryOutput["mainReasonsOccurrences"],
  totalCustomerContacts: number,
): string => {
  if (!mainReasonsOccurrences.length) return "";

  const contactsWithSelectedReason = mainReasonsOccurrences.reduce(
    (sum, reason) => sum + reason.total,
    0,
  );

  const mostFrequentScheduledReasons = Object.entries(contactReasonMap).map(
    ([key, value]) => {
      return {
        contactReason: value,
        value: mainReasonsOccurrences.find((y) => y.key === Number(key))?.total ?? 0,
      };
    },
  );

  const contactsWithoutReason = totalCustomerContacts - contactsWithSelectedReason;

  if (contactsWithoutReason > 0) {
    mostFrequentScheduledReasons.push({
      value: contactsWithoutReason,
      contactReason: "Motivo não informado",
    });
  }

  return mostFrequentScheduledReasons
    .map((reason) => `${reason.contactReason}: ${reason.value}`)
    .join(" | ");
};
