import { createFileRoute } from "@tanstack/react-router";
import { Left } from "@repo/icons";
import { ServicePackageEnum } from "@repo/lib";
import { Page } from "@/components/page";
import { HeaderRoot, HeaderUpButton, HeaderTitle } from "@/components/header";
import { SubscribedAssist } from "@/components/subscribed-assist-pro";
import { validatePackageAccess } from "@/lib/assist-package-guard";

export const SubscribedAssistPage = (): JSX.Element => {
  return (
    <>
      <HeaderRoot>
        <HeaderUpButton icon={Left} align="start" />
        <HeaderTitle title="Livance Assist Pro" />
      </HeaderRoot>
      <Page>
        <SubscribedAssist />
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/assist/subscribed-assist/")({
  component: SubscribedAssistPage,
  beforeLoad: ({context}) => { 
    const { user } = context;
    validatePackageAccess({user, packageService: ServicePackageEnum.Professional});
  }
});
