import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { Mail, PhoneOut, Whatsapp } from "@repo/icons";
import { generateContactUrl } from "@repo/lib/src/helpers/contact-url";
import { useSearch, useRouter } from "@tanstack/react-router";
import { PatientAvatar } from "@/components/patient-avatar";
import { PatientDetailActionListItem } from "@/components/patient-detail-action-list-item";

export const PatientDetailActionListFragment = graphql(/* GraphQL */ `
  fragment PatientDetailActionListFragment on pacientes {
    telefone
    nome
    email
    codPaciente
    ...PatientAvatarFragment
  }
`);

export const PatientDetailActionList = (props: {
  patient: FragmentType<typeof PatientDetailActionListFragment>;
}): JSX.Element => {
  const patient = useFragment(PatientDetailActionListFragment, props.patient);

  const { nome, codPaciente, telefone, email } = patient;
  const router = useRouter();
  const searchParams = useSearch({
    from: "/patients/$patientId",
  });

  const isModalOverlayOpen: boolean = searchParams.action === "expand-avatar-image";
  const handleOpenModalOverlay = (value: boolean): void => {
    if (value) {
      void router.navigate({
        to: "/patients/$patientId",
        params: { patientId: String(codPaciente) },
        search: { ...searchParams, action: "expand-avatar-image" },
      });
    } else if (isModalOverlayOpen) {
      router.history.back();
    }
  };

  return (
    <>
      <div className="flex items-center flex-col w-full ">
        <PatientAvatar
          shouldOpenModalOverlay
          isModalOverlayOpen={isModalOverlayOpen}
          setIsModalOverlayOpen={() => handleOpenModalOverlay(!isModalOverlayOpen)}
          color="primaryLight"
          data={patient}
          size={96}
        />
        <p className="font-medium mt-2">{nome}</p>
      </div>
      <div className="flex justify-between mt-6 mb-2">
        <div className="w-full">
          <PatientDetailActionListItem
            Icon={PhoneOut}
            onClick={() => openUrl(generateContactUrl(telefone, "phone"))}
            text="Ligação"
          />
        </div>
        <div className="w-full mx-2">
          <PatientDetailActionListItem
            Icon={Mail}
            onClick={() => openUrl(generateContactUrl(email ?? "", "email"))}
            text="E-mail"
          />
        </div>
        <div className="w-full">
          <PatientDetailActionListItem
            Icon={Whatsapp}
            onClick={() => openUrl(generateContactUrl(telefone, "whatsapp"), true)}
            text="WhatsApp"
          />
        </div>
      </div>
    </>
  );
};

function openUrl(url: string, openOnNewTab = false): void {
  if (!url) return;

  if (!openOnNewTab) {
    window.open(url);
    return;
  }

  window.open(url, "_blank")?.focus();
}
