
export const AssistProBadge = (): JSX.Element => {
  return (
    <div className="py-1">
      <div
        className="flex items-center justify-center w-[35px] h-[17px] rounded-md"
        style={{
          background:
            "linear-gradient(45deg, #F5AE45, #F5AE45, #F5AE45, #EBBE7B, #EBBE7B, #E1CDAF, #D3D5D6, #D3D5D6, #8BA7B0, #8CA7B1)",
        }}
      >
        <span className="text-xs font-bold text-black">PRO</span>
      </div>
    </div>
  );
};
