import { graphql } from "@repo/graphql-types";
import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { useSearch, useRouter } from "@tanstack/react-router";
import { ProfessionalUserPreferredName } from "@/components/professional-user-preferred-name";
import { ProfilePicture } from "@/components/profile-picture";
import { ProfilePictureAlert } from "@/components/profile-picture-alert";
import { ChangeProfilePictureButton } from "@/components/change-profile-picture-button";

export const MyProfileDetailsSectionFragment = graphql(`
  fragment MyProfileDetailsSectionFragment on query_root {
    ...ProfilePictureFragment
    ...ProfilePictureAlertFragment
    ...ProfessionalUserPreferredNameFragment
  }
`);

export const MyProfileDetailsSection = (props: {
  isError: boolean;
  queryData: FragmentType<typeof MyProfileDetailsSectionFragment>;
}): JSX.Element => {
  const fragmentData = useFragment(MyProfileDetailsSectionFragment, props.queryData);

  const router = useRouter();
  const searchParams = useSearch({
    from: "/settings/profile/",
  });

  const isModalOverlayOpen: boolean = searchParams.action === "expand-avatar-image";
  const handleOpenModalOverlay = (value: boolean): void => {
    if (value) {
      void router.navigate({
        to: "/settings/profile/",
        search: { ...searchParams, action: "expand-avatar-image" },
      });
    } else if (isModalOverlayOpen) {
      router.history.back();
    }
  };
  return (
    <>
      <div className="flex flex-col items-center gap-4 relative">
        <div className="relative">
          <ProfilePicture
            shouldOpenModalOverlay
            isModalOverlayOpen={isModalOverlayOpen}
            setIsModalOverlayOpen={() => handleOpenModalOverlay(!isModalOverlayOpen)}
            isError={props.isError}
            queryData={fragmentData}
          />
          <ChangeProfilePictureButton />
        </div>
        <ProfessionalUserPreferredName isError={props.isError} queryData={fragmentData} />
      </div>
      <ProfilePictureAlert isError={props.isError} queryData={fragmentData} />
    </>
  );
};
