"use client";

import { User1 } from "@repo/icons";
import { clsx } from "clsx";
import { useEffect, useState, type JSX } from "react";

interface CpsAvatarProps {
  size?: 24 | 40 | 56 | 72 | 96 | 156;
  initials?: string;
  src?: string;
  color?: string;
  priority?: boolean;
  alt?: string;
}

interface SizeProps {
  fontSize: string;
  twSize: string;
}

const imageSizes: Record<number, SizeProps> = {
  24: {
    fontSize: "text-xs",
    twSize: "w-6 h-6",
  },
  40: {
    fontSize: "text-md",
    twSize: "w-10 h-10",
  },
  56: {
    fontSize: "text-xl",
    twSize: "w-14 h-14",
  },
  72: {
    fontSize: "text-2xl",
    twSize: "w-[72px] h-[72px]",
  },
  96: {
    fontSize: "text-4xl",
    twSize: "w-[96px] h-[96px]",
  },
  156: {
    fontSize: "text-7xl",
    twSize: "w-[156px] h-[156px]",
  },
};

const backgroundColors: Record<string, string> = {
  white: "bg-white text-neutral-600",
  secondaryLight: "bg-secondary-100 text-neutral-600",
  primaryLight: "bg-primary-100 text-neutral-600",
  neutralLight: "bg-neutral-200 text-neutral-600",
  dangerLight: "bg-danger-200 text-neutral-600",
};

const iconColors: Record<string, string> = {
  white: "fill-neutral-500",
  secondaryLight: "fill-secondary-400",
  primaryLight: "fill-primary-400",
  neutralLight: "fill-neutral-400",
  dangerLight: "fill-danger-400",
};

type AllowedIconSizes = 16 | 32 | 48 | 64 | 76 | 124;

const iconSizes: Record<number, AllowedIconSizes> = {
  24: 16,
  40: 32,
  56: 48,
  72: 64,
  96: 76,
  156: 124,
};

/**
 * Displays an avatar.
 *
 * @param size - The size of the avatar image.
 * @param color - The color of the avatar image.
 * @param initials - The initials of the user.
 * @param src - The source URL of the avatar image.
 * @param alt - The alternative text for the avatar image.
 */
export const CpsAvatar = ({
  size = 72,
  src,
  initials,
  color = "white",
  alt,
}: CpsAvatarProps): JSX.Element => {
  const [showSrc, setShowSrc] = useState(Boolean(src));

  useEffect(() => {
    setShowSrc(Boolean(src));
  }, [src]);

  const commonClasses = src
    ? clsx("relative shrink-0", imageSizes[size].twSize)
    : clsx(
        "flex shrink-0 items-center justify-center rounded-full",
        imageSizes[size].twSize,
        imageSizes[size].fontSize,
        backgroundColors[color],
      );

  let component;

  if (showSrc && src) {
    component = (
      <img
        src={src}
        alt={alt ?? ""}
        className={clsx("rounded-full object-cover object-top", imageSizes[size].twSize)}
        width={size}
        height={size}
        onError={() => setShowSrc(!showSrc)}
      />
    );
  } else if (initials) {
    component = initials.slice(0, 2).toUpperCase();
  } else {
    component = <User1 size={iconSizes[size]} className={iconColors[color]} />;
  }

  return <div className={commonClasses}>{component}</div>;
};

export default CpsAvatar;
