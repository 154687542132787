import { type HTMLAttributes } from "react";
import { AssistProBadge } from "@/routes/settings/assist/-components/assist-pro-badge.tsx";

interface AssistProButtonProps extends HTMLAttributes<HTMLButtonElement> {
  children: string;
}

export const AssistProButton = ({
  children,
  ...props
}: AssistProButtonProps): JSX.Element => {
  return (
    <button
      type="button"
      className="flex justify-center gap-2 px-6 min-h-[52px] min-w-[144px] py-4 rounded-30 bg-secondary-400 text-secondary-400-contrast hover:bg-secondary-400 focus:ring-secondary-300 w-full focus:ring-2 focus:ring-offset-2"
      {...props}
    >
      <p>{children}</p>
      <span>
        <AssistProBadge />
      </span>
    </button>
  );
};
