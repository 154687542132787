import { useRouter, useSearch } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types/gql";
import { type FragmentType, useFragment } from "@repo/graphql-types";
import { type DataItemRelatorioIndependente } from "@repo/graphql-types/graphql";
import {
  ChartRoot,
  ChartBarContainer,
  ChartBarItem,
  ChartBarLegend,
} from "@/components/charts";
import { DashboardSectionEmptyState } from "@/components/dashboard/dashboard-section-empty-state";
import { DashboardInformationalDrawer } from "@/components/dashboard/dashboard-informational-drawer";
import { DashboardSectionHeader } from "@/components/dashboard/dashboard-section-header";
import { DashboardSectionTotalNumber } from "@/components/dashboard/dashboard-section-total-number";
import { trackEvent } from "@/lib/tracking";
import { distributeValuesAsPercentages } from "@/lib/math";
import { MINIMUM_PERCENTAGE_TO_DISPLAY } from "@/lib/constants/dashboards-customer-overview";

export const AppointmentDashboardTotalSchedulesPerAppointmentReceivingOptionsSectionQueryFragment =
  graphql(/* GraphQL */ `
    fragment AppointmentDashboardTotalSchedulesPerAppointmentReceivingOptionsQueryFragment on DataItemRelatorioIndependente {
      agendamentosMarcadosParaOMes
      agendamentosParticularesParaOMes
      agendamentosConveniosParaOMes
      mes
    }
  `);

interface AppointmentDashboardTotalSchedulesPerAppointmentReceivingOptionsSectionProps {
  data: FragmentType<
    typeof AppointmentDashboardTotalSchedulesPerAppointmentReceivingOptionsSectionQueryFragment
  >[];
  selectedMonth: string;
  onSelectedMonthChange: (newMonth: string) => void;
}

export const AppointmentDashboardTotalSchedulesPerAppointmentReceivingOptionsSection = ({
  data,
  selectedMonth,
  onSelectedMonthChange,
}: AppointmentDashboardTotalSchedulesPerAppointmentReceivingOptionsSectionProps): JSX.Element | null => {
  const fragmentData = useFragment(
    AppointmentDashboardTotalSchedulesPerAppointmentReceivingOptionsSectionQueryFragment,
    data,
  );

  const router = useRouter();
  const searchParams = useSearch({
    from: "/settings/assist/customer-appointment-overview/",
  });

  const onOpenChange = (open: boolean): void => {
    const search = {
      ...searchParams,
    };

    if (open) {
      search.showTotalSchedulesDrawer = open;

      trackEvent("Definição Gráfico Aberta", {
        grafico: "Total agendamentos",
      });

      void router.navigate({ search });
    } else if (searchParams.showTotalSchedulesDrawer) {
      router.history.back();
    }
  };

  const currentMonthData = fragmentData.find(
    (monthData) => monthData.mes === selectedMonth,
  );

  const monthFilteredDataIsEmpty = !fragmentData.some(
    (monthData) => monthData.agendamentosMarcadosParaOMes !== 0,
  );

  const totalNumber = currentMonthData?.agendamentosMarcadosParaOMes
    ? (currentMonthData.agendamentosMarcadosParaOMes as number)
    : 0;

  return (
    <div className="mt-4 mb-8 flex flex-col gap-3">
      <DashboardSectionHeader onClick={onOpenChange}>
        Total agendamentos
      </DashboardSectionHeader>
      {fragmentData.length === 0 || monthFilteredDataIsEmpty ? (
        <DashboardSectionEmptyState />
      ) : (
        <>
          <DashboardSectionTotalNumber total={totalNumber} />
          <AppointmentDashboardTotalSchedulesPerAppointmentReceivingOptionsSectionChart
            totalCustomerSchedulesData={fragmentData}
            selectedMonth={selectedMonth}
            onSelectedMonthChange={onSelectedMonthChange}
          />
        </>
      )}
      <DashboardInformationalDrawer
        open={Boolean(searchParams.showTotalSchedulesDrawer)}
        onOpenChange={onOpenChange}
        drawerTitle="Total de Agendamentos para o mês"
      >
        <p className="mb-2">
          Quantidade de consultas agendadas para ocorrer no período selecionado, conforme
          o meio de pagamento escolhido no momento do agendamento:
        </p>
        <ul className="text-md list-disc list-inside gap-1 flex-col mb-2">
          <li className="list-item">
            <span className="font-bold ">Particular: </span>
            <span>Quando o pagamento foi definido como particular.</span>
          </li>
          <li className="list-item">
            <span className="font-bold ">Convênio: </span>
            <span>Quando o pagamento foi definido como convênio.</span>
          </li>
        </ul>
      </DashboardInformationalDrawer>
    </div>
  );
};

interface AppointmentDashboardTotalSchedulesPerAppointmentReceivingOptionsSectionChartProps {
  totalCustomerSchedulesData: readonly DataItemRelatorioIndependente[] | null;
  selectedMonth: string;
  onSelectedMonthChange: (newMonth: string) => void;
}

export const AppointmentDashboardTotalSchedulesPerAppointmentReceivingOptionsSectionChart =
  ({
    totalCustomerSchedulesData,
    selectedMonth,
    onSelectedMonthChange,
  }: AppointmentDashboardTotalSchedulesPerAppointmentReceivingOptionsSectionChartProps): JSX.Element | null => {
    if (!totalCustomerSchedulesData?.length) {
      return null;
    }

    const data = totalCustomerSchedulesData.map(
      ({ agendamentosParticularesParaOMes, agendamentosConveniosParaOMes, mes }) => ({
        Particular: agendamentosParticularesParaOMes as number,
        Convênio: agendamentosConveniosParaOMes as number,
        mes,
      }),
    );

    const labels = data.map((entry) => {
      const values = [entry.Particular, entry["Convênio"]];
      const percentages = distributeValuesAsPercentages(values);

      return {
        mes: entry.mes ?? "",
        Particular:
          percentages[0] >= MINIMUM_PERCENTAGE_TO_DISPLAY
            ? `${String(percentages[0])}%`
            : "",
        Convênio:
          percentages[1] >= MINIMUM_PERCENTAGE_TO_DISPLAY
            ? `${String(percentages[1])}%`
            : "",
      };
    });

    return (
      <div data-testid="customer-appointment-total-schedules-per-appointment-receiving-options-chart">
        <ChartRoot
          selectedKey={selectedMonth}
          onSelectedKeyChange={onSelectedMonthChange}
          data={data}
          labels={labels}
        >
          <ChartBarContainer isStacked height={291} XAxisKey="mes">
            <ChartBarItem dataKey="Particular" fill="#72ABBC" disabledFill="#EDF0F2" />
            <ChartBarItem dataKey="Convênio" fill="#003C52" disabledFill="#D5DADF" />
            <ChartBarLegend />
          </ChartBarContainer>
        </ChartRoot>
      </div>
    );
  };
