import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgCashBanknoteSendArrow = ({
  title,
  titleId,
  size = 16,
  className,
}: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="#000"
      viewBox="0 0 16 16"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.667 8.333v-2M4.333 8.333v-2M8 12H4a2 2 0 0 1-2-2V4.667a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v4M12.666 14l1.333-1.333-1.333-1.334M10.666 12.667h3.333"
      />
      <circle
        cx={7.999}
        cy={7.333}
        r={1.333}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default SvgCashBanknoteSendArrow;
