"use client";

import {
  type ComponentPropsWithoutRef,
  forwardRef,
  type KeyboardEventHandler,
  type MouseEventHandler,
  type ReactNode,
} from "react";
import { cn } from "@repo/cn";
import { cva } from "cva";

export interface CpsBadgeProps extends ComponentPropsWithoutRef<"span"> {
  size?: "default" | "large";
  color?:
    | "neutral"
    | "danger"
    | "warning"
    | "success"
    | "helper"
    | "primary"
    | "secondary";
  variant?: "rounded" | "square";
  shade?: "light" | "dark";
  onClick?: MouseEventHandler<HTMLSpanElement>;
  gap?: boolean;
  children?: ReactNode;
}

const badgeClasses = cva({
  base: "py-1 px-2 flex select-none items-center justify-center text-center font-medium whitespace-nowrap w-fit",
  variants: {
    size: {
      default: "text-xs h-[24px]",
      large: "text-sm h-[26px]",
    },
    shade: {
      light: "",
      dark: "",
    },
    color: {
      neutral: "bg-neutral-100",
      danger: "bg-danger-100",
      warning: "bg-warning-100",
      success: "bg-success-100",
      helper: "bg-helper-100",
      primary: "bg-primary-100",
      secondary: "bg-secondary-100",
    },
    variant: {
      rounded: "rounded-70",
      square: "rounded-md",
    },
    gap: {
      true: "gap-1",
    },
  },
  compoundVariants: [
    { shade: "light", color: "neutral", class: "bg-neutral-100" },
    { shade: "light", color: "danger", class: "bg-danger-100" },
    { shade: "light", color: "warning", class: "bg-warning-100" },
    { shade: "light", color: "success", class: "bg-success-100" },
    { shade: "light", color: "helper", class: "bg-helper-100" },
    { shade: "light", color: "primary", class: "bg-primary-100" },
    { shade: "light", color: "secondary", class: "bg-secondary-100" },

    { shade: "dark", color: "neutral", class: "bg-neutral-300 text-white" },
    { shade: "dark", color: "danger", class: "bg-danger-300 text-white" },
    { shade: "dark", color: "warning", class: "bg-warning-500 text-white" },
    { shade: "dark", color: "success", class: "bg-success-400 text-white" },
    { shade: "dark", color: "helper", class: "bg-helper-500 text-white" },
    { shade: "dark", color: "primary", class: "bg-primary-500 text-white" },
    { shade: "dark", color: "secondary", class: "bg-secondary-300 text-white" },
  ],
  defaultVariants: {
    size: "default",
    color: "neutral",
    variant: "rounded",
    shade: "light",
  },
});

/**
 * Renders a badge.
 *
 * @example
 * ```tsx
 * <CpsBadge color="danger" variant="square" shade="dark">
 *   Text
 * </CpsBadge>
 * ```
 * @param size - The size of the badge.
 * @param color - The color of the badge.
 * @param variant - The shape of the badge: rounded (standard) or square.
 * @param shade - The badge shade: light (default) or dark.
 * @param children - The text of the badge.
 * @param onClick - A function that is called when the badge is clicked.
 *
 * @returns The `CpsBadge` component.
 */
export const CpsBadge = forwardRef<HTMLSpanElement, CpsBadgeProps>(
  (
    {
      size = "default",
      color = "neutral",
      variant = "rounded",
      shade = "light",
      children,
      onClick,
      className,
      gap,
    },
    ref,
  ) => {
    return (
      <span
        className={cn(
          badgeClasses({
            size,
            color,
            variant,
            shade,
            gap,
          }),
          className,
        )}
        ref={ref}
        onClick={onClick}
        onKeyDown={handleKeyDown}
        role={onClick ? "button" : undefined}
        tabIndex={onClick ? 0 : undefined}
      >
        {children}
      </span>
    );
  },
);

CpsBadge.displayName = "CpsBadge";

const handleKeyDown: KeyboardEventHandler<HTMLSpanElement> = (event) => {
  if (event.key === "Enter" || event.key === " ") {
    const syntheticEvent = new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    });
    event.currentTarget.dispatchEvent(syntheticEvent);
  }
};
