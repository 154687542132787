import { useAtom } from "jotai";
import { ButtonTabs } from "@/components/button-tabs";
import { chartsTabSelected } from "@/lib/atoms/payments-atom";

interface PaymentsPayablesStatusChartContainerProps {
  children: JSX.Element;
}

export const PaymentsPayablesStatusChartContainer = ({
  children,
}: PaymentsPayablesStatusChartContainerProps): JSX.Element => {
  const [chartsTabSelectedAtom, setChartsTabSelectedAtom] = useAtom(chartsTabSelected);

  const tabs = [
    {
      id: "payments",
      label: "Pagamentos",
    },
    {
      id: "payables",
      label: "Recebimentos",
    },
  ];

  const descriptionDIctionary: Record<string, string> = {
    payments: "Acompanhe os pagamentos criados e recebidos dos seus pacientes.",
    payables: "Monitore como os valores pagos serão liberados em sua conta.",
  };

  return (
    <div className="bg-[#F4F4F5] py-6 px-2 rounded-xl">
      <ButtonTabs
        tabs={tabs}
        activeTab={chartsTabSelectedAtom}
        onTabChange={(value) => setChartsTabSelectedAtom(value)}
      />
      <p className="text-sm text-neutral-600 mt-2">
        {descriptionDIctionary[chartsTabSelectedAtom]}
      </p>
      <div
        id={`${chartsTabSelectedAtom}-chart-status`}
        key={`${chartsTabSelectedAtom}-chart-status`}
      >
        {children}
      </div>
    </div>
  );
};
