import { Button, type ButtonProps } from "@/components/button";

type AppointmentDrawerActionButtonProps = ButtonProps;

export const AppointmentDrawerActionButton = ({
  onClick,
  children,
  ...props
}: AppointmentDrawerActionButtonProps): JSX.Element => {
  return (
    <Button onClick={onClick} {...props} fullWidth>
      {children}
    </Button>
  );
};
