import { useRouter } from "@tanstack/react-router";
import { Button } from "@/components/button";

export const AboutPaymentAnticipationDetails = (): JSX.Element => {
  const router = useRouter();

  const handleClickBackButton = (): void => {
    router.history.back();
  };

  return (
    <div className="items-center justify-center flex flex-col gap-6 mx-auto max-w-[360px]">
      <h1 className="text-left mt-4 text-xl text-neutral-600 font-medium w-full">
        Antecipação automática
      </h1>
      <div className="text-left text-md text-neutral-500 w-full">
        <p>
          Com a antecipação automática, os pagamentos feitos no crédito serão recebidos em
          D+2, ou seja, no segundo dia útil após o pagamento.
        </p>
        <ul className="list-inside list-disc flex flex-col gap-y-3 mt-4 ml-4 mb-6">
          <li data-testid="text-item" className="mr-4">
            Se você ativar essa função hoje, o primeiro pagamento antecipado será
            realizado em D+3, pois as transferências para o dia seguinte são agendadas
            entre 7h e 9h da manhã do mesmo dia. A partir dos próximos dias, os
            recebimentos seguirão normalmente em D+2.
          </li>
          <li data-testid="text-item" className="mr-4">
            A taxa de antecipação é de 1,69%, calculada a juros simples e aplicada sobre o
            valor líquido da transação.
          </li>
        </ul>
        <p>
          Para mais informações, acesse a nossa{" "}
          <a
            className="underline"
            target="_blank"
            href="https://ajuda.livance.com.br/hc/pt-br/articles/30006359132819-Receba-seus-pagamentos-de-forma-simples-e-segura-com-a-Livance"
            rel="noopener noreferrer"
          >
            Central de ajuda.
          </a>
        </p>
      </div>
      <Button onClick={handleClickBackButton} fullWidth>
        Voltar
      </Button>
    </div>
  );
};
