import { Browser } from "@capacitor/browser";
import { Messages, Refresh, Graph, Click } from "@repo/icons";
import {
  GridCardRoot,
  GridCardItem,
  GridCardItemIcon,
  GridCardItemText,
} from "@/components/grid-card";
import { trackEvent } from "@/lib/tracking";

interface SubscribeAssistProBenefitsProps {
  children?: React.ReactNode;
}
export const SubscribeAssistProBenefitsRoot = ({
  children,
}: SubscribeAssistProBenefitsProps): JSX.Element => {
  return <div className="px-4">{children}</div>;
};

export const SubscribeAssistProBenefitsTitle = (): JSX.Element => {
  return (
    <p className="text-2xl font-semibold text-left mb-4">
      Mais consultas, mais pacientes, mais crescimento para seu consultório.
    </p>
  );
};

interface SubscribeAssistProBenefitsLPLinkProps {
  url: string;
}
export const SubscribeAssistProBenefitsLPLink = ({
  url,
}: SubscribeAssistProBenefitsLPLinkProps): JSX.Element => {
  const handleLinkClick = async (): Promise<void> => {
    trackEvent("Link LP Assist Pro Clicado", {
      url,
    });

    await Browser.open({
      url,
      windowName: "_self",
    });
  };

  return (
    <div className="pt-4 text-sm text-center">
      <button
        type="button"
        onClick={() => {
          void handleLinkClick();
        }}
        className="text-[#0080a5] underline"
      >
        Saiba mais detalhes de tudo que o Livance Assist Pro oferece para você.
      </button>
    </div>
  );
};

export const SubscribeAssistProBenefitsFeatures = (): JSX.Element => {
  return (
    <div className="pt-4">
      <GridCardRoot>
        <GridCardItem>
          <GridCardItemIcon>
            <Messages size={24} />
          </GridCardItemIcon>
          <GridCardItemText>
            Atendimento <strong>personalizado</strong> aos seus pacientes com{" "}
            <strong>secretárias especializadas.</strong>
          </GridCardItemText>
        </GridCardItem>

        <GridCardItem>
          <GridCardItemIcon>
            <Refresh size={24} />
          </GridCardItemIcon>
          <GridCardItemText>
            <strong>Recuperação</strong> de <strong>pacientes</strong> e{" "}
            <strong>consultas</strong> com contatos ativos.
          </GridCardItemText>
        </GridCardItem>

        <GridCardItem>
          <GridCardItemIcon>
            <Graph size={24} />
          </GridCardItemIcon>
          <GridCardItemText>
            Acompanhe a <strong>evolução</strong> do seu consultório com nossos{" "}
            <strong>Relatórios detalhados.</strong>
          </GridCardItemText>
        </GridCardItem>

        <GridCardItem>
          <GridCardItemIcon>
            <Click size={24} />
          </GridCardItemIcon>
          <GridCardItemText>
            <strong>Fidelize pacientes</strong> e <strong>otimize</strong> sua gestão com{" "}
            <strong>Automatizações inteligentes.</strong>
          </GridCardItemText>
        </GridCardItem>
      </GridCardRoot>
    </div>
  );
};
