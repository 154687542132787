import { createFileRoute, useRouter, useSearch } from "@tanstack/react-router";
import { HelpCircle, Left } from "@repo/icons";
import { z } from "zod";
import { graphql, type FragmentType } from "@repo/graphql-types";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import {
  PatientPaymentDetails,
  type PatientPaymentDetailsFragment,
} from "@/components/patient-payment-details";
import { useGraphQL } from "@/hooks/use-graphql";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";

const PatientPaymentDetailsPageQuery = graphql(/* GraphQL */ `
  query PatientPaymentDetailsPage($onlinePaymentGuid: String, $codAgendamento: Int) {
    ExpensumPaymentDetails(
      arg1: { onlinePaymentGuid: $onlinePaymentGuid, codAgendamento: $codAgendamento }
    ) {
      ...PatientPaymentDetailsFragment
    }
  }
`);

const PatientPaymentDetailsPageSearchSchema = z.object({
  about: z.boolean().optional(),
  action: z.string().optional(),
  guid: z.string().optional(),
  appointmentId: z.number().optional(),
});

export const PatientPaymentDetailsPage = (): JSX.Element => {
  const { guid, appointmentId } = useSearch({
    from: "/settings/payments/patient-payments/details-v2",
  });
  const queryResult = useGraphQL(PatientPaymentDetailsPageQuery, {
    onlinePaymentGuid: guid,
    codAgendamento: appointmentId,
  });

  const { data } = queryResult;

  const router = useRouter();

  const handleClickAbout = (): void => {
    void router.navigate({
      to: "/settings/payments/patient-payments/about-payments",
    });
  };

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Detalhes" align="center" />
        <HeaderButton icon={HelpCircle} onClick={handleClickAbout} align="end" />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data ? (
            <PatientPaymentDetails
              data={
                data.ExpensumPaymentDetails as FragmentType<
                  typeof PatientPaymentDetailsFragment
                >
              }
            />
          ) : null}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/payments/patient-payments/details-v2")({
  component: PatientPaymentDetailsPage,
  validateSearch: PatientPaymentDetailsPageSearchSchema,
});
