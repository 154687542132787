import { cn, formatCurrencyFromCentsToReais } from "@repo/lib";
import { useAtomValue } from "jotai";
import { CpsBadge, type CpsBadgeProps } from "corpus";
import { amountVisibilityAtom } from "@/lib/atoms/amount-visibility";
import { PaymentStatusBadge } from "@/components/payment-status-badge";

interface PatientPaymentAmountHeaderProps {
  status?: string;
  amount: number;
  paymentMethod?: string;
  onClickStatusBadge?: CpsBadgeProps["onClick"];
}

export const PatientPaymentsAmountHeader = ({
  status,
  amount,
  paymentMethod,
  onClickStatusBadge,
}: PatientPaymentAmountHeaderProps): JSX.Element => {
  const isAmountVisible = useAtomValue(amountVisibilityAtom);

  const isPaymentRefunded = status === "refunded";
  const lineThroughAmount = status === "canceled";

  const buildAmountText = (): string => {
    if (!isAmountVisible) {
      return `${isPaymentRefunded ? "-" : ""}R$ \u2022\u2022\u2022\u2022\u2022`;
    }

    if (!amount) return "R$ 0,00";

    const adjustedAmount = isPaymentRefunded ? amount * -1 : amount;

    return formatCurrencyFromCentsToReais(adjustedAmount);
  };

  return (
    <div className="flex flex-row justify-start gap-2 flex-wrap">
      <span
        className={cn(
          "text-lg text-neutral-600 mb-1 font-medium inline block",
          isPaymentRefunded && "text-neutral-400",
          lineThroughAmount && "line-through text-neutral-400",
        )}
      >
        {buildAmountText()}
      </span>
      {paymentMethod ? <CpsBadge variant="square">{paymentMethod}</CpsBadge> : null}
      <PaymentStatusBadge status={status} onClickStatusBadge={onClickStatusBadge} />
    </div>
  );
};
