/* eslint-disable camelcase -- Classe gerada pelo Codegen*/
import { CpsInput } from "corpus";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { type z } from "zod";
import { graphql } from "@repo/graphql-types/gql";
import { type FragmentType, useFragment } from "@repo/graphql-types";
import {
  GetOneLivUserExperiencesDocument,
  type Locus_Experiencia_Membro_Insert_Input,
} from "@repo/graphql-types/graphql";
import { useNavigate } from "@tanstack/react-router";
import { MinusCircle } from "@repo/icons";
import { ActionButtonText } from "@/components/action-button-text";
import { oneLivFormExperiences } from "@/lib/form-schemas/oneliv-form-schema";
import {
  FormRoot,
  FormHandlerSubmit,
  FormField,
  FormItem,
  FormControl,
  FormSubmitButton,
} from "@/components/form";
import {
  useGraphQLMutationWithErrorHandler,
  useInvalidateQuery,
} from "@/hooks/use-graphql";

export const OneLivExperiencesFormFragment = graphql(/* GraphQL */ `
  fragment OneLivExperiencesFormFragment on locus_membro {
    id
    experiencias {
      id
      experiencia
    }
  }
`);

const UpdateMemberExperiencesMutation = graphql(/* GraphQL */ `
  mutation UpdateMemberExperiencesMutation(
    $objects: [locus_experiencia_membro_insert_input!]!
  ) {
    insert_locus_experiencia_membro(
      on_conflict: {
        constraint: experiencia_membro_pkey
        update_columns: [experiencia, deletado]
      }
      objects: $objects
    ) {
      affected_rows
    }
  }
`);

export type OneLivFormExperiencesValues = z.infer<typeof oneLivFormExperiences>;

interface OneLivExperiencesFormProps {
  data?: FragmentType<typeof OneLivExperiencesFormFragment>[];
}

export const OneLivExperiencesForm = ({
  data,
}: OneLivExperiencesFormProps): JSX.Element => {
  const fragmentResult = useFragment(OneLivExperiencesFormFragment, data);

  const { mutateAsync } = useGraphQLMutationWithErrorHandler(
    UpdateMemberExperiencesMutation,
  );

  const navigate = useNavigate();

  const originalMemberExperiences = fragmentResult?.[0]?.experiencias;

  const getDefaultValues = (): OneLivFormExperiencesValues => {
    const experiences = originalMemberExperiences?.length
      ? originalMemberExperiences.map((item) => ({
          id: item.id,
          value: item.experiencia,
        }))
      : Array.from({ length: 1 }, () => ({ id: 0, value: "" }));

    return { experiences };
  };

  const form = useForm<OneLivFormExperiencesValues>({
    resolver: zodResolver(oneLivFormExperiences),
    defaultValues: getDefaultValues(),
  });

  const invalidateGetOneLivUserExperiences = useInvalidateQuery(
    GetOneLivUserExperiencesDocument,
  );

  const onSubmit = async (formData: OneLivFormExperiencesValues): Promise<void> => {
    const onSuccess = (): void => {
      invalidateGetOneLivUserExperiences();

      void navigate({
        to: "/oneliv/treatment-focus",
      });
    };

    const memberId = fragmentResult?.[0]?.id;

    const insertUpdatedAndEmptyExperiences = getInsertUpdatedAndEmptyExperiences(
      formData,
      memberId,
    );
    const deletedExperiences = getDeletedExperiences(
      insertUpdatedAndEmptyExperiences,
      memberId,
    );

    const objects = insertUpdatedAndEmptyExperiences.concat(deletedExperiences);

    await mutateAsync(
      {
        objects,
      },
      { onSuccess },
    );
  };

  function getInsertUpdatedAndEmptyExperiences(
    formData: OneLivFormExperiencesValues,
    memberId?: number,
  ): Locus_Experiencia_Membro_Insert_Input[] {
    return formData.experiences
      .filter((item) => !(item.id === 0 && item.value === ""))
      .map((item) => {
        const isDeletado = Boolean(!item.value && item.id);

        const originalExperience = originalMemberExperiences?.find(
          (experience) => experience.id === item.id,
        );

        const insertInput: Locus_Experiencia_Membro_Insert_Input = {
          experiencia: isDeletado ? originalExperience?.experiencia : item.value,
          deletado: isDeletado,
          idMembro: memberId,
          id: item.id || undefined,
        };

        return insertInput;
      });
  }

  function getDeletedExperiences(
    updatedAndEmptyExperiences: Locus_Experiencia_Membro_Insert_Input[],
    memberId?: number,
  ): Locus_Experiencia_Membro_Insert_Input[] {
    if (originalMemberExperiences) {
      const deletedExperiences = originalMemberExperiences.filter(
        (deleteItems) =>
          !updatedAndEmptyExperiences.some(
            (insertItems) => insertItems.id === deleteItems.id,
          ),
      );

      return deletedExperiences.map((item) => {
        const deleteInput: Locus_Experiencia_Membro_Insert_Input = {
          experiencia: item.experiencia,
          deletado: true,
          idMembro: memberId,
          id: item.id || undefined,
        };
        return deleteInput;
      });
    }
    return [];
  }

  const experienceValues = form.watch("experiences");

  const addNewExperience = (): void => {
    form.setValue("experiences", [...experienceValues, { id: 0, value: "" }]);
  };

  const removeExperiences = (index: number): void => {
    const formValue = experienceValues.filter((_, i) => i !== index);
    form.setValue("experiences", formValue);
  };

  function renderDeleteButton(index: number): React.ReactNode {
    return (
      <ActionButtonText
        Icon={MinusCircle}
        color="danger"
        onClick={() => removeExperiences(index)}
      >
        Remover
      </ActionButtonText>
    );
  }

  return (
    <FormRoot {...form}>
      <FormHandlerSubmit handleSubmit={onSubmit}>
        <p className="font-medium text-neutral-600">
          Inclua as áreas e/ou sub áreas de sua atuação profissional, uma por campo (exs.:
          Cardiologia do Esporte, Neuropsicologia, Dermatologia estética, Nutrição
          clínica, etc).
        </p>
        {experienceValues.map((item, index) => (
          <FormField
            // eslint-disable-next-line react/no-array-index-key -- Caso o usuário não tenho registros o id será 0
            key={`${item.id}-${index}`}
            control={form.control}
            name={`experiences.${index}.value`}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <CpsInput
                    title={`Experiência (${index + 1})`}
                    placeholder="Digite aqui"
                    inputMode="text"
                    type="text"
                    actionNode={renderDeleteButton(index)}
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        ))}

        <ActionButtonText onClick={addNewExperience}>
          Adicionar nova experiência
        </ActionButtonText>

        <FormSubmitButton type="submit">Avançar</FormSubmitButton>
      </FormHandlerSubmit>
    </FormRoot>
  );
};
