import { type FragmentType, graphql, useFragment } from "@repo/graphql-types";
import { mapPaymentsToChartData } from "@/lib/mappers/payments-charts";
import { PaymentsPayablesStatusChart } from "@/components/payments-payables-status-chart";

export const PaymentsStatusChartFragment = graphql(/* GraphQL */ `
  fragment PaymentsStatusChartFragment on ExpensumGetPaymentsChartsInfoOutput {
    periods {
      intervalEnd
      intervalStart
      onlinePayments {
        totalAmount
        created {
          totalAmount
          payments {
            amount
            status
          }
        }
        paid {
          totalAmount
          payments {
            amount
            status
          }
        }
      }
      totalAmount
      totemPayments {
        totalAmount
        created {
          totalAmount
          payments {
            amount
            status
          }
        }
        paid {
          totalAmount
          payments {
            amount
            status
          }
        }
      }
    }
  }
`);

interface PaymentsStatusChartProps {
  data: FragmentType<typeof PaymentsStatusChartFragment>;
}

export const PaymentsStatusChart = ({ data }: PaymentsStatusChartProps): JSX.Element => {
  const statusChartData = useFragment(PaymentsStatusChartFragment, data);

  const chartData = mapPaymentsToChartData(statusChartData);

  return (
    <PaymentsPayablesStatusChart
      chartData={chartData}
      statusChartData={statusChartData}
      titlePrimaryTotal="Total pagos"
      titleSecondaryTotal="Total criados"
      labelPrimaryLegend="Pagos"
      labelSecondaryLegend="Criados"
      dataKeys={{
        primary: "paid",
        secondary: "created",
      }}
    />
  );
};
