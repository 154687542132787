import { Camera, type GalleryPhoto } from "@capacitor/camera";
import { Edit1 } from "@repo/icons";
import { logError } from "@repo/lib";
import imageCompression from "browser-image-compression";
import { CpsToast } from "corpus";
import { useState, forwardRef } from "react";
import { Button } from "@/components/button";

interface OneLivChangeProfilePictureButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "onChange"> {
  onChange: (value?: Blob) => void;
}

export const OneLivChangeProfilePictureButton = forwardRef<
  HTMLButtonElement,
  OneLivChangeProfilePictureButtonProps
>(({ onChange }, ref) => {
  const [showDeniedPermissionErrorToast, setShowDeniedPermissionErrorToast] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const checkAndRequestPermissions = async (): Promise<boolean> => {
    let permission = await Camera.checkPermissions();
    if (permission.photos === "denied") {
      permission = await Camera.requestPermissions();
    }

    if (permission.photos === "denied") {
      setShowDeniedPermissionErrorToast(true);
      return false;
    }

    return true;
  };

  const pickImage = async (): Promise<GalleryPhoto | null> => {
    const result = await Camera.pickImages({ limit: 1 });

    return result.photos.at(0) ?? null;
  };

  const fetchAndCompressImage = async (webPath: string): Promise<File> => {
    const response = await fetch(webPath);
    const responseBlob = await response.blob();
    const fileFromBlob = new File([responseBlob], "profile_picture", {
      type: responseBlob.type,
    });

    const options = {
      maxSizeMB: 0.7,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    setIsLoading(true);

    return await imageCompression(fileFromBlob, options);
  };

  const openFileSelector = async (): Promise<void> => {
    try {
      const hasPermission = await checkAndRequestPermissions();
      if (!hasPermission) return;

      const firstSelectedPhoto = await pickImage();
      if (!firstSelectedPhoto) return;

      const compressedFile = await fetchAndCompressImage(firstSelectedPhoto.webPath);

      setIsLoading(false);

      onChange(compressedFile);
    } catch (error) {
      logError(error);
    }
  };

  return (
    <>
      <div className="absolute bottom-0 left-24">
        <Button
          className="h-[56px] w-[56px]"
          Icon={Edit1}
          onClick={() => void openFileSelector()}
          loading={isLoading}
          rounded
          ref={ref}
        />
      </div>
      <CpsToast
        title="Você não autorizou a permissão de acesso às imagens do dispositivo. Verifique suas permissões nas configurações do dispositivo."
        show={showDeniedPermissionErrorToast}
        onClose={() => setShowDeniedPermissionErrorToast(false)}
        type="danger"
        duration={5}
      />
    </>
  );
});

OneLivChangeProfilePictureButton.displayName = "OneLivChangeProfilePictureButton";
