import { graphql } from "@repo/graphql-types/gql";
import { UserQueryDocument } from "@repo/graphql-types/graphql";
import { useRouter, useSearch } from "@tanstack/react-router";
import { PartyConfetti } from "@repo/icons";
import { Browser } from "@capacitor/browser";
import { Button } from "@/components/button";
import {
  useInvalidateQuery,
  useGraphQLMutationWithErrorHandler,
} from "@/hooks/use-graphql";
import {
  MessageDrawerActionButton,
  MessageDrawerActions,
  MessageDrawerBody,
  MessageDrawerRoot,
  MessageDrawerTitle,
} from "@/components/message-drawer";
import { trackEvent } from "@/lib/tracking";

const AcceptLivanceAssistMutation = graphql(/* GraphQL */ `
  mutation AcceptLivanceAssistMutation {
    contratarPacoteServicos(input: { codPacoteServico: 1 }) {
      contratarPacoteServicosOutput {
        codPacoteServico
      }
      errors {
        ... on ValidationError {
          __typename
          message
        }
      }
    }
  }
`);

export const SubscribeAssistProSubscriptionConfirmButton = (): JSX.Element => {
  const router = useRouter();
  const invalidateUserQuery = useInvalidateQuery(UserQueryDocument);

  const { mutate, isPending } = useGraphQLMutationWithErrorHandler(
    AcceptLivanceAssistMutation,
  );

  const onConfirmSubscription = (): void => {
    trackEvent("Botão Ativar Livance Assist Pro Clicado");

    const onSuccess = (): void => {
      invalidateUserQuery();

      void router.navigate({
        to: "/settings/assist/subscribe-assist-pro/confirm",
        search: { action: "show-welcome-to-pro-drawer" },
        replace: true,
      });
    };

    mutate({}, { onSuccess });
  };

  const onTermsClick = async (): Promise<void> => {
    await Browser.open({
      url: "https://ajuda.livance.com.br/hc/pt-br/articles/39398692911763-Termos-de-Uso-Livance-Assist-Pro",
    });
  };

  return (
    <>
      <Button fullWidth loading={isPending} onClick={onConfirmSubscription}>
        Ativar Livance Assist Pro
      </Button>

      <p className="text-sm text-neutral-500 text-center">
        Ao ativar o período de teste você está concordando com nossos{" "}
        <button type="button" onClick={() => void onTermsClick()} className="underline">
          Termos de uso
        </button>
        .
      </p>

      <SubscribeAssistProDrawer />
    </>
  );
};

const SubscribeAssistProDrawer = (): JSX.Element => {
  const router = useRouter();

  const { action } = useSearch({
    from: "/settings/assist/subscribe-assist-pro/confirm/",
  });

  const onExploreBenefitsClick = (): void => {
    trackEvent("Botão Explorar Benefícios Assist Pro Clicado");

    void router.navigate({
      to: "/settings/assist",
    });
  };

  return (
    <MessageDrawerRoot
      icon={PartyConfetti}
      setOpen={(open) => {
        if (!open) {
          void router.navigate({
            search: { action: "show-welcome-to-pro-drawer" },
            replace: true,
          });
        }
      }}
      open={action === "show-welcome-to-pro-drawer"}
      variant="secondary"
      dismissible={false}
    >
      <MessageDrawerTitle>Você ativou o Livance Assist Pro!</MessageDrawerTitle>
      <MessageDrawerBody>
        Agora você tem acesso a ferramentas exclusivas para otimizar o seu consultório e
        aumentar sua conversão em consultas.
      </MessageDrawerBody>
      <MessageDrawerActions>
        <MessageDrawerActionButton onClick={onExploreBenefitsClick}>
          Explore seus benefícios
        </MessageDrawerActionButton>
      </MessageDrawerActions>
    </MessageDrawerRoot>
  );
};
