"use client";

import { type SVGIconProps } from "@repo/icons";
import { cn } from "@repo/cn";
import {
  type ReactNode,
  createContext,
  useContext,
  useState,
  type ComponentPropsWithoutRef,
  type FunctionComponent,
  forwardRef,
  type JSX,
  type ChangeEvent,
  type ForwardedRef,
  type KeyboardEvent,
} from "react";

export const CpsSelectorRoot = ({
  children,
  ...props
}: ComponentPropsWithoutRef<"div">): JSX.Element => {
  return (
    <div {...props} className="flex flex-col w-full">
      {children}
    </div>
  );
};

interface CpsSelectTitleProps extends ComponentPropsWithoutRef<"label"> {
  required?: boolean;
}

export const CpsSelectorTitle = ({
  children,
  className,
  required,
  ...props
}: CpsSelectTitleProps): JSX.Element => {
  return (
    <label {...props} className={cn("font-medium mb-1", className)}>
      {children}
      {required ? "*" : null}
    </label>
  );
};

export type CpsSelectorType = string | number | ChangeEvent<HTMLDivElement>;

export interface CpsSingleSelectorProps
  extends Omit<ComponentPropsWithoutRef<"div">, "onChange"> {
  children: ReactNode;
  onChange?: (value: CpsSelectorType) => void;
  fullWidth?: boolean;
  value?: CpsSelectorType;
  required?: boolean;
  activeBackgroundColor?: "primary" | "secondary";
}

export const CpsSingleSelector = forwardRef<HTMLDivElement, CpsSingleSelectorProps>(
  (
    {
      children,
      onChange,
      value,
      fullWidth = false,
      activeBackgroundColor = "primary",
      className,
      ...props
    },
    ref: ForwardedRef<HTMLDivElement>,
  ): JSX.Element => {
    const handleChange = (receivedValue: CpsSelectorType): void => {
      onChange && onChange(receivedValue);
    };

    return (
      <CpsSelectorListContext.Provider
        value={{
          onChange: handleChange,
          selectedValue: value,
          fullWidth,
          activeBackgroundColor,
        }}
      >
        <div
          {...props}
          className={cn(
            "flex flex-row flex-wrap w-full justify-between gap-y-2",
            fullWidth && "gap-1",
            className,
          )}
          ref={ref}
        >
          {children}
        </div>
      </CpsSelectorListContext.Provider>
    );
  },
);

export interface CpsMultiSelectorProps
  extends Omit<CpsSingleSelectorProps, "onChange" | "value"> {
  onChange?: (value: CpsSelectorType[]) => void;
  value?: CpsSelectorType[];
  activeBackgroundColor?: "primary" | "secondary";
}

export const CpsMultiSelector = forwardRef<HTMLDivElement, CpsMultiSelectorProps>(
  (
    {
      children,
      onChange,
      value = [],
      fullWidth = false,
      activeBackgroundColor = "primary",
      ...props
    },
    ref: ForwardedRef<HTMLDivElement>,
  ): JSX.Element => {
    const handleChange = (receivedValue: CpsSelectorType): void => {
      let newValue;
      const isItemSelected = selectedValue.some((x) => x === receivedValue);

      if (isItemSelected) {
        newValue = selectedValue.filter((x) => x !== receivedValue);
      } else {
        newValue = [...selectedValue, receivedValue];
      }
      setSelectedValue(newValue);

      onChange && onChange(newValue);
    };

    const [selectedValue, setSelectedValue] = useState<CpsSelectorType[]>(value);

    return (
      <CpsSelectorListContext.Provider
        value={{
          onChange: handleChange,
          selectedValue,
          fullWidth,
          activeBackgroundColor,
        }}
      >
        <div
          {...props}
          className={cn(
            "flex flex-row flex-wrap w-full justify-between gap-y-2",
            fullWidth && "gap-1",
          )}
          ref={ref}
        >
          {children}
        </div>
      </CpsSelectorListContext.Provider>
    );
  },
);

interface CpsSelectorItemProps extends ComponentPropsWithoutRef<"div"> {
  children: ReactNode;
  Icon?: FunctionComponent<SVGIconProps>;
  value: CpsSelectorType;
  unavailable?: boolean;
  onClick?: () => void;
}

export const CpsSelectorItem = ({
  children,
  Icon,
  onClick,
  value,
  unavailable = false,
  className,
  ...props
}: CpsSelectorItemProps): JSX.Element => {
  const { onChange, selectedValue, fullWidth, activeBackgroundColor } =
    useContext(CpsSelectorListContext);

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter" || event.key === " " || event.type === "click") {
      !unavailable && onChange(value);
      onClick && onClick();
    }
  };

  const isActive = (): boolean => {
    if (selectedValue) {
      return Array.isArray(selectedValue)
        ? selectedValue.some((x) => x === value)
        : selectedValue === value;
    }

    return false;
  };

  return (
    <div
      {...props}
      className={cn(
        "flex items-center justify-center rounded-full p-2 min-w-[48px] min-h-[48px] font-medium gap-1",
        isActive()
          ? `bg-${activeBackgroundColor}-400 text-white`
          : "bg-neutral-50 text-neutral-600",
        unavailable && "text-neutral-300",
        !fullWidth ? "w-[48px] h-[48px]" : "flex-[1_0_auto]",
        className,
      )}
      onClick={() => {
        !unavailable && onChange(value);
        onClick && onClick();
      }}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      role="button"
    >
      {Icon ? (
        <Icon
          size={24}
          className={cn(isActive() ? "fill-white" : `fill-${activeBackgroundColor}-400`)}
        />
      ) : null}
      {children}
    </div>
  );
};

interface CpsSelectorContextProps {
  children?: ReactNode;
  onChange: (value: CpsSelectorType) => void;
  selectedValue?: CpsSelectorType | CpsSelectorType[];
  fullWidth?: boolean;
  activeBackgroundColor?: "primary" | "secondary";
}

const CpsSelectorListContext = createContext<CpsSelectorContextProps>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function -- default value
  onChange: () => {},
  activeBackgroundColor: "primary",
});
