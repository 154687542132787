import { formatWithZonedDate, ServicePackageEnum } from "@repo/lib";
import { useRouteContext } from "@tanstack/react-router";
import { AssistProBanner } from "@/routes/settings/assist/-components/assist-pro-banner.tsx";

export const AssistProMenuBanner = (): JSX.Element => {
  const { user, flags } = useRouteContext({ strict: false });
  const shouldShowBannerWithPhoneNumberMessage =
    flags["habilita-fluxo-com-numero-de-telefone-no-assist"];

  const subscribedPackage =
    user.pacotesServicos.find(
      (servicePkg) =>
        servicePkg.codPacoteServicos === ServicePackageEnum.Professional.valueOf(),
    ) ??
    user.pacotesServicos.find(
      (servicePkg) =>
        servicePkg.codPacoteServicos === ServicePackageEnum.Independent.valueOf(),
    );

  const isPro =
    subscribedPackage?.codPacoteServicos === ServicePackageEnum.Professional.valueOf();
  const isTrial = subscribedPackage?.trialAtivo ?? false;
  const isCanceled = subscribedPackage?.cancelado ?? false;
  const isTrialAvailable =
    subscribedPackage?.trialFinalizado !== undefined
      ? !subscribedPackage.trialFinalizado
      : true;
  const trialEndDate = subscribedPackage?.dataFimTrial;

  if (!isPro || isCanceled) {
    return (
      <AssistProBanner
        title="Desbloquear benefícios Pro"
        description={isTrialAvailable && !isTrial ? "Grátis no primeiro mês" : ""}
        to="/settings/assist/subscribe-assist-pro"
        hasIcon
      />
    );
  }

  const formattedEndDate = trialEndDate
    ? formatWithZonedDate(trialEndDate, "dd 'de' MMMM")
    : "";

  const trialDescription = trialEndDate
    ? `Seu período de teste acaba em ${formattedEndDate}`
    : undefined;

  if (shouldShowBannerWithPhoneNumberMessage) {
    return (
      <AssistProBanner
        title="Confira aqui os detalhes do seu plano e seu telefone exclusivo"
        description={trialDescription}
        to="/settings/assist/subscribed-assist"
      />
    );
  }

  if (isTrial) {
    return (
      <AssistProBanner
        title="Você é um membro Pro"
        description={trialDescription}
        to="/settings/assist/subscribed-assist"
        hasIcon
      />
    );
  }

  return (
    <AssistProBanner
      title="Você é um membro Pro"
      to="/settings/assist/subscribed-assist"
      hasIcon
    />
  );
};
