import { CustomerIO } from "@repo/customerio-plugin";
import { pageView } from "@/lib/tracking";

export const trackPageView = (
  pathname: string,
  properties: Record<string, string>,
): void => {
  void pageView({ from: properties.from });

  CustomerIO.screenEvent({ screenName: pathname, properties });
};
