import { createFileRoute, redirect } from "@tanstack/react-router";
import { Left } from "@repo/icons";
import { graphql } from "@repo/graphql-types";
import { z } from "zod";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { ensureQueryData, useGraphQL } from "@/hooks/use-graphql";
import { BankAccountDetails } from "@/components/bank-account-details";
import { RecipientInvalidAlertWrapperQuery } from "@/components/recipient-invalid-alert-wrapper";

const BankAccountPageQuery = graphql(/* GraphQL */ `
  query BankAccountPageQuery($codUsuario: Int!, $codClinica: Int! = 0) {
    ExpensumGetRecipient(arg1: { codUsuario: $codUsuario, codClinica: $codClinica }) {
      recipient {
        bankAccounts {
          pagarmeId
        }
      }
    }
    ...BankAccountDetailsQueryFragment
  }
`);

const bankAccountPageSearchParamsSchema = z.object({
  action: z.string().optional(),
});

export const BankAccountPage = (): JSX.Element => {
  const { user } = Route.useRouteContext();

  const queryResult = useGraphQL(BankAccountPageQuery, {
    codUsuario: user.codUsuario,
    codClinica: user.codClinica,
  });
  const { data } = queryResult;

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Conta para recebimento" align="center" />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data ? <BankAccountDetails data={data} /> : null}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/payments/bank-account/")({
  component: BankAccountPage,
  loader: async ({ context }) => {
    try {
      const variables = {
        codUsuario: context.user.codUsuario,
        codClinica: context.user.codClinica,
      };

      // aqui basta validar se o usuário tem um recebedor cadastrado
      // por isso, otimização com busca no cache da query da /settings/payments com ensureQueryData
      const data = await ensureQueryData(
        context,
        RecipientInvalidAlertWrapperQuery,
        variables,
      );

      const hasRecipient = Boolean(data.expensumRecipient?.recipient);

      if (!hasRecipient) throw new Error("Recipient not found!");
    } catch (e) {
      redirect({
        to: "/settings/payments",
        throw: true,
      });
    }
  },
  validateSearch: (search) => bankAccountPageSearchParamsSchema.parse(search),
});
