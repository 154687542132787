import {
  MessageDrawerActionButton,
  MessageDrawerActions,
  MessageDrawerBody,
  MessageDrawerRoot,
  MessageDrawerTitle,
} from "@/components/message-drawer";

interface ChangeOwnershipDrawerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const ChangeOwnershipDrawer = ({
  open,
  setOpen,
}: ChangeOwnershipDrawerProps): JSX.Element => {
  return (
    <MessageDrawerRoot setOpen={setOpen} open={open}>
      <MessageDrawerTitle>Trocar titularidade</MessageDrawerTitle>
      <MessageDrawerBody>
        <div className="flex flex-col gap-4 text-center">
          <p>
            Para alterar a titularidade da sua conta de Pessoa Física para Jurídica, ou
            vice-versa, é necessário falar com o nosso time de atendimento.
          </p>
          <p className="font-semibold">
            Evite problemas com os seus recebimentos programados.
          </p>
          <p className="text-sm">
            *Você será redirecionado para o WhatsApp com o nosso time de atendimento.
          </p>
        </div>
      </MessageDrawerBody>
      <MessageDrawerActions>
        <a
          target="_blank"
          href="https://api.whatsapp.com/send/?phone=551132304410&text&type=phone_number&app_absent=0"
          rel="noopener noreferrer"
        >
          <MessageDrawerActionButton>Conversar com o time</MessageDrawerActionButton>
        </a>
        <MessageDrawerActionButton secondary onClick={() => setOpen(false)}>
          Voltar
        </MessageDrawerActionButton>
      </MessageDrawerActions>
    </MessageDrawerRoot>
  );
};
