import { Left } from "@repo/icons";
import { ServicePackageEnum } from "@repo/lib";
import { createFileRoute } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types/gql";
import { HeaderRoot, HeaderButton, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { SubscribeAssistProSubscriptionConfirmCard } from "@/components/subscribe-assist-pro/confirm/subscribe-assist-pro-confirm-card";
import { useGraphQL } from "@/hooks/use-graphql";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { SubscribeAssistProSubscriptionConfirmButton } from "@/components/subscribe-assist-pro/confirm/subscribe-assist-pro-confirm-button";

const SubscribeAssistProConfirmPageQuery = graphql(/* GraphQL */ `
  query SubscribeAssistProConfirmPageQuery($codPacoteServico: Int!) {
    ...SubscribeAssistProSubscriptionConfirmCardFragment
  }
`);

export const SubscribeAssistProConfirmPage = (): JSX.Element => {
  const queryResult = useGraphQL(SubscribeAssistProConfirmPageQuery, {
    codPacoteServico: ServicePackageEnum.Professional.valueOf(),
  });

  const { data } = queryResult;

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Livance Assist Pro" align="center" />
      </HeaderRoot>
      <Page>
        <div className="flex flex-col gap-2">
          <h2 className="text-2xl font-semibold text-left">
            Confirme sua ativação do Livance Assist Pro
          </h2>
          <p className="text-sm text-neutral-400">
            Confira os detalhes do seu plano antes de continuar
          </p>
        </div>

        <AsyncDataWrapper {...queryResult}>
          {data ? (
            <div className="flex flex-col gap-6">
              <SubscribeAssistProSubscriptionConfirmCard data={data} />
              <SubscribeAssistProSubscriptionConfirmButton />
            </div>
          ) : null}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/assist/subscribe-assist-pro/confirm/")({
  component: SubscribeAssistProConfirmPage,
});
