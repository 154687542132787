import {
  type ComponentProps,
  type ReactNode,
  Children,
  isValidElement,
  useContext,
} from "react";
import {
  BarChart,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Cell,
  LabelList,
  Legend,
  ReferenceLine,
} from "recharts";
import { type ContentType } from "recharts/types/component/Label";
import { ChartContext } from "@/lib/context/chart-context";
import { ChartLegendItem } from "@/components/charts/chart-legend-item";

const DEFAULT_UNSELECTED_COLORS = ["#B4BEC6", "#D5DADF", "#EDF0F2", "#F6F7F8"];
const DEFAULT_BAR_COLORS = ["#003C52", "#72ABBC", "#ABD3DD", "#E1F0F5"];
const DEFAULT_WHITE_TEXT_CELLS = ["#003C52", "#72ABBC"];
const DEFAULT_TEXT_COLOR = "#1F2937";

interface ChartBarContainerProps extends ComponentProps<typeof BarChart> {
  children: ReactNode;
  width?: number;
  height?: number;
  isStacked?: boolean;
  XAxisKey: string;
  showYAxis?: boolean;
}

export const ChartBarContainer = ({
  children,
  isStacked,
  width,
  height,
  XAxisKey,
  showYAxis = true,
  barCategoryGap = 4,
  ...props
}: ChartBarContainerProps): JSX.Element => {
  const context = useContext(ChartContext);

  if (!context) {
    throw new Error("ChartBarContainer must be used within a ChartProvider");
  }

  const { data } = context;

  return (
    <ResponsiveContainer width={width ?? "100%"} height={height ?? "100%"} minWidth={100}>
      <BarChart data={data} reverseStackOrder barCategoryGap={barCategoryGap} {...props}>
        <CartesianGrid vertical={false} strokeDasharray="10" opacity={0.5} />
        <XAxis
          dataKey={XAxisKey}
          strokeOpacity={0.1}
          fontSize={12}
          fontWeight={500}
          fill="#4B5563"
          dy={8}
          fontFamily="Poppins"
        />
        {showYAxis ? (
          <YAxis
            width={30}
            orientation="right"
            axisLine={false}
            tickLine={false}
            fontSize={12}
            fontFamily="Poppins"
            fontWeight={500}
            allowDecimals={false}
          />
        ) : null}

        {Children.map(children, (child, barIndex) => {
          if (isValidElement(child) && child.type === ChartBarItem) {
            const {
              dataKey,
              fill,
              disabledFill,
              isStacked: isChildStacked,
            } = child.props as ChartBarItemProps;

            //TODO: Utilizar o componente <ChartBarItem> a partir do Recharts 3.0
            return ChartBarItem({
              dataKey,
              fill,
              disabledFill,
              barIndex,
              XAxisKey,
              isStacked: isStacked ?? isChildStacked,
            });
          }

          if (isValidElement(child) && child.type === ChartBarLegend) {
            const { variations } = child.props as ChartBarLegendProps;

            return (
              <Legend
                wrapperStyle={{ paddingTop: "24px" }}
                content={<ChartBarLegend chartsItem={children} variations={variations} />}
              />
            );
          }

          if (isValidElement(child) && child.type === ReferenceLine) {
            return child;
          }

          return null;
        })}
      </BarChart>
    </ResponsiveContainer>
  );
};

interface ChartBarItemProps {
  dataKey: string;
  fill?: string;
  disabledFill?: string;
  barIndex?: number;
  isStacked?: boolean;
  XAxisKey?: string;
}

export const ChartBarItem = ({
  dataKey,
  fill,
  disabledFill,
  barIndex = 0,
  isStacked,
  XAxisKey,
}: ChartBarItemProps): JSX.Element | null => {
  const chartContext = useContext(ChartContext);

  if (!chartContext) {
    throw new Error("ChartBarItem must be used within a ChartProvider");
  }

  if (!XAxisKey) return null;

  const { selectedKey, onSelectedKeyChange, data, id, labels } = chartContext;

  const getLabelColor = (fillColor: string): string =>
    DEFAULT_WHITE_TEXT_CELLS.includes(fillColor) ? "#fff" : DEFAULT_TEXT_COLOR;

  const getFillColor = (isSelected: boolean): string => {
    if (!selectedKey || isSelected) {
      return fill ?? DEFAULT_BAR_COLORS[barIndex];
    }

    return disabledFill ?? DEFAULT_UNSELECTED_COLORS[barIndex];
  };

  const setBarRadius = (): number | [number, number, number, number] => {
    if (!isStacked) {
      return 4;
    }

    const isTopBar = barIndex === 0;
    const dataFieldsCount = Object.keys(data[0]).filter((k) => k !== XAxisKey).length - 1;
    const isBottomBar = barIndex === dataFieldsCount - 1;

    if (isTopBar) {
      return [4, 4, 0, 0];
    } else if (isBottomBar) {
      return [0, 0, 4, 4];
    }

    return [0, 0, 0, 0];
  };

  const renderCustomizedLabel: ContentType = (props) => {
    const { x, y, index, width, height, className } = props;

    if (
      typeof x !== "number" ||
      typeof width !== "number" ||
      typeof y !== "number" ||
      typeof height !== "number" ||
      typeof index !== "number"
    ) {
      return null;
    }

    const cx = x + width / 2;
    const cy = y + height / 2;

    const label = labels?.[index]?.[dataKey];

    const cellFill = getFillColor(className?.includes("selected") ?? false);
    const labelColor = getLabelColor(cellFill);

    return (
      <g>
        <text
          x={cx}
          y={cy}
          fill={labelColor}
          textAnchor="middle"
          dominantBaseline="middle"
          pointerEvents="none"
          fontSize="12px"
          fontWeight="500"
        >
          {label}
        </text>
      </g>
    );
  };

  return (
    <Bar
      dataKey={dataKey}
      radius={setBarRadius()}
      stackId={isStacked ? id : undefined}
      isAnimationActive={false}
    >
      {data.map((entry: Record<string, unknown>) => {
        const barKey = String(entry[XAxisKey]);
        const isCellSelected = selectedKey === barKey;
        const cellFill = getFillColor(isCellSelected);

        return (
          <Cell
            key={`cell-${String(entry[XAxisKey])}-${dataKey}`}
            cursor="pointer"
            fill={cellFill}
            onClick={() =>
              onSelectedKeyChange && onSelectedKeyChange(String(entry[XAxisKey]))
            }
            className={`transition-colors duration-200 ease-in-out ${isCellSelected ? "selected" : ""}`}
          />
        );
      })}

      {labels ? (
        <LabelList
          dataKey={dataKey}
          position="center"
          fill="white"
          fontSize={12}
          content={renderCustomizedLabel}
        />
      ) : null}
    </Bar>
  );
};

interface ChartBarLegendProps {
  payload?: { value: string; dataKey: string | number; color: string }[];
  chartsItem?: ReactNode | ReactNode[];
  variations?: Record<string, number>;
}

export const ChartBarLegend = ({
  payload,
  chartsItem,
  variations,
}: ChartBarLegendProps): JSX.Element => {
  const context = useContext(ChartContext);

  if (!context) {
    throw new Error("ChartBarLegend must be used within a ChartProvider");
  }

  const { data, selectedKey } = context;

  const getFillColorFromChartsItem = (
    chartElements: ReactNode | ReactNode[],
    entry: { dataKey: string | number; color: string },
  ): string => {
    return (
      (
        Children.toArray(chartElements).find(
          (child) =>
            isValidElement(child) &&
            (child.props as { dataKey: string | number }).dataKey === entry.dataKey,
        ) as React.ReactElement<{ fill?: string }> | undefined
      )?.props.fill ?? entry.color
    );
  };

  return (
    <div className="flex flex-wrap gap-2 w-full">
      {payload?.map((entry, index) => {
        const fill = getFillColorFromChartsItem(chartsItem, entry);

        const selectedData = data.find((d) => Object.values(d).includes(selectedKey));
        const dataKey = String(entry.dataKey);
        const value = selectedData ? selectedData[dataKey] : null;
        const displayText = entry.value;
        const variation = variations?.[displayText];

        return (
          <ChartLegendItem
            key={`legend-item-${String(index)}-${displayText}`}
            fill={fill}
            value={String(value)}
            displayText={displayText}
            index={index}
            variation={variation}
          />
        );
      })}
    </div>
  );
};
