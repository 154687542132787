import {
  Adjustments,
  Cash,
  Rotate,
  User1,
  Logout,
  CalendarTime,
  Invite,
  HelpCircle,
  GraphList,
} from "@repo/icons";
import { createFileRoute, useRouteContext } from "@tanstack/react-router";
import { useQuery } from "@tanstack/react-query";
import { getAppInfo, ServicePackageEnum } from "@repo/lib";
import { useMemo } from "react";
import { CpsBadge } from "corpus";
import { BottomBar } from "@/components/bottom-bar";
import { MenuList, type MenuListItem } from "@/components/menu-list";
import { HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";

export const SettingsPage = (): JSX.Element => {
  const { user } = useRouteContext({ strict: false });
  const actualVersion = useQuery({
    queryKey: ["appVersion"],
    queryFn: async () => {
      return await getAppInfo();
    },
  });

  const userHasAssist = user.pacotesServicos.some(
    (servicePackage) => servicePackage.grupoUpgrade === "Assist",
  );

  const userHasAssistLegacy = user.pacotesServicos.some(
    (servicePackage) => servicePackage.codPacoteServicos === ServicePackageEnum.Legacy.valueOf(),
  );

  const { flags } = useRouteContext({
    strict: false,
  });

  const showAssistMenuFF = useMemo(() => flags["exibir-menu-livance-via"], [flags]);

  const showLivanceAssistMenu = showAssistMenuFF || userHasAssist || userHasAssistLegacy;

  const menuItens: (MenuListItem & { show: boolean })[] = [
    {
      name: "Livance Assist",
      Icon: GraphList,
      route: "/settings/assist",
      show: showLivanceAssistMenu,
      tag: <CpsBadge color="helper">Novo</CpsBadge>,
      description:
        "Aumente sua conversão de agendamentos, fidelize pacientes e veja seu consultório crescer.",
    },
    {
      name: "Indique e ganhe",
      Icon: Invite,
      route: "/settings/referral",
      show: true,
    },
    {
      name: "Meu perfil",
      Icon: User1,
      route: "/settings/profile",
      show: true,
    },
    {
      name: "Configurações da agenda",
      Icon: Adjustments,
      route: "/settings/schedule-options",
      show: true,
    },
    {
      name: "Gerenciar pagamentos",
      Icon: Cash,
      route: "/settings/payments",
      show: true,
    },
    {
      name: "Histórico de uso",
      Icon: Rotate,
      route: "/settings/billing",
      show: true,
    },
    {
      name: "Período garantido",
      Icon: CalendarTime,
      route: "/settings/reserved-schedules",
      show: true,
    },
    {
      name: "Central de ajuda",
      Icon: HelpCircle,
      externalRoute: "https://ajuda.livance.com.br/hc/pt-br",
      show: true,
      target: "_blank",
    },
    {
      name: "Sair do aplicativo",
      Icon: Logout,
      route: "/auth/logout",
      show: true,
    },
  ];

  const activeMenuItens = menuItens.filter((item) => item.show);

  return (
    <>
      <HeaderRoot>
        <HeaderTitle title="Mais opções" />
      </HeaderRoot>
      <Page className="pt-2 pb-[120px]">
        <MenuList menuItems={activeMenuItens} />
        {actualVersion.data ? (
          <p className="flex justify-center  text-xs text-slate-300">
            {actualVersion.data.version}
          </p>
        ) : null}
      </Page>
      <BottomBar />
    </>
  );
};

export const Route = createFileRoute("/settings/")({
  component: SettingsPage,
});
