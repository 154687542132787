import { type ParsedLocation, redirect } from "@tanstack/react-router";
import { type UserContext } from "@repo/lib";

export const validateFeatureAccess = ({
  location,
  user,
  codFuncionalidade,
}: {
  location: ParsedLocation;
  user: UserContext;
  codFuncionalidade: number;
}): void => {
  const userHasAccess = hasFeatureAccess(user, codFuncionalidade);

  if (location.pathname.includes("assist/subscribe-assist-pro")) {
    if (userHasAccess) {
      redirectToSettings();
    }
    return;
  }

  if (!userHasAccess) {
    redirectToSubscribe();
  }
};

const hasFeatureAccess = (user: UserContext, codFuncionalidade: number): boolean => {
return user.funcionalidadesContratadas.some(
    (x) => x.codFuncionalidade === codFuncionalidade && x.contratada,
  );
};

const redirectToSubscribe = (): void => {
  redirect({ to: "/settings/assist/subscribe-assist-pro", throw: true });
};

const redirectToSettings = (): void => {
  redirect({ to: "/settings/assist/", throw: true });
};
