import { createFileRoute, useRouter, useSearch } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types/gql";
import { subMonths } from "date-fns";
import { format } from "date-fns-tz";
import {
  capitalizeFirstLetter,
  formatWithZonedDate,
  getMonthFirstAndLastDates,
} from "@repo/lib";
import { Page } from "@/components/page";
import { StoriesFeedRoot } from "@/components/stories-feed";
import {
  StoryCustomerContactAppointmentQuantity,
  StoryCustomerContactMainContactReason,
  StoryCustomerContactNoAppointmentReason,
  StoryCustomerContactRetrospective,
  StoryCustomerContactStatistics,
  StoryCustomerContactSummary,
  StoryCustomerContactCheckIns,
  StoryCustomerContactAccessOverview,
} from "@/components/customer-contact-overview/customer-contact-stories";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { useGraphQL } from "@/hooks/use-graphql";
import "@/styles/stories-feed.css";
import { trackEvent } from "@/lib/tracking";

const NuntiusGetCustomerContactPeriodSummaryQuery = graphql(`
  query NuntiusGetCustomerContactPeriodSummary($from: String!, $to: String!) {
    NuntiusGetCustomerContactPeriodSummary(arg1: { from: $from, to: $to }) {
      totalCustomerContacts
      totalCustomers
      totalOldCustomers
      percentOldCustomers
      totalNewCustomers
      percentNewCustomers
      mostFrequentMainReasonId
      totalMostFrequentMainReason
      percentMostFrequentMainReason
      totalCustomerContactsNewAppointmentMainReason
      totalCustomerContactsAppointmentsScheduled
      percentCustomerContactsAppointmentsScheduled
      mostFrequentNotScheduledAppointmentReasonsIds
      totalAllocatedRooms
      totalAllocatedRoomsOnPeriod
    }
  }
`);

export const CustomerContactOverviewStoriesPage = (): JSX.Element => {
  const router = useRouter();

  const { month, year }: { month?: number; year?: number } = useSearch({
    from: "/settings/assist/customer-contact-overview/stories-feed/",
  });

  const returnToPreviousPage = (): void => {
    void router.navigate({ to: "../" });
  };

  const redirectToCustomerContactOverview = (date: string): void => {
    const selectedMonth = capitalizeFirstLetter(formatWithZonedDate(date, "MMM/yy"));

    trackEvent("Stories CTA Relatorio Acessado");
    void router.navigate({
      to: "/settings/assist/customer-contact-overview",
      search: {
        selectedMonth,
      },
    });
  };

  const getMonthIndex = (): number => {
    if (month === undefined) return defaultDate.getMonth();

    return month;
  };

  const trackStoryLoad = (index: number): void => {
    trackEvent("Stories Carregado", {
      index,
    });
  };

  const trackAllStoriesEnd = (index: number): void => {
    trackEvent("Stories Fechado", {
      index,
    });
  };

  const now = new Date();
  const defaultDate = subMonths(now, 1);
  const selectedMonth = getMonthIndex();
  const selectedYear = year ?? defaultDate.getFullYear();

  const selectedDate = new Date(selectedYear, selectedMonth, 1);
  const { firstDayOfMonth, lastDayOfMonth } = getMonthFirstAndLastDates(selectedDate);

  const from = format(firstDayOfMonth, "yyyy-MM-dd HH:mm:ssXXX", {
    timeZone: "America/Sao_Paulo",
  });
  const to = format(lastDayOfMonth, "yyyy-MM-dd HH:mm:ssXXX", {
    timeZone: "America/Sao_Paulo",
  });

  const queryResult = useGraphQL(NuntiusGetCustomerContactPeriodSummaryQuery, {
    from,
    to,
  });

  const data = queryResult.data?.NuntiusGetCustomerContactPeriodSummary;

  return (
    <Page>
      <AsyncDataWrapper {...queryResult}>
        {data ? (
          <StoriesFeedRoot
            open
            onOpenChange={returnToPreviousPage}
            showCloseButton
            defaultInterval={4200}
            onStoryStart={trackStoryLoad}
            onStoriesCloseAction={trackAllStoriesEnd}
          >
            <StoryCustomerContactRetrospective
              from={firstDayOfMonth}
              to={lastDayOfMonth}
            />
            <StoryCustomerContactSummary data={data} />
            <StoryCustomerContactStatistics data={data} />
            {data.mostFrequentMainReasonId ? (
              <StoryCustomerContactMainContactReason data={data} />
            ) : null}
            <StoryCustomerContactAppointmentQuantity data={data} />
            {data.mostFrequentNotScheduledAppointmentReasonsIds.length ? (
              <StoryCustomerContactNoAppointmentReason data={data} />
            ) : null}
            <StoryCustomerContactCheckIns data={data} />
            <StoryCustomerContactAccessOverview
              onAccessOverviewClick={() => redirectToCustomerContactOverview(from)}
            />
          </StoriesFeedRoot>
        ) : null}
      </AsyncDataWrapper>
    </Page>
  );
};

export const Route = createFileRoute(
  "/settings/assist/customer-contact-overview/stories-feed/",
)({
  component: CustomerContactOverviewStoriesPage,
});
