import { Favicon, MusicAudioPlay, X } from "@repo/icons";
import { Link, useRouteContext } from "@tanstack/react-router";
import { subMonths, differenceInMonths } from "date-fns";
import { useState } from "react";
import { format, getCurrentDate } from "@repo/lib";
import { trackEvent } from "@/lib/tracking";

export const CustomerContactStoriesBanner = (): JSX.Element => {
    const { user } = useRouteContext({ strict: false });
  
  const isUserOlderThanOneMonth = user.dataValidacao
    ? differenceInMonths(getCurrentDate(), user.dataValidacao) > 0
    : false;

  const [isVisible, setIsVisible] = useState(true);
  const handleIsVisible = (): void => {
    setIsVisible(false);
    trackEvent("Banner Retrospectiva Ocultado");
  };


  const buildPreviousMonthText = (): string => {
    const previousMonthDate = subMonths(new Date(), 1);
    const previousMonthText = format(previousMonthDate, "MMMM");

    return previousMonthText ? `de ${previousMonthText}` : "do mês passado";
  };

  const trackOnNavigateToStoriesPage = (): void => {
    trackEvent("Banner Retrospectiva CTA Clicado");
  };

  return (
    <>
      {isVisible && isUserOlderThanOneMonth ? (
        <div className="relative rounded-2xl bg-secondary-400 p-4 flex flex-col overflow-hidden">
          <button
            className="flex justify-end z-10"
            onClick={handleIsVisible}
            type="button"
          >
            <X size={24} className="fill-white" />
          </button>
          <h1 className="text-xl text-white font-medium">
            Sua retrospectiva {buildPreviousMonthText()} chegou!
          </h1>
          <div className="pt-4 z-10">
            <Link
              to="/settings/assist/customer-contact-overview/stories-feed"
              onClick={trackOnNavigateToStoriesPage}
              className="flex items-center w-48 h-9 bg-primary-400 text-sm font-medium px-4 py-2 rounded-full"
            >
              <MusicAudioPlay className="fill-transparent" size={24} />
              Ver retrospectiva
            </Link>
          </div>
          <Favicon
            size={24}
            className="absolute rotate-[66deg] scale-[30] z-0
-top-[18rem] -right-[4rem] sm:-top-[20rem] sm:right-[10rem] lg:-top-[18rem] lg:right-[13rem] fill-warning-50 opacity-15"
          />
        </div>
      ) : null}
    </>
  );
};
