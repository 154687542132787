import { useState } from "react";
import { formatCurrencyFromCentsToReais, formatWithZonedDate } from "@repo/lib";
import { DrinkColored, Eye, EyeOff, HelpCircle } from "@repo/icons";
import { useAtom } from "jotai";
import { useRouter, useSearch } from "@tanstack/react-router";
import {
  ChartBarContainer,
  ChartBarItem,
  ChartLegendItem,
  ChartRoot,
} from "@/components/charts";
import { displayValuesAtom } from "@/lib/atoms/payments-atom";
import {
  EmptyStateV2Description,
  EmptyStateV2Icon,
  EmptyStateV2Root,
  EmptyStateV2Title,
} from "@/components/empty-state-v2";
import { PaymentStatusChartsAboutDrawer } from "@/components/payments-status-charts-about-drawer";

interface ChartDataType {
  week: string;
  created?: number;
  paid?: number;
  waitingFunds?: number;
  released?: number;
  [key: string]: unknown;
}

interface StatusChartProps {
  chartData: ChartDataType[];
  statusChartData: { periods: { intervalStart: string; intervalEnd: string }[] };
  titlePrimaryTotal: string;
  titleSecondaryTotal: string;
  labelPrimaryLegend: string;
  labelSecondaryLegend: string;
  dataKeys: {
    primary: PaymentStatusType;
    secondary: PaymentStatusType;
  };
}

type PaymentStatusType = "created" | "paid" | "waitingFunds" | "released";

const FILL_PRIMARY = "#004B66";
const FILL_SECONDARY = "#ABD3DD";
const UNSELECTED_FILL_PRIMARY = "#B4BEC6";
const UNSELECTED_FILL_SECONDARY = "#D5DADF";

export const PaymentsPayablesStatusChart = ({
  chartData,
  statusChartData,
  titlePrimaryTotal,
  titleSecondaryTotal,
  labelPrimaryLegend,
  labelSecondaryLegend,
  dataKeys,
}: StatusChartProps): JSX.Element => {
  const router = useRouter();
  const searchParams = useSearch({ from: "/settings/payments/evolution-charts/" });

  const lastWeekWithValue = (): string | undefined => {
    const lastWeek = chartData
      .slice()
      .reverse()
      .find((week) => Number(week[dataKeys.primary]) || Number(week[dataKeys.secondary]));
    return lastWeek ? lastWeek.week : undefined;
  };

  const [barSelectedKey, setBarSelectedKey] = useState<string | undefined>(
    lastWeekWithValue,
  );
  const [displayValues, setDisplayValues] = useAtom(displayValuesAtom);

  const dataIsEmpty = (): boolean => {
    return chartData.every(
      (week) => week[dataKeys.primary] === 0 && week[dataKeys.secondary] === 0,
    );
  };

  const buildLabelInterval = (
    periods: { intervalStart: string; intervalEnd: string }[],
  ): string => {
    const { intervalStart } = periods[0];
    const { intervalEnd } = periods[periods.length - 1];

    const start = formatWithZonedDate(intervalStart, "PP");
    const end = formatWithZonedDate(intervalEnd, "PP");
    return `${start} - ${end}`;
  };

  const buildLegendValue = (type: PaymentStatusType): string => {
    if (!displayValues) {
      return "R$ \u2022\u2022\u2022\u2022\u2022";
    }

    if (!barSelectedKey) {
      const totalValue = chartData.reduce((acc, week) => acc + (week[type] ?? 0), 0);
      return formatCurrencyFromCentsToReais(totalValue);
    }

    const selectedWeek = chartData.find((week) => week.week === barSelectedKey);
    const selectedValue = selectedWeek ? selectedWeek[type] : 0;

    return formatCurrencyFromCentsToReais(selectedValue ?? 0);
  };

  const buildVariation = (type: PaymentStatusType): number => {
    const selectedWeekIndex = chartData.findIndex((week) => week.week === barSelectedKey);

    if (selectedWeekIndex === -1 || selectedWeekIndex === 0) return 0;

    const selectedValue = chartData[selectedWeekIndex]?.[type] ?? 0;
    const previousValue = chartData[selectedWeekIndex - 1]?.[type] ?? 0;

    if (previousValue === 0) {
      return 0;
    }

    const variation = ((selectedValue - previousValue) / previousValue) * 100;

    const variationRounded = Math.round(variation);

    if (variationRounded === -100) {
      return 0;
    }

    return variationRounded;
  };

  const calculateTotalAmount = (type: PaymentStatusType): string => {
    if (!displayValues) {
      return "R$ \u2022\u2022\u2022\u2022\u2022";
    }

    const totalAmount = chartData.reduce((acc, week) => acc + (week[type] ?? 0), 0);
    return formatCurrencyFromCentsToReais(totalAmount);
  };

  const getSelectedBarPeriod = (): string => {
    if (!barSelectedKey) return buildLabelInterval(statusChartData.periods);

    const selectedWeek = chartData.find((week) => week.week === barSelectedKey);

    return selectedWeek ? (selectedWeek.period as string) : "";
  };

  const toggleAmountVisibility = (): void => {
    setDisplayValues((prev) => !prev);
  };

  const handleHelpCircleClick = (status: PaymentStatusType): void => {
    void router.navigate({
      search: {
        ...searchParams,
        action: `status-${status}-about`,
      },
    });
  };

  const filteredChartData = chartData.map((item: ChartDataType) => {
    const newItem = { ...item };
    Object.keys(newItem).forEach((key) => {
      if (typeof newItem[key] === "number" && newItem[key] < 0) {
        newItem[key] = 0;
      }
    });
    return newItem;
  });

  return (
    <div>
      <div className="mt-4">
        <div className="flex justify-between items-center">
          <span className="text-neutral-500 text-xs">
            {buildLabelInterval(statusChartData.periods)}
          </span>

          <button
            data-testid="eye-button"
            onClick={toggleAmountVisibility}
            type="button"
            className="focus:outline-none"
          >
            {displayValues ? (
              <Eye size={24} className="fill-neutral-600" />
            ) : (
              <EyeOff size={24} className="fill-neutral-600" />
            )}
          </button>
        </div>

        <div>
          <span className="text-xs font-medium text-neutral-700">
            {titlePrimaryTotal}
          </span>
          <div className="flex-row flex gap-2 items-center">
            <h2 className="text-2xl font-medium text-neutral-700 leading-none">
              {calculateTotalAmount(dataKeys.primary)}
            </h2>
            <button onClick={() => handleHelpCircleClick(dataKeys.primary)} type="button">
              <HelpCircle size={16} className="fill-neutral-500" />
            </button>
          </div>
        </div>

        <div>
          <span className="text-xs font-medium text-neutral-700">
            {titleSecondaryTotal}
          </span>
          <div className="flex-row flex gap-2 items-center">
            <h3 className="text-md font-semibold self-start leading-none">
              {calculateTotalAmount(dataKeys.secondary)}
            </h3>
            <button
              onClick={() => handleHelpCircleClick(dataKeys.secondary)}
              type="button"
            >
              <HelpCircle size={16} className="fill-neutral-500" />
            </button>
          </div>
        </div>

        <div className="justify-between flex-row flex h-[134px] mb-2 mt-8">
          <ChartRoot
            className="aspect-video w-[100%]"
            data={filteredChartData}
            onSelectedKeyChange={setBarSelectedKey}
            selectedKey={barSelectedKey}
          >
            <ChartBarContainer XAxisKey="week" showYAxis={false} height={134} barGap={0}>
              <ChartBarItem
                dataKey={dataKeys.primary}
                fill={FILL_PRIMARY}
                disabledFill={UNSELECTED_FILL_PRIMARY}
              />
              <ChartBarItem
                dataKey={dataKeys.secondary}
                fill={FILL_SECONDARY}
                disabledFill={UNSELECTED_FILL_SECONDARY}
              />
            </ChartBarContainer>
          </ChartRoot>
        </div>
        <label className="text-neutral-500 text-xs">{getSelectedBarPeriod()}</label>
        <div className="flex flex-col w-full gap-2 mt-2">
          <ChartLegendItem
            displayText={labelSecondaryLegend}
            value={buildLegendValue(dataKeys.secondary)}
            variation={buildVariation(dataKeys.secondary)}
            index={2}
            className="bg-white"
            fill={FILL_SECONDARY}
            direction="row"
          />
          <ChartLegendItem
            displayText={labelPrimaryLegend}
            value={buildLegendValue(dataKeys.primary)}
            variation={buildVariation(dataKeys.primary)}
            index={1}
            className="bg-white"
            fill={FILL_PRIMARY}
            direction="row"
          />
        </div>

        {dataIsEmpty() ? (
          <EmptyStateV2Root>
            <EmptyStateV2Icon Icon={DrinkColored} className="bg-white" />
            <EmptyStateV2Title>Sem dados para exibição.</EmptyStateV2Title>
            <EmptyStateV2Description>
              No momento não temos dados suficientes para exibir no gráfico. Os dados são
              alimentados através da geração de pagamentos e recebimentos das suas
              consultas.
            </EmptyStateV2Description>
          </EmptyStateV2Root>
        ) : null}
      </div>
      <PaymentStatusChartsAboutDrawer />
    </div>
  );
};
