import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgPartyConfetti = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M8.496 10.398a.25.25 0 0 0-.4.065l-3.79 7.58a1.231 1.231 0 0 0 1.651 1.652l7.58-3.79a.25.25 0 0 0 .065-.4zm1.06-1.06a1.75 1.75 0 0 0-2.803.454l-3.79 7.58a2.731 2.731 0 0 0 3.665 3.664l7.58-3.79a1.75 1.75 0 0 0 .454-2.802zM5 2.25a.75.75 0 0 1 .75.75v.5a.75.75 0 0 1-1.5 0V3A.75.75 0 0 1 5 2.25M5 5.75a.75.75 0 0 1 .75.75V7a.75.75 0 0 1-1.5 0v-.5A.75.75 0 0 1 5 5.75M5.75 5a.75.75 0 0 1 .75-.75H7a.75.75 0 0 1 0 1.5h-.5A.75.75 0 0 1 5.75 5M2.25 5A.75.75 0 0 1 3 4.25h.5a.75.75 0 0 1 0 1.5H3A.75.75 0 0 1 2.25 5M11.33 2.665a.75.75 0 0 1 1.005-.336 2.724 2.724 0 0 1 1.049 3.948l-.76 1.139a.75.75 0 1 1-1.248-.832l.76-1.14a1.224 1.224 0 0 0-.471-1.773.75.75 0 0 1-.336-1.006M18.556 11.864l-1.14.76a.75.75 0 1 1-.832-1.248l1.14-.76a2.724 2.724 0 0 1 3.947 1.049.75.75 0 1 1-1.342.67 1.224 1.224 0 0 0-1.774-.47M19 16.25a.75.75 0 0 1 .75.75v.5a.75.75 0 0 1-1.5 0V17a.75.75 0 0 1 .75-.75M19 19.75a.75.75 0 0 1 .75.75v.5a.75.75 0 0 1-1.5 0v-.5a.75.75 0 0 1 .75-.75M19.75 19a.75.75 0 0 1 .75-.75h.5a.75.75 0 0 1 0 1.5h-.5a.75.75 0 0 1-.75-.75M16.25 19a.75.75 0 0 1 .75-.75h.5a.75.75 0 0 1 0 1.5H17a.75.75 0 0 1-.75-.75M19 2.25a.75.75 0 0 1 .75.75v.5a.75.75 0 0 1-1.5 0V3a.75.75 0 0 1 .75-.75M19 5.75a.75.75 0 0 1 .75.75V7a.75.75 0 0 1-1.5 0v-.5a.75.75 0 0 1 .75-.75M19.75 5a.75.75 0 0 1 .75-.75h.5a.75.75 0 0 1 0 1.5h-.5a.75.75 0 0 1-.75-.75M16.25 5a.75.75 0 0 1 .75-.75h.5a.75.75 0 0 1 0 1.5H17a.75.75 0 0 1-.75-.75M15.53 8.47a.75.75 0 0 1 0 1.06l-1 1a.75.75 0 1 1-1.06-1.06l1-1a.75.75 0 0 1 1.06 0"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgPartyConfetti;
