import { LogoTertiary, Favicon, LogoSecondary } from "@repo/icons";
import { CpsBadge, CpsButton } from "corpus";
import { type NuntiusGetCustomerContactPeriodSummaryOutput } from "@repo/graphql-types/graphql";
import { formatWithZonedDate } from "@repo/lib";
import { type MutableRefObject, useRef } from "react";
import {
  StoriesFeedContainer,
  StoriesHeaderIcon,
  StoriesFeedContent,
  StoriesFeedShareButton,
} from "@/components/stories-feed";
import {
  type ContactReason,
  contactReasonMap,
} from "@/enums/customer-contact/contact-reason";
import { noSchedulingReasonMap } from "@/enums/customer-contact/no-scheduling-reason";

interface StoriesSetupProps {
  storiesRef: MutableRefObject<HTMLDivElement | null>;
}

const useStoriesSetup = (): StoriesSetupProps => {
  const storiesRef = useRef<HTMLDivElement | null>(null);

  return { storiesRef };
};

export const StoryCustomerContactRetrospective = ({
  from,
  to,
}: {
  from: Date;
  to: Date;
}): JSX.Element => {
  const { storiesRef } = useStoriesSetup();

  const fromDate = formatWithZonedDate(from, "dd MMM");
  const toDate = formatWithZonedDate(to, "dd MMM");

  return (
    <StoriesFeedContainer
      key="story-customer-contact-1"
      className="bg-secondary-400"
      ref={storiesRef}
    >
      <StoriesHeaderIcon>
        <LogoTertiary size={96} />
      </StoriesHeaderIcon>

      <StoriesFeedContent>
        <div className="font-medium text-white z-10">
          <p className="text-sm mb-4 lowercase">
            {fromDate} - {toDate}
          </p>
          <div className="text-[40px] mb-2 leading-10">
            <p>Sua retrospectiva</p>
            <p>chegou!</p>
          </div>
          <div className="text-2xl leading-8">
            <p>Veja como o seu consultório</p>
            <p>evoluiu no último mês.</p>
          </div>
        </div>
        <Favicon
          size={24}
          className="absolute rotate-[66deg] scale-[65] z-0 will-change-transform
-top-[22rem] -right-[7rem] sm:-top-[5rem] sm:right-[12rem] lg:right-[26rem] fill-warning-50 opacity-15 animate-[moveIconPointedDownToRight_20s_linear_infinite]"
        />
        <StoriesFeedShareButton targetRef={storiesRef} />
      </StoriesFeedContent>
    </StoriesFeedContainer>
  );
};
export const StoryCustomerContactSummary = ({
  data,
}: {
  data: NuntiusGetCustomerContactPeriodSummaryOutput;
}): JSX.Element => {
  const { storiesRef } = useStoriesSetup();

  const totalCustomerText = data.totalCustomers === 1 ? "pessoa" : "pessoas";
  const totalCustomerDifferenceText =
    data.totalCustomers === 1 ? "diferente" : "diferentes";
  return (
    <StoriesFeedContainer
      key="story-customer-contact-2"
      className="bg-primary-400"
      ref={storiesRef}
    >
      <StoriesHeaderIcon>
        <LogoSecondary size={96} />
      </StoriesHeaderIcon>

      <StoriesFeedContent>
        <div className="text-[#056785] font-medium text-[40px] leading-[48px]">
          <p>Você recebeu</p>
          <p className="text-[#034052] font-bold">
            {data.totalCustomerContacts} contatos
          </p>
          <p>
            de <span className="text-[#034052] font-bold">{data.totalCustomers}</span>{" "}
            {totalCustomerText}
          </p>
          <p className="text-[#034052] font-bold">
            {totalCustomerDifferenceText}
            <span className="text-[#056785] font-medium">.</span>
          </p>
        </div>

        <StoriesFeedShareButton targetRef={storiesRef} isSecondary />
      </StoriesFeedContent>
    </StoriesFeedContainer>
  );
};

export const StoryCustomerContactStatistics = ({
  data,
}: {
  data: NuntiusGetCustomerContactPeriodSummaryOutput;
}): JSX.Element => {
  const { storiesRef } = useStoriesSetup();

  const isSingleCustomer = data.totalCustomers === 1;
  const customerPluralPrefix = isSingleCustomer ? "Dessa" : "Dessas";
  const customerPluralLabel = isSingleCustomer ? "pessoa" : "pessoas";

  return (
    <StoriesFeedContainer
      key="story-customer-contact-3"
      className="bg-secondary-400"
      ref={storiesRef}
    >
      <StoriesHeaderIcon>
        <LogoTertiary size={96} />
      </StoriesHeaderIcon>

      <StoriesFeedContent>
        <div className="font-medium text-white z-10">
          <div className="mb-8 text-[40px] leading-10">
            <p>
              {customerPluralPrefix} {data.totalCustomers}
            </p>
            <p>{customerPluralLabel}</p>
          </div>
          <div className="p-4 flex flex-col items-center">
            <p className="text-7xl font-bold leading-none">{data.totalOldCustomers}</p>
            <p className="text-2xl mb-2">
              {data.totalOldCustomers === 1 ? "já era paciente" : "já eram pacientes"}
            </p>
            <CpsBadge className="bg-success-100 py-[18px] px-[14px] text-neutral-600 text-lg font-medium">
              {`${Math.trunc(data.percentOldCustomers)}%`}
            </CpsBadge>
          </div>

          <hr className="w-[305px] mx-auto my-0 h-0.5 border-0 rounded bg-white" />
          <div className="p-4 flex flex-col items-center">
            <p className="text-7xl font-bold leading-none">{data.totalNewCustomers}</p>
            <p className="text-2xl mb-2">
              {data.totalNewCustomers === 1 ? (
                <>
                  é um <span className="font-bold">novo contato</span>
                </>
              ) : (
                <>
                  são <span className="font-bold">novos contatos</span>
                </>
              )}
            </p>
            <CpsBadge className="bg-success-100 py-[18px] px-[14px] text-neutral-600 text-lg font-medium">
              {`${Math.trunc(data.percentNewCustomers)}%`}
            </CpsBadge>
          </div>
        </div>
        <Favicon
          size={24}
          className="absolute scale-[65] z-0 animate-[moveIconLeftCornerToRight_15s_linear_infinite] will-change-transform
      bottom-[-21.5rem] right-[-25.5rem] sm:bottom-[-5.5rem] sm:right-[3rem] md:right-[-4rem] md:bottom-[-18rem] lg:right-[8rem] xl:right-[17rem] fill-warning-50 opacity-15"
        />

        <StoriesFeedShareButton targetRef={storiesRef} />
      </StoriesFeedContent>
    </StoriesFeedContainer>
  );
};

export const StoryCustomerContactMainContactReason = ({
  data,
}: {
  data: NuntiusGetCustomerContactPeriodSummaryOutput;
}): JSX.Element | null => {
  const { storiesRef } = useStoriesSetup();

  if (!data.mostFrequentMainReasonId) return null;

  const getHumanizedReason = (reasonId: ContactReason): string => {
    return contactReasonMap[reasonId] || "";
  };

  const reason = data.mostFrequentMainReasonId
    ? getHumanizedReason(data.mostFrequentMainReasonId as ContactReason)
    : "";

  return (
    <StoriesFeedContainer
      key="story-customer-contact-4"
      className="bg-secondary-400"
      ref={storiesRef}
    >
      <StoriesHeaderIcon>
        <LogoTertiary size={96} />
      </StoriesHeaderIcon>

      <StoriesFeedContent>
        <div className="font-medium text-white z-10 flex flex-col items-center">
          <div className="mb-10 flex flex-col gap-2">
            <div className="text-2xl leading-8">
              <p>O principal motivo de</p>
              <p>contato foi...</p>
            </div>
            <div className="text-[44px] font-bold leading-none">
              <p>{reason}</p>
            </div>
          </div>

          <div className="bg-primary-400 rounded-full w-60 h-60 text-2xl flex flex-col justify-center items-center leading-none">
            <p>representando</p>
            <p className="font-bold text-7xl leading-[80px]">
              {Math.trunc(data.percentMostFrequentMainReason)}%
            </p>
            <p>do total.</p>
          </div>
        </div>
        <Favicon
          size={24}
          className="absolute rotate-[66deg] scale-[65] z-0 will-change-transform
      -top-[22rem] -right-[7rem] sm:-top-[5rem] sm:right-[12rem] lg:right-[26rem] fill-warning-50 opacity-15 animate-[moveIconPointedDownToRight_20s_linear_infinite]"
        />

        <StoriesFeedShareButton targetRef={storiesRef} />
      </StoriesFeedContent>
    </StoriesFeedContainer>
  );
};

export const StoryCustomerContactAppointmentQuantity = ({
  data,
}: {
  data: NuntiusGetCustomerContactPeriodSummaryOutput;
}): JSX.Element => {
  const { storiesRef } = useStoriesSetup();

  return (
    <StoriesFeedContainer
      key="story-customer-contact-5"
      className="bg-primary-400"
      ref={storiesRef}
    >
      <StoriesHeaderIcon>
        <LogoSecondary size={96} />
      </StoriesHeaderIcon>

      <StoriesFeedContent>
        <div className="text-2xl z-10 text-[#034052]">
          <div className="leading-8 mb-4 font-medium">
            <p>Das pessoas que disseram</p>
            <p>que queriam agendar…</p>
          </div>

          <div className="leading-5">
            <p className="text-9xl font-normal">
              {Math.trunc(data.percentCustomerContactsAppointmentsScheduled)}%
            </p>
            <p className="text-[44px] font-semibold">agendaram</p>
          </div>
        </div>
        <Favicon
          size={24}
          className="absolute rotate-[90deg] scale-[65] z-0 animate-[moveIconRightCornerToRight_15s_linear_infinite] will-change-transform
      bottom-[-30.5rem] left-[-24.5rem] sm:bottom-[-30rem] sm:left-[-6rem] lg:left-[3rem] xl:left-[16rem] xl:bottom-[-33rem] fill-warning-50 opacity-15"
        />
      </StoriesFeedContent>

      <StoriesFeedShareButton targetRef={storiesRef} isSecondary />
    </StoriesFeedContainer>
  );
};

export const StoryCustomerContactNoAppointmentReason = ({
  data,
}: {
  data: NuntiusGetCustomerContactPeriodSummaryOutput;
}): JSX.Element => {
  const { storiesRef } = useStoriesSetup();

  const reasons = data.mostFrequentNotScheduledAppointmentReasonsIds
    .map((id) => noSchedulingReasonMap[id])
    .filter(Boolean);

  return (
    <StoriesFeedContainer
      key="story-customer-contact-6"
      className="bg-secondary-400"
      ref={storiesRef}
    >
      <StoriesHeaderIcon>
        <LogoTertiary size={96} />
      </StoriesHeaderIcon>

      <StoriesFeedContent>
        <div className="text-white">
          <div className="leading-7 text-xl mb-14 font-medium">
            <p>Os principais motivos de</p>
            <p>não agendamento foram:</p>
          </div>

          <div className="flex flex-col gap-[34px]">
            {reasons.map((reason, index) => (
              <div className="flex items-center gap-4" key={reason}>
                <div className="w-12 h-12 bg-primary-400 rounded-full leading-0 font-normal shrink-0">
                  <span className="text-[30px] text-secondary-400 h-full flex items-center justify-center">
                    {`${index + 1}º`}
                  </span>
                </div>
                <div className="text-left leading-[27px] text-2xl font-medium max-w-[233px]">
                  {reason}
                </div>
              </div>
            ))}
          </div>
        </div>

        <StoriesFeedShareButton targetRef={storiesRef} />
      </StoriesFeedContent>
    </StoriesFeedContainer>
  );
};

export const StoryCustomerContactCheckIns = ({
  data,
}: {
  data: NuntiusGetCustomerContactPeriodSummaryOutput;
}): JSX.Element => {
  const { storiesRef } = useStoriesSetup();

  return (
    <StoriesFeedContainer
      key="story-customer-contact-7"
      className="bg-primary-400"
      ref={storiesRef}
    >
      <StoriesHeaderIcon>
        <LogoSecondary size={96} />
      </StoriesHeaderIcon>

      <StoriesFeedContent>
        <div className="font-medium text-[#034052] z-10">
          <div className="leading-9 text-3xl mb-2">
            <p>Foram {data.totalAllocatedRoomsOnPeriod} check-ins</p>
            <p>realizados.</p>
          </div>
          <p className="text-2xl leading-8 mb-6">Alcançando a marca de…</p>
          <div className="flex flex-col gap-2">
            <p className="text-9xl font-normal leading-none">
              {data.totalAllocatedRooms}
            </p>
            <div className="text-[40px] leading-[44px]">
              <p>atendimentos no</p>
              <p>seu consultório</p>
            </div>
          </div>
        </div>
        <Favicon
          size={24}
          className="absolute rotate-[66deg] scale-[65] z-0 will-change-transform
        -top-[22rem] -right-[7rem] sm:-top-[5rem] sm:right-[12rem] lg:right-[26rem] fill-warning-50 opacity-15 animate-[moveIconPointedDownToRight_20s_linear_infinite]"
        />

        <StoriesFeedShareButton targetRef={storiesRef} isSecondary />
      </StoriesFeedContent>
    </StoriesFeedContainer>
  );
};

interface StoryCustomerContactAccessOverviewProps {
  onAccessOverviewClick: () => void;
}

export const StoryCustomerContactAccessOverview = ({
  onAccessOverviewClick,
}: StoryCustomerContactAccessOverviewProps): JSX.Element => {
  return (
    <StoriesFeedContainer key="story-customer-contact-8" className="bg-secondary-400">
      <StoriesHeaderIcon>
        <LogoTertiary size={96} />
      </StoriesHeaderIcon>

      <StoriesFeedContent>
        <div className="text-[40px] font-medium z-10 text-white leading-[44px] my-10 container">
          Acompanhe a <span className="font-bold">evolução</span> do seu consultório e
          descubra mais sobre seus{" "}
          <span className="font-bold">contatos e agendamentos.</span>
        </div>

        <div className="flex justify-center z-[1000]">
          <CpsButton
            rounded
            fullWidth
            className="text-xl font-medium"
            onClick={onAccessOverviewClick}
          >
            Acesse o relatório
          </CpsButton>
        </div>
        <Favicon
          size={24}
          className="absolute rotate-[90deg] scale-[65] z-0 animate-[moveIconRightCornerToRight_15s_linear_infinite] will-change-transform
      bottom-[-11rem] left-[-24.5rem] sm:bottom-[-7rem] sm:left-[-6rem] md:bottom-[-10rem] lg:left-[3rem] lg:bottom-[-6rem] xl:left-[22rem] xl:bottom-[-6rem] 2xl:bottom-[5rem] 2xl:left-[60rem] fill-warning-50 opacity-15"
        />
      </StoriesFeedContent>
    </StoriesFeedContainer>
  );
};
