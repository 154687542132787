import { graphql } from "@repo/graphql-types/gql";
import { Left } from "@repo/icons";
import { createFileRoute, useRouteContext, useLoaderData } from "@tanstack/react-router";
import { z } from "zod";
import { getCurrentDate } from "@repo/lib";
import { useGraphQL } from "@/hooks/use-graphql";
import { MyProfileDetailsSection } from "@/components/my-profile-details-section";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { CurrentPlanInfo } from "@/components/current-plan-info";
import { CardInfoWithEditOption } from "@/components/card-info-with-edit-option";
import { OneLivProfileCard } from "@/components/oneliv-profile-card";

const MyProfilePageQuery = graphql(/* GraphQL */ `
  query MyProfilePageQuery($codUsuario: Int!, $currentDate: datetime2!) {
    ...MyProfileDetailsSectionFragment
    ...CardInfoQueryFragment
    ...CurrentPlanInfoFragment
    ...OneLivProfileCardFragment
  }
`);

const profileSchema = z.object({
  action: z.string().optional(),
});

export const MyProfilePage = (): JSX.Element => {
  const { user } = useRouteContext({ strict: false });

  const routerContext = useLoaderData({
    from: "/settings/profile/",
  });

  const queryResult = useGraphQL(MyProfilePageQuery, {
    codUsuario: Number(user.codUsuario),
    currentDate: getCurrentDate().toISOString(),
  });
  const { data, isError } = queryResult;

  const showPlanMigrationPage = routerContext.showPlanMigrationPage;
  const scheduledPlanMigration = routerContext.scheduledPlanMigration;

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Meu perfil" align="center" />
      </HeaderRoot>
      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data ? (
            <div className="flex flex-col gap-6">
              <MyProfileDetailsSection isError={isError} queryData={data} />

              {showPlanMigrationPage ? (
                <CurrentPlanInfo
                  showScheduledMigration={scheduledPlanMigration}
                  data={data}
                />
              ) : null}

              <CardInfoWithEditOption data={data} />

              <OneLivProfileCard data={data} />
            </div>
          ) : null}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/profile/")({
  component: MyProfilePage,
  validateSearch: profileSchema,
  beforeLoad: ({ context }) => {
    return {
      showPlanMigrationPage: context.ldClient.getShowPlanMigrationPage(),
      scheduledPlanMigration: context.ldClient.getScheduledPlanMigration(),
    };
  },
  loader: ({ context }) => {
    const showPlanMigrationPage = context.showPlanMigrationPage;
    const scheduledPlanMigration = context.scheduledPlanMigration;
    return { showPlanMigrationPage, scheduledPlanMigration };
  },
});
