import { cn, PagarmePayableStatus } from "@repo/lib";

interface PayableStatusProps {
  status: string;
}

export const PayableStatus = ({ status }: PayableStatusProps): JSX.Element => {
  const labelStatusDictionary: Record<string, string> = {
    [PagarmePayableStatus.Paid]: "Liberado",
    [PagarmePayableStatus.WaitingFunds]: "Previsto",
    refund: "Estornado",
  };

  const colorStatusDictionary: Record<string, string> = {
    [PagarmePayableStatus.Paid]: "bg-success-400",
    [PagarmePayableStatus.WaitingFunds]: "bg-primary-400",
    refund: "bg-danger-400",
  };

  return (
    <div className="flex flex-row items-center gap-1 mt-2">
      <div
        className={cn("w-[12px] h-[12px] rounded-full", colorStatusDictionary[status])}
      />
      <span className="text-xs text-neutral-500 font-semibold uppercase">
        Recebimento {labelStatusDictionary[status]}
      </span>
    </div>
  );
};
