import {
  createRootRouteWithContext,
  Outlet,
  type ParsedLocation,
  redirect,
} from "@tanstack/react-router";
import "@/styles/index.css";
import "corpus/styles.css";
import "@/styles/calendar.css";
import { graphql } from "@repo/graphql-types";
import type {
  FuncionalidadeContratadaOutput,
  PacoteServicoOutput,
  UserQueryQuery,
} from "@repo/graphql-types/graphql";
import * as Sentry from "@sentry/react";
import {
  getAppInfo,
  type UserContext,
  Version,
  convertDateTimeIgnoringTimezone,
} from "@repo/lib";
import type { Auth0Client, User } from "@auth0/auth0-spa-js";
import { z } from "zod";
import { useEffect, useState } from "react";
import { Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";
import { getDefaultStore } from "jotai";
import { type FragmentDefinitionNode } from "graphql";
import { queryFn } from "@/hooks/use-graphql";
import { useIdentifyUser } from "@/hooks/use-identify-user";
import { usePushNotificationPrompt } from "@/hooks/use-push-notification-prompt";
import { MainElement } from "@/components/main-element";
import type { RootRouteContext } from "@/routes/-root-context";
import { AllocatedRoomBarController } from "@/components/allocated-room-bar-controller";
import { ReturnRoomActionSearchParamsSchema } from "@/lib/action-schemas/return-room.ts";
import {
  logLogoutAccessDenied,
  logLogoutInvalidUser,
  logNotAuthenticated,
} from "@/lib/breadcrumbs.ts";
import { UserFeedbackButton } from "@/components/user-feedback-button.tsx";
import { DisconnectedErrorDrawer } from "@/components/disconnected-error-drawer";
import { useNetworkStatus } from "@/hooks/use-network-status";
import { useSetMaxZoom } from "@/hooks/use-set-max-zoom";
import { ErrorDrawerProvider } from "@/lib/context/error-drawer-context.tsx";
import { ErrorDrawer } from "@/components/error-drawer.tsx";
import { useUserPilotInitializer } from "@/hooks/use-user-pilot-initializer";
import { deepLinkAtom } from "@/lib/atoms/deep-link-atom.ts";

const defaultStore = getDefaultStore();

const HASURA_JWT_CLAIMS = "https://hasura.io/jwt/claims";
const X_HASURA_DEFAULT_ROLE = "x-hasura-default-role";
const HASURA_USER_ROLE = "user";
const COD_PORTO_SEGURO = 1;

const isRoleUser = (authUser?: User): boolean => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- safe
  return authUser?.[HASURA_JWT_CLAIMS]?.[X_HASURA_DEFAULT_ROLE] === HASURA_USER_ROLE;
};

const logoutAndThrowInvalidUser = async (authClient: Auth0Client): Promise<never> => {
  await authClient.logout();
  throw new Error("Usuário não autorizado.");
};

const logoutAndThrowAccessDenied = async (
  authClient: Auth0Client,
  cause: string,
): Promise<never> => {
  await authClient.logout();
  throw new Error("Acesso Negado", { cause });
};

const redirectToDeepLink = (location: ParsedLocation): void => {
  const deepLinkUrl = defaultStore.get(deepLinkAtom);

  if (deepLinkUrl && location.pathname !== deepLinkUrl) {
    defaultStore.set(deepLinkAtom, undefined);
    redirect({ to: deepLinkUrl, throw: true });
  } else if (deepLinkUrl) {
    defaultStore.set(deepLinkAtom, undefined);
  }
};

const rootBeforeLoad = async (
  context: RootRouteContext,
  location: ParsedLocation,
): Promise<void> => {
  if (location.pathname.includes("/auth")) {
    // Skip this middleware on auth routes
    return;
  }

  redirectToDeepLink(location);

  const isAuthenticated = await context.authClient.isAuthenticated();
  const accessDenied = "accessDenied" in location.search && location.search.accessDenied;

  if (!isAuthenticated && accessDenied) {
    const cause = "cause" in location.search ? String(location.search.cause) : "";
    logLogoutAccessDenied({ location, reason: cause });
    await logoutAndThrowAccessDenied(context.authClient, cause);
  }

  if (!isAuthenticated) {
    logNotAuthenticated({ location });
    redirect({ to: "/auth/login", search: { returnTo: location.pathname }, throw: true });
  }

  const authUser = await context.authClient.getUser();

  if (authUser) {
    Sentry.setUser({ username: authUser.sub });
  }

  const isValidRole = isRoleUser(authUser);
  if (!isValidRole) {
    logLogoutInvalidUser({ location, reason: "Invalid Role" });
    await logoutAndThrowInvalidUser(context.authClient);
  }

  const oldUser = context.user;

  context.user = await getUserData({ context, sub: authUser?.sub ?? "" });

  if (oldUser.codUsuario !== context.user.codUsuario) {
    Sentry.setUser({
      id: context.user.codUsuario,
      username: authUser?.sub ?? "",
      email: context.user.email,
      fullName: context.user.apelido ?? `${context.user.nome} ${context.user.sobrenome}`,
    });

    const appInfo = await getAppInfo();

    if (!context.ldClient.userContextAlreadyAdded()) {
      const platform = Capacitor.getPlatform();
      const { identifier: deviceId } = await Device.getId();

      await context.ldClient.addContexts([
        {
          kind: "user",
          key: `${context.user.codUsuario}`,
        },
        {
          kind: "device",
          key: deviceId,
          platform,
          version: appInfo.version,
        },
      ]);
    }

    const actualVersion = new Version(appInfo.version);
    const minimalVersion = context.ldClient.getMinimalAppVersion();

    if (actualVersion.lowerThan(minimalVersion)) {
      redirect({
        to: "/update-required",
        search: { redirect: location.href },
        throw: true,
      });
    }
  }

  if (context.user.codPerfil !== 3) {
    logLogoutInvalidUser({ location, reason: "Invalid codPerfil" });
    await logoutAndThrowInvalidUser(context.authClient);
  }

  if (!context.user.ativo || context.user.bloqueado) {
    redirect({
      to: "/access-blocked",
      search: { redirect: location.href },
      throw: true,
    });
  }
};

const getUserData = async (authContext: {
  context: RootRouteContext;
  sub: string;
}): Promise<UserContext> => {
  const UserQuery = graphql(/* GraphQL */ `
    query UserQuery {
      usuarios {
        codUsuario
        ativo
        apelido
        codPerfil
        email
        nome
        sobrenome
        usuarioProfissional: UsuariosProfissionai {
          codConselhoClasse
          bloqueado
          dataInicioDivida
          dataValidacao
          usuarioTelefone {
            ramalTroncoNormalizado
          }
        }
      }
      clinicas: tbClinicas {
        codClinica
      }
      integracoes: tbUsuariosIntegracoes(
        where: { ativo: { _eq: true }, codParceiro: { _eq: 1 } }
      ) {
        codParceiro
      }
      membro {
        altaTaxaCancelamento
        funcionalidadesContratadas {
          codFuncionalidade
          ativo
          contratada
        }
        pacotesServicos {
          cancelado
          codPacoteServicos
          dataDesativacao
          dataFimTrial
          trialAtivo
          trialFinalizado
          grupoUpgrade
          pacoteServicoPreco {
            valorUnitarioVariavel
            valorUnitarioFixo
          }
        }
      }
    }
  `);

  const ONE_DAY_STALE_TIME = 86400000;
  const userQuery = await authContext.context.queryClient.fetchQuery<
    UserQueryQuery | undefined
  >({
    queryKey: [
      (UserQuery.definitions[0] as FragmentDefinitionNode).name.value,
      authContext.sub,
    ],
    queryFn: () => queryFn(UserQuery, authContext.context.authClient),
    staleTime: ONE_DAY_STALE_TIME,
  });

  const user = userQuery?.usuarios[0];
  const clinic = userQuery?.clinicas[0];
  const integration = userQuery?.integracoes[0];
  const member = userQuery?.membro;
  const authUser = await authContext.context.authClient.getUser();

  const funcionalidadesContratadas: FuncionalidadeContratadaOutput[] =
    (member?.funcionalidadesContratadas ?? []) as FuncionalidadeContratadaOutput[];

  const pacotesServicos: PacoteServicoOutput[] = (member?.pacotesServicos ??
    []) as PacoteServicoOutput[];

  return {
    apelido: user?.apelido,
    ativo: user?.ativo ?? false,
    nome: user?.nome ?? "",
    sobrenome: user?.sobrenome ?? "",
    codUsuario: user?.codUsuario ?? 0,
    codPerfil: user?.codPerfil ?? 0,
    codConselhoClasse: user?.usuarioProfissional?.codConselhoClasse ?? "",
    codClinica: clinic?.codClinica,
    email: user?.email,
    bloqueado: user?.usuarioProfissional?.bloqueado ?? false,
    dataInicioDivida: user?.usuarioProfissional?.dataInicioDivida
      ? new Date(user.usuarioProfissional.dataInicioDivida)
      : undefined,
    dataValidacao: user?.usuarioProfissional?.dataValidacao
      ? convertDateTimeIgnoringTimezone(user.usuarioProfissional.dataValidacao)
      : undefined,
    codParceiroPortoSeguro: integration?.codParceiro === COD_PORTO_SEGURO,
    altaTaxaCancelamento: member?.altaTaxaCancelamento ?? false,
    funcionalidadesContratadas,
    pacotesServicos,
    sub: authUser?.sub ?? "",
    ramalTroncoNormalizado:
      user?.usuarioProfissional?.usuarioTelefone?.ramalTroncoNormalizado ?? "",
  };
};

const RootRoute = (): JSX.Element => {
  useIdentifyUser();
  usePushNotificationPrompt();
  useUserPilotInitializer();
  useSetMaxZoom();

  const isConnected = useNetworkStatus();
  const [showDisconnectedError, setShowDisconnectedError] = useState(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    if (!isConnected) {
      setShowDisconnectedError(true);
      setIsDrawerVisible(true);
    } else if (showDisconnectedError) {
      timer = setTimeout(() => {
        setShowDisconnectedError(false);
        setIsDrawerVisible(false);
      }, 3000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isConnected, showDisconnectedError]);

  const disconnectedErrorDrawerDismiss = (value: boolean): void => {
    if (!value) {
      setShowDisconnectedError(false);
      setIsDrawerVisible(false);
    }
  };

  return (
    <MainElement>
      <ErrorDrawerProvider>
        <Outlet />
        <AllocatedRoomBarController />
        <UserFeedbackButton />
        <ErrorDrawer />
        <DisconnectedErrorDrawer
          setOpen={disconnectedErrorDrawerDismiss}
          open={isDrawerVisible}
        />
      </ErrorDrawerProvider>
    </MainElement>
  );
};

const GenericActionSearchParams = z.object({
  action: z.string().optional(),
});

const actionSearchParamsSchema = z.union([
  GenericActionSearchParams,
  ReturnRoomActionSearchParamsSchema,
  z.any(),
]);

export const Route = createRootRouteWithContext<RootRouteContext>()({
  component: RootRoute,
  beforeLoad: async ({ context, location }) => {
    await rootBeforeLoad(context, location);
  },
  validateSearch: actionSearchParamsSchema,
});
