import { graphql } from "@repo/graphql-types";
import { type InvalidRecipientStatus } from "@repo/lib";
import { useRouteContext } from "@tanstack/react-router";
import { CpsAlert } from "corpus";
import { useGraphQL } from "@/hooks/use-graphql";
import { AsyncDataWrapper } from "@//components/async-data-wrapper";

export const RecipientInvalidAlertWrapperQuery = graphql(`
  query RecipientInvalidAlertWrapperQuery($codUsuario: Int!, $codClinica: Int) {
    expensumRecipient: ExpensumGetRecipient(
      arg1: { codUsuario: $codUsuario, codClinica: $codClinica }
    ) {
      recipient {
        status
      }
    }
  }
`);

const recipientInvalidStatuses: InvalidRecipientStatus[] = [
  "refused",
  "suspended",
  "inactive",
  "blocked",
];

interface RecipientInvalidAlertWrapperProps {
  children: JSX.Element;
}

export const RecipientInvalidAlertWrapper = ({
  children,
}: RecipientInvalidAlertWrapperProps): JSX.Element => {
  const { user } = useRouteContext({
    strict: false,
  });

  const queryData = useGraphQL(RecipientInvalidAlertWrapperQuery, {
    codUsuario: Number(user.codUsuario),
    codClinica: Number(user.codClinica),
  });

  const { data } = queryData;

  const recipientStatus = data?.expensumRecipient?.recipient?.status;
  const hasRecipientInvalidStatus = recipientInvalidStatuses.some(
    (invalidStatus) => invalidStatus === recipientStatus,
  );

  return (
    <>
      {hasRecipientInvalidStatus ? (
        <CpsAlert
          title="Não é possível gerenciar novos pagamentos no momento, apenas visualizar o histórico. Dúvidas? Contate o time de Relacionamento."
          description=""
          type="info"
        />
      ) : null}
      <AsyncDataWrapper {...queryData}>{data ? children : null}</AsyncDataWrapper>
    </>
  );
};
