import { graphql } from "@repo/graphql-types/gql";
import { Left } from "@repo/icons";
import { convertDateTimeIgnoringTimezone, formatWithZonedDate } from "@repo/lib";
import { createFileRoute, redirect, useRouter } from "@tanstack/react-router";
import { z } from "zod";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { type FragmentType } from "@repo/graphql-types";
import { useResetAtom } from "jotai/utils";
import { fetchQuery } from "@/hooks/use-graphql";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import {
  AppointmentForm,
  type AppointmentFormFragment,
} from "@/components/appointment-form";
import { appointmentFormAtom } from "@/lib/atoms/appointment-form-atom";
import { FullPageSpinner } from "@/components/full-page-spinner";
import {
  type BlockedActionsListAlertFragment,
  BlockedAppointmentAlert,
} from "@/components/blocked-appointment-alert";

const ReschedulePageQuery = graphql(`
  query ReschedulePageQuery($dataInicio: date!, $codAgendamento: Int!) {
    usuariosAgendas(
      where: {
        ativo: { _eq: true }
        tbUsuariosAgendasCompromissos: {
          ativo: { _eq: true }
          UsuarioCompromisso: {
            usuariosCompromissosCanaisAgendamentos: { codCanalAgendamento: { _eq: 2 } }
          }
        }
        _or: [{ dataFim: { _gte: $dataInicio } }, { dataFim: { _is_null: true } }]
      }
    ) {
      codUsuarioAgenda
    }

    ...AppointmentFormFragment
    ...BlockedActionsListAlertFragment

    agendamento: agendamentos_by_pk(codAgendamento: $codAgendamento) {
      codUnidade
      codUsuarioCompromisso
      codUsuarioFormaRecebimento
      data
      codAgendamento
    }
  }
`);

export const ReschedulePage = (): JSX.Element => {
  const loaderData = Route.useLoaderData().data;

  const [appointmentFormAtomValue, setAppointmentFormAtomValue] =
    useAtom(appointmentFormAtom);

  const setInitialValues = (): void => {
    if (loaderData?.agendamento) {
      const {
        codUnidade,
        codUsuarioCompromisso,
        codUsuarioFormaRecebimento,
        data,
        codAgendamento,
      } = loaderData.agendamento;

      const { appointmentDate, appointmentType, paymentMethod, unit, appointmentId } =
        appointmentFormAtomValue;

      if (!appointmentId || appointmentId !== codAgendamento) {
        setAppointmentFormAtomValue({
          ...appointmentFormAtomValue,
          appointmentDate: convertDateTimeIgnoringTimezone(data),
          appointmentType: codUsuarioCompromisso,
          paymentMethod: codUsuarioFormaRecebimento,
          unit: codUnidade,
          appointmentId: codAgendamento,
        });
        return;
      }

      setAppointmentFormAtomValue({
        ...appointmentFormAtomValue,
        appointmentDate: appointmentDate ?? convertDateTimeIgnoringTimezone(data),
        appointmentType: appointmentType || codUsuarioCompromisso,
        paymentMethod: paymentMethod || codUsuarioFormaRecebimento,
        unit: unit || codUnidade,
        appointmentId,
      });
    }
  };

  const isPending = !appointmentFormAtomValue.appointmentId;

  useEffect(() => {
    setInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Deve ser executado apenas na montagem do componente
  }, []);

  const router = useRouter();

  const resetRescheduleAppointmentForm = useResetAtom(appointmentFormAtom);

  const onBackButtonClick = (): void => {
    resetRescheduleAppointmentForm();
    router.history.back();
  };

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" onClick={onBackButtonClick} />
        <HeaderTitle title="Reagendamento" align="center" />
      </HeaderRoot>

      <Page>
        {!isPending ? (
          <>
            <div className="pb-4">
              <BlockedAppointmentAlert
                blockedActionsListFragmentData={
                  loaderData as FragmentType<typeof BlockedActionsListAlertFragment>
                }
              />
            </div>
            <AppointmentForm
              queryData={loaderData as FragmentType<typeof AppointmentFormFragment>}
            />
          </>
        ) : (
          <FullPageSpinner />
        )}
      </Page>
    </>
  );
};

export const Route = createFileRoute("/appointment/$appointmentId/reschedule")({
  component: ReschedulePage,
  parseParams: (params) => ({
    appointmentId: z.number().int().parse(Number(params.appointmentId)),
  }),
  beforeLoad: ({ context }) => {
    return {
      showAppointmentsAndCloseSchedulesDuringSchedule:
        context.ldClient.getShowAppointmentsAndCloseSchedulesDuringSchedule(),
    };
  },
  loader: async (opts) => {
    try {
      const variables = {
        dataInicio: formatWithZonedDate(new Date(), "yyyy-MM-dd"),
        codAgendamento: opts.params.appointmentId,
      };

      const data = await fetchQuery(opts.context, ReschedulePageQuery, variables);

      if (!data.agendamento) throw new Error("Appointment not found!");

      return {
        data,
        showAppointmentsAndCloseSchedulesDuringSchedule:
          opts.context.showAppointmentsAndCloseSchedulesDuringSchedule,
      };
    } catch (e) {
      redirect({
        to: "/",
        throw: true,
      });
    }

    return {};
  },
});
