import { type SVGIconProps } from "@repo/icons";
import { cn } from "@repo/lib";
import { type ReactNode, type FunctionComponent } from "react";

interface EmptyStateV2IconProps {
  className?: string;
  Icon: FunctionComponent<SVGIconProps>;
}
export const EmptyStateV2Icon = ({
  className,
  Icon,
}: EmptyStateV2IconProps): JSX.Element => (
  <div className={cn("rounded-full bg-neutral-100 p-4 inline-flex", className)}>
    <Icon className="stroke-neutral-500" size={24} />
  </div>
);

interface EmptyStateV2Props {
  children: JSX.Element | string;
  className?: string;
}

export const EmptyStateV2Title = ({
  children,
  className,
}: EmptyStateV2Props): JSX.Element => (
  <h2 className={cn("mt-2 text-lg font-semibold text-gray-900", className)}>
    {children}
  </h2>
);

export const EmptyStateV2Description = ({
  children,
  className,
}: EmptyStateV2Props): JSX.Element => (
  <p className={cn("mt-2 text-md text-gray-600", className)}>{children}</p>
);

interface EmptyStateV2RootProps {
  children: ReactNode;
  className?: string;
}

export const EmptyStateV2Root = ({
  children,
  className,
}: EmptyStateV2RootProps): JSX.Element => (
  <div className={cn("py-8", className)}>{children}</div>
);
