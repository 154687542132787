import { type ReactNode } from "react";

interface DashboardSectionHeaderProps {
  children: ReactNode;
  onClick: (open: boolean) => void;
}

export const DashboardSectionHeader = ({
  children,
  onClick,
}: DashboardSectionHeaderProps): JSX.Element => {
  return (
    <div className="flex flex-col gap-1">
      <h2 className="font-medium text-neutral-600 text-md">{children}</h2>
      <button
        className="font-medium text-sm text-[#0080A5] self-start underline"
        onClick={() => {
          onClick(true);
        }}
        type="button"
      >
        O que é isso?
      </button>
    </div>
  );
};
