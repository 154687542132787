import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgTransfer = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="#000"
      viewBox="0 0 16 16"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.667 10H3.333A1.333 1.333 0 0 1 2 8.667V3.333C2 2.597 2.597 2 3.333 2h7.334C11.403 2 12 2.597 12 3.333V6"
      />
      <path strokeLinecap="round" strokeLinejoin="round" d="m7.666 11 1-1-1-1" />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.666 8v-.667C4.666 6.597 5.263 6 5.999 6h6.667c.737 0 1.333.597 1.333 1.333v5.334c0 .736-.596 1.333-1.333 1.333H5.999a1.333 1.333 0 0 1-1.333-1.333v0V12"
      />
    </svg>
  );
};
export default SvgTransfer;
