import {
  createFileRoute,
  useRouter,
  useNavigate,
  useSearch,
} from "@tanstack/react-router";
import { Left } from "@repo/icons";
import { graphql } from "@repo/graphql-types/gql";
import { ServicePackageEnum } from "@repo/lib";
import { HeaderRoot, HeaderTitle, HeaderButton } from "@/components/header";
import { Page } from "@/components/page";
import { AsyncDataWrapper } from "@/components/async-data-wrapper.tsx";
import { useGraphQL } from "@/hooks/use-graphql.ts";
import { validatePackageAccess } from "@/lib/assist-package-guard";
import {
  SubscribeAssistProBenefitsFeatures,
  SubscribeAssistProBenefitsLPLink,
  SubscribeAssistProBenefitsRoot,
  SubscribeAssistProBenefitsTitle,
} from "@/components/subscribe-assist-pro/subscribe-assist-pro-benefits";
import { SubscribeAssistProCard } from "@/components/subscribe-assist-pro/subscribe-assist-pro-card";
import { SUBSCRIPTION_PAGE_FEATURES } from "@/lib/constants/subscribe-assist-pro";
import { trackEvent } from "@/lib/tracking";

const ServicePackagesQuery = graphql(/* GraphQL */ `
  query ServicePackagesQuery($codPacoteServico: Int!) {
    ...ServicePackagesFragment
  }
`);

export const SubscribeAssistProPage = (): JSX.Element => {
  const router = useRouter();
  const navigate = useNavigate();
  const { navigateBack } = useSearch({
    from: "/settings/assist/subscribe-assist-pro/",
  });

  const professionalServicePackageEnumValue: number =
    ServicePackageEnum.Professional.valueOf();
  const queryResult = useGraphQL(ServicePackagesQuery, {
    codPacoteServico: professionalServicePackageEnumValue,
  });
  const { data } = queryResult;

  const onBackButtonClick = (): void => {
    if (navigateBack) {
      router.history.back();
    } else {
      void navigate({ to: "../" });
    }
  };

  const onDeclineClick = (): void => {
    const url = "/settings/assist/";
    trackEvent("Botão Decidir Depois Assist Pro Clicado", {
      destino: url,
    });

    void navigate({
      to: url,
    });
  };

  return (
    <>
      <HeaderRoot>
        <HeaderButton onClick={onBackButtonClick} icon={Left} align="start" />
        <HeaderTitle title="Livance Assist Pro" align="center" />
      </HeaderRoot>
      <Page className="px-0 pb-0 flex flex-col">
        <AsyncDataWrapper {...queryResult}>
          {data ? (
            <>
              <SubscribeAssistProBenefitsRoot>
                <SubscribeAssistProBenefitsTitle />
                <SubscribeAssistProBenefitsFeatures />
                <SubscribeAssistProBenefitsLPLink url="https://consultorios.livance.com.br/lp/livanceassistpro" />
              </SubscribeAssistProBenefitsRoot>

              <SubscribeAssistProCard
                data={data}
                features={SUBSCRIPTION_PAGE_FEATURES}
                onDeclineClick={onDeclineClick}
              />
            </>
          ) : null}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/assist/subscribe-assist-pro/")({
  component: SubscribeAssistProPage,
  beforeLoad: ({ context }) => {
    const user = context.user;
    validatePackageAccess({
      user,
      packageService: ServicePackageEnum.Independent,
    });
  },
});
