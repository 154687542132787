/* eslint-disable camelcase -- Necessário para montagem dos objetos gerados pelo codegen */
/* eslint-disable react/no-array-index-key -- Utilizado para identificar os campos */
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { type z } from "zod";
import { graphql } from "@repo/graphql-types/gql";
import { type FragmentType, useFragment } from "@repo/graphql-types";
import {
  GetOneLivUserMedicalInstitutionsDocument,
  type Locus_Membro_Instituicao_Medica_Bool_Exp,
  type Locus_Membro_Instituicao_Medica_Insert_Input,
  type Locus_Membro_Instituicao_Medica_Updates,
} from "@repo/graphql-types/graphql";
import { useNavigate } from "@tanstack/react-router";
import {
  FormRoot,
  FormHandlerSubmit,
  FormField,
  FormItem,
  FormControl,
  FormSubmitButton,
} from "@/components/form";
import { ActionButtonText } from "@/components/action-button-text";
import {
  type oneLivFormMedicalInstitution,
  oneLivFormMedicalInstitutionWithValidation,
} from "@/lib/form-schemas/oneliv-form-schema";
import { OneLivMedicalInstitutionsSelectInput } from "@/components/oneliv/oneliv-medical-institutions-select-input";
import {
  useGraphQLMutationWithErrorHandler,
  useInvalidateQuery,
} from "@/hooks/use-graphql";

export const OneLivMedicalInstitutionsFormFragment = graphql(/* GraphQL */ `
  fragment OneLivMedicalInstitutionsFormFragment on query_root {
    membro: locus_membro {
      id
    }
    membroInstituicoesMedicas: locus_membro_instituicao_medica {
      idInstituicaoMedica
    }

    instituicoesMedicas: locus_instituicao_medica {
      ...OneLivMedicalInstitutionsSelectInputFragment
    }
  }
`);
const InsertAndUpdateMembroInstituicoesMedicasMutation = graphql(/* GraphQL */ `
  mutation InsertAndUpdateMembroInstituicoesMedicasMutation(
    $updates: [locus_membro_instituicao_medica_updates!]!
    $inserts: [locus_membro_instituicao_medica_insert_input!]!
    $deletes: [locus_membro_instituicao_medica_bool_exp!]
  ) {
    update_locus_membro_instituicao_medica_many(updates: $updates) {
      affected_rows
    }

    insert_locus_membro_instituicao_medica(objects: $inserts) {
      affected_rows
    }

    delete_locus_membro_instituicao_medica(where: { _or: $deletes }) {
      affected_rows
    }
  }
`);

export type OneLivFormMedicalInstitutionsValues = z.infer<
  typeof oneLivFormMedicalInstitution
>;

interface OneLivMedicalInstitutionsFormProps {
  data?: FragmentType<typeof OneLivMedicalInstitutionsFormFragment>;
}

export const OneLivMedicalInstitutionsForm = ({
  data,
}: OneLivMedicalInstitutionsFormProps): JSX.Element => {
  const fragmentResult = useFragment(OneLivMedicalInstitutionsFormFragment, data);

  const initialMedicalInstitutions = fragmentResult?.membroInstituicoesMedicas;

  const { mutateAsync } = useGraphQLMutationWithErrorHandler(
    InsertAndUpdateMembroInstituicoesMedicasMutation,
  );

  const navigate = useNavigate();

  const invalidateGetOneLivUserMedicalInstitutions = useInvalidateQuery(
    GetOneLivUserMedicalInstitutionsDocument,
  );

  const getDefaultValues = (): OneLivFormMedicalInstitutionsValues => {
    const memberMedicalInstitutions = fragmentResult?.membroInstituicoesMedicas;

    const medicalInstitutions = memberMedicalInstitutions?.length
      ? memberMedicalInstitutions.map((item, index) => ({
          hiddenId: index + 1,
          idMedicalInstitution: item.idInstituicaoMedica,
        }))
      : Array.from({ length: 1 }, () => ({
          hiddenId: 0,
          idMedicalInstitution: 0,
        }));

    const hiddenMedicalInstitutions = medicalInstitutions.map((item) => ({
      ...item,
    }));

    return { medicalInstitutions, hiddenMedicalInstitutions };
  };

  const form = useForm<OneLivFormMedicalInstitutionsValues>({
    resolver: zodResolver(oneLivFormMedicalInstitutionWithValidation),
    defaultValues: getDefaultValues(),
  });

  function buildDeleteMedicalInstitutionsBackgroundCondition(
    finalMemberMedicalInstitutions: OneLivFormMedicalInstitutionsValues["medicalInstitutions"],
    membroId?: number,
  ): Locus_Membro_Instituicao_Medica_Bool_Exp[] {
    if (!finalMemberMedicalInstitutions || !membroId) {
      return [];
    }

    const formationsToDelete = initialMedicalInstitutions?.filter(
      (item) =>
        !finalMemberMedicalInstitutions.some(
          (x) => x.idMedicalInstitution === item.idInstituicaoMedica,
        ),
    );

    const locusMembroInstituicaoMedicaExp =
      formationsToDelete?.map((item) => {
        return {
          idInstituicaoMedica: { _eq: item.idInstituicaoMedica },
          idMembro: { _eq: membroId },
        };
      }) ?? [];

    return locusMembroInstituicaoMedicaExp;
  }

  const onSubmit = async (
    formData: OneLivFormMedicalInstitutionsValues,
  ): Promise<void> => {
    const onSuccess = (): void => {
      invalidateGetOneLivUserMedicalInstitutions();

      void navigate({
        to: "/oneliv/faq",
      });
    };

    const medicalInstitutions = formData.medicalInstitutions ?? [];
    const hiddenMedicalInstitutions = formData.hiddenMedicalInstitutions ?? [];

    const membro = fragmentResult?.membro[0];
    const membroId = membro?.id;

    const { toBeUpdated, toBeInserted } = medicalInstitutions.reduce<{
      toBeUpdated: Locus_Membro_Instituicao_Medica_Updates[];
      toBeInserted: Locus_Membro_Instituicao_Medica_Insert_Input[];
    }>(
      (acc, item) => {
        const hiddenItemWithSameId = hiddenMedicalInstitutions.find(
          (x) => x.hiddenId === item.hiddenId && item.hiddenId,
        );

        const medicalInstitution = {
          idMembro: membroId,
          idInstituicaoMedica: item.idMedicalInstitution,
        };

        if (hiddenItemWithSameId) {
          const isItemUpdated =
            hiddenItemWithSameId.idMedicalInstitution !== item.idMedicalInstitution;

          if (isItemUpdated) {
            const updatesObject: Locus_Membro_Instituicao_Medica_Updates = {
              _set: medicalInstitution,
              where: {
                idInstituicaoMedica: { _eq: hiddenItemWithSameId.idMedicalInstitution },
                idMembro: { _eq: membroId },
              },
            };

            acc.toBeUpdated.push(updatesObject);
          }
        } else if (!item.hiddenId && item.idMedicalInstitution) {
          acc.toBeInserted.push({ ...medicalInstitution, ativo: true });
        }

        return acc;
      },
      { toBeUpdated: [], toBeInserted: [] },
    );

    const toBeDeleted = buildDeleteMedicalInstitutionsBackgroundCondition(
      medicalInstitutions,
      membroId,
    );

    await mutateAsync(
      {
        inserts: toBeInserted,
        updates: toBeUpdated,
        deletes: toBeDeleted,
      },
      { onSuccess },
    );
  };

  const medicalInstitutionsValues = form.watch("medicalInstitutions") ?? [];

  const addNewMedicalInstitution = (): void => {
    form.setValue("medicalInstitutions", [
      ...medicalInstitutionsValues,
      { hiddenId: 0, idMedicalInstitution: 0 },
    ]);
  };

  const removeMedicalInstitution = (index: number): void => {
    const formValue = medicalInstitutionsValues.filter((_, i) => i !== index);
    form.setValue("medicalInstitutions", formValue);
  };

  return (
    <FormRoot {...form}>
      <FormHandlerSubmit handleSubmit={onSubmit}>
        <p className="font-medium text-neutral-600">
          Informe o nome das instituições de saúde e clínicas onde atua.
        </p>
        {medicalInstitutionsValues.map((item, index) => (
          <FormField
            key={`${item.idMedicalInstitution}-${index}`}
            control={form.control}
            name={`medicalInstitutions.${index}.idMedicalInstitution`}
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormControl>
                  <OneLivMedicalInstitutionsSelectInput
                    title=""
                    data={fragmentResult?.instituicoesMedicas}
                    header={`Instituição de saúde (${index + 1})`}
                    onClickDelete={() => removeMedicalInstitution(index)}
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        ))}

        <ActionButtonText onClick={addNewMedicalInstitution}>
          Adicionar nova instituição de saúde
        </ActionButtonText>

        <FormSubmitButton type="submit">Avançar</FormSubmitButton>
      </FormHandlerSubmit>
    </FormRoot>
  );
};
