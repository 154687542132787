import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { Left } from "@repo/icons";
import { ServicePackageEnum } from "@repo/lib";
import { graphql } from "@repo/graphql-types/gql";
import { HeaderRoot, HeaderTitle, HeaderButton } from "@/components/header";
import { Page } from "@/components/page";
import { validatePackageAccess } from "@/lib/assist-package-guard";
import { AsyncDataWrapper } from "@/components/async-data-wrapper.tsx";
import { useGraphQL } from "@/hooks/use-graphql.ts";
import { LEGACY_SUBSCRIPTION_PAGE_FEATURES } from "@/lib/constants/subscribe-assist-pro";
import {
  SubscribeAssistProBenefitsFeatures,
  SubscribeAssistProBenefitsLPLink,
  SubscribeAssistProBenefitsRoot,
  SubscribeAssistProBenefitsTitle,
} from "@/components/subscribe-assist-pro/subscribe-assist-pro-benefits";
import { SubscribeAssistProCard } from "@/components/subscribe-assist-pro/subscribe-assist-pro-card";
import { trackEvent } from "@/lib/tracking";

const RETURN_URL = "/settings";

const ServicePackagesQuery = graphql(/* GraphQL */ `
  query ServicePackagesQuery($codPacoteServico: Int!) {
    ...ServicePackagesFragment
  }
`);

export const SubscribeAssistProLegacyPage = (): JSX.Element => {
  const navigate = useNavigate();
  const professionalServicePackageEnumValue: number =
    ServicePackageEnum.Professional.valueOf();
  const queryResult = useGraphQL(ServicePackagesQuery, {
    codPacoteServico: professionalServicePackageEnumValue,
  });
  const { data } = queryResult;

  const onDeclineClick = (): void => {
    trackEvent("Botão Decidir Depois Assist Pro Clicado", {
      destino: RETURN_URL,
    });

    void navigate({
      to: RETURN_URL,
    });
  };

  const onBackClick = (): void => {
    void navigate({
      to: RETURN_URL,
    });
  };

  return (
    <>
      <HeaderRoot>
        <HeaderButton onClick={onBackClick} icon={Left} align="start" />
        <HeaderTitle title="Livance Assist Pro" align="center" />
      </HeaderRoot>
      <Page className="px-0 pb-0 flex flex-col">
        <AsyncDataWrapper {...queryResult}>
          {data ? (
            <>
              <SubscribeAssistProBenefitsRoot>
                <SubscribeAssistProBenefitsTitle />
                <SubscribeAssistProBenefitsFeatures />
                <SubscribeAssistProBenefitsLPLink url="https://livance.com.br/lp/livanceassistpro-legado" />
              </SubscribeAssistProBenefitsRoot>

              <SubscribeAssistProCard
                data={data}
                features={LEGACY_SUBSCRIPTION_PAGE_FEATURES}
                onDeclineClick={onDeclineClick}
              />
            </>
          ) : null}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/assist/subscribe-assist-pro-legacy/")({
  component: SubscribeAssistProLegacyPage,
  beforeLoad: ({ context }) => {
    const user = context.user;
    validatePackageAccess({
      user,
      packageService: ServicePackageEnum.Legacy,
    });
  },
});
