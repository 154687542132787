import { Left } from "@repo/icons";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { Button } from "@/components/button";

export const OneLivUseTermsPage = (): JSX.Element => {
  const navigate = useNavigate();

  const handleUseTermsAcceptance = (): void => {
    void navigate({
      to: "/oneliv/basic-info",
    });
  };

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Perfil OneLiv" align="center" />
      </HeaderRoot>

      <Page>
        <div className="flex flex-col gap-10">
          <div className="flex flex-col gap-2">
            <p className="font-medium text-xl text-neutral-600">
              Como funciona e termos de adesão
            </p>
            <p className="font-normal text-sm text-neutral-500">
              Confira abaixo como funciona e leia atentamente nossos termos para adesão ao
              OneLiv:
            </p>
          </div>
          <ol className="flex flex-col gap-3 list-decimal font-normal text-sm text-neutral-500 px-4">
            <li>
              <span className="text-neutral-600 font-medium">Definição de preços:</span>{" "}
              será necessário definir um preço padrão para suas consultas no OneLiv. Isso
              não impede que você ofereça descontos e crie pacotes com valores
              diferenciados posteriormente;
            </li>
            <li>
              <span className="text-neutral-600 font-medium">Agendamento:</span> as
              consultas serão agendadas exclusivamente com pagamento particular.
              Informações sobre atendimentos por convênio não serão exibidas no seu
              perfil;
            </li>
            <li>
              <span className="text-neutral-600 font-medium">Formas de pagamento:</span>{" "}
              será necessário aceitar pagamentos por cartões de crédito, débito e/ou via
              Pix mesmo que o pagamento não ocorra no totem da Livance;
            </li>
            <li>
              <span className="text-neutral-600 font-medium">Divulgação do Perfil:</span>{" "}
              os sites dos membros da Livance recebem mais de 200 mil visitantes mensais.
              Ao aceitar os termos, você concorda em promover seu perfil do OneLiv no seu
              site da Livance, potencializando sua visibilidade para atrair mais
              pacientes;
            </li>
            <li>
              <span className="text-neutral-600 font-medium">Promoção e Divulgação:</span>{" "}
              seu perfil poderá ser exposto em vários canais para divulgar o OneLiv,
              incluindo comunicação para aqueles que já foram seus pacientes. Para
              enriquecer ainda mais a experiência dos potenciais pacientes, também podemos
              recomendar seu perfil a outros profissionais e direcionar pacientes que
              buscam uma gama mais ampla de especialidades. Essa recomendação pode
              aumentar ainda mais sua visibilidade e conectar você a uma rede maior de
              potenciais novos pacientes.
            </li>
            <li>
              <span className="text-neutral-600 font-medium">
                Avaliação de Pacientes:
              </span>{" "}
              avaliações e depoimentos são, comprovadamente, as melhores formas de
              inspirar credibilidade em um novo paciente que ainda não conhece seu
              trabalho. Após o término da consulta, nós enviaremos uma mensagem ao seu
              paciente solicitando uma avaliação do atendimento.
            </li>
          </ol>
          <Button onClick={handleUseTermsAcceptance} fullWidth>
            Li e aceito os termos
          </Button>
        </div>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/oneliv/use-terms")({
  component: OneLivUseTermsPage,
});
