export enum AssistFeatures {
  RescueLeftScheduling = 1,
  FollowUpAppointmentReminder = 2,
  CustomerAppointmentOverview = 3,
  CustomerContactOverview = 4,
  PersonalizeAppointment = 5,
  RescueCancelAppointment = 6,
  RescueNoShowAppointment = 7,
  SiteOverview = 8,
  CreateTrackableLinks = 12,
}
