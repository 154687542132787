import { Share } from "@repo/icons";
import { graphql } from "@repo/graphql-types";
import { endOfDay } from "date-fns";
import { format } from "date-fns-tz";
import { useState } from "react";
import { type DataItemRelatorioIndependente } from "@repo/graphql-types/graphql";
import { formatDateAndTimeIgnoringTimezone } from "@repo/lib";
import { HeaderButton } from "@/components/header";
import {
  getShareReportFromDate,
  shareReport,
  fillMissingPeriodData,
} from "@/lib/dashboard";
import { FIVE_MINUTES_STALE_TIME } from "@/lib/constants/dashboards-customer-overview";
import { useGraphQL } from "@/hooks/use-graphql";
import { createFileContentByCsvRows, createFileName } from "@/lib/csv-utils";
import { useFormattedMonthsArray } from "@/hooks/use-formatted-month-array-for-overview";

const AppointmentDashboardShareReportQuery = graphql(/* GraphQL */ `
  query AppointmentDashboardShareReportQuery($from: String!, $to: String!) {
    analyticsMembroRelatorioIndependente(arg1: { dataInicio: $from, dataFim: $to }) {
      data {
        agendamentosMarcados
        consultasRealizadas
        consultasVirtuais
        mes
        reagendamentos
        agendamentosNovos
        consultasPresenciais
        consultasNoShow
        cancelamentosDefinitivos
        agendamentosPacienteAtrasado
        agendamentosConveniosParaOMes
        agendamentosMarcadosParaOMes
        agendamentosParticularesParaOMes
        cancelamentos
      }
    }
  }
`);

interface AppointmentDashboardShareReportProps {
  earliestDate: Date;
}

export const AppointmentDashboardShareReport = ({
  earliestDate,
}: AppointmentDashboardShareReportProps): JSX.Element => {
  const [shouldFetchShareReportData, setShouldFetchShareReportData] = useState(false);

  const endOfToday = endOfDay(new Date());

  const fromDate = getShareReportFromDate(earliestDate);

  const toDate = format(endOfToday, "yyyy-MM-dd", {
    timeZone: "America/Sao_Paulo",
  });

  const monthsList = useFormattedMonthsArray({
    fromDate: new Date(fromDate),
    toDate: endOfToday,
  });

  const { data, isPending, refetch } = useGraphQL(
    AppointmentDashboardShareReportQuery,
    {
      from: formatDateAndTimeIgnoringTimezone(fromDate),
      to: toDate,
    },
    {
      staleTime: FIVE_MINUTES_STALE_TIME,
      enabled: shouldFetchShareReportData,
    },
  );

  let periodData = data?.analyticsMembroRelatorioIndependente?.data ?? [];
  periodData = fillMissingPeriodData(
    monthsList,
    periodData as DataItemRelatorioIndependente[],
  );

  const generateReportData = (): { fileName: string; fileContent: string } => {
    const csvRows: string[][] = [];

    const headerRow = [
      "Mês/Ano",
      "Total de agendamentos",
      "Total de agendamentos - Particulares",
      "Total de agendamentos - Convênios",
      "Total de consultas realizadas",
      "Consultas realizadas - Consultas na Livance",
      "Consultas realizadas - Consultas virtuais",
      "Agendamentos Cancelados",
      "Faltas nas consultas",
      "Pacientes atrasados",
    ];

    csvRows.push(headerRow);

    periodData.forEach((item) => {
      const row = [
        `${item?.mes}`,
        `${item?.agendamentosMarcadosParaOMes}`,
        `${item?.agendamentosParticularesParaOMes}`,
        `${item?.agendamentosConveniosParaOMes}`,
        `${item?.consultasRealizadas}`,
        `${item?.consultasPresenciais}`,
        `${item?.consultasVirtuais}`,
        `${item?.cancelamentos}`,
        `${item?.consultasNoShow}`,
        `${item?.agendamentosPacienteAtrasado}`,
      ];

      csvRows.push(row);
    });

    return {
      fileContent: createFileContentByCsvRows(csvRows),
      fileName: createFileName("relatorio_de_consultas"),
    };
  };

  const handleShareReport = async (): Promise<void> => {
    setShouldFetchShareReportData(true);

    if (isPending) {
      const refetchResult = await refetch();

      periodData = refetchResult.data?.analyticsMembroRelatorioIndependente?.data ?? [];
      periodData = fillMissingPeriodData(
        monthsList,
        periodData as DataItemRelatorioIndependente[],
      );
    }

    if (!periodData.length) return;

    const { fileName, fileContent } = generateReportData();

    await shareReport({
      fileName,
      fileContent,
      trackingLabel: "consultas",
    });
  };

  return (
    <div className="absolute right-4">
      <HeaderButton
        icon={Share}
        onClick={() => void handleShareReport()}
        title="Compartilhar relatório de consultas"
      />
    </div>
  );
};
