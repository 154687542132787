/* eslint-disable import/no-duplicates -- There's no way to avoid importing the locale */
import { useMemo } from "react";
import { isBefore, addMonths, format as formatDate, startOfDay } from "date-fns";
import { utcToZonedTime, format as formatTz } from "date-fns-tz";
import { ptBR } from "date-fns/locale";

interface UseFormattedMonthsArrayParams {
  fromDate: Date;
  toDate: Date;
}

export function useFormattedMonthsArray({
  fromDate,
  toDate,
}: UseFormattedMonthsArrayParams): string[] {
  const monthsList = useMemo(() => {
    function capitalizeFirstLetter(str: string): string {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    function formatFromDateToMmmSlashYy(date: Date): string {
      const month = formatDate(date, "MMM", { locale: ptBR });
      const year = formatDate(date, "yy", { locale: ptBR });
      return `${capitalizeFirstLetter(month)}/${year}`;
    }

    const result: string[] = [];

    let currentDate = utcToZonedTime(startOfDay(fromDate), timeZone);
    const endDate = utcToZonedTime(toDate, timeZone);
    
    while (
      isBefore(currentDate, endDate) ||
      formatTz(currentDate, "yyyy-MM-dd", { timeZone }) ===
        formatTz(endDate, "yyyy-MM-dd", { timeZone })
    ) {
      result.push(formatFromDateToMmmSlashYy(currentDate));
      currentDate = addMonths(currentDate, 1);
    }

    return result;
  }, [fromDate, toDate]);
  return monthsList;
}
