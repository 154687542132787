import { CapacitorUpdater } from "@capgo/capacitor-updater";
import { SplashScreen } from "@capacitor/splash-screen";
import { resetAppInfoCache } from "@repo/lib";

export const initAutoUpdate = (): void => {
  addListeners();

  void CapacitorUpdater.notifyAppReady();
};

const addListeners = (): void => {
  void CapacitorUpdater.addListener("appReady", hideSplashScreen);

  void CapacitorUpdater.addListener("updateFailed", () => hideSplashScreen());

  void CapacitorUpdater.addListener("downloadFailed", () => hideSplashScreen());
};

const hideSplashScreen = (): void => {
  void SplashScreen.hide();
  resetAppInfoCache();
};
