import { createFileRoute } from "@tanstack/react-router";
import { Left } from "@repo/icons";
import { z } from "zod";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { CreatePaymentConfirm } from "@/components/create-payment-confirm";
import { RecipientInvalidAlertWrapper } from "@/components/recipient-invalid-alert-wrapper";

export const CreatePaymentConfirmationPage = (): JSX.Element => {
  return (
    <>
      <HeaderRoot>
        <HeaderButton align="start" icon={Left} />
        <HeaderTitle title="Criar pagamento" align="center" />
      </HeaderRoot>
      <Page>
        <RecipientInvalidAlertWrapper>
          <CreatePaymentConfirm />
        </RecipientInvalidAlertWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/payments/create/confirmation")({
  component: CreatePaymentConfirmationPage,
  validateSearch: z.object({
    appointmentId: z.number().optional(),
    appointmentTypeId: z.number().optional(),
    action: z.string().optional(),
    guid: z.string().optional(),
    origin: z.string(),
    paymentOnlineType: z.string(),
  }),
});
