import { Capacitor } from "@capacitor/core";
import { getAppInfo } from "@repo/lib";
import { Device } from "@capacitor/device";
import { Network } from "@capacitor/network";
import { type PacoteServicoOutput } from "@repo/graphql-types/graphql";

interface DeviceData {
  app: {
    build: string;
    name: string;
    namespace: string;
    version: string;
  };
  device: {
    manufacturer: string;
    model: string;
    name?: string;
  };
  network: {
    wifi: boolean;
  };
  os: {
    name: string;
    version: string;
  };
}

async function getDeviceData(): Promise<DeviceData> {
  const appInfo = await getAppInfo();
  const deviceInfo = await Device.getInfo();
  const networkStatus = await Network.getStatus();

  return {
    app: {
      build: appInfo.build,
      name: appInfo.name,
      namespace: appInfo.id,
      version: appInfo.version,
    },
    device: {
      manufacturer: deviceInfo.manufacturer,
      model: deviceInfo.model,
      name: deviceInfo.name,
    },
    network: {
      wifi: networkStatus.connectionType === "wifi",
    },
    os: {
      name: deviceInfo.operatingSystem,
      version: deviceInfo.osVersion,
    },
  };
}

interface PageViewProps {
  from: string;
}

export const pageView = async ({ from }: PageViewProps): Promise<void> => {
  const deviceData = await getDeviceData();

  window.rudderanalytics?.page(
    undefined,
    undefined,
    {
      platform: Capacitor.getPlatform(),
      from,
    },
    {
      ...deviceData,
    },
  );
};

export const trackIdentify = (
  codUsuario: number,
  nome?: string,
  sobrenome?: string,
  email?: string,
  apelido?: string | null,
  codClinica?: number,
  pacotesServicos?: PacoteServicoOutput[],
): void => {
  void getDeviceData().then((deviceData) => {
    window.rudderanalytics?.identify(String(codUsuario), {
      name: apelido ?? `${nome} ${sobrenome}`,
      email,
      codUsuario,
      codClinica,
      pacotesServicos,
      ...deviceData,
    });
  });
};

export const trackEvent = (
  eventName: string,
  properties?: Record<string, unknown>,
): void => {
  void getDeviceData().then((deviceData) => {
    window.rudderanalytics?.track(eventName, { ...deviceData, ...properties });
  });
};
