import { format } from "date-fns-tz";
import { zonedDate } from "@repo/lib";

/**
 * Cria o conteúdo de um arquivo csv a partir de linhas de dados.
 *
 * @param csvRows - As linhas de dados a serem utilizadas no arquivo csv.
 * @returns O conteúdo do arquivo csv.
 */
export const createFileContentByCsvRows = (csvRows: string[][]): string => {
  return csvRows.map((row) => row.map((value) => `"${value}"`).join(",")).join("\n");
};

/**
 * Cria o nome de um arquivo csv com um prefixo e a data atual.
 *
 * @param fileNamePrefix - O prefixo do nome do arquivo.
 * @returns O nome do arquivo csv.
 */
export const createFileName = (fileNamePrefix: string): string => {
  const currentDate = zonedDate(new Date());
  const formattedDate = format(currentDate, "dd-MM-yyyy");
  return `${fileNamePrefix}_${formattedDate}.csv`;
};
