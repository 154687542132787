import { CpsButton, type CpsButtonProps } from "corpus";
import { forwardRef } from "react";

export type ButtonProps = CpsButtonProps;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  return <CpsButton variant="default" color="secondary" ref={ref} {...props} />;
});

Button.displayName = "Button";
