import { type FragmentType, useFragment } from "@repo/graphql-types";
import { graphql } from "@repo/graphql-types/gql";
import { useRouter } from "@tanstack/react-router";
import {
  SubscribeAssistProSubscriptionCardRoot,
  SubscribeAssistProSubscriptionCardHeader,
  SubscribeAssistProSubscriptionCardPrices,
  SubscribeAssistProSubscriptionCardDivider,
  SubscribeAssistProSubscriptionCardFeatures,
  SubscribeAssistProSubscriptionCardButtons,
} from "@/components/subscribe-assist-pro/subscribe-assist-pro-subscription-card";
import { trackEvent } from "@/lib/tracking";

export const ServicePackagesFragment = graphql(/* GraphQL */ `
  fragment ServicePackagesFragment on query_root {
    servicePackage: pacotesServicos_by_pk(codPacoteServicos: $codPacoteServico) {
      pacotesServicosPreco {
        valorUnitarioFixo
      }
    }
  }
`);

interface SubscribeAssistProCardProps {
  data: FragmentType<typeof ServicePackagesFragment>;
  features: string[];
  onDeclineClick: () => void;
}

export const SubscribeAssistProCard = ({
  data,
  features,
  onDeclineClick,
}: SubscribeAssistProCardProps): JSX.Element => {
  const router = useRouter();
  const fragmentData = useFragment(ServicePackagesFragment, data);

  const servicePackagePrice = fragmentData.servicePackage?.pacotesServicosPreco;

  const onAcceptedAssist = (): void => {
    trackEvent("Botão Aceitar Assist Pro Clicado");
    void router.navigate({ to: "/settings/assist/subscribe-assist-pro/confirm" });
  };

  return (
    <SubscribeAssistProSubscriptionCardRoot hasBackground>
      <SubscribeAssistProSubscriptionCardHeader />
      <SubscribeAssistProSubscriptionCardPrices
        fixedPrice={servicePackagePrice?.valorUnitarioFixo}
      />

      <SubscribeAssistProSubscriptionCardDivider />

      <SubscribeAssistProSubscriptionCardFeatures features={features} />
      <SubscribeAssistProSubscriptionCardButtons
        onAcceptClick={onAcceptedAssist}
        onDeclineClick={onDeclineClick}
      />
    </SubscribeAssistProSubscriptionCardRoot>
  );
};
