/* eslint-disable camelcase -- Classe gerada pelo Codegen*/
import { CpsInput } from "corpus";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { type z } from "zod";
import { graphql } from "@repo/graphql-types/gql";
import { type FragmentType, useFragment } from "@repo/graphql-types";
import {
  GetOneLivUserTreatmentFocusDocument,
  type Locus_Foco_Tratamento_Membro_Insert_Input,
} from "@repo/graphql-types/graphql";
import { useNavigate } from "@tanstack/react-router";
import { MinusCircle } from "@repo/icons";
import { oneLivFormTreatmentFocus } from "@/lib/form-schemas/oneliv-form-schema";
import {
  FormRoot,
  FormHandlerSubmit,
  FormField,
  FormItem,
  FormControl,
  FormSubmitButton,
} from "@/components/form";
import {
  useGraphQLMutationWithErrorHandler,
  useInvalidateQuery,
} from "@/hooks/use-graphql";
import { ActionButtonText } from "@/components/action-button-text";

export const OneLivTreatmentFocusFormFragment = graphql(/* GraphQL */ `
  fragment OneLivTreatmentFocusFormFragment on locus_membro {
    id
    focosTratamentos {
      id
      focoTratamento
    }
  }
`);

const UpdateMemberTreatmentFocusMutation = graphql(/* GraphQL */ `
  mutation UpdateMemberTreatmentFocusMMutation(
    $objects: [locus_foco_tratamento_membro_insert_input!]!
  ) {
    insert_locus_foco_tratamento_membro(
      on_conflict: {
        constraint: foco_tratamento_membro_pkey
        update_columns: [focoTratamento, deletado]
      }
      objects: $objects
    ) {
      affected_rows
    }
  }
`);

export type OneLivFormTreatmentFocusValues = z.infer<typeof oneLivFormTreatmentFocus>;

interface OneLivTreatmentFocusFormProps {
  data?: FragmentType<typeof OneLivTreatmentFocusFormFragment>[];
}

export const OneLivTreatmentFocusForm = ({
  data,
}: OneLivTreatmentFocusFormProps): JSX.Element => {
  const fragmentResult = useFragment(OneLivTreatmentFocusFormFragment, data);

  const { mutateAsync } = useGraphQLMutationWithErrorHandler(
    UpdateMemberTreatmentFocusMutation,
  );

  const navigate = useNavigate();

  const invalidateGetOneLivUserTreatmentFocus = useInvalidateQuery(
    GetOneLivUserTreatmentFocusDocument,
  );

  const originalMemberTreatmentFocus = fragmentResult?.[0]?.focosTratamentos;

  const getDefaultValues = (): OneLivFormTreatmentFocusValues => {
    const treatmentFocus = originalMemberTreatmentFocus?.length
      ? originalMemberTreatmentFocus.map((item) => ({
          id: item.id,
          value: item.focoTratamento,
        }))
      : Array.from({ length: 1 }, () => ({ id: 0, value: "" }));

    return { treatmentFocus };
  };

  const form = useForm<OneLivFormTreatmentFocusValues>({
    resolver: zodResolver(oneLivFormTreatmentFocus),
    defaultValues: getDefaultValues(),
  });

  const onSubmit = async (formData: OneLivFormTreatmentFocusValues): Promise<void> => {
    const onSuccess = (): void => {
      invalidateGetOneLivUserTreatmentFocus();

      void navigate({
        to: "/oneliv/educational-background",
      });
    };

    const memberId = fragmentResult?.[0]?.id;
    const insertUpdatedAndEmptyTreatmentFocus = getInsertUpdatedAndEmptyTreatmentFocus(
      formData,
      memberId,
    );
    const deletedTreatmentFocus = getDeletedTreatmentFocus(
      insertUpdatedAndEmptyTreatmentFocus,
      memberId,
    );

    const objects = insertUpdatedAndEmptyTreatmentFocus.concat(deletedTreatmentFocus);

    await mutateAsync(
      {
        objects,
      },
      { onSuccess },
    );
  };

  function getInsertUpdatedAndEmptyTreatmentFocus(
    formData: OneLivFormTreatmentFocusValues,
    memberId?: number,
  ): Locus_Foco_Tratamento_Membro_Insert_Input[] {
    return formData.treatmentFocus
      .filter((item) => !(item.id === 0 && item.value === ""))
      .map((item) => {
        const isDeletado = Boolean(!item.value && item.id);

        const originalTreatmentFocus = originalMemberTreatmentFocus?.find(
          (treatmentFocus) => treatmentFocus.id === item.id,
        );

        const insertInput: Locus_Foco_Tratamento_Membro_Insert_Input = {
          focoTratamento: isDeletado
            ? originalTreatmentFocus?.focoTratamento
            : item.value,
          deletado: isDeletado,
          idMembro: memberId,
          id: item.id || undefined,
        };

        return insertInput;
      });
  }

  function getDeletedTreatmentFocus(
    updatedAndEmptyTreatmentFocus: Locus_Foco_Tratamento_Membro_Insert_Input[],
    memberId?: number,
  ): Locus_Foco_Tratamento_Membro_Insert_Input[] {
    if (originalMemberTreatmentFocus) {
      const deletedTreatmentFocus = originalMemberTreatmentFocus.filter(
        (deleteItems) =>
          !updatedAndEmptyTreatmentFocus.some(
            (insertItems) => insertItems.id === deleteItems.id,
          ),
      );

      return deletedTreatmentFocus.map((item) => {
        const deleteInput: Locus_Foco_Tratamento_Membro_Insert_Input = {
          focoTratamento: item.focoTratamento,
          deletado: true,
          idMembro: memberId,
          id: item.id || undefined,
        };
        return deleteInput;
      });
    }
    return [];
  }

  const treatmentFocusValues = form.watch("treatmentFocus");

  const addNewTreatmentFocus = (): void => {
    form.setValue("treatmentFocus", [...treatmentFocusValues, { id: 0, value: "" }]);
  };

  const removeTreatmentFocus = (index: number): void => {
    const formValue = treatmentFocusValues.filter((_, i) => i !== index);
    form.setValue("treatmentFocus", formValue);
  };

  function renderDeleteButton(index: number): React.ReactNode {
    return (
      <ActionButtonText
        Icon={MinusCircle}
        color="danger"
        onClick={() => removeTreatmentFocus(index)}
      >
        Remover
      </ActionButtonText>
    );
  }

  return (
    <FormRoot {...form}>
      <FormHandlerSubmit handleSubmit={onSubmit}>
        <p className="font-medium text-neutral-600">
          Liste as principais condições clínicas / áreas de atuação como seus focos de
          tratamento, uma por campo.
        </p>
        {treatmentFocusValues.map((item, index) => (
          <FormField
            // eslint-disable-next-line react/no-array-index-key -- Caso o usuário não tenho registros o id será 0
            key={`${item.id}-${index}`}
            control={form.control}
            name={`treatmentFocus.${index}.value`}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <CpsInput
                    title={`Foco de tratamento (${index + 1})`}
                    placeholder="Digite aqui"
                    inputMode="text"
                    type="text"
                    actionNode={renderDeleteButton(index)}
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        ))}

        <ActionButtonText onClick={addNewTreatmentFocus}>
          Adicionar novo foco de tratamento
        </ActionButtonText>

        <FormSubmitButton type="submit">Avançar</FormSubmitButton>
      </FormHandlerSubmit>
    </FormRoot>
  );
};
