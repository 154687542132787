import { type ReactNode } from "react";
import { cn, format, formatCurrency } from "@repo/lib";
import { Check } from "@repo/icons";
import { CpsBadge } from "corpus";
import { Button } from "@/components/button";
import {
  IconListItem,
  IconListItemIcon,
  IconListItemText,
  IconListRoot,
} from "@/components/icon-list";
import {
  DIGITAL_CONTACT_PRICE,
  HUMAN_CONTACT_PRICE,
  TRIAL_LIMIT_DATE,
  START_CHARGE_DATE,
} from "@/lib/constants/subscribe-assist-pro";
import { AssistProBadge } from "@/routes/settings/assist/-components/assist-pro-badge";

interface SubscribeAssistProSubscriptionCardProps {
  children: ReactNode | ReactNode[];
  hasBackground?: boolean;
}

export const SubscribeAssistProSubscriptionCardRoot = ({
  children,
  hasBackground,
}: SubscribeAssistProSubscriptionCardProps): JSX.Element => {
  return (
    <div
      className={cn(
        hasBackground ? "bg-neutral-100 py-8 px-4" : "bg-white",
        "mt-10 w-full rounded-t-50 flex flex-col gap-6 flex-1",
      )}
      data-testid="subscribe-assist-pro-subscription-card-root"
    >
      <div className="border border-secondary-200 pt-2 pb-4 px-4 rounded-50 bg-white">
        {children}
      </div>
    </div>
  );
};

export const SubscribeAssistProSubscriptionCardHeader = (): JSX.Element => {
  return (
    <div className="flex justify-between my-4 items-center gap-8">
      <div className="flex flex-col leading-6">
        <p className="font-semibold text-2xl">Livance</p>
        <div className="flex gap-1 items-center">
          <p className="font-semibold text-2xl">Assist</p>
          <AssistProBadge />
        </div>
      </div>

      <CpsBadge color="success" variant="square" shade="dark">
        GRÁTIS ATÉ {format(TRIAL_LIMIT_DATE, "dd 'DE' MMM'").toUpperCase()}
      </CpsBadge>
    </div>
  );
};

interface SubscribeAssistProSubscriptionCardPricesProps {
  fixedPrice?: number;
}

export const SubscribeAssistProSubscriptionCardPrices = ({
  fixedPrice,
}: SubscribeAssistProSubscriptionCardPricesProps): JSX.Element => {
  return (
    <>
      <p className="font-bold text-xl text-neutral-500 mb-2">
        R$0 até {format(TRIAL_LIMIT_DATE, "dd 'de' MMM'")}
      </p>
      <div className="flex flex-col gap-1">
        <p className="font-medium text-sm text-neutral-500">
          {formatCurrency(fixedPrice ?? 0)} /mês (a partir de{" "}
          {format(START_CHARGE_DATE, "dd 'de' MMMM'")})
        </p>

        <div className="flex flex-col gap-1 text-xs text-neutral-500 ml-2">
          <p data-testid="digital-contact-price">
            + {formatCurrency(DIGITAL_CONTACT_PRICE)} /atendimento digital
          </p>
          <p data-testid="human-contact-price">
            <span className="font-bold">OU</span> + {formatCurrency(HUMAN_CONTACT_PRICE)}{" "}
            /atendimento humano
          </p>
        </div>
      </div>
    </>
  );
};

export const SubscribeAssistProSubscriptionCardDivider = (): JSX.Element => {
  return <hr className="h-px my-4 -mx-4 bg-secondary-200 border-0" />;
};

interface SubscribeAssistProSubscriptionCardFeaturesProps {
  features: string[];
}

export const SubscribeAssistProSubscriptionCardFeatures = ({
  features,
}: SubscribeAssistProSubscriptionCardFeaturesProps): JSX.Element => {
  return (
    <IconListRoot>
      {features.map((feature) => (
        <IconListItem key={feature}>
          <IconListItemIcon>
            <Check />
          </IconListItemIcon>
          <IconListItemText>{feature}</IconListItemText>
        </IconListItem>
      ))}
    </IconListRoot>
  );
};

interface SubscribeAssistProSubscriptionCardButtonsProps {
  onAcceptClick: () => void;
  onDeclineClick: () => void;
}

export const SubscribeAssistProSubscriptionCardButtons = ({
  onAcceptClick,
  onDeclineClick,
}: SubscribeAssistProSubscriptionCardButtonsProps): JSX.Element => {
  return (
    <div className="flex flex-col gap-2 mt-4">
      <Button className="font-medium" fullWidth onClick={onAcceptClick}>
        Experimente grátis até {format(TRIAL_LIMIT_DATE, "MMMM")}
      </Button>
      <Button color="secondaryLight" fullWidth onClick={onDeclineClick}>
        Quero decidir depois
      </Button>
    </div>
  );
};

interface SubscribeAssistProSubscriptionCardStepperInfoProps {
  fixedPrice?: number;
}

export const SubscribeAssistProSubscriptionCardStepperInfo = ({
  fixedPrice,
}: SubscribeAssistProSubscriptionCardStepperInfoProps): JSX.Element => {
  return (
    <div className="flex flex-col gap-1 text-neutral-500 text-xs">
      <div>
        <p>
          <span className="font-bold">
            Hoje até {format(TRIAL_LIMIT_DATE, "dd 'de' MMMM")}:
          </span>{" "}
          Período de teste gratuito
        </p>
      </div>
      <div>
        <p className="font-bold">
          A partir de {format(START_CHARGE_DATE, "dd 'de' MMMM")}:
        </p>
        <div className="flex flex-col gap-1 text-xs text-neutral-500 ml-2">
          <p> {formatCurrency(fixedPrice ?? 0)} /mês</p>
          <p data-testid="digital-contact-price">
            + {formatCurrency(DIGITAL_CONTACT_PRICE)} /atendimento digital
          </p>
          <p data-testid="human-contact-price">
            <span className="font-bold">OU </span>+ {formatCurrency(HUMAN_CONTACT_PRICE)}{" "}
            /atendimento humano
          </p>
        </div>
      </div>
    </div>
  );
};
