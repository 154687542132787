import { useRouteContext, useRouter } from "@tanstack/react-router";
import { Button } from "@/components/button";

export const PatientPaymentAbout = (): JSX.Element => {
  const router = useRouter();
  const { flags } = useRouteContext({
    strict: false,
  });

  const isV2Enabled = flags["exibe-nova-lista-de-pagamentos-com-recebimentos"];

  const handleClickBackButton = (): void => {
    router.history.back();
  };

  return (
    <div className="items-center justify-center flex flex-col gap-6 mx-auto max-w-[360px]">
      {isV2Enabled ? (
        <>
          <h1 className="text-left mt-4 text-xl text-neutral-600 font-medium w-full">
            Pagamentos
          </h1>

          <section className="flex-col flex gap-2 text-neutral-500">
            <p>
              Cada item da listagem representa um pagamento criado por você e enviado a um
              paciente.
            </p>
            <h2 className="text-md font-semibold text-neutral-600">
              Status dos pagamentos:
            </h2>
            <ul className="list-inside list-disc flex flex-col gap-y-3 ml-4">
              <li>
                <span className="font-semibold text-neutral-600">Em aberto:</span> O
                pagamento foi criado, mas ainda não foi realizado.
              </li>

              <li>
                <span className="font-semibold text-neutral-600">Pago:</span> O pagamento
                foi concluído com sucesso e está aprovado.
              </li>

              <li>
                <span className="font-semibold text-neutral-600">Cancelado:</span> O
                pagamento foi criado, mas não foi efetuado e teve seu status alterado.
              </li>

              <li>
                <span className="font-semibold text-neutral-600">Estornado:</span> O valor
                foi devolvido ao paciente após um pedido de estorno. O prazo para estorno
                depende do tipo de pagamento e do emissor do cartão.
              </li>
            </ul>
          </section>

          <h1 className="text-left mt-4 text-xl text-neutral-600 font-medium w-full">
            Recebimentos
          </h1>

          <section className="flex-col flex gap-2 text-neutral-500">
            <p>
              Os recebimentos representam os valores líquidos que você tem a receber, já
              descontadas as taxas aplicáveis dos pagamentos realizados pelos pacientes.
              Cada recebimento está vinculado a um pagamento correspondente.
            </p>

            <h2 className="text-md font-semibold text-neutral-600">
              Status dos recebimentos:
            </h2>

            <ul className="list-inside list-disc flex flex-col gap-y-3 ml-4">
              <li>
                <span className="font-semibold text-neutral-600">Previsto:</span> O
                pagamento foi realizado e está agendado para recebimento. A transferência
                ocorrerá no próximo dia útil após a data de liberação.
              </li>

              <li>
                <span className="font-semibold text-neutral-600">Liberado:</span> O valor
                já foi liberado e será transferido automaticamente para sua conta
                bancária. Caso não conste na conta, verifique o prazo de liberação e os
                dados cadastrados.
              </li>

              <li>
                <span className="font-semibold text-neutral-600">Estornado:</span> O
                pagamento foi estornado, e o valor correspondente foi lançado como débito.
              </li>
            </ul>
          </section>
        </>
      ) : (
        <>
          <h1 className="text-left mt-4 text-xl text-neutral-600 font-medium w-full">
            Pagamentos
          </h1>

          <p className="text-neutral-500">
            Nesta seção, você pode consultar todas as informações relacionadas aos
            pagamentos realizados pelos pacientes. É possível visualizar o valor pago, a
            forma de pagamento utilizada, detalhes sobre o agendamento, o nome do paciente
            e o status atual de cada pagamento.
          </p>

          <section className="flex-col flex gap-4 text-neutral-500">
            <h2 className="text-lg font-semibold text-neutral-600">Entenda os status:</h2>

            <p>
              <span className="font-semibold text-neutral-600">Em aberto:</span> O
              pagamento ainda não foi realizado.
            </p>

            <p>
              <span className="font-semibold text-neutral-600">Pago:</span> O pagamento
              foi realizado com sucesso e está aprovado.
            </p>

            <p>
              <span className="font-semibold text-neutral-600">Estornado:</span> O valor
              do pagamento foi devolvido ao paciente após o pedido do estorno. O prazo
              para estorno depende do tipo de pagamento que foi realizado e pode variar de
              acordo com o emissor do cartão.
            </p>
          </section>
        </>
      )}
      <Button onClick={handleClickBackButton} fullWidth>
        Voltar
      </Button>
    </div>
  );
};
