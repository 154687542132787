import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgStarCheckMark = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M10.586 2.668a1.75 1.75 0 0 1 2.829 0l1.472 2.022a.25.25 0 0 0 .24.1l2.471-.389a1.75 1.75 0 0 1 2 2l-.387 2.471a.25.25 0 0 0 .1.241l2.02 1.473c.96.699.96 2.13 0 2.829l-2.02 1.472a.25.25 0 0 0-.1.241l.388 2.47a1.75 1.75 0 0 1-2 2.001l-2.471-.388a.25.25 0 0 0-.241.1l-1.472 2.021a1.75 1.75 0 0 1-2.83 0l-1.472-2.021a.25.25 0 0 0-.24-.1l-2.471.388a1.75 1.75 0 0 1-2-2l.387-2.471a.25.25 0 0 0-.1-.24l-2.02-1.473a1.75 1.75 0 0 1 0-2.83l2.02-1.472a.25.25 0 0 0 .1-.24l-.388-2.471a1.75 1.75 0 0 1 2-2l2.471.388a.25.25 0 0 0 .241-.1zm1.616.884a.25.25 0 0 0-.404 0l-1.473 2.021a1.75 1.75 0 0 1-1.686.698l-2.47-.388a.25.25 0 0 0-.286.286l.388 2.47a1.75 1.75 0 0 1-.698 1.687L3.55 11.798a.25.25 0 0 0 0 .404l2.022 1.473c.53.386.8 1.037.698 1.686l-.388 2.47a.25.25 0 0 0 .286.286l2.47-.388a1.75 1.75 0 0 1 1.687.698l1.472 2.022a.25.25 0 0 0 .404 0l1.473-2.022a1.75 1.75 0 0 1 1.686-.698l2.47.388a.25.25 0 0 0 .286-.286l-.388-2.47a1.75 1.75 0 0 1 .698-1.686l2.022-1.473a.25.25 0 0 0 0-.404l-2.022-1.472a1.75 1.75 0 0 1-.698-1.686l.388-2.471a.25.25 0 0 0-.286-.286l-2.47.388a1.75 1.75 0 0 1-1.687-.698z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M15.53 9.47a.75.75 0 0 1 0 1.06l-3.75 3.75a.75.75 0 0 1-1.06 0l-2.25-2.25a.75.75 0 1 1 1.06-1.06l1.72 1.72 3.22-3.22a.75.75 0 0 1 1.06 0"
        clipRule="evenodd"
      />
    </svg>
  );
};
export default SvgStarCheckMark;
