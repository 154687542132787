import { createFileRoute } from "@tanstack/react-router";
import { Left } from "@repo/icons";
import { HeaderRoot, HeaderUpButton, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";

export const CustomerSiteOverviewPage = (): JSX.Element => {
  return (
    <>
      <HeaderRoot>
        <HeaderUpButton icon={Left} align="start" />
        <HeaderTitle title="Relat&oacute;rio do site" align="center" />
      </HeaderRoot>

      <Page>
        <div>Pagina de relatório do site</div>
      </Page>
    </>
  );
};
export const Route = createFileRoute("/settings/assist/customer-site-overview/")({
  component: CustomerSiteOverviewPage,
});
