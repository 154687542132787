import { graphql } from "@repo/graphql-types/gql";
import {
  Link,
  useNavigate,
  useRouteContext,
  useRouter,
  useSearch,
} from "@tanstack/react-router";
import { Refresh } from "@repo/icons";
import {
  AppointmentPurpose,
  checkIfAppointmentHasOccurred,
  defineAppointmentType,
} from "@repo/lib";
import { CpsSpinner } from "corpus";
import {
  AppointmentDrawerHeader,
  AppointmentDrawerPatientInfo,
  AppointmentDrawerPatientContactActions,
  AppointmentDrawerScheduleInformation,
  AppointmentDrawerActions,
  AppointmentDrawerActionButton,
  AppointmentDrawerActionButtonSecondary,
  AppointmentDrawerRoot,
  AppointmentDrawerAlert,
} from "@/components/appointment-drawer";
import { useGraphQL } from "@/hooks/use-graphql";
import { type AvatarColor } from "@/components/patient-avatar";
import { FlexiblePriceAppointmentDrawerAlert } from "@/components/appointment-drawer/flexible-price-appointment-drawer-alert";
import { isAppointmentNeedsToBeChargedByCancellationFee } from "@/lib/flexible-pricing";
import { ModalAvatarOverlay } from "@/components/modal-avatar-overlay";
import { PatientPhotoQuery } from "@/components/patient-avatar";
import { DrawerBody } from "@/components/drawer";
import { RecommendationMembersForPatientsButton } from "@/components/recommendation-members-for-patients-button.tsx";
import { trackEvent } from "@/lib/tracking.ts";
import { AppointmentDrawerOnlinePaymentButton } from "@/components/appointment-drawer-online-payment-button";

const CalendarAppointmentDetailsDrawerAppointment = graphql(/* GraphQL */ `
  query CalendarAppointmentDetailsDrawerAppointment($codAgendamento: Int!) {
    agendamento: agendamentos_by_pk(codAgendamento: $codAgendamento) {
      codAgendamento
      data
      horaInicio
      horaFim
      cancelado
      confirmado
      cobraCancelamento
      pago
      Unidade {
        nome
      }
      UsuarioCompromisso {
        nome
      }
      AgendamentosCheckin {
        codAgendamento
      }
      FinalidadesAgendamento {
        codFinalidadeAgendamento
        taxaCobrancaAtraso
      }
      Paciente {
        codPaciente
        ...AppointmentDrawerPatientInfoFragment
        ...AppointmentDrawerPatientContactActionsFragment
      }
      ...AppointmentDrawerOnlinePaymentButtonFragment
    }
  }
`);

const appointmentTypes: Record<
  string,
  {
    type: string;
    badgerColor: "neutral" | "danger" | "warning" | "success" | "helper";
    avatarColor: AvatarColor;
    shouldShowCancelButton: boolean;
    primaryBtnLabel: string;
    secondaryBtnLabel: string;
    shouldShowPatientChangeButton: boolean;
  }
> = {
  pending: {
    type: "Pendente",
    badgerColor: "warning",
    avatarColor: "primaryLight",
    shouldShowCancelButton: true,
    primaryBtnLabel: "Reagendar",
    secondaryBtnLabel: "Cancelar agendamento",
    shouldShowPatientChangeButton: true,
  },
  confirmed: {
    type: "Confirmado",
    badgerColor: "helper",
    avatarColor: "secondaryLight",
    shouldShowCancelButton: true,
    primaryBtnLabel: "Reagendar",
    secondaryBtnLabel: "Cancelar agendamento",
    shouldShowPatientChangeButton: true,
  },
  old: {
    type: "",
    badgerColor: "neutral",
    avatarColor: "neutralLight",
    shouldShowCancelButton: false,
    primaryBtnLabel: "Criar novo agendamento",
    secondaryBtnLabel: "",
    shouldShowPatientChangeButton: false,
  },
  cancelled: {
    type: "Cancelado",
    badgerColor: "danger",
    avatarColor: "dangerLight",
    shouldShowCancelButton: false,
    primaryBtnLabel: "Criar novo agendamento",
    secondaryBtnLabel: "",
    shouldShowPatientChangeButton: false,
  },
};

export interface AppointmentDrawerProps {
  handleAppointmentCancel: (
    appointmentId: number,
    shouldDisplayFlexiblePriceDrawer: boolean,
  ) => void;
  handleCloseAppointmentDrawer: () => void;
}

export const AppointmentDrawer = ({
  handleAppointmentCancel,
  handleCloseAppointmentDrawer,
}: AppointmentDrawerProps): JSX.Element => {
  const navigate = useNavigate();

  const router = useRouter();
  const searchParams = useSearch({
    from: "/",
  });

  const { data: appointmentQueryData, isPending } = useGraphQL(
    CalendarAppointmentDetailsDrawerAppointment,
    {
      codAgendamento: searchParams.appointmentId ?? 0,
    },
  );

  const { ldClient } = useRouteContext({ strict: false });

  const showMembersRecommendationForPatientsButtons =
    ldClient.getShowMembersRecommendationForPatientsButtons();

  const appointment = appointmentQueryData?.agendamento;
  const codPaciente = appointment?.Paciente?.codPaciente ?? 0;

  const { data: patientPhotoData } = useGraphQL(
    PatientPhotoQuery,
    { codPaciente },
    { staleTime: Infinity, enabled: Boolean(appointmentQueryData) },
  );

  const defaultAppointment = {
    codAgendamento: 0,
    pago: false,
    data: "2024-01-01",
    horaInicio: "00:00:00",
    horaFim: "00:00:00",
    cobraCancelamento: false,
    FinalidadesAgendamento: {
      codFinalidadeAgendamento: 0,
      taxaCobrancaAtraso: 0,
    },
    Unidade: { nome: "" },
    AgendamentosCheckin: [],
    UsuarioCompromisso: { nome: "" },
    Paciente: {
      codPaciente: 0,
      nome: "",
      telefone: "",
      email: "",
    },
    cancelado: false,
    confirmado: false
  };

  const {
    codAgendamento,
    data,
    horaFim,
    horaInicio,
    cobraCancelamento,
    Unidade,
    UsuarioCompromisso,
    pago,
    AgendamentosCheckin,
    FinalidadesAgendamento,
    Paciente: appointmentPatient,
  } = appointment ? { ...appointment } : defaultAppointment;

  const appointmentType = defineAppointmentType(appointment ?? defaultAppointment);

  const appointmentTypeData = appointmentTypes[appointmentType];

  const appointmentAlreadyStarted = checkIfAppointmentHasOccurred(data, horaInicio);

  const shouldDisplayRecommendationMembersForPatientsButton =
    appointmentAlreadyStarted &&
    appointmentPatient?.codPaciente &&
    showMembersRecommendationForPatientsButtons;

  const shouldDisplaySwapPatientOption =
    !pago &&
    !AgendamentosCheckin &&
    !appointmentAlreadyStarted &&
    appointmentTypes[appointmentType].shouldShowPatientChangeButton;

  const shouldShowCancelButton =
    appointmentTypes[appointmentType].shouldShowCancelButton &&
    !appointmentAlreadyStarted;

  const primaryBtnOnClick = (): void => {
    const isReschedule =
      newAppointmentRouteConfig.to === "/appointment/$appointmentId/reschedule";
    if (isReschedule) {
      handleRescheduleAction();
    } else {
      void navigate({
        ...newAppointmentRouteConfig,
      });
    }
  };

  const handleSecondaryBtnOnClick = (): void => {
    trackEvent("Cancelar agendamento selecionado", {
      agendamento: codAgendamento,
    });
    const shouldDisplayFlexiblePriceDrawer =
      cobraCancelamento && isAppointmentNeedsToBeChargedByCancellationFee(ldClient, data);

    handleAppointmentCancel(codAgendamento, shouldDisplayFlexiblePriceDrawer);
  };

  const handleRescheduleAction = (): void => {
    const shouldDisplayRescheduleAlertDrawer =
      cobraCancelamento && isAppointmentNeedsToBeChargedByCancellationFee(ldClient, data);

    if (shouldDisplayRescheduleAlertDrawer) {
      void navigate({
        from: "/",
        search: {
          ...searchParams,
          action: "reschedule-flexible-price-appointment",
          appointmentId: codAgendamento,
        },
        replace: true,
      });
    } else {
      void navigate({
        ...newAppointmentRouteConfig,
      });
    }
  };

  const imageSrc = patientPhotoData?.patient?.foto
    ? `data:image/png;base64,${patientPhotoData.patient.foto as string}`
    : undefined;

  const handleOpenAvatarOverlay = (value: boolean): void => {
    if (imageSrc) {
      if (value) {
        void router.navigate({
          to: "/",
          search: { ...searchParams, action: "expand-avatar-image" },
        });
      } else if (searchParams.action === "expand-avatar-image") {
        router.history.back();
      }
    }
  };

  type LinkMapType = Record<
    string,
    {
      to: string;
      params?: { appointmentId: number };
      search?: { patientId: number; appointmentId: number };
    }
  >;

  const newAppointmentRoutesConfig: LinkMapType = {
    old: {
      to: "/appointment/create",
      search: {
        patientId: Number(appointmentPatient?.codPaciente ?? 0),
        appointmentId: codAgendamento,
      },
    },
    cancelled: {
      to: "/appointment/create",
      search: {
        patientId: Number(appointmentPatient?.codPaciente ?? 0),
        appointmentId: codAgendamento,
      },
    },
    default: {
      to: "/appointment/$appointmentId/reschedule",
      params: { appointmentId: codAgendamento },
    },
  };

  const newAppointmentRouteConfig = newAppointmentRoutesConfig[appointmentType] ?? {
    to: "/appointment/$appointmentId/reschedule",
    params: { appointmentId: codAgendamento },
  };
  return (
    <>
      <div>
        <ModalAvatarOverlay
          src={imageSrc}
          alt={appointment?.UsuarioCompromisso?.nome ?? ""}
          show={searchParams.action === "expand-avatar-image"}
          setShow={() => handleOpenAvatarOverlay(false)}
        />
      </div>
      <AppointmentDrawerRoot
        open={searchParams.action === "appointment-details"}
        handleCloseCalendarAppointmentDetails={handleCloseAppointmentDrawer}
      >
        {isPending ? (
          <div className="flex justify-center items-center h-[200px]">
            <CpsSpinner />
          </div>
        ) : (
          <>
            <DrawerBody>
              <AppointmentDrawerHeader key="header" appointmentType={appointmentType} />
              {appointment?.Paciente ? (
                <AppointmentDrawerPatientInfo
                  onClick={() => handleOpenAvatarOverlay(true)}
                  key="patient-info"
                  appointmentType={appointmentType}
                  patient={appointment.Paciente}
                />
              ) : null}

              {shouldDisplaySwapPatientOption ? (
                <Link
                  params={{ appointmentId: codAgendamento }}
                  to="/appointment/$appointmentId/patient-swap-search"
                  className="flex flex-row justify-center items-center w-full gap-1"
                >
                  <Refresh className="fill-secondary-400" size={16} />
                  <button
                    className="text-sm font-medium leading-6 text-secondary-400 xl:text-xl"
                    type="button"
                  >
                    Trocar paciente
                  </button>
                </Link>
              ) : null}
              {appointment?.Paciente ? (
                <AppointmentDrawerPatientContactActions
                  key="patient-contact"
                  patient={appointment.Paciente}
                />
              ) : null}

              <div className="flex flex-col gap-6 mt-8 empty:mt-0">
                {appointmentQueryData?.agendamento ? (
                  <AppointmentDrawerOnlinePaymentButton
                    fragmentData={appointmentQueryData.agendamento}
                  />
                ) : null}
              </div>

              <AppointmentDrawerScheduleInformation
                key="schedule-info"
                appointmentTypeName={UsuarioCompromisso?.nome ?? ""}
                date={data}
                startTime={horaInicio}
                endTime={horaFim}
                unit={Unidade.nome}
              />

              <div className="flex flex-col gap-6 mt-8 empty:mt-0">
                {shouldDisplayRecommendationMembersForPatientsButton ? (
                  <RecommendationMembersForPatientsButton
                    patientId={appointmentPatient.codPaciente}
                  />
                ) : null}
              </div>

              {FinalidadesAgendamento.codFinalidadeAgendamento ===
                AppointmentPurpose.HorariosMenores.valueOf() && (
                <AppointmentDrawerAlert
                  alertData={FinalidadesAgendamento.taxaCobrancaAtraso.toString()}
                />
              )}

              {cobraCancelamento ? (
                <div className="my-4">
                  <FlexiblePriceAppointmentDrawerAlert />
                </div>
              ) : null}
            </DrawerBody>
            <AppointmentDrawerActions key="button-group">
              <AppointmentDrawerActionButton
                key="button-1"
                onClick={() => primaryBtnOnClick()}
              >
                {appointmentTypeData.primaryBtnLabel}
              </AppointmentDrawerActionButton>
              {shouldShowCancelButton ? (
                <AppointmentDrawerActionButtonSecondary
                  key="button-2"
                  onClick={() => handleSecondaryBtnOnClick()}
                >
                  {appointmentTypeData.secondaryBtnLabel}
                </AppointmentDrawerActionButtonSecondary>
              ) : null}
            </AppointmentDrawerActions>
          </>
        )}
      </AppointmentDrawerRoot>
    </>
  );
};
