import { Link, useRouteContext } from "@tanstack/react-router";
import { AssistFeaturesEnum, cn, ServicePackageEnum } from "@repo/lib";
import { AssistProButton } from "@/routes/settings/assist/-components/assist-pro-button";
import { trackEvent } from "@/lib/tracking";

export interface UserTrackableLinksListCreateButtonProps {
  sticky?: boolean;
}

export const UserTrackableLinksListCreateButton = ({
  sticky,
}: UserTrackableLinksListCreateButtonProps): JSX.Element => {
  const { user } = useRouteContext({
    strict: false,
  });

  const subscribedFeatures = user.funcionalidadesContratadas;

  const trackableLinkFeature = subscribedFeatures.find(
    (feature) =>
      feature.codFuncionalidade === Number(AssistFeaturesEnum.CriarLinksRastreaveis),
  );

  const isTrackableLinkFeatureActiveAndSubscribed =
    trackableLinkFeature?.contratada && trackableLinkFeature.ativo;

  const buttonProperties = isTrackableLinkFeatureActiveAndSubscribed
    ? {
        link: "/settings/assist/user-trackable-links/create",
        targetPage: "Criação do link rastreável",
      }
    : {
        link: "/settings/assist/subscribe-assist-pro?navigateBack=true",
        targetPage: "Assinatura do Assist Pro",
      };

  const onAssistProButtonClick = (): void => {
    const userActiveServicePackages = user.pacotesServicos
      .filter((pct) => !pct.cancelado)
      .map((pct) => ServicePackageEnum[pct.codPacoteServicos])
      .join(",");

    trackEvent("Botão Criar Link Rastreamento Clicado", {
      planos: userActiveServicePackages,
      destino: buttonProperties.targetPage,
    });
  };

  return (
    <div className={cn(sticky ? "sticky bottom-0 pb-4" : "mb-4 w-full")}>
      <Link to={buttonProperties.link} data-testid="create-trackable-link-button">
        <AssistProButton onClick={() => onAssistProButtonClick()}>
          Criar link
        </AssistProButton>
      </Link>
    </div>
  );
};
