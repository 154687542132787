import { type ReactElement, type InputHTMLAttributes, type FormEvent, type JSX } from "react";
import * as RadioGroup from "@radix-ui/react-radio-group";

export interface RadioButtonV2Props extends InputHTMLAttributes<HTMLInputElement> {
  value: string | number | string[];
  label: string;
}

export const CpsRadioButtonV2 = ({
  label,
  value,
  disabled,
  id,
  children,
}: RadioButtonV2Props): JSX.Element => (
  <label
    className="relative flex cursor-pointer items-center p-4 gap-2 w-full bg-neutral-50 rounded-20 justify-between"
    htmlFor={id}
    data-testid={id}
  >
    <div className="flex items-center gap-4">
      <RadioGroup.Item
        className="data-[state=checked]:border-primary-400 data-[state=checked]:bg-primary-400 data-[state=unchecked]:border-neutral-200 w-6 h-6 flex items-center justify-center rounded-full transition-all duration-200 border-2 hover:border-neutral-400 bg-white outline-none shrink-0"
        value={value.toString()}
        disabled={disabled}
        id={id}
      >
        <RadioGroup.Indicator className="flex items-center justify-center w-full h-full relative after:content-[''] bg-primary-400 border-primary-400 border-2 rounded-full after:block after:w-1.5 after:h-1.5 after:rounded-full after:bg-white" />
      </RadioGroup.Item>
      <p className={`font-medium ${disabled ? "text-neutral-400" : "text-neutral-600"}`}>
        {label}
      </p>
    </div>
    {children}
  </label>
);

export interface CpsRadioButtonGroupProps extends RadioGroup.RadioGroupProps {
  defaultValue?: string;
  onChange?: (value: string | FormEvent<HTMLDivElement>) => void;
  children: ReactElement<RadioButtonV2Props> | ReactElement<RadioButtonV2Props>[];
}

export const CpsRadioButtonGroup = ({
  children,
  onChange,
  ...props
}: CpsRadioButtonGroupProps): JSX.Element => (
  <RadioGroup.Root className="flex flex-col gap-2" onValueChange={onChange} {...props}>
    {children}
  </RadioGroup.Root>
);
