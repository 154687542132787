import { redirect } from "@tanstack/react-router";
import { type UserContext, type ServicePackageEnum } from "@repo/lib";

export const validatePackageAccess = ({
  user,
  packageService,
}: {
  user: UserContext;
  packageService: ServicePackageEnum;
}): void => {
    const userHasAccess = hasAccess(user, packageService);
    
  if (userHasAccess) {
    return;
  }

  redirectToAssistMenu();
};

const hasAccess = (user: UserContext, packageService: ServicePackageEnum): boolean => {
  return Boolean(
    user.pacotesServicos.find((x) => x.codPacoteServicos === packageService.valueOf()),
  );
};

const redirectToAssistMenu = (): void => {
  redirect({ to: "/settings/assist/", throw: true });
};
