import { CreditCard, Mastercard, Pix, Visa, type SVGIconProps } from "@repo/icons";
import { type FunctionComponent } from "react";

const PAYMENT_METHOD_ICONS: Record<string, FunctionComponent<SVGIconProps>> = {
  Visa,
  Mastercard,
  default: CreditCard,
};

interface PaymentMethodDisplayProps {
  paymentMethod?: string | null;
  brand?: string | null;
  installments?: number | null;
}

export const PatientPaymentMethodInfo = ({
  paymentMethod,
  brand,
  installments,
}: PaymentMethodDisplayProps): JSX.Element | null => {
  const buildCreditLabel = (): string =>
    installments ? `Crédito em ${installments}x` : "Crédito";

  const PAYMENT_METHOD_LABEL: Record<string, string> = {
    credito: buildCreditLabel(),
    debito: "Débito",
    pix: "Pix",
  };

  if (!paymentMethod) {
    return null;
  }

  const PaymentIcon =
    paymentMethod === "pix"
      ? Pix
      : // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- Podem haver outras bandeiras de cartão
        PAYMENT_METHOD_ICONS[brand ?? ""] || PAYMENT_METHOD_ICONS.default;

  return (
    <div className="flex flex-row items-center gap-2">
      <p>{PAYMENT_METHOD_LABEL[paymentMethod] ?? ""}</p>
      <PaymentIcon size={16} className="text-medium-500" />
    </div>
  );
};
