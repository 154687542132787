import { formatDateAndTimeIgnoringTimezone } from "@repo/lib";
import { Calendar2 } from "@repo/icons";
import { type FragmentType, graphql, useFragment } from "@repo/graphql-types";
import { formatTimeHourMinute } from "@/lib/time";
import { InfoSectionV2, type InfoSectionV2Props } from "@/components/info-section-v2";

export const PatientPaymentDetailsAppointmentSectionFragment = graphql(`
  fragment PatientPaymentDetailsAppointmentSectionFragment on ExpensumPaymentDetailsOutput {
    appointment {
      date
      startHour
      unit
    }
  }
`);

interface PatientPaymentDetailsAppointmentSectionProps {
  data: FragmentType<typeof PatientPaymentDetailsAppointmentSectionFragment>;
}

export const PatientPaymentDetailsAppointmentSection = ({
  data,
}: PatientPaymentDetailsAppointmentSectionProps): JSX.Element | null => {
  const payment = useFragment(PatientPaymentDetailsAppointmentSectionFragment, data);

  const buildAppointmentSection = (): InfoSectionV2Props | undefined => {
    const appointment = payment.appointment;

    if (!appointment) return undefined;

    const appointmentTime = formatTimeHourMinute(appointment.startHour);

    return {
      section: {
        Icon: Calendar2,
        title: "Detalhes do agendamento",
        fields: [
          {
            label: "Agendamento",
            value: formatDateAndTimeIgnoringTimezone(
              appointment.date,
              appointmentTime,
              "dd/MM/yyyy • HH:mm",
            ),
          },
          { label: "Unidade", value: appointment.unit },
        ],
      },
    };
  };

  const appointmentSection = buildAppointmentSection();

  if (!appointmentSection) return null;

  return <InfoSectionV2 {...appointmentSection} />;
};
