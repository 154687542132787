import { Share } from "@repo/icons";
import { useRouteContext } from "@tanstack/react-router";
import { formatPhoneNumber } from "@repo/lib";
import { useState } from "react";
import { Share as CapacitorShare } from "@capacitor/share";
import { CpsToast } from "corpus";
import { AssistProBadge } from "@/routes/settings/assist/-components/assist-pro-badge";
import { CardRoot, CardContent, CardHeader } from "@/components/card";
import { trackEvent } from "@/lib/tracking";

export const SubscribedAssistProPhoneNumberCard = (): JSX.Element | null => {
  const { user, flags } = useRouteContext({ strict: false });
  const [openCopiedLinkToast, setOpenCopiedLinkToast] = useState(false);

  const shouldShowPhoneNumberCard =
    flags["habilita-fluxo-com-numero-de-telefone-no-assist"];

  if (!shouldShowPhoneNumberCard) return null;

  const hasPatientPhoneNumber = Boolean(user.ramalTroncoNormalizado);

  const onClickToSharePhoneNumber = async (): Promise<void> => {
    if (!user.ramalTroncoNormalizado) return;

    trackEvent("Numero Compartilhado Assist Pro");

    const canShare = await CapacitorShare.canShare();
    if (!canShare.value) {
      await navigator.clipboard.writeText(user.ramalTroncoNormalizado ?? "");
      setOpenCopiedLinkToast(true);

      return;
    }

    await CapacitorShare.share({
      title: "Aqui está meu número exclusivo para contato!",
      text: user.ramalTroncoNormalizado,
    });
  };

  return hasPatientPhoneNumber ? (
    <>
      <CardRoot>
        <CardContent className="flex flex-col justify-center items-start w-full">
          <CardHeader className="py-0 px-0 flex flex-row justify-between items-center w-full gap-2">
            <p className="text-xs text-neutral-600 font-medium">
              Seu telefone exclusivo está pronto para uso! Ligue e ouça a mensagem de
              atendimento que seus pacientes vão ouvir ao te ligar 😉
            </p>
            <AssistProBadge />
          </CardHeader>
          <div className="h-12 w-full flex flex-row justify-between items-center bg-neutral-50 py-4 px-3 mt-3">
            <p className="text-neutral-500 text-xs font-medium">
              {formatPhoneNumber(user.ramalTroncoNormalizado?.replace("+55", "") ?? "")}
            </p>
            <button
              type="button"
              onClick={() => void onClickToSharePhoneNumber()}
              data-testid="assist-pro-phone-number-share-button"
            >
              <Share className="fill-secondary-400" size={24} />
            </button>
          </div>
        </CardContent>
      </CardRoot>

      <CpsToast
        title="Número de telefone copiado para a área de transferência"
        show={openCopiedLinkToast}
        onClose={() => setOpenCopiedLinkToast(false)}
        type="success"
        duration={5}
      />
    </>
  ) : (
    <CardRoot>
      <CardContent className="flex flex-col justify-center items-start w-full">
        <CardHeader className="py-0 px-0 flex flex-row justify-between items-center w-full gap-2">
          <p className="text-xs text-neutral-600 font-medium">
            Seu telefone exclusivo está a caminho. Vamos te avisar assim que ele for
            ativado!
          </p>
          <AssistProBadge />
        </CardHeader>
        <div className="h-12 w-full flex flex-row justify-between items-center bg-neutral-50 py-4 px-3 mt-3">
          <p className="text-neutral-400 text-xs font-medium">(00) 0000-0000</p>
          <Share className="fill-neutral-200" size={24} />
        </div>
      </CardContent>
    </CardRoot>
  );
};
