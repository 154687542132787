import { graphql } from "@repo/graphql-types/gql";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { ListRoot } from "@/components/list";
import {
  type AgendamentoProps,
  PatientHistoryListItem,
} from "@/components/patient-history-list-item";

export const PatientHistoryListFragment = graphql(/* GraphQL */ `
  fragment PatientHistoryListFragment on pacientes {
    Agendamentos(
      where: {
        codFinalidadeAgendamento: { _in: [1, 3] }
        _not: {
          _and: [
            { codAgendamentoOrigem: { _is_null: false } }
            { cancelado: { _eq: true } }
          ]
        }
      }
      order_by: { codAgendamento: desc }
    ) {
      codAgendamento
      codAgendamentoOrigem
      data
      horaInicio
      horaFim
      cancelado
      AgendamentoAlocacao {
        horaFim
      }
      Unidade {
        nome
      }
    }
  }
`);

export const PatientHistoryList = (props: {
  patient: FragmentType<typeof PatientHistoryListFragment>;
}): JSX.Element => {
  const patient = useFragment(PatientHistoryListFragment, props.patient);

  const originAppointments = new Set();

  for (const appointment of patient.Agendamentos) {
    if (appointment.codAgendamentoOrigem) {
      originAppointments.add(appointment.codAgendamentoOrigem);
    }
  }

  patient.Agendamentos = patient.Agendamentos.filter(
    (appointment) => !originAppointments.has(appointment.codAgendamento),
  );

  return (
    <div className="justify-center items-center gap-x-6">
      <p className="font-medium">Agendamentos</p>
      <ListRoot>
        {patient.Agendamentos.length ? (
          patient.Agendamentos.map((appointment) => (
            <PatientHistoryListItem
              key={appointment.codAgendamento}
              item={appointment as AgendamentoProps}
            />
          ))
        ) : (
          <p className="p-8 justify-self-center">Nenhum agendamento encontrado.</p>
        )}
      </ListRoot>
    </div>
  );
};
