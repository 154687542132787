import { type ReactNode, type ReactElement } from "react";

interface IconListRootProps {
  children: ReactElement<IconListItemProps>[];
}

export const IconListRoot = ({ children }: IconListRootProps): JSX.Element => {
  return <div className="flex flex-col gap-2">{children}</div>;
};

interface IconListItemProps {
  children: ReactNode;
}

export const IconListItem = ({ children }: IconListItemProps): JSX.Element => {
  return (
    <div className="flex gap-1 items-center">
      {children}
    </div>
  );
};

export const IconListItemIcon = ({ children }: { children: ReactNode }): JSX.Element => {
  return <div className="shrink-0 fill-secondary-500">{children}</div>;
};

export const IconListItemText = ({ children }: { children: ReactNode }): JSX.Element => {
  return <span className="text-neutral-500 text-sm">{children}</span>;
}
