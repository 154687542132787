import { CpsBadge } from "corpus";
import React from "react";

interface NumberedListRootProps {
  children: React.ReactNode;
}

export const NumberedListRoot = ({ children }: NumberedListRootProps): JSX.Element => {
  return <ul className="gap-2 flex flex-col">{children}</ul>;
};

interface NumberedListItemProps {
  children: React.ReactNode;
}

export const NumberedListItem = ({ children }: NumberedListItemProps): JSX.Element => {
  return (
    <li
      className="relative bg-neutral-50 rounded-lg h-[52px] border border-neutral-100 flex w-auto justify-between items-center px-3 gap-2"
      data-testid="numbered-list-item"
    >
      {children}
    </li>
  );
};

interface NumberedListLabelProps {
  children: React.ReactNode;
  index?: number;
}

export const NumberedListLabel = ({
  children,
  index,
}: NumberedListLabelProps): JSX.Element => {
  return (
    <div className="relative flex items-center gap-2">
      {typeof index === "number" ? (
        <span className="font-medium z-10 text-sm w-4 text-neutral-600 flex justify-center">
          {`${index + 1}° `}
        </span>
      ) : null}

      <span className="text-xs z-10 text-neutral-600">{children}</span>
    </div>
  );
};

interface NumberedListValueProps {
  value: number | string;
  percentage: number;
}

export const NumberedListValue = ({
  value,
  percentage,
}: NumberedListValueProps): JSX.Element => {
  return (
    <>
      <span
        className="absolute left-0 top-0 h-full bg-secondary-100 rounded-20 z-0"
        style={{ width: `${percentage}%` }}
      />
      <div className="flex relative z-10 items-center gap-2">
        <span className="font-medium text-neutral-600">{value}</span>
        <CpsBadge className="w-14 py-0.5 px-4 bg-secondary-500 text-white">{`${percentage}%`}</CpsBadge>
      </div>
    </>
  );
};
