import { useNavigate, useRouteContext } from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { CpsTextArea } from "corpus";
import { type FragmentType, graphql, useFragment } from "@repo/graphql-types";
import {
  FormControl,
  FormField,
  FormHandlerSubmit,
  FormItem,
  FormRoot,
  FormSubmitButton,
} from "@/components/form";
import { useGraphQLMutationWithErrorHandler } from "@/hooks/use-graphql.ts";
import {
  PageTitleDescription,
  PageTitleMainContent,
  PageTitleRoot,
} from "@/components/page-title/page-title-root.tsx";

export const SecretaryDetailsRestrictionsFormFragment = graphql(/* GraphQL */ `
  fragment SecretaryDetailsRestrictionsFormFragment on query_root {
    personalizeAppointment: usuariosProfissionaisOrientacoesSecretarias {
      restrictions: informacoesSobreRestricoes
    }
  }
`);

const upsertRestrictionsMutation = graphql(/* GraphQL */ `
  mutation UpsertSecretaryDetailsRestrictionsMutation(
    $input: usuariosProfissionaisOrientacoesSecretarias_insert_input!
  ) {
    insert_usuariosProfissionaisOrientacoesSecretarias_one(
      if_matched: {
        match_columns: codUsuario
        update_columns: informacoesSobreRestricoes
      }
      object: $input
    ) {
      informacoesSobreRestricoes
    }
  }
`);

const RestrictionsSchema = z.object({
  restrictions: z
    .string()
    .max(1000, {
      message: "As restrições não podem ter mais de 1000 caracteres",
    })
    .optional(),
});

export const SecretaryDetailsRestrictionsForm = ({
  data,
}: {
  data: FragmentType<typeof SecretaryDetailsRestrictionsFormFragment>;
}): React.ReactNode => {
  const { mutateAsync } = useGraphQLMutationWithErrorHandler(upsertRestrictionsMutation);

  const { user } = useRouteContext({ strict: false });
  const navigate = useNavigate();
  const fragmentData = useFragment(SecretaryDetailsRestrictionsFormFragment, data);

  const form = useForm<z.infer<typeof RestrictionsSchema>>({
    resolver: zodResolver(RestrictionsSchema),
    values: {
      restrictions: fragmentData.personalizeAppointment[0]?.restrictions ?? "",
    },
  });

  const onSubmit = async (values: z.infer<typeof RestrictionsSchema>): Promise<void> => {
    await mutateAsync({
      input: {
        codUsuario: user.codUsuario,
        informacoesSobreRestricoes: values.restrictions,
      },
    });

    await navigate({
      to: "../",
      replace: true,
    });
  };

  return (
    <FormRoot {...form}>
      <FormHandlerSubmit handleSubmit={onSubmit} className="gap-0">
        <PageTitleRoot>
          <PageTitleMainContent>Restrições</PageTitleMainContent>
          <PageTitleDescription>
            <p>
              Liste aqui situações ou práticas que devem ser evitadas ao contatar você ou
              organizar seus agendamentos.
            </p>
            <p>Exemplo:</p>
            <p>Não avisar sobre atraso de paciente.</p>
          </PageTitleDescription>
        </PageTitleRoot>

        <FormField
          control={form.control}
          name="restrictions"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <CpsTextArea rows={15} {...field} />
              </FormControl>
              <p className="text-neutral-500">{`${form.watch(field.name)?.length ?? 0} / 1000`}</p>
            </FormItem>
          )}
        />

        <FormSubmitButton type="submit" disabled={!form.formState.isDirty}>
          Salvar
        </FormSubmitButton>
      </FormHandlerSubmit>
    </FormRoot>
  );
};
