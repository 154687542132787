import { type ReactElement, type ReactNode } from "react";

interface GridCardRootProps {
  children: ReactElement<GridCardItemProps>[] | ReactElement<GridCardItemProps>;
}

export const GridCardRoot = ({ children }: GridCardRootProps): JSX.Element => {
  return <div className="gap-4 grid grid-cols-2 auto-rows-fr">{children}</div>;
};

interface GridCardItemProps {
  children: JSX.Element[] | JSX.Element;
}

export const GridCardItem = ({ children }: GridCardItemProps): JSX.Element => {
  return (
    <div className="p-4 bg-neutral-50 rounded-30 flex flex-col gap gap-2">{children}</div>
  );
};

export const GridCardItemIcon = ({ children }: { children: ReactNode }): JSX.Element => {
  return <div className="shrink-0 fill-primary-400">{children}</div>;
};

export const GridCardItemText = ({ children }: { children: ReactNode }): JSX.Element => {
  return <p className="font-medium text-neutral-500 text-sm">{children}</p>;
};
