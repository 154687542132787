import {
  Clock,
  Phone,
  Left,
  Smile,
  Doctor,
  Link1,
  UserRefresh,
  Laptop,
} from "@repo/icons";
import { createFileRoute, useRouteContext } from "@tanstack/react-router";
import { HeaderUpButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { MenuList, type MenuListItem } from "@/components/menu-list";
import { Page } from "@/components/page";
import { AssistProBadge } from "@/routes/settings/assist/-components/assist-pro-badge.tsx";
import { AssistProMenuBanner } from "@/routes/settings/assist/-components/assist-pro-menu-banner.tsx";

export const LivanceAssistMenuPage = (): JSX.Element => {
  const { flags } = useRouteContext({ strict: false });

  const shouldShowBanner = flags["exibe-banner-assist-no-menu"];
  const shouldShowSiteOverviewItem =
    flags["habilita-item-de-relatorio-do-site-no-menu-do-assist"];

  const menuItens: (MenuListItem & { show?: boolean })[] = [
    {
      name: "Relatório de consultas",
      Icon: Doctor,
      route: "/settings/assist/customer-appointment-overview",
      description: "Monitore o fluxo de consultas agendadas e realizadas.",
    },
    {
      name: "Relatório de contatos",
      Icon: Phone,
      route: "/settings/assist/customer-contact-overview",
      tag: <AssistProBadge />,
      description: "Acompanhe o progresso dos contatos do seu consultório.",
    },
    {
      name: "Relatório do site",
      Icon: Laptop,
      route: "/settings/assist/customer-site-overview",
      description: "Acompanhe o alcance e a receita gerada pelo seu site Livance.",
      show: shouldShowSiteOverviewItem,
    },
    {
      name: "Links com rastreamento",
      Icon: Link1,
      route: "/settings/assist/user-trackable-links",
      description:
        "Crie links com rastreamento para monitorar os acessos e medir a performance das suas divulgações.",
    },
    {
      name: "Lembretes programados",
      Icon: Clock,
      route: "/settings/assist/follow-up-appointment-reminder",
      description: "Veja os pacientes com lembretes de retorno programados por você.",
      tag: <AssistProBadge />,
    },
    {
      name: "Personalizar atendimento",
      Icon: Smile,
      route: "/settings/assist/secretary-details",
      description:
        "Forneça informações para as secretárias atenderem de forma personalizada e agendarem mais.",
      tag: <AssistProBadge />,
    },
    {
      name: "Recuperação de agendamentos",
      Icon: UserRefresh,
      route: "/settings/assist/rescued-appointment-overview",
      description:
        "Automatize mensagens para recuperar consultas canceladas ou perdidas de forma prática.",
      tag: <AssistProBadge />,
    },
  ];

  const activeMenuItens = menuItens.filter((item) => item.show !== false);

  return (
    <>
      <HeaderRoot>
        <HeaderUpButton icon={Left} align="start" />
        <HeaderTitle title="Livance Assist" />
      </HeaderRoot>
      <Page className="pt-2">
        {shouldShowBanner ? (
          <div className="pt-4 pb-2">
            <AssistProMenuBanner />
          </div>
        ) : null}
        <MenuList menuItems={activeMenuItens} />
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/assist/")({
  component: LivanceAssistMenuPage,
});
