import { useMemo } from "react";
import { Bank, Dolar, Left, ReceiptMoney, CreditCard, Chart } from "@repo/icons";
import { createFileRoute } from "@tanstack/react-router";
import { CpsBadge } from "corpus";
import { MenuList, type MenuListItem } from "@/components/menu-list";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { RecipientEmptyStateWrapper } from "@/components/recipient-empty-state";
import { RecipientInvalidAlertWrapper } from "@/components/recipient-invalid-alert-wrapper";

export const ManagePaymentsPage = (): JSX.Element => {
  const { flags } = Route.useRouteContext();

  const buildMenuItems = useMemo((): MenuListItem[] => {
    const menuItems: MenuListItem[] = [
      {
        name: "Gráficos de evolução",
        Icon: Chart,
        route: "/settings/payments/evolution-charts",
        description: "Acompanhe os pagamentos dos pacientes e seus recebimentos.",
        tag: <CpsBadge color="helper">Novo</CpsBadge>,
      },
      {
        name: "Criar pagamento avulso",
        Icon: CreditCard,
        route: "/settings/payments/create",
        description:
          "Crie links de pagamentos para cobrar de seus pacientes serviços realizados fora da Livance.",
        tag: <CpsBadge color="helper">Novo</CpsBadge>,
      },
      {
        name: "Lista de pagamentos",
        Icon: Dolar,
        route: "/settings/payments/patient-payments",
        description:
          "Consulte os detalhes dos pagamentos dos pacientes e acompanhe a liberação dos seus recebíveis, com valores líquidos e status atualizado.",
      },
      {
        name: "Recebimentos",
        Icon: ReceiptMoney,
        route: "/settings/payments/payables",
        description: "Acompanhe detalhes dos seus recebíveis.",
      },
      {
        name: "Conta de recebimento",
        Icon: Bank,
        route: "/settings/payments/bank-account",
        description:
          "Configure sua conta corrente para recebimentos e antecipação automática para pagamentos no crédito.",
      },
    ];

    const menu = [...menuItems];

    if (flags["remove-lista-de-recebimentos-de-gerenciar-pagamentos"]) {
      return menu.filter((item) => item.name !== "Recebimentos");
    }

    return menu;
  }, [flags]);

  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Gerenciar Pagamentos" align="center" />
      </HeaderRoot>
      <Page className="pt-2">
        <RecipientEmptyStateWrapper>
          <RecipientInvalidAlertWrapper>
            <MenuList menuItems={buildMenuItems} />
          </RecipientInvalidAlertWrapper>
        </RecipientEmptyStateWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/payments/")({
  component: ManagePaymentsPage,
});
