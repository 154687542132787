import { type ReactNode } from "react";

interface DashboardVariationBadgeProps {
  children: ReactNode;
}

export const CustomerContactVariationBadge = ({
  children,
}: DashboardVariationBadgeProps): JSX.Element | null => {
  const variationValue = Number(children);

  const { backgroundClass, displayValue } = getBadgeDetails(variationValue);

  return (
    <span
      className={`${backgroundClass} rounded-70 px-2 py-1 my-[5.5px]`}
      data-testid="dashboard-variation-badge"
    >
      <p className="font-medium text-[10px]">{displayValue}</p>
    </span>
  );
};

const getBadgeDetails = (
  variationValue: number,
): { backgroundClass: string; displayValue: string } => {
  if (variationValue === 0) {
    return {
      backgroundClass: "bg-neutral-100",
      displayValue: "0%",
    };
  }

  const isPositive = variationValue > 0;

  return {
    backgroundClass: isPositive ? "bg-success-100" : "bg-danger-100",
    displayValue: `${isPositive ? "+" : "-"} ${Math.abs(variationValue)}%`,
  };
};
