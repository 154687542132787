import { useRouter, useSearch } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types/gql";
import { useFragment, type FragmentType } from "@repo/graphql-types";
import { type CustomerContactDashboardContactReasonSectionQueryFragmentFragment } from "@repo/graphql-types/graphql";
import { useMemo } from "react";
import { DashboardSectionHeader } from "@/components/dashboard/dashboard-section-header";
import { DashboardInformationalDrawer } from "@/components/dashboard/dashboard-informational-drawer";
import { DashboardSectionEmptyState } from "@/components/dashboard/dashboard-section-empty-state";
import { DashboardSectionTotalNumber } from "@/components/dashboard/dashboard-section-total-number";
import { contactReasonMap } from "@/enums/customer-contact/contact-reason";
import { trackEvent } from "@/lib/tracking";
import { distributeValuesAsPercentages } from "@/lib/math";
import {
  NumberedListRoot,
  NumberedListItem,
  NumberedListValue,
  NumberedListLabel,
} from "@/components/numbered-list";
import { shouldShowVariationBadge } from "@/lib/dashboard";

export const CustomerContactDashboardContactReasonSectionQueryFragment = graphql(
  /* GraphQL */ `
    fragment CustomerContactDashboardContactReasonSectionQueryFragment on NuntiusGetCustomerContactManyPeriodSummaryOutput {
      totalCustomerContacts
      period
      percentGrowthTotalCustomerContacts
      mainReasonsOccurrences {
        key
        total
      }
    }
  `,
);

interface CustomerContactDashboardContactReasonSectionProps {
  data: FragmentType<typeof CustomerContactDashboardContactReasonSectionQueryFragment>[];
  selectedMonth: string;
  earliestDate: Date;
}

export const CustomerContactDashboardContactReasonSection = ({
  data,
  selectedMonth,
  earliestDate,
}: CustomerContactDashboardContactReasonSectionProps): JSX.Element => {
  const fragmentData = useFragment(
    CustomerContactDashboardContactReasonSectionQueryFragment,
    data,
  );

  const router = useRouter();
  const searchParams = useSearch({
    from: "/settings/assist/customer-contact-overview/",
  });

  const onOpenChange = (open: boolean): void => {
    const search = {
      ...searchParams,
    };

    if (open) {
      search.showCustomerContactReasonDrawer = open;

      trackEvent("Definição Gráfico Aberta", {
        grafico: "Motivos de contato",
      });

      void router.navigate({ search });
    } else if (searchParams.showCustomerContactReasonDrawer) {
      router.history.back();
    }
  };

  const currentMonthData = fragmentData.find(
    (monthData) => monthData.period === selectedMonth,
  );

  const variation = shouldShowVariationBadge(earliestDate, selectedMonth)
    ? currentMonthData?.percentGrowthTotalCustomerContacts
    : undefined;

  return (
    <div className="my-8 flex flex-col gap-3">
      <DashboardSectionHeader onClick={onOpenChange}>
        Motivos de contato
      </DashboardSectionHeader>
      {data.length === 0 ||
      !currentMonthData ||
      currentMonthData.mainReasonsOccurrences.length === 0 ||
      currentMonthData.mainReasonsOccurrences.every((x) => x.total === 0) ? (
        <DashboardSectionEmptyState />
      ) : (
        <>
          <DashboardSectionTotalNumber
            total={currentMonthData.totalCustomerContacts}
            variation={variation}
          />
          <CustomerContactDashboardContactReasonSectionPieChart
            reasonChartData={currentMonthData}
          />
        </>
      )}
      <DashboardInformationalDrawer
        open={Boolean(searchParams.showCustomerContactReasonDrawer)}
        onOpenChange={onOpenChange}
        drawerTitle="Motivos de contato"
      >
        <p>Classificação dos contatos recebidos: </p>
        <ul className="text-md list-disc list-inside">
          <li className="mb-1 list-item">
            <span className="font-semibold ">Novo agendamento: </span>
            <span>Contatos realizados com a intenção de marcar uma nova consulta.</span>
          </li>
          <li className="list-item">
            <span className="font-semibold">Tratar de consulta marcada: </span>
            <span>Contatos referentes a consultas que já estavam agendadas.</span>
          </li>
          <li className="list-item">
            <span className="font-semibold">Dúvida pós-consulta: </span>
            <span>Contatos feitos após uma consulta ter sido realizada.</span>
          </li>
          <li className="list-item">
            <span className="font-semibold">Informações diversas: </span>
            <span>Agrupamento de motivos diversos.</span>
          </li>
        </ul>
      </DashboardInformationalDrawer>
    </div>
  );
};

interface CustomerContactDashboardContactReasonSectionPieChartProps {
  reasonChartData: CustomerContactDashboardContactReasonSectionQueryFragmentFragment;
}

export const CustomerContactDashboardContactReasonSectionPieChart = ({
  reasonChartData,
}: CustomerContactDashboardContactReasonSectionPieChartProps): JSX.Element => {
  const contactsWithSelectedReason = reasonChartData.mainReasonsOccurrences.reduce(
    (sum, reason) => sum + reason.total,
    0,
  );

  const chartRootData = Object.entries(contactReasonMap).map(([key, value]) => {
    return {
      contactReason: value,
      value:
        reasonChartData.mainReasonsOccurrences.find((y) => y.key === Number(key))
          ?.total ?? 0,
    };
  });

  const contactsWithoutReason =
    reasonChartData.totalCustomerContacts - contactsWithSelectedReason;

  if (contactsWithoutReason > 0) {
    chartRootData.push({
      value: contactsWithoutReason,
      contactReason: "Motivo não informado",
    });
  }

  const sortedChartData = useMemo(
    () => [...chartRootData].sort((a, b) => b.value - a.value),
    [chartRootData],
  );

  const values = useMemo(
    () => sortedChartData.map((item) => item.value),
    [sortedChartData],
  );
  const percentages = useMemo(() => distributeValuesAsPercentages(values), [values]);

  return (
    <div className="mt-2 flex flex-col gap-4" data-testid="customer-contact-reason-chart">
      <NumberedListRoot>
        {sortedChartData.map((x, index) => (
          <NumberedListItem key={`${x.contactReason} - ${x.value}`}>
            <NumberedListLabel index={index}>{x.contactReason}</NumberedListLabel>
            <NumberedListValue value={x.value} percentage={percentages[index]} />
          </NumberedListItem>
        ))}
      </NumberedListRoot>
    </div>
  );
};
