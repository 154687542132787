import { useRouter, useSearch } from "@tanstack/react-router";
import { graphql } from "@repo/graphql-types/gql";
import { type FragmentType, useFragment } from "@repo/graphql-types";
import { type CustomerContactDashboardContactsDistributionSectionQueryFragmentFragment } from "@repo/graphql-types/graphql";
import {
  ChartRoot,
  ChartBarContainer,
  ChartBarItem,
  ChartBarLegend,
} from "@/components/charts";
import { DashboardSectionEmptyState } from "@/components/dashboard/dashboard-section-empty-state";
import { DashboardInformationalDrawer } from "@/components/dashboard/dashboard-informational-drawer";
import { DashboardSectionHeader } from "@/components/dashboard/dashboard-section-header";
import { DashboardSectionTotalNumber } from "@/components/dashboard/dashboard-section-total-number";
import { trackEvent } from "@/lib/tracking";
import { MINIMUM_PERCENTAGE_TO_DISPLAY } from "@/lib/constants/dashboards-customer-overview";
import { shouldShowVariationBadge } from "@/lib/dashboard";

export const CustomerContactDashboardContactsDistributionSectionQueryFragment = graphql(
  /* GraphQL */ `
    fragment CustomerContactDashboardContactsDistributionSectionQueryFragment on NuntiusGetCustomerContactManyPeriodSummaryOutput {
      totalCustomerContacts
      totalOldCustomerContacts
      percentOldCustomerContacts
      totalNewCustomerContacts
      percentNewCustomerContacts
      period
      percentGrowthTotalCustomerContacts
    }
  `,
);

interface CustomerContactDashboardContactsDistributionSectionProps {
  data: FragmentType<
    typeof CustomerContactDashboardContactsDistributionSectionQueryFragment
  >[];
  selectedMonth: string;
  onSelectedMonthChange: (newMonth: string) => void;
  earliestDate: Date;
}

export const CustomerContactDashboardContactsDistributionSection = ({
  data,
  selectedMonth,
  onSelectedMonthChange,
  earliestDate,
}: CustomerContactDashboardContactsDistributionSectionProps): JSX.Element => {
  const fragmentData = useFragment(
    CustomerContactDashboardContactsDistributionSectionQueryFragment,
    data,
  );

  const currentMonthData = fragmentData.find(
    (monthData) => monthData.period === selectedMonth,
  );

  const router = useRouter();
  const searchParams = useSearch({
    from: "/settings/assist/customer-contact-overview/",
  });

  const onOpenChange = (open: boolean): void => {
    const search = {
      ...searchParams,
    };

    if (open) {
      search.showCustomerContactsDistributionDrawer = open;

      trackEvent("Definição Gráfico Aberta", {
        grafico: "Distribuição de contatos",
      });

      void router.navigate({ search });
    } else if (searchParams.showCustomerContactsDistributionDrawer) {
      router.history.back();
    }
  };

  const fragmentDataIsEmpty = !fragmentData.some(
    (monthData) => monthData.totalCustomerContacts !== 0,
  );

  const variation = shouldShowVariationBadge(earliestDate, selectedMonth)
    ? currentMonthData?.percentGrowthTotalCustomerContacts
    : undefined;

  return (
    <div className="my-8 flex flex-col gap-3">
      <DashboardSectionHeader onClick={onOpenChange}>
        Distribuição de contatos
      </DashboardSectionHeader>
      {fragmentData.length === 0 || fragmentDataIsEmpty ? (
        <DashboardSectionEmptyState />
      ) : (
        <>
          <DashboardSectionTotalNumber
            total={currentMonthData?.totalCustomerContacts ?? 0}
            variation={variation}
          />
          <CustomerContactDashboardContactsDistributionSectionChart
            chartData={
              fragmentData as CustomerContactDashboardContactsDistributionSectionQueryFragmentFragment[]
            }
            selectedMonth={selectedMonth}
            onSelectedMonthChange={onSelectedMonthChange}
          />
        </>
      )}
      <DashboardInformationalDrawer
        open={Boolean(searchParams.showCustomerContactsDistributionDrawer)}
        onOpenChange={onOpenChange}
        drawerTitle="Distribuição de contatos"
      >
        <p>
          Distribuição dos contatos recebidos nos canais de atendimento, classificados por
          origem:
        </p>
        <ul className="text-md list-disc list-inside">
          <li className="mb-1 list-item">
            <span className="font-semibold ">Pacientes: </span>
            <span>contatos de pessoas que já possuem consultas agendadas.</span>
          </li>
          <li className="list-item">
            <span className="font-semibold">Novos contatos: </span>
            <span>contatos de pessoas que ainda não realizaram nenhum agendamento.</span>
          </li>
        </ul>
      </DashboardInformationalDrawer>
    </div>
  );
};

interface CustomerContactDashboardContactsDistributionSectionChartProps {
  chartData: CustomerContactDashboardContactsDistributionSectionQueryFragmentFragment[];
  selectedMonth: string;
  onSelectedMonthChange: (newMonth: string) => void;
}

export const CustomerContactDashboardContactsDistributionSectionChart = ({
  chartData,
  selectedMonth,
  onSelectedMonthChange,
}: CustomerContactDashboardContactsDistributionSectionChartProps): JSX.Element => {
  const data = chartData.map((x) => {
    return {
      Pacientes: x.totalOldCustomerContacts,
      "Novos contatos": x.totalNewCustomerContacts,
      mes: x.period,
    };
  });

  const labels = chartData.map((y) => {
    return {
      Pacientes:
        y.percentOldCustomerContacts >= MINIMUM_PERCENTAGE_TO_DISPLAY
          ? `${y.percentOldCustomerContacts}%`
          : "",
      "Novos contatos":
        y.percentNewCustomerContacts >= MINIMUM_PERCENTAGE_TO_DISPLAY
          ? `${y.percentNewCustomerContacts}%`
          : "",
    };
  });

  return (
    <div data-testid="customer-contact-distribution-chart">
      <ChartRoot
        selectedKey={String(selectedMonth)}
        onSelectedKeyChange={onSelectedMonthChange}
        data={data}
        labels={labels}
      >
        <ChartBarContainer isStacked height={291} XAxisKey="mes">
          <ChartBarItem dataKey="Novos contatos" fill="#FFD094" disabledFill="#EDF0F2" />
          <ChartBarItem dataKey="Pacientes" fill="#E59112" disabledFill="#D5DADF" />
          <ChartBarLegend />
        </ChartBarContainer>
      </ChartRoot>
    </div>
  );
};
