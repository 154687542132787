import { graphql } from "@repo/graphql-types/gql";
import { Left } from "@repo/icons";
import { formatWithZonedDate } from "@repo/lib";
import {
  createFileRoute,
  useRouteContext,
  useRouter,
  useSearch,
} from "@tanstack/react-router";
import { endOfWeek, startOfWeek, subWeeks } from "date-fns";
import { z } from "zod";
import { useAtom } from "jotai";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { PaymentsOriginChart } from "@/components/payments-origin-chart";
import { PaymentsStatusChart } from "@/components/payments-status-chart";
import { useGraphQL } from "@/hooks/use-graphql";
import { PaymentsPayablesStatusChartContainer } from "@/components/payments-payables-status-chart-container";
import { PayablesStatusChart } from "@/components/payables-status-chart";
import { chartsTabSelected } from "@/lib/atoms/payments-atom";

const EvolutionChartsPageQuery = graphql(`
  query EvolutionChartsPageQuery(
    $paymentsInput: ExpensumGetPaymentsChartsInfoInput!
    $payablesInput: ExpensumGetPayablesChartsInfoInput!
  ) {
    ExpensumGetPaymentsChartsInfo(arg1: $paymentsInput) {
      ...PaymentsStatusChartFragment
      ...PaymentsOriginChartFragment
    }
    ExpensumGetPayablesChartsInfo(arg1: $payablesInput) {
      ...PayablesStatusChartFragment
    }
  }
`);

const evolutionChartsPageSearchParams = z.object({
  back: z.number().optional(),
  action: z.string().optional(),
});

export const EvolutionChartsPage = (): JSX.Element => {
  const { user } = useRouteContext({ strict: false });

  const router = useRouter();
  const searchParams = useSearch({
    from: "/settings/payments/evolution-charts/",
  });

  const [chartsTabSelectedAtom] = useAtom(chartsTabSelected);

  const endDate = endOfWeek(new Date(), { weekStartsOn: 1 });
  const startDate = startOfWeek(subWeeks(endDate, 3), { weekStartsOn: 1 });

  const queryInput = {
    periodicity: "weekly",
    intervalEnd: formatWithZonedDate(endDate, "yyyy-MM-dd"),
    intervalStart: formatWithZonedDate(startDate, "yyyy-MM-dd"),
  };

  const queryResult = useGraphQL(EvolutionChartsPageQuery, {
    paymentsInput: queryInput,
    payablesInput: { ...queryInput, codClinica: user.codClinica ?? null },
  });

  const { data } = queryResult;

  const navigateBack = (): void => {
    const backSteps = searchParams.back ?? 0;
    const historySteps = -1 - backSteps;

    router.history.go(historySteps);
  };

  return (
    <>
      <HeaderRoot>
        <HeaderButton onClick={navigateBack} icon={Left} align="start" />
        <HeaderTitle title="Gráficos de evolução" />
      </HeaderRoot>

      <Page>
        <AsyncDataWrapper {...queryResult}>
          {data ? (
            <div
              id="payments-evolution-charts-container"
              key="payments-evolution-charts-container"
              className="flex flex-col space-y-2"
            >
              <PaymentsPayablesStatusChartContainer>
                {chartsTabSelectedAtom === "payments" ? (
                  <PaymentsStatusChart data={data.ExpensumGetPaymentsChartsInfo} />
                ) : (
                  <PayablesStatusChart data={data.ExpensumGetPayablesChartsInfo} />
                )}
              </PaymentsPayablesStatusChartContainer>

              {chartsTabSelectedAtom === "payments" ? (
                <PaymentsOriginChart data={data.ExpensumGetPaymentsChartsInfo} />
              ) : null}
            </div>
          ) : null}
        </AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/payments/evolution-charts/")({
  component: EvolutionChartsPage,
  validateSearch: evolutionChartsPageSearchParams,
});
