import { createFileRoute } from "@tanstack/react-router";
import { Left } from "@repo/icons";
import { HeaderButton, HeaderRoot, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { UserTrackableLinksCreateForm } from "@/components/user-trackable-links/user-trackable-links-create-form";
import { validateFeatureAccess } from "@/lib/assist-feature-guard";
import { AssistFeatures } from "@/enums/assist-features";

export const UserTrackableLinksCreatePage = (): JSX.Element => {
  return (
    <>
      <HeaderRoot>
        <HeaderButton icon={Left} align="start" />
        <HeaderTitle title="Links com rastreamento" />
      </HeaderRoot>
      <Page className="px-4 pt-6">
        <UserTrackableLinksCreateForm />
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/assist/user-trackable-links/create/")({
  component: UserTrackableLinksCreatePage,
  beforeLoad: ({ context, location }) => {
    const user = context.user;
    validateFeatureAccess({
      location,
      user,
      codFuncionalidade: AssistFeatures.CreateTrackableLinks,
    });
  },
});
