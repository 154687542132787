import { cn } from "@repo/lib";
import { CpsBadge, type CpsBadgeProps } from "corpus";

interface ChartLegendItemProps {
  fill?: string;
  value: string;
  displayText: string;
  index: number;
  variation?: number;
  className?: string;
  direction?: "row" | "column";
}

export const ChartLegendItem = ({
  fill,
  value,
  displayText,
  index,
  variation,
  className,
  direction,
}: ChartLegendItemProps): JSX.Element => {
  const flexDirection =
    direction === "row"
      ? "flex-row-reverse justify-between w-full items-center"
      : "flex-col";

  return (
    <div
      key={`legend-item-${String(index)}`}
      className={cn(
        "flex items-center gap-2 bg-neutral-50 rounded-30 border border-neutral-100 w-full flex-[1_0_40%] p-3 shrink-0",
        className,
      )}
      data-testid={`legend-item-${String(index)}`}
    >
      {fill ? (
        <div
          className="rounded-full w-3 h-3 flex-shrink-0"
          data-testid="chart-legend-color"
          style={{ backgroundColor: fill }}
        />
      ) : null}
      <div className={cn("flex items-start", flexDirection)}>
        <div
          className={cn(
            "flex gap-2 items-center",
            direction === "row" ? "flex-row-reverse" : null,
          )}
        >
          <span
            className="color-neutral-600 text-sm font-medium"
            data-testid="chart-legend-value"
          >
            {value}
          </span>

          {variation ? (
            <CpsBadge
              className="px-1.5 py-0 text-[10px] font-medium h-[17px]"
              color={(variation > 0 ? "success" : "danger") as CpsBadgeProps["color"]}
            >
              {`${variation > 0 ? "+" : "-"} ${Math.abs(variation)}%`}
            </CpsBadge>
          ) : null}
        </div>
        <span className="text-xs" data-testid="chart-legend-text">
          {displayText}
        </span>
      </div>
    </div>
  );
};
