import { Eye, EyeOff } from "@repo/icons";
import { useAtom } from "jotai";
import { useRouteContext, useRouter, useSearch } from "@tanstack/react-router";
import { CpsInput, CpsToast } from "corpus";
import { useState } from "react";
import { Share as CapacitorShare } from "@capacitor/share";
import { type FragmentType, graphql, useFragment } from "@repo/graphql-types";
import { PatientPaymentsAmountHeader } from "@/components/patient-payments-amount-header";
import { PatientInfoCard } from "@/components/patient-info-card";
import { PaymentStatusChangeDrawer } from "@/components/payment-status-change-drawer";
import { PatientPaymentDetailsAppointmentSection } from "@/components/patient-payments-details-appointment-section";
import { PatientPaymentsDetailsPaymentSection } from "@/components/patient-payments-details-payment-section";
import { PatientPaymentsDetailsPayableSection } from "@/components/patient-payments-details-payable-section";
import { amountVisibilityAtom } from "@/lib/atoms/amount-visibility";
import { trackEvent } from "@/lib/tracking";
import { Button } from "@/components/button";
import { generateOnlinePaymentLink } from "@/lib/online-payment";

export const PatientPaymentDetailsFragment = graphql(`
  fragment PatientPaymentDetailsFragment on ExpensumPaymentDetailsOutput {
    amount
    status
    origin
    paymentMethod
    installments
    patient {
      name
      phone
      email
    }
    onlinePaymentInfo {
      guid
    }
    ...PatientPaymentDetailsAppointmentSectionFragment
    ...PatientPaymentsDetailsPaymentSectionFragment
    ...PatientPaymentsDetailsPayableSectionFragment
  }
`);

interface PatientPaymentDetailsProps {
  data: FragmentType<typeof PatientPaymentDetailsFragment>;
}

export const PatientPaymentDetails = ({
  data,
}: PatientPaymentDetailsProps): JSX.Element => {
  const payment = useFragment(PatientPaymentDetailsFragment, data);
  const router = useRouter();

  const { flags } = useRouteContext({ strict: false });
  const searchParams = useSearch({
    from: "/settings/payments/patient-payments/details-v2",
  });

  const [displayValues, setDisplayValues] = useAtom(amountVisibilityAtom);

  const [openCopiedLinkToast, setOpenCopiedLinkToast] = useState(false);

  const { onlinePaymentInfo } = payment;

  const shouldDisplayPaymentLink =
    payment.origin === "Online" &&
    onlinePaymentInfo?.guid &&
    payment.status === "pending";

  const paymentLinkUrl = generateOnlinePaymentLink(onlinePaymentInfo?.guid ?? "");

  const handleStatusBadgeClick = (): void => {
    void router.navigate({
      search: {
        ...searchParams,
        action: "payment-status-change",
      },
    });
  };

  const isStatusBadgeClickable =
    flags["ativa-fluxo-de-alterar-status-do-pagamento"] &&
    payment.status === "pending" &&
    payment.origin === "Online";

  const toggleAmountVisibility = (): void => {
    setDisplayValues((prev) => !prev);
  };

  const shareLink = async (): Promise<void> => {
    trackEvent("Pagamento Online Link Compartilhado", {
      origem: "Detalhe pagamento",
    });

    const canShare = await CapacitorShare.canShare();
    if (!canShare.value) {
      await navigator.clipboard.writeText(paymentLinkUrl);
      setOpenCopiedLinkToast(true);

      return;
    }

    await CapacitorShare.share({
      url: paymentLinkUrl,
    });
  };

  const buildPaymentMethodInformation = (): string => {
    const installments = payment.installments;
    const paymentType = payment.paymentMethod;

    const buildCreditLabel = (): string => {
      if (!installments) return "Crédito";

      return `Crédito ${installments}x`;
    };

    const paymentMethodsDictionary: Record<string, string> = {
      credito: buildCreditLabel(),
      debito: "Débito",
      pix: "PIX",
    };

    return paymentType ? paymentMethodsDictionary[paymentType] : "";
  };

  const paymentMethodInfo = buildPaymentMethodInformation();

  return (
    <>
      <div className="flex flex-row justify-between gap-2 mt-2">
        <PatientPaymentsAmountHeader
          status={payment.status}
          amount={payment.amount}
          paymentMethod={paymentMethodInfo}
          onClickStatusBadge={isStatusBadgeClickable ? handleStatusBadgeClick : undefined}
        />

        <button
          aria-label="Mostrar/Esconder valores"
          onClick={toggleAmountVisibility}
          type="button"
          className="focus:outline-none"
        >
          {displayValues ? (
            <Eye size={24} className="fill-neutral-600" />
          ) : (
            <EyeOff size={24} className="fill-neutral-600" />
          )}
        </button>
      </div>
      <div className="flex flex-col gap-6">
        <PatientInfoCard patient={payment.patient} />

        <div className="flex flex-col gap-8">
          <PatientPaymentDetailsAppointmentSection data={payment} />
          <PatientPaymentsDetailsPaymentSection data={payment} />
          <PatientPaymentsDetailsPayableSection data={payment} />
        </div>

        {shouldDisplayPaymentLink ? (
          <div className="flex flex-col gap-3">
            <CpsInput
              inputMode="text"
              type="text"
              value={paymentLinkUrl}
              readOnly
              onClick={() => void shareLink()}
              data-testid="copy-payment-link-input"
            />
            <Button onClick={() => void shareLink()}>Compartilhar</Button>
          </div>
        ) : null}
      </div>
      <CpsToast
        title="Link copiado para a área de transferência"
        show={openCopiedLinkToast}
        onClose={() => setOpenCopiedLinkToast(false)}
        type="success"
        duration={10}
      />
      <PaymentStatusChangeDrawer />
    </>
  );
};
