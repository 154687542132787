import { format } from "date-fns";

//TODO: utilizar variavel do banco após adição desses campos - por enquanto hardcoded até definirem os valores corretos do Assist Pro
export const START_CHARGE_DATE = new Date(2025, 5, 16);
export const TRIAL_LIMIT_DATE = new Date(2025, 5, 15);
export const DIGITAL_CONTACT_PRICE = 2.5;
export const HUMAN_CONTACT_PRICE = 7.5;
export const SUBSCRIPTION_PAGE_FEATURES = [
  "Número de telefone exclusivo.",
  `Cobrança a partir de ${format(START_CHARGE_DATE, "dd/MM/yyyy")}.`,
  "Cancele a qualquer momento.",
];
export const LEGACY_SUBSCRIPTION_PAGE_FEATURES = [
  "Seu número exclusivo continua o mesmo.",
  `Cobrança a partir de ${format(START_CHARGE_DATE, "dd/MM/yyyy")}.`,
  "Cancele a qualquer momento.",
];
