import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { DrinkColored } from "@repo/icons";
import { useRouteContext } from "@tanstack/react-router";
import { ListRoot } from "@/components/list";
import { PatientPaymentListItem } from "@/components/patient-payments-list-item";
import { PatientPaymentListItemV2 } from "@/components/patient-payments-list-item-v2";
import {
  EmptyStateV2Description,
  EmptyStateV2Icon,
  EmptyStateV2Root,
  EmptyStateV2Title,
} from "@/components/empty-state-v2";

export const PatientPaymentsListFragment = graphql(/* GraphQL */ `
  fragment PatientPaymentsListFragment on ExpensumGetPaymentsV2Output {
    payments {
      codAgendamento
      ...PatientPaymentsListItemFragment
      ...PatientPaymentsListItemV2Fragment
    }
  }
`);

interface PatientPaymentsListProps {
  data: FragmentType<typeof PatientPaymentsListFragment>;
}

export const PatientPaymentsList = ({ data }: PatientPaymentsListProps): JSX.Element => {
  const { flags } = useRouteContext({
    strict: false,
  });
  const isV2Enabled = flags["exibe-nova-lista-de-pagamentos-com-recebimentos"];

  const { payments } = useFragment(PatientPaymentsListFragment, data);

  return (
    <ListRoot>
      {payments.length === 0 ? (
        <EmptyStateV2Root>
          <EmptyStateV2Icon Icon={DrinkColored} />
          <EmptyStateV2Title>
            <>
              <h2>Ops! </h2>
              <h2>Não encontramos nada por aqui.</h2>
            </>
          </EmptyStateV2Title>
          <EmptyStateV2Description>
            Tente ajustar os filtros ou o período da busca para descobrir o que está
            procurando.
          </EmptyStateV2Description>
        </EmptyStateV2Root>
      ) : (
        payments.map((payment, index) => {
          if (isV2Enabled && payment) {
            return (
              <PatientPaymentListItemV2
                // eslint-disable-next-line react/no-array-index-key -- Não há identificar único
                key={`${payment.codAgendamento ?? 0}-${index}`}
                data={payment}
              />
            );
          }
          // TODO: Remover após finalizar a migração para V2 da lista de pagamentos
          return (
            <PatientPaymentListItem
              // eslint-disable-next-line react/no-array-index-key -- Não há identificar único
              key={`${payment?.codAgendamento ?? 0}-${index}`}
              data={payment}
            />
          );
        })
      )}
    </ListRoot>
  );
};
