import { useRouter, useSearch } from "@tanstack/react-router";
import {
  MessageDrawerBody,
  MessageDrawerRoot,
  MessageDrawerTitle,
} from "@/components/message-drawer";

export const PaymentStatusChartsAboutDrawer = (): JSX.Element => {
  const { action } = useSearch({
    from: "/settings/payments/evolution-charts/",
  });
  const router = useRouter();

  const statusActionDictionary: Record<string, string> = {
    paid: "status-paid-about",
    created: "status-created-about",
    waitingFunds: "status-waitingFunds-about",
    released: "status-released-about",
  };

  const isOpen = action ? Object.values(statusActionDictionary).includes(action) : false;
  const status = Object.keys(statusActionDictionary).find(
    (key) => statusActionDictionary[key] === action,
  );

  const handleDrawerDismiss = (value: boolean): void => {
    if (!value && isOpen) {
      router.history.back();
    }
  };

  const buildStatusDictionary = (statusKey: string | undefined): string | null => {
    const statusDictionary: Record<string, string> = {
      waitingFunds: "previstos",
      released: "liberados",
      paid: "pagos",
      created: "criados",
    };
    return statusKey ? statusDictionary[statusKey] : null;
  };

  const buildTextBody = (statusKey: string | undefined): JSX.Element | null => {
    const statusDictionary: Record<string, JSX.Element> = {
      paid: textPaid(),
      created: textCreated(),
      waitingFunds: textWaitingFunds(),
      released: textReleased(),
    };
    return statusKey ? statusDictionary[statusKey] : null;
  };

  const textCreated = (): JSX.Element => {
    return (
      <>
        <p className="text-start">
          O valor apresentado aqui é o somatório dos valores de todos os{" "}
          <b>pagamentos criados no período</b> e que não tenha sido cancelado.
        </p>
        <p className="text-start">
          Esse valor é referente aos valores tanto do Totem quanto do Link de pagamento.
        </p>
      </>
    );
  };

  const textPaid = (): JSX.Element => {
    return (
      <>
        <p className="text-start">
          O valor apresentado aqui é o somatório dos valores de todos os{" "}
          <b>pagamentos realizados no período.</b>
        </p>
        <p className="text-start">
          Esse valor é referente aos valores tanto do Totem quanto do Link de pagamento.
        </p>
      </>
    );
  };

  const textWaitingFunds = (): JSX.Element => {
    return (
      <>
        <p className="text-start">
          O valor apresentado aqui é o somatório dos{" "}
          <b>recebimentos previstos para serem liberados no período.</b>
        </p>
        <p className="text-start">
          O valor inclui tanto transações realizadas pelo totem quanto pelo link de
          pagamento.
        </p>
      </>
    );
  };

  const textReleased = (): JSX.Element => {
    return (
      <>
        <p className="text-start">
          O valor apresentado aqui é o somatório dos{" "}
          <b>recebimentos liberados no período</b> e que são transferidos automaticamente
          para sua conta bancária.
        </p>
        <p className="text-start">
          Caso o valor não conste em sua conta, verifique os dados bancários cadastrados.
        </p>
        <p className="text-start">
          O valor inclui tanto transações realizadas pelo totem quanto pelo link de
          pagamento.
        </p>
      </>
    );
  };

  return (
    <MessageDrawerRoot open={isOpen} setOpen={handleDrawerDismiss}>
      <MessageDrawerTitle>
        <h2 className="text-start text-lg">
          Valor total {buildStatusDictionary(status)}
        </h2>
      </MessageDrawerTitle>
      <MessageDrawerBody>{buildTextBody(status)}</MessageDrawerBody>
    </MessageDrawerRoot>
  );
};
