import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { graphql } from "@repo/graphql-types/gql";
import { forwardRef } from "react";
import { type SelectDrawerItemProps } from "@/components/select-drawer";
import { SelectInput, type SelectInputProps } from "@/components/select-input";

export const OneLivSpecialtySelectInputFragment = graphql(/* GraphQL */ `
  fragment OneLivSpecialtySelectInputFragment on locus_especialidade {
    id
    especialista
  }
`);

interface OneLivSpecialtySelectInputProps
  extends Omit<SelectInputProps<number>, "items"> {
  data?: FragmentType<typeof OneLivSpecialtySelectInputFragment>[];
}

export const OneLivSpecialtySelectInput = forwardRef<
  HTMLInputElement,
  OneLivSpecialtySelectInputProps
>(
  (
    { data, ...props }: OneLivSpecialtySelectInputProps,
    ref: React.Ref<HTMLInputElement>,
  ): JSX.Element => {
    const specialties = useFragment(OneLivSpecialtySelectInputFragment, data);

    const getItems = (): SelectDrawerItemProps<number>[] => {
      if (!specialties || specialties.length === 0) return [];

      const items: SelectDrawerItemProps<number>[] = specialties.map((specialty) => {
        return {
          label: specialty.especialista ?? "",
          value: specialty.id,
        };
      });

      const noSpecialtySelected: SelectDrawerItemProps<number> = {
        label: "Nenhuma especialidade selecionada",
        value: 0,
      };

      items.unshift(noSpecialtySelected);

      return items;
    };

    const items = getItems();

    return <SelectInput<number> ref={ref} {...props} items={items} searchable />;
  },
);

OneLivSpecialtySelectInput.displayName = "OneLivSpecialtySelectInput";
