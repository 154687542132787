import { graphql } from "@repo/graphql-types";
import type { FragmentType } from "@repo/graphql-types/fragment-masking";
import { useFragment } from "@repo/graphql-types/fragment-masking";
import { Edit1 } from "@repo/icons";
import { CpsAlert } from "corpus";
import { Link } from "@tanstack/react-router";
import { formatWithZonedDate } from "@repo/lib";
import { CardContent, CardRoot } from "@/components/card";
import { getPlanValueWithAllowance } from "@/lib/allowance";
import { getNextPaymentDate } from "@/lib/plans";

export const CurrentPlanInfoFragment = graphql(/* GraphQL */ `
  fragment CurrentPlanInfoFragment on query_root {
    tiposMovimentacoes(
      where: { ativo: { _eq: true }, identificacao: { _eq: "Mensalidade" } }
    ) {
      valorUnitario
    }
    usuariosProfissionaisPlanos(
      where: {
        ativo: { _eq: true }
        plano: {
          tipoMovimentacao: { usuariosMovimentacoesRecorrentes: { ativo: { _eq: true } } }
        }
      }
    ) {
      plano {
        tipoMovimentacao {
          valorUnitario
          usuariosMovimentacoesRecorrentes {
            quantidade
            ativo
            dataInicio
          }
        }
      }
    }
    usuarioPlano: usuariosProfissionaisPlanos(where: { ativo: { _eq: true } }) {
      plano {
        nome
        temFidelidade
        nomeExibicao
        tipoPlano
      }
    }
    usuarioPlanoFuturo: usuariosProfissionaisPlanos(
      where: {
        dataInicio: { _gt: $currentDate }
        ativo: { _eq: false }
        dataFim: { _is_null: true }
      }
    ) {
      plano {
        nomeExibicao
      }
      dataInicio
    }
    usuariosProfissionais {
      diaVencimento
      dataValidacao
    }
  }
`);

interface CurrentPlanInfoProps {
  data: FragmentType<typeof CurrentPlanInfoFragment>;
  showScheduledMigration?: boolean;
}

export const CurrentPlanInfo = ({
  data,
  showScheduledMigration = false,
}: CurrentPlanInfoProps): JSX.Element => {
  const fragmentResult = useFragment(CurrentPlanInfoFragment, data);
  const {
    usuariosProfissionaisPlanos,
    usuariosProfissionais,
    usuarioPlano,
    usuarioPlanoFuturo,
    tiposMovimentacoes,
  } = fragmentResult;

  const hasFuturePlan = usuarioPlanoFuturo.length > 0;
  const futurePlanName = usuarioPlanoFuturo[0]?.plano.nomeExibicao ?? "";
  const futurePlanDate = formatWithZonedDate(
    usuarioPlanoFuturo[0]?.dataInicio ?? new Date(),
    "dd/MM/yyyy",
  );

  const basePrice = tiposMovimentacoes[0].valorUnitario;

  const planoMovimentacoes =
    Array.isArray(usuariosProfissionaisPlanos) && usuariosProfissionaisPlanos.length > 0
      ? usuariosProfissionaisPlanos[0].plano
      : null;

  const plano =
    Array.isArray(usuarioPlano) && usuarioPlano.length > 0 ? usuarioPlano[0].plano : null;

  const planName = plano?.nomeExibicao ?? "";

  const movimentacaoRecorrente = planoMovimentacoes?.tipoMovimentacao ?? {
    valorUnitario: 0,
    usuariosMovimentacoesRecorrentes: [],
  };

  const planPrice = getPlanValueWithAllowance(movimentacaoRecorrente, basePrice);

  const paymentDueDay = usuariosProfissionais[0].diaVencimento ?? 0;
  const validationDay = usuariosProfissionais[0].dataValidacao ?? "";

  const canChangePlan = !planName.toLowerCase().includes("alice");

  return (
    <>
      {plano ? (
        <Link
          to={
            showScheduledMigration || canChangePlan
              ? "/settings/profile/change-plan"
              : undefined
          }
        >
          <CardRoot>
            <CardContent className="flex flex-col justify-center items-start w-full">
              <div className="flex flex-row justify-between items-center w-full">
                {plano.tipoPlano ? (
                  <div className="flex flex-row items-center justify-center text-primary font-medium text-xs bg-neutral-100 py-1 px-2 rounded-xl">
                    {plano.tipoPlano}
                  </div>
                ) : null}
                {showScheduledMigration || canChangePlan ? (
                  <div className="flex flex-row items-center justify-center text-secondary-400 font-medium text-xs">
                    <Edit1 className="fill-secondary-400" />
                    Alterar Plano
                  </div>
                ) : null}
              </div>
              <div className="text-neutral-600 font-medium text-md mt-4">{planName}</div>

              <div className="text-neutral-600 font-semibold text-2xl">
                R$ {planPrice}
                <span className="text-neutral-500 font-normal text-xs">/mês</span>
              </div>

              {showScheduledMigration && hasFuturePlan ? (
                <p className="text-neutral-500 font-normal text-xs mt-2">
                  Seu plano será alterado para o {futurePlanName} em{" "}
                  <span className="font-bold">{futurePlanDate}</span>.
                </p>
              ) : (
                <div className="text-neutral-500 font-normal text-xs mt-2">
                  Sua próxima cobrança será no dia{" "}
                  {getNextPaymentDate(paymentDueDay, validationDay)}
                </div>
              )}
            </CardContent>
          </CardRoot>
        </Link>
      ) : (
        <CpsAlert
          description=""
          title="Você ainda não tem um plano cadastrado. Consulte nosso time de atendimento ao membro"
          type="info"
        />
      )}
    </>
  );
};
