import { CheckCircle } from "@repo/icons";
import { Link } from "@tanstack/react-router";
import {
  MessageDrawerActions,
  MessageDrawerBody,
  MessageDrawerRoot,
  MessageDrawerTitle,
} from "@/components/message-drawer";

interface SuccessChangePlanDrawerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onClickProfile: () => void;
  onClickSchedule: () => void;
  description?: string;
}

export const SuccessChangePlanDrawer = ({
  open,
  setOpen,
  onClickProfile,
  onClickSchedule,
  description = "A partir de agora, você está no novo plano. Verifique sua fatura para eventuais ajustes devido à diferença de valores entre os planos.",
}: SuccessChangePlanDrawerProps): JSX.Element => {
  return (
    <MessageDrawerRoot
      open={open}
      setOpen={setOpen}
      icon={CheckCircle}
      variant="secondary"
    >
      <MessageDrawerTitle>Plano alterado com sucesso</MessageDrawerTitle>
      <MessageDrawerBody>{description}</MessageDrawerBody>
      <MessageDrawerActions>
        <div className="flex flex-col">
          <div className="flex flex-col gap-2 justify-center">
            <Link
              onClick={onClickProfile}
              className="px-4 py-5 bg-secondary-400 text-white text-center text-md font-medium rounded-lg"
            >
              Voltar para meu perfil
            </Link>
            <Link
              onClick={onClickSchedule}
              preload={false}
              className="px-4 py-5 text-neutral-500 text-center text-md font-medium"
            >
              Ir para agenda
            </Link>
          </div>
        </div>
      </MessageDrawerActions>
    </MessageDrawerRoot>
  );
};
