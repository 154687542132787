import { Left } from "@repo/icons";
import {
  createFileRoute,
  useRouteContext,
  useRouter,
  useSearch,
} from "@tanstack/react-router";
import { endOfDay, endOfMonth, isBefore, startOfMonth } from "date-fns";
import { formatIgnoringTimezone } from "@repo/lib";
import { graphql } from "@repo/graphql-types/gql";
import { type FragmentType } from "@repo/graphql-types";
import { CpsSpinner } from "corpus";
import { type DataItemRelatorioIndependente } from "@repo/graphql-types/graphql";
import { format } from "date-fns-tz";
import {
  FIVE_MINUTES_STALE_TIME,
  MONTHS_TO_DISPLAY,
} from "@/lib/constants/dashboards-customer-overview";
import { HeaderRoot, HeaderUpButton, HeaderTitle } from "@/components/header";
import { Page } from "@/components/page";
import { DashboardTimePeriodSelector } from "@/components/dashboard/dashboard-time-period-selector";
import { getMonthDateFromFormattedString } from "@/lib/date";
import { trackEvent } from "@/lib/tracking";
import { DashboardSectionEmptyState } from "@/components/dashboard/dashboard-section-empty-state";
import { useGraphQL } from "@/hooks/use-graphql";
import { AsyncDataWrapper } from "@/components/async-data-wrapper";
import {
  AppointmentDashboardTotalSchedulesPerAppointmentReceivingOptionsSection,
  type AppointmentDashboardTotalSchedulesPerAppointmentReceivingOptionsSectionQueryFragment,
} from "@/components/customer-appointment-overview/appointment-dashboard-total-schedules-per-appointment-receiving-options-section";
import {
  AppointmentDashboardCompletedAppointmentsSection,
  type AppointmentDashboardCompletedAppointmentsSectionQueryFragment,
} from "@/components/customer-appointment-overview/appointment-dashboard-completed-appointments-section";
import {
  AppointmentDashboardNoShowSection,
  type AppointmentDashboardNoShowSectionQueryFragment,
} from "@/components/customer-appointment-overview/appointment-dashboard-no-show-section";
import {
  AppointmentDashboardTotalCancelledSection,
  type AppointmentDashboardTotalCancelledSectionQueryFragment,
} from "@/components/customer-appointment-overview/appointment-dashboard-total-cancelled-section";
import {
  AppointmentDashboardLatePatientsSection,
  type AppointmentDashboardLatePatientsSectionQueryFragment,
} from "@/components/customer-appointment-overview/appointment-dashboard-late-patients-section";
import { CheckOtherDashboardButton } from "@/components/dashboard/check-other-dashboard-button";
import { fillMissingPeriodData } from "@/lib/dashboard";
import { useFormattedMonthsArray } from "@/hooks/use-formatted-month-array-for-overview";
import { useInitialDateForOverviews } from "@/hooks/use-initial-date-for-overviews";
import { AppointmentDashboardShareReport } from "@/components/customer-appointment-overview/appointment-dashboard-share-report";

const CustomerAppointmentOverviewPageQuery = graphql(/* GraphQL */ `
  query CustomerAppointmentOverviewPageQuery($from: String!, $to: String!) {
    analyticsMembroRelatorioIndependente(arg1: { dataInicio: $from, dataFim: $to }) {
      data {
        ... on DataItemRelatorioIndependente {
          ...AppointmentDashboardTotalCancelledSectionQueryFragment
          ...AppointmentDashboardCompletedAppointmentsSectionQueryFragment
          ...AppointmentDashboardNoShowSectionQueryFragment
          ...AppointmentDashboardLatePatientsSectionQueryFragment
          ...AppointmentDashboardTotalSchedulesPerAppointmentReceivingOptionsQueryFragment
        }
      }
    }
  }
`);

export const CustomerAppointmentOverviewPage = (): JSX.Element => {
  const router = useRouter();
  const searchParams: Record<string, string> = useSearch({
    from: "/settings/assist/customer-appointment-overview/",
  });

  const { user } = useRouteContext({ strict: false });

  const { dataFirstMonth, endOfToday } = getDatesToMonthList(user);
  const monthsList = useFormattedMonthsArray({
    fromDate: dataFirstMonth,
    toDate: endOfToday,
  });

  let selectedMonth = searchParams.selectedMonth;
  if (!monthsList.includes(selectedMonth)) {
    selectedMonth = monthsList[monthsList.length - 1];
    searchParams.selectedMonth = selectedMonth;
    void router.navigate({ search: searchParams, replace: true });
  }

  const fromDate = useInitialDateForOverviews(endOfToday, "yyyy-MM-dd");
  const toDate = format(endOfToday, "yyyy-MM-dd", {
    timeZone: "America/Sao_Paulo",
  });

  const initialSearch = useGraphQL(
    CustomerAppointmentOverviewPageQuery,
    {
      from: fromDate,
      to: toDate,
    },
    {
      staleTime: FIVE_MINUTES_STALE_TIME,
    },
  );

  const { data } = initialSearch;
  let periodData = data?.analyticsMembroRelatorioIndependente?.data ?? [];
  if (periodData.length < MONTHS_TO_DISPLAY) {
    periodData = fillMissingPeriodData(
      monthsList,
      periodData as DataItemRelatorioIndependente[],
    ).slice(-MONTHS_TO_DISPLAY);
  }

  const paginatedData = periodData.slice(-MONTHS_TO_DISPLAY);

  const shouldLoadNewMonthData =
    periodData.length > 0 &&
    periodData.every((item) => item && "mes" in item && item.mes !== selectedMonth);

  let formattedFromDateNewMonth = "";
  let formattedToDateNewMonth = "";

  if (shouldLoadNewMonthData) {
    ({ formattedFromDateNewMonth, formattedToDateNewMonth } = getFormattedDatesToQuery(
      monthsList,
      String((periodData[0] as DataItemRelatorioIndependente).mes),
      selectedMonth,
    ));
  }

  const newPeriodDataResult = useGraphQL(
    CustomerAppointmentOverviewPageQuery,
    {
      from: formattedFromDateNewMonth,
      to: formattedToDateNewMonth,
    },
    {
      staleTime: FIVE_MINUTES_STALE_TIME,
      enabled: shouldLoadNewMonthData,
    },
  );

  const { data: newPeriodResult } = newPeriodDataResult;

  const newPeriodQueryData =
    newPeriodResult?.analyticsMembroRelatorioIndependente?.data ?? [];

  if (newPeriodDataResult.isFetched && newPeriodResult) {
    periodData = [...newPeriodQueryData, ...periodData];

    periodData = fillMissingPeriodData(
      monthsList,
      periodData as DataItemRelatorioIndependente[],
    );
  }

  const selectedMonthIndex = monthsList.indexOf(selectedMonth);

  if (
    monthsList.length > MONTHS_TO_DISPLAY &&
    selectedMonthIndex < monthsList.length - MONTHS_TO_DISPLAY &&
    newPeriodDataResult.isFetched
  ) {
    const firstDisplayedMonthIndex = monthsList.indexOf(
      String((paginatedData[0] as DataItemRelatorioIndependente).mes),
    );
    for (let i = firstDisplayedMonthIndex; selectedMonthIndex < i; i--) {
      const elementToPush = periodData.find(
        (item) => item && "mes" in item && item.mes === monthsList[i - 1],
      );

      if (elementToPush) {
        paginatedData.pop();
        paginatedData.unshift(elementToPush);
      }
    }
  }

  const onSelectedMonthChange = (newMonth: string): void => {
    trackEvent("Novo Mês Relatório Selecionado", {
      "Mês selecionado": newMonth,
      Origem: "Livance Assist",
      Relatorio: "Consultas",
    });

    const search = {
      ...searchParams,
    };

    search.selectedMonth = newMonth;
    void router.navigate({ search, replace: true });
  };

  const renderContent = (): JSX.Element => {
    if (!data) {
      return <DashboardSectionEmptyState />;
    }

    if (shouldLoadNewMonthData && !newPeriodDataResult.isFetched) {
      return <CpsSpinner />;
    }

    return (
      <>
        <AppointmentDashboardTotalSchedulesPerAppointmentReceivingOptionsSection
          data={
            paginatedData as FragmentType<
              typeof AppointmentDashboardTotalSchedulesPerAppointmentReceivingOptionsSectionQueryFragment
            >[]
          }
          selectedMonth={selectedMonth}
          onSelectedMonthChange={onSelectedMonthChange}
        />

        <AppointmentDashboardCompletedAppointmentsSection
          data={
            paginatedData as FragmentType<
              typeof AppointmentDashboardCompletedAppointmentsSectionQueryFragment
            >[]
          }
          selectedMonth={selectedMonth}
          onSelectedMonthChange={onSelectedMonthChange}
        />

        <AppointmentDashboardTotalCancelledSection
          data={
            paginatedData as FragmentType<
              typeof AppointmentDashboardTotalCancelledSectionQueryFragment
            >[]
          }
          selectedMonth={selectedMonth}
          onSelectedMonthChange={onSelectedMonthChange}
        />
        <AppointmentDashboardNoShowSection
          data={
            paginatedData as FragmentType<
              typeof AppointmentDashboardNoShowSectionQueryFragment
            >[]
          }
          selectedMonth={selectedMonth}
          onSelectedMonthChange={onSelectedMonthChange}
        />
        <AppointmentDashboardLatePatientsSection
          data={
            paginatedData as FragmentType<
              typeof AppointmentDashboardLatePatientsSectionQueryFragment
            >[]
          }
          selectedMonth={selectedMonth}
          onSelectedMonthChange={onSelectedMonthChange}
        />
        <CheckOtherDashboardButton
          buttonText="Ver relatório de contatos"
          targetRoute="/settings/assist/customer-contact-overview/"
          searchParams={{ selectedMonth }}
        />
      </>
    );
  };

  return (
    <>
      <HeaderRoot>
        <HeaderUpButton icon={Left} align="start" />
        <HeaderTitle title="Relatório de Consultas" align="center" />
        <AppointmentDashboardShareReport earliestDate={dataFirstMonth} />
      </HeaderRoot>
      <DashboardTimePeriodSelector
        selectedMonth={searchParams.selectedMonth}
        monthsList={monthsList}
        onSelectedMonthChange={onSelectedMonthChange}
      />
      <Page>
        <AsyncDataWrapper {...initialSearch}>{renderContent()}</AsyncDataWrapper>
      </Page>
    </>
  );
};

export const Route = createFileRoute("/settings/assist/customer-appointment-overview/")({
  component: CustomerAppointmentOverviewPage,
});

function getFormattedDatesToQuery(
  monthsList: string[],
  earliestLoadedMonth: string,
  selectedMonth: string,
): { formattedFromDateNewMonth: string; formattedToDateNewMonth: string } {
  const mostRecentNotLoadedMonthString =
    monthsList[monthsList.indexOf(earliestLoadedMonth) - 1];

  const mostRecentNotLoadedMonth = getMonthDateFromFormattedString(
    mostRecentNotLoadedMonthString,
  );

  const fromDateMonth = getMonthDateFromFormattedString(selectedMonth);

  const formattedFromDateNewMonth = formatIgnoringTimezone(
    startOfMonth(fromDateMonth),
    "yyyy-MM-dd",
  );

  const formattedToDateNewMonth = formatIgnoringTimezone(
    endOfMonth(mostRecentNotLoadedMonth),
    "yyyy-MM-dd",
  );

  return {
    formattedFromDateNewMonth,
    formattedToDateNewMonth,
  };
}

const getDatesToMonthList = (user: {
  dataValidacao?: Date;
}): { dataFirstMonth: Date; endOfToday: Date } => {
  const dataCollectionStart = new Date(2024, 0);
  const memberFirstMonth = user.dataValidacao ?? dataCollectionStart;

  const dataFirstMonth = isBefore(memberFirstMonth, dataCollectionStart)
    ? dataCollectionStart
    : memberFirstMonth;

  const endOfToday = endOfDay(new Date());
  return { dataFirstMonth, endOfToday };
};
