import { StarCheckMark, Right } from "@repo/icons";
import { type LinkOptions } from "@tanstack/react-router";
import {
  BannerMenuItemContent,
  BannerMenuItemContentDescription,
  BannerMenuItemContentTitle,
  BannerMenuItemLinkRoot,
} from "@/components/banner-menu-item/banner-menu-item.tsx";
import { trackEvent } from "@/lib/tracking";

export interface AssistProBannerProps {
  title: string;
  description?: string;
  to: LinkOptions["to"];
  hasIcon?: boolean; //TODO: Remover icone após remoção da feature flag de habilita-fluxo-com-numero-de-telefone-no-assist
}

export const AssistProBanner = ({
  title,
  description,
  to,
  hasIcon,
}: AssistProBannerProps): JSX.Element => {
  const onBannerClick = (): void => {
    trackEvent("Banner Assist Clicado", {
      titulo: title,
      destino: to,
    });
  };

  return (
    <BannerMenuItemLinkRoot to={to} onClick={onBannerClick}>
      {hasIcon ? (
        <StarCheckMark size={32} className="fill-secondary-400 shrink-0" />
      ) : (
        hasIcon
      )}
      <BannerMenuItemContent>
        <BannerMenuItemContentTitle>{title}</BannerMenuItemContentTitle>
        {description ? (
          <BannerMenuItemContentDescription>
            {description}
          </BannerMenuItemContentDescription>
        ) : null}
      </BannerMenuItemContent>
      <Right size={16} className="shrink-0" />
    </BannerMenuItemLinkRoot>
  );
};
