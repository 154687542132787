import { clsx } from "clsx";
import { SVGIconProps } from "../interface";
const iconSize: Record<string, string> = {
  8: "h-2",
  12: "h-3",
  16: "h-4",
  24: "h-6",
  32: "h-8",
  40: "h-10",
  48: "h-12",
  56: "h-14",
  80: "h-20",
  124: "h-30",
};
const SvgMoney = ({ title, titleId, size = 16, className }: SVGIconProps) => {
  const sizeClass = iconSize[size];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="#000"
      viewBox="0 0 16 16"
      width={size}
      height={size}
      className={clsx(sizeClass, className)}
      aria-labelledby={titleId}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.333 12.667H2.666a1.333 1.333 0 0 1-1.333-1.333V4.667c0-.737.597-1.333 1.333-1.333h10.667c.737 0 1.333.596 1.333 1.333v6.667c0 .736-.596 1.333-1.333 1.333"
        clipRule="evenodd"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.178 6.822a1.667 1.667 0 1 1-2.357 2.357 1.667 1.667 0 0 1 2.357-2.357M4 6.334v3.333M12 6.334v3.333"
      />
    </svg>
  );
};
export default SvgMoney;
